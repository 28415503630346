import React, {useContext, useEffect, useState} from 'react';
import {Redirect, Route as RouterRoute} from 'react-router-dom';
import PropTypes from 'prop-types';
import {api} from 'api';
import config from 'config';
import {AutoLogoutModal} from 'macros';
import {useB2V, useMarel, useUser} from 'hooks';
import {ProjectionsContext} from 'store/Projections';

import {useTheme} from '@material-ui/styles';

let logoutTimeout;

function Route(props) {
    const {
        layout: Layout,
        component: Component,
        authenticated,
        breadcrumb,
        background,
        disabled,
        disableHeader,
        disableFooter,
        ...rest
    } = props;
    const theme = useTheme();

    const {user, setUser} = useUser();
    const {setProjections} = useContext(ProjectionsContext);
    const {setB2V} = useB2V();
    const {setMarel} = useMarel();

    const [autoLogout, setAutoLogout] = useState(false);
    const logout = () => {
        setAutoLogout(true);

        setUser({
            ...user,
            logged: false
        });
    };

    // On a private route (i.e `authenticated`), user with individual scope (that excludes test session users)
    // refresh api_client_token each time view changes (for 20 minutes) and refresh auto logout to be in 20 minutes
    const setLogoutTimeout = (delayInMinutes) => {
        clearTimeout(logoutTimeout);
        logoutTimeout = setTimeout(() => {
            logout();
        }, delayInMinutes * 60 * 1000);
    };
    useEffect(() => {
        if (authenticated && !user.persona && user.scopes && user.scopes.includes('axa')) {
            api.post(`/login/api-client/refresh-token/${user.origin}`);
            setLogoutTimeout(21);
        }

        if (user.logged === false) {
            api.post(`/login/api-client/revoke-token/${user.origin}`).catch(() => {
                setUser({
                    origin: user.origin,
                    logged: false
                });
                setProjections({});
                setMarel({});
                setB2V(null);
                sessionStorage.clear();
                localStorage.clear();

                if (['individual', 'individual_transmission'].includes(user.origin))
                    window.location.href = config.AXA_CLIENT_SPACE_URL[process.env.REACT_APP_ENVIRONMENT];
            });
        }

        // eslint-disable-next-line
    }, [user.scopes, user.logged, authenticated, Component, user.actions]);

    if (disabled) return null;

    return (
        <RouterRoute
            {...rest}
            render={(matchProps) =>
                authenticated ? (
                    user.id ? (
                        <Layout
                            breadcrumb={breadcrumb}
                            background={background || theme.palette.background.main}
                            disableHeader={disableHeader}
                            disableFooter={disableFooter}
                        >
                            <Component {...matchProps} />
                            <AutoLogoutModal open={autoLogout && authenticated} />
                        </Layout>
                    ) : (
                        <Redirect to="/" />
                    )
                ) : (
                    <Layout
                        breadcrumb={breadcrumb}
                        background={background || theme.palette.background.main}
                        disableHeader={disableHeader}
                        disableFooter={disableFooter}
                    >
                        <Component {...matchProps} />
                    </Layout>
                )
            }
        />
    );
}

Route.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    authenticated: PropTypes.bool,
    breadcrumb: PropTypes.node,
    background: PropTypes.string,
    disableHeader: PropTypes.bool,
    disableFooter: PropTypes.bool,
    disabled: PropTypes.bool
};

export default Route;
