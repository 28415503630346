import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationsEN from './locales/en.json';
import translationsFR from './locales/fr.json';

const resources = {
    fr: {
        translation: translationsFR
    },
    en: {
        translation: translationsEN
    }
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: 'fr',
    lng: 'fr',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
