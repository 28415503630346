import React from 'react';
import {Icon, Inputs, Typography} from 'components';
import {amount} from 'utils';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {useMediaQuery, useTheme} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {},
    columnItems: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:not(:last-child):not(.flexGrow)': {
            marginRight: 20
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            '& > *': {
                width: '100%'
            },
            '.flexGrow': {
                display: 'none !important'
            }
        },
        '&>div>span, &>div>p': {
            margin: '0 !important'
        }
    },
    rowItems: {
        display: 'flex',
        flexDirection: 'column'
    },
    info: {
        border: `1px solid ${theme.palette.blue}`,
        padding: theme.spacing(1)
    },
    button: {
        cursor: 'pointer',
        border: 'none',
        padding: '2px'
    },
    icon: {
        verticalAlign: 'middle',
        padding: '0px 0px 0px 10px'
    },
    percentInput: {
        maxWidth: 85,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        }
    },
    removeButton: {
        [theme.breakpoints.down('sm')]: {
            borderBottom: '1px solid grey'
        }
    },
    inputAmount: {
        maxWidth: 150
    }
}));

const RemovePatrimony = ({...rest}) => {
    const classes = useStyles();

    return (
        <button
            className={classes.button}
            {...rest}
        >
            <Typography
                color="redLight"
                gutterBottom={false}
                className={classes.removeButton}
            >
                Supprimer
                <Icon
                    name="axa-icons-remove-circle"
                    className={classes.icon}
                />
            </Typography>
        </button>
    );
};

const updateFormStateParent = ({formStateParent, setFormStateParent, index, name, value}) => {
    let otherGoods = formStateParent.values.other_goods;
    otherGoods.splice(index, 1, {
        ...otherGoods[index],
        [name]: value
    });
    setFormStateParent({
        ...formStateParent,
        values: {
            ...formStateParent.values,
            other_goods: otherGoods
        }
    });
};

function Patrimony({index, formStateParent, setFormStateParent}) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={classes.columnItems}>
            <div className={classes.columnItems}>
                <Inputs.Select
                    options={[
                        {
                            label: 'Bien immobilier',
                            value: 'immovable'
                        },
                        {
                            label: 'Bien financier (hors Ass vie)',
                            value: 'financial'
                        },
                        {
                            label: 'Autres biens',
                            value: 'others'
                        }
                    ]}
                    value={formStateParent.values.other_goods[index].type}
                    onInput={(event) => {
                        updateFormStateParent({
                            formStateParent,
                            setFormStateParent,
                            index,
                            name: 'type',
                            value: event.target.value
                        });
                    }}
                />
                <div className="flexGrow" />
                <div className={classes.columnItems}>
                    <Inputs.Number
                        className={clsx(!isMobile && classes.inputAmount)}
                        format="money-2"
                        adornment="€"
                        right
                        value={formStateParent.values.other_goods[index].amount}
                        onInput={(event) => {
                            updateFormStateParent({
                                formStateParent,
                                setFormStateParent,
                                index,
                                name: 'amount',
                                value: amount.parse(event.target.value)
                            });
                        }}
                    />
                    <Inputs.Number
                        className={classes.percentInput}
                        format="percentage"
                        adornment="%"
                        right
                        value={formStateParent.values.other_goods[index].rate}
                        onInput={(event) => {
                            let rate = parseInt(amount.parse(event.target.value));
                            if (rate < 0) rate = '0';
                            if (rate > 100) rate = '100';
                            rate = rate.toString();
                            event.target.value = rate;
                            updateFormStateParent({
                                formStateParent,
                                setFormStateParent,
                                index,
                                name: 'rate',
                                value: rate.toString()
                            });
                        }}
                    />
                </div>
            </div>
            {formStateParent.values?.other_goods?.length > 1 && (
                <RemovePatrimony
                    key={`remove-patrimony`}
                    onClick={(e) => {
                        e.preventDefault();
                        let otherGoods = formStateParent.values.other_goods;
                        otherGoods.splice(index, 1);
                        setFormStateParent({
                            ...formStateParent,
                            values: {
                                ...formStateParent.values,
                                other_goods: otherGoods
                            }
                        });
                    }}
                />
            )}
        </div>
    );
}

export default Patrimony;
