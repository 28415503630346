import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Icon, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        alignItems: 'center',
        height: 45,
        padding: theme.spacing(0, 2),
        background: theme.palette.white,
        borderBottom: `solid 1px ${theme.palette.grayLight}`
    }
}));

function NavButton({url, label, semibold, external, ...rest}) {
    const classes = useStyles();

    return (
        <Link
            className={classes.item}
            aria-current={window.location.pathname === url}
            to={!external ? url || window.location.pathname : ''}
            onClick={() => {
                if (external) window.location.href = url;
            }}
            {...rest}
        >
            {typeof rest.icon === 'string' ? (
                <Icon
                    name={rest.icon}
                    color="blue"
                />
            ) : (
                rest.icon
            )}
            <Typography
                gutterBottom={false}
                size={16}
                semibold={semibold || window.location.pathname === url}
                color={'blue'}
            >
                {label}
            </Typography>
        </Link>
    );
}

NavButton.propTypes = {
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    semibold: PropTypes.bool
};

export default NavButton;
