import 'moment/locale/fr';
import {amount} from 'utils';

const getAllocated = (account, distribution, column) => {
    if (!distribution) return 0;

    return Object.entries(distribution)
        .filter(([key]) => key.startsWith(column) && key.includes(account.policy_id))
        .map(([, value]) => amount.parse(value))
        .reduce((acc, val) => acc + val, 0);
};

const getContractsDistribution = (accounts, distribution) =>
    accounts.map((account) => {
        const capital = getAllocated(account, distribution, 'capital');
        const annuity = getAllocated(account, distribution, 'annuity');

        return {
            policy_id: account.policy_id,
            capital_total: capital,
            annuity_total: annuity,
            rest:
                account.balance -
                getAllocated(account, distribution, 'capital') -
                getAllocated(account, distribution, 'annuity')
        };
    });

function withdrawRetirementProperties(payload, accounts) {
    return {
        user: payload.user,
        payee: payload.payee,
        beneficiaries: payload.beneficiaries,
        contracts: getContractsDistribution(accounts, payload.distribution),
        annuity_type: payload.annuity_type,
        reversion_percentage: payload.reversion_percentage,
        warranty_period: payload.warranty_period
    };
}

export default withdrawRetirementProperties;
