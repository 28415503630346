import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 2,
            background: theme.palette.blue
        }
    },
    selected: {
        borderBottom: `solid 0px transparent`,
        '&:before': {
            height: '0 !important'
        }
    }
}));

function NavButton({url, label}) {
    const classes = useStyles();

    return (
        <Link
            className={clsx({
                [classes.button]: true,
                [classes.selected]: window.location.pathname === url
            })}
            to={url}
            aria-current={window.location.pathname === url}
        >
            <Typography
                size={18}
                semibold
                gutterBottom={false}
                color={'blue'}
                caps
            >
                {label}
            </Typography>
        </Link>
    );
}

NavButton.propTypes = {
    label: PropTypes.string.isRequired,
    url: PropTypes.string
};

export default NavButton;
