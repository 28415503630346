import {
    CaptchaGoogle,
    Checkbox,
    Datepicker,
    File,
    FileDrop,
    FileProgressBar,
    Number,
    Radio,
    RadioButton,
    Select,
    Slider,
    Switch,
    Text,
    Textarea
} from './components';

export default {
    Checkbox,
    Datepicker,
    File,
    FileDrop,
    FileProgressBar,
    CaptchaGoogle,
    Number,
    Radio,
    RadioButton,
    Select,
    Slider,
    Switch,
    Text,
    Textarea
};
