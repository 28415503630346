import React, {useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Typography} from 'components';
import {useForm} from 'hooks';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 0)
    },
    input: {
        width: '100%',
        height: 36,
        padding: 14,
        background: '#ededed',
        border: 'none',
        borderRadius: '0.25em',
        outline: 'none',
        '&:focus': {
            border: `solid 1px ${theme.palette.blue} !important`
        }
    }
}));

function Textarea(props) {
    const {name, className, label, labelColor, defaultValue, sup, ...rest} = props;
    const classes = useStyles();
    const {formState, setFormState} = useForm();

    useEffect(() => {
        if (defaultValue) {
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    [name]: defaultValue
                }
            });
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    const handleChange = (event) => {
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [name]: true
            }
        });
    };

    const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

    return (
        <div className={classes.root}>
            <Typography
                color={labelColor}
                variant="caption"
            >
                {label}
                {sup && <sup>{sup}</sup>}
            </Typography>
            <div className="relative">
                <textarea
                    className={clsx({
                        [classes.input]: true,
                        [className]: className
                    })}
                    name={name}
                    onChange={handleChange}
                    spellCheck={false}
                    placeholder=" "
                    value={formState.values[name] || defaultValue || ''}
                    {...rest}
                />
            </div>
            <Typography
                className="error-message"
                color="redLight"
            >
                {hasError(name) ? formState.errors[name][0] : null}
            </Typography>
        </div>
    );
}

Textarea.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    labelColor: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sup: PropTypes.string
};

export default Textarea;
