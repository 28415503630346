import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Grid, Typography} from 'components';
import {Menu, Tab} from './components';
import {modal} from 'utils';

import {Grow, Modal as MuiModal} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify(window);

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
        position: 'absolute',
        top: 250,
        right: 70,
        width: '100%',
        maxWidth: 500,
        background: theme.palette.white,
        boxShadow: theme.shadows[5],
        [theme.breakpoints.down('xs')]: {
            top: '150px !important',
            right: '10px !important'
        }
    },
    content: {
        padding: '15px 20px 0px !important'
    },
    container: {
        overflow: 'auto',
        minHeight: 180,
        maxHeight: 260,
        padding: theme.spacing(0.5, 3),
        lineHeight: '14px',
        textAlign: 'justify'
    }
}));

function LexiconBox({config, hash}) {
    const classes = useStyles();
    const [tabSelected, setTabSelected] = useState(config[0]);
    const [itemSelected, setItemSelected] = useState(config[0].items[0]);

    return (
        <MuiModal
            open={modal.isOpen(hash)}
            onClose={() => modal.close(hash)}
            disablePortal
            closeAfterTransition
        >
            <Grow
                in={modal.isOpen(hash)}
                style={{transformOrigin: 'bottom right'}}
                timeout={300}
            >
                <div>
                    <Grid
                        className={clsx({
                            [classes.root]: true,
                            [classes.show]: modal.isOpen(hash)
                        })}
                        container
                        spacing={2}
                    >
                        {config.map((tab) => (
                            <Tab
                                key={`tab-${tab.name}`}
                                tab={tab}
                                tabSelected={tabSelected}
                                setTabSelected={setTabSelected}
                                setItemSelected={setItemSelected}
                            />
                        ))}
                        <div className={classes.content}>
                            {tabSelected.items.map((item) => (
                                <Menu
                                    key={`menu-${item.name}`}
                                    item={item}
                                    itemSelected={itemSelected}
                                    setItemSelected={setItemSelected}
                                />
                            ))}
                        </div>
                        <div className={classes.container}>
                            <Typography semibold>{itemSelected.title}</Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(itemSelected.text)
                                }}
                            />
                        </div>
                    </Grid>
                </div>
            </Grow>
        </MuiModal>
    );
}

LexiconBox.propTypes = {
    config: PropTypes.array.isRequired,
    hash: PropTypes.string.isRequired
};

export default LexiconBox;
