import React from 'react';
import {Grid, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 320,
        padding: theme.spacing(2)
    }
}));

function StepResultOpinion() {
    const classes = useStyles();

    return (
        <Grid
            item
            xs={12}
            className={classes.root}
        >
            <Typography>Merci</Typography>
        </Grid>
    );
}

export default StepResultOpinion;
