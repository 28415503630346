const config = {
    offensive: 'Offensif',
    balanced: 'Équilibré',
    defensive: 'Défensif',
    security: 'Sécuritaire',
};

const translateProfileRisk = profileRisk => {
    if (Object.keys(config).includes(profileRisk))
        return config[profileRisk];
    return profileRisk;
};

export default translateProfileRisk;