import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import {useScript, useUser} from 'hooks';
import {browser} from 'utils';

export const TagsHelmet = () => (
    <Helmet>
        <script>
            {`
                tc_vars = []
            `}
        </script>
        <script
            src="https://cdn.tagcommander.com/2406/tc_AXAUsineasites_1.js"
            async
        />
    </Helmet>
);

const APPLICATIONS = {
    collective: 'easyprojets',
    individual: 'ma_retraite_360',
    individual_transmission: 'ma_transmission_360'
};

const getMainCompany = (user) => {
    if (!user || !user?.scopes) return null;

    const scopes = user?.scopes;
    if (scopes && !['ais', 's2e', 'axa'].some((element) => scopes?.includes(element))) {
        return null;
    }

    if (user?.origin !== 'collective') return user.axa_profile?.company_name || null;
    if (user?.origin === 'collective') {
        if (scopes.includes('s2e') && user.s2e_profile?.companies?.length > 0) {
            return user.s2e_profile.companies?.find((company) => company.main_company)?.name;
        } else if (scopes.includes('ais') && user.ais_profile?.ais_account_details?.length > 0) {
            return user.ais_profile?.ais_account_details?.find((account) => account.status === 'ENCOURS')?.company_name;
        }
    }
    return null;
};

export const sendTag = (user, page, optionalsDatalayer, isCta) => {
    if (window.location.pathname === '/login') return;
    const application = APPLICATIONS[user.origin] || 'easyprojets';
    page = page ? page?.toLowerCase()?.replaceAll(' ', '_') : '';

    const mainCompany = getMainCompany(user);
    const company = mainCompany ? {entreprise: mainCompany} : {};
    const axa_advisor = user.axa_advisor?.type ? {reseau_distribution: user.axa_advisor?.type || null} : {};
    const pageUrl = window.location.href.split(window.location.origin)[1].split('?')[0];
    const idClient = {}
    if(user.origin === "collective" ) {
        idClient.ID_client = user.oauth_profile?.sub
    } else if (user.origin === "individual" || user.origin === "individual_transmission" ) {
        idClient.ID_client = user.axa_profile?.customer_id
    }

    try {
        let options = {
            detail: {
                Datalayer: {
                    origine: user.origin || 'public',
                    environnement: process.env.REACT_APP_ENVIRONMENT,
                    nom_site: 'easyprojets',
                    uri: window.location.href.replace('#', '/'),
                    xiti_xtpage: page
                        ? `${application}::${page}`
                        : `${application}${
                            pageUrl !== '/' ? pageUrl.replace('#', '/').replace('/', '::') : '::hp::accueil'
                        }`,
                    page_url: pageUrl,
                    ...idClient,
                    ...company,
                    ...axa_advisor,
                    ...optionalsDatalayer
                },
                Event: !isCta ? 'N' : 'C',
                Type: !isCta ? 'V' : 'A',
                Label: !isCta ? 'xiti_xtpage' : page
            }
        };

        let tagEvent;
        if (browser.isIE11()) {
            let event = document.createEvent('CustomEvent');
            tagEvent = event.initCustomEvent('sendTag', false, false, options);
        } else {
            tagEvent = new CustomEvent('sendTag', options);
        }

        if (tagEvent) window.dispatchEvent(tagEvent);
    } catch (error) {
        console.error(error);
    }
};

const TagsProvider = ({children}) => {
    const {user} = useUser();

    const location = useLocation();

    const status = useScript(
        process.env.REACT_APP_ENVIRONMENT === 'production'
            ? 'https://cdn.tagcommander.com/2406/tc_AXAUsineasites_5.js'
            : 'https://cdn.tagcommander.com/2406/uat/tc_AXAUsineasites_5.js'
    );

    useEffect(() => {
        if (status === 'ready') {
            sendTag(user);
        }

        // eslint-disable-next-line
    }, [status, user.origin, user.axa_advisor, location]);

    return <>{children}</>;
};

export default TagsProvider;
