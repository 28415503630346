import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {LinearProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        height: 28
    },
    absolute: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    loader: {
        height: '100%'
    }
}));

function Bar(props) {
    const {children, className} = props;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LinearProgress className={clsx(classes.loader, className)} />
            <div className={classes.absolute}>{children}</div>
        </div>
    );
}

Bar.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default Bar;
