import React from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

import {Box, Divider, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {Buttons, Icon, Typography} from 'components';
import {amount} from 'utils';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2.5, 0)
    },
    title: {
        lineHeight: '0.85em',
        marginBottom: theme.spacing(1)
    },
    wrapper: {
        display: 'flex',
        border: `1px solid ${theme.palette.grayLight}`,
        borderRadius: 8,
        padding: theme.spacing(0, 1.5),
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center'
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: theme.spacing(1)
        }
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        '& > i:before': {
            margin: theme.spacing(2, 0),
            background: theme.palette.blue
        }
    },
    icon: {
        marginRight: theme.spacing(1),
        '&:before': {
            padding: theme.spacing(1),
            background: theme.palette.blue
        }
    },
    button: {
        marginBottom: theme.spacing(0.5),
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'unset'
        }
    },
    amount: {
        whiteSpace: 'nowrap'
    }
}));

const OptionBulletinChoiceMobile = ({option_bulletin}) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={clsx(classes.root, classes.wrapper)}>
            <div className={classes.item}>
                <Icon
                    className={classes.icon}
                    name='axa-icons-handshake'
                    circled
                    color="white"
                    size={24}
                />
                <Box
                    dislay="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography
                        variant="h5"
                        semibold
                        gutterBottom={false}
                        noWrap
                    >
                        {option_bulletin.type === 'PARTICIPATION' && 'Ma prime de participation'}
                        {option_bulletin.type === 'INTERESSEMENT' && "Ma prime d'intéressement"}
                    </Typography>

                    <Typography>
                        (Exercice {moment(option_bulletin.start_date).format('DD/MM/YYYY')} -{' '}
                        {moment(option_bulletin.end_date).format('DD/MM/YYYY')})
                    </Typography>
                </Box>
            </div>

            <Box mb={1.5}>
                <Divider />
            </Box>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography
                    color="grayDark"
                    gutterBottom={false}
                >
                    À saisir avant le{' '}
                    <Typography
                        component="span"
                        semibold
                        gutterBottom={false}
                    >
                        {moment(option_bulletin.deadline_date).format('DD/MM/YYYY')}
                    </Typography>
                </Typography>

                <Typography
                    className={classes.amount}
                    variant="h3max"
                    color="blue"
                    size={22}
                    semibold
                >
                    {amount.format(option_bulletin.amount)}
                </Typography>
            </Box>

            <Buttons.Default
                className={classes.button}
                label="Commencer"
                color="orangeRed"
                center
                next
                onClick={() => history.push(`/transactional/bulletin-option/${option_bulletin.id}`)}
            />
        </div>
    );
};

const OptionBulletinChoiceDesktop = ({option_bulletin}) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.root}>
            <Typography
                className={classes.title}
                variant="h6"
            >
                Montant total de votre entreprise{' '}
                <Typography
                    component="span"
                    color="blue"
                    semibold
                >
                    {amount.format(option_bulletin.company_amount)}
                </Typography>
            </Typography>

            <div className={classes.wrapper}>
                <Icon
                    className={classes.icon}
                    name="axa-icons-handshake"
                    circled
                    color="white"
                    size={20}
                />

                <Box>
                    <Typography
                        variant="h5"
                        semibold
                        gutterBottom={false}
                        noWrap
                    >
                        {option_bulletin.type === 'PARTICIPATION' && 'Ma prime de participation'}
                        {option_bulletin.type === 'INTERESSEMENT' && "Ma prime d'intéressement"}
                    </Typography>
                    <Typography
                        gutterBottom={false}
                        noWrap
                    >
                        <Typography
                            component="span"
                            size={22}
                            color="blue"
                            bold
                        >
                            {amount.format(option_bulletin.amount)}{' '}
                        </Typography>
                        (Exercice {moment(option_bulletin.start_date).format('DD/MM/YYYY')} -{' '}
                        {moment(option_bulletin.end_date).format('DD/MM/YYYY')})
                    </Typography>
                </Box>

                <Box flexGrow={1} />

                <Box>
                    <Buttons.Default
                        className={classes.button}
                        label="Commencer"
                        color="orangeRed"
                        next
                        onClick={() => history.push(`/transactional/bulletin-option/${option_bulletin.id}`)}
                    />
                    <Typography
                        color="grayDark"
                        right
                    >
                        À saisir avant le{' '}
                        <Typography
                            component="span"
                            semibold
                        >
                            {moment(option_bulletin.deadline_date).format('DD/MM/YYYY')}
                        </Typography>
                    </Typography>
                </Box>
            </div>
        </div>
    );
};

const OptionBulletinChoice = ({option_bulletin}) => (
    <>
        <Hidden xsDown>
            <OptionBulletinChoiceDesktop option_bulletin={option_bulletin} />
        </Hidden>
        <Hidden smUp>
            <OptionBulletinChoiceMobile option_bulletin={option_bulletin} />
        </Hidden>
    </>
);

export default OptionBulletinChoice;
