import {Box} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        height: 78,
        marginTop: 20,
        padding: 16,
        borderRadius: 8,
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 143, 0.15)',
        borderLeft: `solid 4px #000`,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 0,
        [theme.breakpoints.down('sm')]: {
            height: 150
        },
        '&:hover': {
            borderLeft: `solid 4px #000`,
            border: `solid 2px ${theme.palette.blue}`,
            borderBottomLeftRadius: 4
        }
    },
    blue: {
        borderTop: `1px solid ${theme.palette.mercury} !important`,
        borderBottom: `1px solid ${theme.palette.mercury} !important`,
        borderRight: `1px solid ${theme.palette.mercury} !important`,
        borderLeft: `4px solid ${theme.palette.blue} ! important`,
        transition: 'box-shadow 0.3ms',
        '&:hover': {
            boxShadow: 'none'
        }
    }
}));

const Card = (props) => {
    const classes = useStyles();
    const {variant, children} = props;

    return <Box className={clsx(classes.root, variant && classes[variant])}>{children}</Box>;
};

export default Card;
