import React, {lazy, Suspense, useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {Redirect, Switch, withRouter} from 'react-router-dom';

import {Breadcrumb, Loaders, Route} from 'components';
import {Collective, Individual, Kerialis, None, Public} from 'layouts';
import {useUser} from 'hooks';

import {useTheme} from '@material-ui/styles';
import {profileURL} from './utils';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const MaintenancePage = lazy(() => import('./views/Maintenance/Maintenance'));
const Dev_Panel = lazy(() => ENVIRONMENT !== 'production' && import('./views/_Developer/Panel'));
const Dev_Framework = lazy(() => ENVIRONMENT !== 'production' && import('./views/_Developer/Framework'));
const Dev_PDFRender = lazy(
    () => !['preproduction', 'production'].includes(ENVIRONMENT) && import('./views/_Developer/PDFRender')
);
const Dev_Contexts = lazy(() => !['production'].includes(ENVIRONMENT) && import('./views/_Developer/Contexts'));
const Dev_Demo = lazy(() => !['production'].includes(ENVIRONMENT) && import('./views/_Developer/Demo'));
const Dev_Version = lazy(() => import('./views/_Developer/Version'));

const Public_Home = lazy(() => import('./views/Public/Home'));
const Public_Articles = lazy(() => import('./views/Public/Articles'));
const Public_LegalNotice = lazy(() => import('./views/Public/LegalNotice'));
const Public_Contact = lazy(() => import('./views/Public/Contact'));
const Public_PersonalData = lazy(() => import('./views/Public/PersonalData'));
const Public_PersonalDataSavings = lazy(() => import('./views/Public/PersonalData/PersonalDataSavings'));
const Public_Attachment = lazy(() => import('./views/Public/Attachment'));
const Public_SimulatorVV = lazy(() => import('./views/Public/SimulatorVV'));

const Login_OAuthRedirect = lazy(() => import('./views/Login/OAuthRedirect'));
const Login_Presentation = lazy(() => import('./views/Login/Presentation'));
const Login_ChangePassword = lazy(() => import('./views/Login/ChangePassword'));
const Login_Logout = lazy(() => import('./views/Login/Logout'));
const Login_Error = lazy(() => import('./views/Login/Error'));
const Login_RoboAdvisorAdmin = lazy(() => import('./views/Login/RoboAdvisorOAuthCallback'));

const Connected_Main = lazy(() => import('./views/Connected/Main'));
const Connected_Detail = lazy(() => import('./views/Connected/Detail/SavingsDetail'));
const Connected_SavingDetail = lazy(() => import('./views/Connected/SavingDetail'));

const Connected_Accounts = lazy(() => import('./views/Connected/Accounts'));
const Connected_Aggregation = lazy(() => import('./views/Connected/Aggregation'));
const Connected_LifeProjectInput = lazy(() => import('./views/Connected/LifeProjectInput'));
const Connected_LifeProject = lazy(() => import('./views/Connected/LifeProject'));
const Connected_LifeProjection = lazy(() => import('./views/Connected/LifeProjection'));
const Connected_SimulatorMRM = lazy(() => import('./views/Connected/SimulatorMRM'));
const Connected_SimulatorVV = lazy(() => import('./views/Connected/SimulatorVV'));
const Connected_Withdraw = lazy(() => import('./views/Connected/Withdraw'));
const Connected_VoluntaryPayment = lazy(() =>
    import('./views/Connected/Transactional/VoluntaryPayment/VoluntaryPayment')
);
const Connected_TransactionalRedirect = lazy(() =>
    import('./views/Connected/Transactional/Redirect/TransactionalRedirect')
);
const Connected_WithdrawAIS = lazy(() => import('./views/Connected/WithdrawAIS'));
const Connected_WithdrawFormAIS = lazy(() => import('./views/Connected/WithdrawForm'));
const Connected_TransactionalArbitration = lazy(() => import('./views/Connected/Transactional/Arbitration'));
const Connected_TransactionalOptionBulletin = lazy(() => import('./views/Connected/Transactional/OptionBulletin'));
const Connected_TransactionalOperation = lazy(() => import('./views/Connected/Transactional/Operation'));
const Connected_Transfer = lazy(() => import('./views/Connected/Transfer'));
const Connected_TransferForm = lazy(() => import('./views/Connected/TransferForms'));
const Connected_Operations = lazy(() => import('./views/Connected/Operations'));
const Connected_OperationDetail = lazy(() => import('./views/Connected/Operations/components/OperationDetail'));
const Connected_RoboAdvisor = lazy(() => import('./views/Connected/RoboAdvisor'));
const Connected_RoboAdvisorAdmin = lazy(() => import('./views/Connected/RoboAdvisorAdmin'));
const Connected_Documents = lazy(() => import('./views/Connected/Documents'));
const Connected_Messages = lazy(() => import('./views/Connected/Messages'));
const Connected_Profile = lazy(() => import('./views/Connected/Profile/Profile'));
const Connected_Filib = lazy(() => import('./views/Connected/Filib'));
const Connected_Attachment = lazy(() => import('./views/Connected/Attachment'));
const Connected_SiteMap = lazy(() => import('./views/Connected/SiteMap'));

const Kerialis_Login = lazy(() => import('./views/Kerialis/Login'));
const Kerialis_Main = lazy(() => import('./views/Kerialis/Main'));
const Kerialis_CGU = lazy(() => import('./views/Kerialis/CGU'));
const Kerialis_Contacts = lazy(() => import('./views/Kerialis/Contacts'));
const Kerialis_Legal = lazy(() => import('./views/Kerialis/Legal'));

const Misc_ExternalCompany = lazy(() => ENVIRONMENT !== 'production' && import('./views/Misc/ExternalCompany'));

export const layouts = {
    collective: Collective,
    individual: Individual,
    individual_transmission: Individual,
    none: None
};

export const FallBack = ({layout: Layout}) => {
    if (
        [
            '/login',
            '/authcallback',
            '/logout/easyprojets',
            '/presentation',
            '/callback/easyprojets',
            '/callback/maretraite360',
            '/callback/matransmission360'
        ].includes(window.location.pathname)
    )
        return (
            <Loaders.Circular
                open
                transparent
            />
        );

    if (['/kerialis', '/kerialis/init-session'].includes(window.location.pathname))
        return (
            <Kerialis>
                <Loaders.Circular
                    open
                    transparent
                />
                ;
            </Kerialis>
        );

    return (
        <Layout>
            <Loaders.Circular
                open
                transparent
                disableShrink
            />
        </Layout>
    );
};

function isMaintenancePeriod(dateRanges) {
    const currentDate = new Date();

    for (const range of dateRanges) {
        const startDate = new Date(range.start);
        const endDate = new Date(range.end);

        if (currentDate >= startDate && currentDate <= endDate) {
            return true;
        }
    }

    return false;
}

function Routes({history}) {
    const theme = useTheme();
    const {user} = useUser();

    const layout = useMemo(
        () => (Object.keys(layouts).includes(user.origin) ? layouts[user.origin] : Public),
        [user.origin]
    );

    useEffect(() => {
        const unListen = history.listen(() => {
            if (!window.location.hash) window.scrollTo(0, 0);
        });
        return () => {
            unListen();
        };

        // eslint-disable-next-line
    }, []);

    const breadcrumb = (parentLabel, parentHref, childLabel = '', childHref = '', subChildLabel = '') => (
        <Breadcrumb
            parentLabel={parentLabel}
            parentHref={parentHref}
            childLabel={childLabel}
            childHref={childHref}
            subChildLabel={subChildLabel}
        />
    );
    const maintenancePeriod = [
        {
            start: '2023-12-06T21:00:00',
            end: '2023-12-07T00:05:00'
        },
        {
            start: '2023-12-07T01:30:00',
            end: '2023-12-07T08:00:00'
        }
    ];
    const isMaintenance = isMaintenancePeriod(maintenancePeriod);

    return (
        <Suspense fallback={<FallBack layout={layout} />}>
            <Helmet
                title={
                    ENVIRONMENT === 'production'
                        ? 'AXA | Easyprojets | Épargne retraite'
                        : `🔑 Easyprojets (${ENVIRONMENT})`
                }
            />

            <Switch>
                {/* _________ Development pages _________ */}
                {isMaintenance && (
                    <>
                        <Route
                            component={MaintenancePage}
                            layout={None}
                            path={'/'}
                        />
                        <Redirect to="/" />
                    </>
                )}
                <Route
                    component={Dev_Panel}
                    exact
                    layout={layout}
                    path="/developer"
                    background={theme.palette.pale}
                />
                <Route
                    component={Dev_Framework}
                    exact
                    layout={layout}
                    path="/developer/framework"
                    breadcrumb={breadcrumb('Developer', '/developer', 'Framework')}
                    background={theme.palette.pale}
                />
                <Route
                    component={Dev_PDFRender}
                    exact
                    layout={layout}
                    path="/developer/pdf-render"
                    breadcrumb={breadcrumb('Developer', '/developer', 'PDF Render')}
                    background={theme.palette.pale}
                />
                <Route
                    component={Dev_Contexts}
                    exact
                    layout={layout}
                    path="/developer/contexts"
                    breadcrumb={breadcrumb('Developer', '/developer', 'Contexts')}
                    background={theme.palette.pale}
                />
                <Route
                    component={Dev_Demo}
                    exact
                    layout={None}
                    path="/demo"
                    background={theme.palette.pale}
                />
                <Route
                    component={Dev_Version}
                    exact
                    layout={None}
                    path="/version"
                />

                {/* _________ Public pages _________ */}
                <Route
                    component={Public_Home}
                    exact
                    layout={Public}
                    path="/"
                />
                <Route
                    component={Public_Articles}
                    layout={Public}
                    path="/articles"
                    background={theme.palette.pale}
                />
                <Route
                    component={Public_LegalNotice}
                    exact
                    layout={layout}
                    background={theme.palette.veryLightGray}
                    path="/mentions-legales"
                />
                <Route
                    component={Public_Contact}
                    exact
                    layout={layout}
                    path="/contact"
                />
                <Route
                    component={Public_PersonalData}
                    exact
                    layout={layout}
                    background={theme.palette.veryLightGray}
                    path="/donnees-personnelles"
                />
                <Route
                    component={Public_PersonalDataSavings}
                    exact
                    layout={layout}
                    background={theme.palette.veryLightGray}
                    path="/donnees-personnelles/epargne-salariale"
                />
                <Route
                    component={Public_Attachment}
                    exact
                    layout={layout}
                    path="/attachment/:step"
                    disableHeader
                    background={theme.palette.pale}
                />
                <Route
                    component={Public_SimulatorVV}
                    exact
                    layout={layout}
                    path="/simulateur/impots"
                />

                <Route
                    component={Login_Error}
                    exact
                    layout={layout}
                    path="/error-page"
                    disableHeader
                />

                {/* Maintenance */}

                {/*
                <Redirect
                    exact
                    to='/error-page?reason=ERR_DEPLOYMENT'
                />
                 */}

                {/* _________ Login pages _________ */}
                <Route
                    component={Login_OAuthRedirect}
                    exact
                    layout={None}
                    path="/login"
                    disableHeader
                    disableFooter
                />
                <Route
                    component={Login_OAuthRedirect}
                    exact
                    layout={None}
                    path="/authcallback"
                    disableHeader
                    disableFooter
                />
                <Route
                    component={Login_OAuthRedirect}
                    exact
                    layout={None}
                    path="/callback/:application"
                    disableHeader
                    disableFooter
                />
                <Route
                    component={Login_Presentation}
                    exact
                    layout={layout}
                    path="/presentation"
                    disableHeader
                    disableFooter
                />
                <Route
                    component={Login_ChangePassword}
                    exact
                    layout={layout}
                    path="/change-password"
                    authenticated
                />
                <Route
                    component={Login_Logout}
                    exact
                    layout={layout}
                    path="/logout/:application"
                />
                {/* _________ Connected pages _________ */}
                <Route
                    component={Connected_Main}
                    exact
                    layout={layout}
                    path="/epargne"
                    authenticated
                    background={theme.palette.veryLightGray}
                />
                <Route
                    component={Connected_Detail}
                    exact
                    layout={layout}
                    path="/epargne/detail/:selectionFilter/:filter"
                    authenticated
                    background={theme.palette.veryLightGray}
                />
                <Route
                    component={Connected_SavingDetail}
                    exact
                    layout={layout}
                    path="/epargne/detail/:selectionFilter/:filter/:id"
                    authenticated
                    background={theme.palette.veryLightGray}
                />
                <Route
                    component={Login_OAuthRedirect}
                    exact
                    layout={layout}
                    path="/rattachement/:step"
                    disableHeader
                    background={theme.palette.pale}
                    authenticated
                />
                <Route
                    component={Connected_Accounts}
                    exact
                    layout={layout}
                    path="/accounts"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_LifeProjectInput}
                    exact
                    layout={Collective}
                    path="/simulator/life-projection/:type/input"
                    authenticated
                />
                <Route
                    component={Connected_LifeProject}
                    exact
                    layout={Collective}
                    path="/simulator/life-projection/:type/settings"
                    authenticated
                />
                <Route
                    component={Connected_LifeProjection}
                    exact
                    layout={Collective}
                    path="/simulator/life-projection"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_SimulatorMRM}
                    exact
                    layout={Collective}
                    path="/simulator/mrm"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_SimulatorVV}
                    exact
                    layout={Collective}
                    path="/simulator/vv"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_Withdraw}
                    exact
                    layout={Collective}
                    path="/savings/withdraw"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_WithdrawAIS}
                    exact
                    layout={Collective}
                    path="/savings/withdraw/ais"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_WithdrawFormAIS}
                    exact
                    layout={Collective}
                    path="/savings/withdraw/ais/:withdrawCase"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_TransactionalOperation}
                    exact
                    layout={Collective}
                    path="/transactional/operations/:idTransaction"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_TransactionalOptionBulletin}
                    exact
                    layout={Collective}
                    path="/transactional/bulletin-option/:idBulletin"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_TransactionalArbitration}
                    exact
                    layout={Collective}
                    path="/transactional/arbitration/"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_Operations}
                    exact
                    layout={Collective}
                    path="/operations"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_TransactionalRedirect}
                    exact
                    layout={None}
                    path="/transactional/redirect/:action"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_OperationDetail}
                    exact
                    layout={Collective}
                    path="/operations/:transaction_id"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_VoluntaryPayment}
                    exact
                    layout={Collective}
                    path="/transactional/payment/:scope?/:company_id?"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_Transfer}
                    exact
                    layout={Collective}
                    path="/savings/transfer"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_TransferForm}
                    exact
                    layout={Collective}
                    path="/savings/transfer/:scope"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_RoboAdvisor}
                    exact
                    layout={Collective}
                    path="/placements/advisor"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_RoboAdvisorAdmin}
                    exact
                    layout={None}
                    path="/robo-advisor/admin"
                    background={theme.palette.pale}
                />
                <Route
                    component={Login_RoboAdvisorAdmin}
                    exact
                    layout={None}
                    path="/robo-advisor/admin/callback"
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_Documents}
                    exact
                    layout={Collective}
                    path="/documents"
                    authenticated
                />
                <Route
                    component={Connected_Messages}
                    exact
                    layout={Collective}
                    path="/messages"
                    authenticated
                    background={theme.palette.pale}
                />
                <Route
                    component={Connected_Profile}
                    exact
                    layout={Collective}
                    path="/profile/:scope/:journey?/:action?"
                    authenticated
                    background={theme.palette.veryLightGray}
                />
                <Route
                    component={Connected_Filib}
                    exact
                    layout={Collective}
                    path="/filib"
                    authenticated
                />
                <Route
                    component={Connected_Main}
                    exact
                    layout={Individual}
                    path="/transmission"
                    disableSubHeader
                    authenticated
                />
                <Route
                    component={Connected_Aggregation}
                    exact
                    layout={layout}
                    path="/aggregation"
                    authenticated
                />
                <Route
                    component={Connected_Aggregation}
                    exact
                    layout={layout}
                    path="/aggregation/:step"
                    authenticated
                    disableFooter={window.location.href.includes('callback')}
                    disableHeader={window.location.href.includes('callback')}
                />
                <Route
                    component={Connected_Attachment}
                    exact
                    layout={Collective}
                    path="/connected-attachment/:filter"
                    authenticated
                />
                <Route
                    component={Connected_SiteMap}
                    exact
                    authenticated
                    layout={layout}
                    background={theme.palette.veryLightGray}
                    path="/plan-du-site"
                />

                {/* _________ Kerialis pages _________ */}
                <Route
                    component={Kerialis_Login}
                    exact
                    layout={Kerialis}
                    path="/kerialis/init-session"
                />
                <Route
                    component={Kerialis_Main}
                    exact
                    layout={Kerialis}
                    path="/kerialis"
                />
                <Route
                    component={Kerialis_CGU}
                    exact
                    layout={Kerialis}
                    path="/kerialis/cgu"
                    background={theme.palette.pale}
                />
                <Route
                    component={Kerialis_Contacts}
                    exact
                    layout={Kerialis}
                    path="/kerialis/contacts"
                    background={theme.palette.pale}
                />
                <Route
                    component={Kerialis_Legal}
                    exact
                    layout={Kerialis}
                    path="/kerialis/legal"
                    background={theme.palette.pale}
                />

                {/* _________ Misc pages _________ */}
                <Route
                    component={Misc_ExternalCompany}
                    exact
                    layout={None}
                    path="/external/:company/:step"
                />

                {/* _________ Redirect rules _________ */}
                <Redirect
                    exact
                    from="/session-builder"
                    to="/developer"
                />
                <Route
                    exact
                    path="/simulateur/pereco.xls"
                    layout={Public}
                    component={() => {
                        window.location.href = '/simulateur/pereco.xlsm';
                    }}
                />
                <Redirect
                    exact
                    from="/profile"
                    to={profileURL()}
                />
                <Redirect to="/error-page?reason=ERR_NOT_FOUND" />
            </Switch>
        </Suspense>
    );
}

export default withRouter(Routes);
