import React, {useState} from 'react';
import {json} from 'utils';

export const MarelContext = React.createContext({
    marel: {},
    setMarel: () => {}
});

export const MarelProvider = (props) => {
    const setMarel = (marel) => {
        setState((state) => ({...state, marel: marel}));
        localStorage.setItem('EP_M@REL', JSON.stringify(marel));
    };

    const setCredentials = (credentials) => {
        setState((state) => ({...state, credentials: credentials}));
    };

    const setPublicKey = (publicKey) => {
        setState((state) => ({...state, publicKey: publicKey}));
    };

    const initState = {
        marel: json.parse(localStorage.getItem('EP_M@REL')) || {},
        setMarel: setMarel,
        credentials: {},
        setCredentials: setCredentials,
        publicKey: '',
        setPublicKey: setPublicKey
    };

    const [state, setState] = useState(initState);

    return <MarelContext.Provider value={state}>{props.children}</MarelContext.Provider>;
};
