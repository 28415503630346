import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Icon, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';
import {Box, Grid} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 45,
        padding: theme.spacing(0, 2),
        background: theme.palette.white,
        borderBottom: `solid 1px ${theme.palette.grayLight}`,
        width: '100%',
        border: '1px solid transparent'
    },
    reversed: {
        transform: 'translateY(-4px) rotate(180deg)'
    },
    buttonExpand: {
        background: theme.palette.white,
        display: 'flex',
        alignItems: 'center',
        padding: '13px 45px',
        '&:hover': {
            background: theme.palette.pale
        }
    },
    labelExpand: {
        lineHeight: 1
    },
    disabled: {
        cursor: 'not-allowed',
        userSelect: 'none',
        '& p': {
            color: `${theme.palette.gray} !important`
        },
        '&:hover': {
            background: theme.palette.white
        }
    },
    subMenuItemW: {width: '100%'},
    subMenuItem: {
        width: '100%'
    },
    ul: {
        listStyleType: 'none',
        borderBottom: `solid 1px ${theme.palette.grayLight}`
    },
    li: {
        '&:last-child': {marginBottom: 1}
    }
}));

function NavButtonExpand({label, items, semibold, ...rest}) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    return (
        <Grid
            container
            {...rest}
        >
            <Box
                component={'button'}
                className={classes.item}
                aria-label={label}
                tabIndex={0}
                aria-expanded={open}
                onClick={() => setOpen(!open)}
                onFocus={() => setOpen(!open)}
                onKeyDown={(e) => {
                    e.target.tagName !== 'A' && e.key === 'Tab' && e.shiftKey && setOpen(false);
                }}
            >
                <Typography
                    aria-describedby={label}
                    component={'span'}
                    gutterBottom={false}
                    size={16}
                    semibold={semibold || items.map((item) => item.url).includes(window.location.pathname)}
                    color={'blue'}
                >
                    {label}
                </Typography>
                <div className="flexGrow" />
                <Icon
                    className={clsx({
                        [classes.reversed]: open
                    })}
                    color="blue"
                    name="axa-icons-arrow-down"
                    size={16}
                />
            </Box>
            <div
                className={clsx({
                    [classes.subMenuItemW]: true,
                    hide: !open
                })}
                onFocus={() => setOpen(true)}
            >
                <ul
                    className={clsx(classes.subMenuItem, classes.ul)}
                    onKeyDown={(e) => {
                        const list = e.currentTarget;
                        const lastItem = list.lastElementChild;
                        e.key === 'Tab' &&
                            !e.shiftKey &&
                            document.activeElement.parentNode === lastItem &&
                            setOpen(false);
                    }}
                >
                    {items.map((item, index) =>
                        item.disabled ? (
                            <Grid
                                key={index}
                                item
                                component={'li'}
                                id={label}
                            >
                                <div
                                    className={clsx(classes.buttonExpand, classes.disabled)}
                                    key={`navButtonExpand-${item.label}`}
                                    title="Fonctionnalité momentanément indisponible"
                                >
                                    <Typography
                                        component={'span'}
                                        className={classes.labelExpand}
                                        gutterBottom={false}
                                        size={16}
                                        color={'blue'}
                                    >
                                        {item.label}
                                    </Typography>
                                </div>
                            </Grid>
                        ) : (
                            <Grid
                                key={index}
                                item
                                component={'li'}
                                className={classes.li}
                            >
                                <Link
                                    className={classes.buttonExpand}
                                    key={`navButtonExpand-${item.label}`}
                                    to={item.url}
                                    onClick={() => {
                                        if (item.action) item.action();
                                    }}
                                >
                                    {item.icon && item.icon}
                                    <Typography
                                        component={'span'}
                                        className={classes.labelExpand}
                                        semibold={window.location.pathname === item.url}
                                        gutterBottom={false}
                                        size={16}
                                        color={'blue'}
                                    >
                                        {item.label}
                                    </Typography>
                                </Link>
                            </Grid>
                        )
                    )}
                </ul>
            </div>
        </Grid>
    );
}

NavButtonExpand.propTypes = {
    label: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    semibold: PropTypes.bool
};

export default NavButtonExpand;
