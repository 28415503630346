import React, {useEffect, useState} from 'react';
import {Structure, Typography} from 'components';
import JSEncrypt from 'jsencrypt';

import {SubStepChannel, SubStepLogin, SubStepLoginAmeli, SubStepLoginDgfip} from './components';

import {fetchMarelPublicKey} from "api/calls";
import {useMarel} from "hooks";

export function encryptCredential(credential, publicKey) {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey);
    const encryptedMessage = encryptor.encrypt(credential);
    return encryptedMessage
}

function StepLogin(props) {
    const {step, setStep, setSessionId, sessionId} = props;
    const {setPublicKey, publicKey} = useMarel();

    const [subStep, setSubStep] = useState(0);

    useEffect(() => {
        if (!publicKey) {
            fetchMarelPublicKey().then((data) => {
                setPublicKey(data)
            })
        }
        if (step === 2) {
            setSubStep();
            setTimeout(() => setSubStep(0), 100);
        }
        // eslint-disable-next-line
    }, [step, subStep]);

    return (
        <>
            <Typography
                variant="h1"
                color="blue"
            >
                Ma retraite de base et complémentaire
            </Typography>
            <Typography
                variant="h5"
                color="blue"
                width={640}
            >
                Pour réaliser ma simulation à partir des données connues de mes régimes de retraite, je vais devoir me
                connecter au site officiel de l'Info Retraite.
            </Typography>
            <Structure.Carousel
                current={subStep}
                style={{minHeight: 300, padding: '10px 0'}}
            >
                <SubStepLogin
                    setStepParent={setStep}
                    setSessionId={setSessionId}
                />
                <SubStepChannel/>
                <SubStepLoginDgfip
                    setStepParent={setStep}
                    setSessionId={setSessionId}
                />
                <SubStepLoginAmeli
                    setStepParent={setStep}
                    setSessionId={setSessionId}
                    sessionId={sessionId}
                />
            </Structure.Carousel>
        </>
    );
}

export default StepLogin;
