import React from 'react';
import {useHistory} from 'react-router-dom';
import {Buttons, Structure} from 'components';
import MrmSteps from './MrmSteps';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 0, 0)
    }
}));

function MrmSimulator() {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.root}>
            <Structure.Stepper
                firstAction={
                    <Buttons.Default
                        label="Retour"
                        color="blueAlpha"
                        previous
                        onClick={() => history.push('/epargne')}
                    />
                }
                lastAction={
                    <Buttons.Default
                        label="Retour à l'accueil"
                        color="orange"
                        next
                        onClick={() => history.push('/epargne')}
                    />
                }
                variant="blue"
            >
                {React.Children.map(MrmSteps({withdraw: false}), (child) => child.props.children)}
            </Structure.Stepper>
        </div>
    );
}

export default MrmSimulator;
