import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Typography} from 'components';
import {useForm} from 'hooks';

import {makeStyles} from '@material-ui/styles';
import './slider.scss';

function Slider(props) {
    const {
        name,
        defaultValue,
        formatLabel,
        min,
        max,
        disabled,
        minLabel,
        maxLabel,
        reduced,
        color,
        callback,
        variant,
        ...rest
    } = props;

    const classes = makeStyles((theme) => ({
        root: {
            position: 'relative'
        },
        input: {
            '&::-webkit-slider-runnable-track': {
                background: `linear-gradient(${theme.palette.blue}, ${theme.palette.blue}) 0/var(--sx) 100% no-repeat ${
                    color || '#cde1f9'
                }`
            }
        },
        variant: {
            '&::-webkit-slider-runnable-track': {
                background: theme.palette.blueLight,
                height: 5,
                borderRadius: 0
            },
            '&::-moz-range-track': {
                background: theme.palette.blueLight,
                height: 5,
                borderRadius: 0
            },
            '&::-ms-track': {
                background: theme.palette.blueLight,
                height: 5,
                borderRadius: 0
            },
            '&::-webkit-slider-thumb': {
                border: `solid 3px ${theme.palette.blueLight} !important`,
                width: 25,
                height: 25,
                marginTop: -10,
                boxShadow: 'none !important'
            },
            '&::-moz-range-thumb': {
                border: `solid 3px ${theme.palette.blueLight} !important`,
                width: 25,
                height: 25,
                marginTop: -10,
                boxShadow: 'none !important'
            },
            '&::-moz-range-progress': {
                background: theme.palette.blueLight,
                height: 5,
                borderRadius: 0
            }
        }
    }))();

    const {formState, setFormState} = useForm();

    const inputRef = useRef(null);

    const handleChange = (event) => {
        setCurrentValue(event.target.value);
    };

    const handleReleased = (event) => {
        if (name) {
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    [name]: event.target.value
                },
                touched: {
                    ...formState.touched,
                    [name]: true
                }
            });
        }

        if (callback instanceof Function) {
            callback(event.target.value);
        }
    };

    const [currentValue, setCurrentValue] = useState(formState.values[name] || parseInt(defaultValue));
    useEffect(() => {
        let input = inputRef.current;
        input.value = currentValue;
        if (input && currentValue) input.style.setProperty('--val', currentValue);
    }, [currentValue]);

    useEffect(() => {
        setCurrentValue(parseInt(defaultValue));

        if (name) {
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    [name]: defaultValue
                },
                touched: {
                    ...formState.touched,
                    [name]: true
                }
            });
        }

        // eslint-disable-next-line
    }, [defaultValue]);

    const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

    return (
        <div className={classes.root}>
            {!reduced && (
                <Typography
                    color="blue"
                    semibold
                    variant="h4"
                >
                    {formatLabel instanceof Function && formatLabel(currentValue || parseInt(defaultValue))}
                </Typography>
            )}
            <input
                className={clsx(classes.input, variant && classes.variant)}
                name={name}
                min={min}
                max={max}
                type="range"
                disabled={disabled}
                onChange={handleChange}
                onMouseUp={handleReleased}
                onTouchEnd={handleReleased}
                onMouseMove={(event) => event.stopPropagation()}
                ref={inputRef}
                value={currentValue || parseInt(defaultValue) || 0}
                style={{
                    '--min': min,
                    '--max': max,
                    '--val': currentValue || parseInt(defaultValue) || 0
                }}
                {...rest}
            />
            <div style={{height: 20}}>
                <Typography
                    className="toLeft"
                    variant="caption"
                    gutterBottom={false}
                >
                    {minLabel ? minLabel : formatLabel instanceof Function && formatLabel(min)}
                </Typography>
                <Typography
                    className="toRight"
                    variant="caption"
                    gutterBottom={false}
                >
                    {maxLabel ? maxLabel : formatLabel instanceof Function && formatLabel(max)}
                </Typography>
            </div>
            <Typography
                className="error-message"
                color="redLight"
            >
                {hasError(name) ? formState.errors[name][0] : null}
            </Typography>
        </div>
    );
}

Slider.propTypes = {
    name: PropTypes.string,
    color: PropTypes.oneOf(['white']),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    formatLabel: PropTypes.func,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    minLabel: PropTypes.string,
    maxLabel: PropTypes.string,
    reduced: PropTypes.bool,
    callback: PropTypes.func,
    variant: PropTypes.bool
};

export default Slider;
