import React from 'react';
import PropTypes from 'prop-types';
import {Inputs, Typography} from 'components';
import {DistributionTable} from '../index';
import {useForm} from 'hooks';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .error-message': {
            display: 'none'
        }
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    spaceLeft: {
        paddingLeft: 20
    }
}));

function AnnuityCapitalDistribution({account, index}) {
    const classes = useStyles();

    const {formState, setFormState} = useForm();

    return (
        <div className={classes.root}>
            <Typography
                variant="h5"
                semibold
                gutterBottom={false}
            >
                {account.title}

                <Typography
                    className={classes.spaceLeft}
                    component="span"
                    color="grayDark"
                    size={13}
                    gutterBottom={false}
                >
                    N° de contrat&nbsp;{account.policy_id}
                </Typography>
            </Typography>

            {((['PER', 'PERO', 'PERECO', 'PERU'].includes(account.type) && account.balance < 30000) ||
                (account.type === 'A83' && account.balance < 30000)) && (
                <div className={classes.wrapper}>
                    <Typography
                        semibold
                        gutterBottom={false}
                    >
                        Tout débloquer en :
                    </Typography>
                    <Inputs.Radio
                        name={`radio-${index}`}
                        className={classes.spaceLeft}
                        defaultValue="annuity"
                        row
                        options={[
                            {
                                label: 'Capital',
                                value: 'capital'
                            },
                            {
                                label: 'Rente',
                                value: 'annuity'
                            }
                        ]}
                        onInput={(event) => {
                            account.type === 'A83' &&
                                setFormState({
                                    ...formState,
                                    values: {
                                        ...formState.values,
                                        [`capital_${account.policy_id}`]:
                                            event.target.value === 'capital' ? account.balance.toString() : '0.00',
                                        [`annuity_${account.policy_id}`]:
                                            event.target.value === 'annuity' ? account.balance.toString() : '0.00'
                                    }
                                });
                            ['PER', 'PERO', 'PERECO', 'PERU'].includes(account.type) &&
                                setFormState({
                                    ...formState,
                                    values: {
                                        ...formState.values,
                                        [`capital_${account.policy_id}_co`]:
                                            event.target.value === 'capital' ? account.balance_co.toString() : '0.00',
                                        [`annuity_${account.policy_id}_co`]:
                                            event.target.value === 'annuity' ? account.balance_co.toString() : '0.00',
                                        [`capital_${account.policy_id}_vv`]:
                                            event.target.value === 'capital' ? account.balance_vv.toString() : '0.00',
                                        [`annuity_${account.policy_id}_vv`]:
                                            event.target.value === 'annuity' ? account.balance_vv.toString() : '0.00',
                                        [`capital_${account.policy_id}_es`]:
                                            event.target.value === 'capital' ? account.balance_es.toString() : '0.00',
                                        [`annuity_${account.policy_id}_es`]:
                                            event.target.value === 'annuity' ? account.balance_es.toString() : '0.00'
                                    }
                                });
                        }}
                    />
                </div>
            )}

            {account.type === 'PER' && account.balance >= 30000 && (
                <Typography>
                    Compte tenu du montant, vous bénéficierez de votre épargne sous forme de rente pour le compartiment
                    "Versements obligatoires".
                </Typography>
            )}
            {account.type === 'A83' && account.balance >= 30000 && (
                <Typography>Compte tenu du montant, vous bénéficierez de votre épargne sous forme de rente.</Typography>
            )}

            <DistributionTable
                index={index}
                account={account}
                redemption={false}
            />
        </div>
    );
}

AnnuityCapitalDistribution.propTypes = {
    account: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

export default AnnuityCapitalDistribution;
