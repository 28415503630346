import React, {useRef, useState} from 'react';
import clsx from 'clsx';
import {Icon, Typography} from 'components';
import PropTypes from 'prop-types';
import {useForm} from 'hooks';
import {bytesToSize} from 'utils';
import uuid from 'uuid';

import {makeStyles} from '@material-ui/styles';
import {Box, Hidden, LinearProgress} from '@material-ui/core';
import {Clear} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {},
    button: {
        display: 'flex',
        position: 'relative',
        zIndex: 1,
        height: '46px !important',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.headerPale,
        boxShadow: theme.shadows[3]
    },
    label: {
        marginLeft: 10
    },
    icon: {
        cursor: 'pointer',
        color: theme.palette.errorRed
    },
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        border: `solid 1px ${theme.palette.grayLight}`,
        borderTop: 'none'
    },
    flex: {
        position: 'relative',
        cursor: 'pointer',
        zIndex: 1,
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(1, 1)
    },
    progressBar: {
        position: 'absolute',
        zIndex: 0,
        bottom: 0,
        width: '100%',
        height: 4,
        background: `${theme.palette.headerPale} !important`,
        '& > div': {
            background: `${theme.palette.blue}`
        }
    },
    filename: {
        fontSize: 14,
        wordBreak: 'break-word',
        marginRight: theme.spacing(1)
    },
    size: {
        width: 70,
        marginRight: theme.spacing(1)
    },
    iconError: {
        marginRight: 10
    }
}));

const toBase64 = (file, setProgress) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        setProgress(0);
        reader.onprogress = (event) => {
            setProgress((event.loaded * 100) / event.total);
        };
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const config = {
    pdf: 'application/pdf',
    png: 'image/png',
    jpg: 'image/jpeg, image/jpg'
};

const AcceptedFile = ({filename, size, handleDelete, progress}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.flex}>
                <Box mr={1}>
                    <Icon
                        name="axa-icons-document-o"
                        size={24}
                        flex
                    />
                </Box>

                <Typography
                    className={classes.filename}
                    gutterBottom={false}
                >
                    {filename}
                </Typography>

                <Hidden smDown>
                    <Box flexGrow={3} />
                    <Typography
                        className={classes.size}
                        gutterBottom={false}
                    >
                        {bytesToSize(size)}
                    </Typography>
                </Hidden>

                <Box
                    onClick={handleDelete}
                    display="flex"
                    ml={1}
                >
                    <Typography
                        color="errorRed"
                        gutterBottom={false}
                    >
                        Supprimer
                    </Typography>
                    <Clear
                        className={classes.icon}
                        name="axa-icons-cross"
                        size={14}
                        color="errorRed"
                    />
                </Box>
            </div>
            <LinearProgress
                className={classes.progressBar}
                variant="determinate"
                value={progress}
            />
        </div>
    );
};

function FileProgressBar(props) {
    const {name, className, label, labelColor = 'blue', accept, isRequired = false, ...rest} = props;

    const classes = useStyles();

    const {formState, setFormState} = useForm();

    const inputRef = useRef();

    const [filename, setFilename] = useState('');
    const [fileSize, setFileSize] = useState(null);

    const [uploading, setUploading] = useState(false);

    const [progress, setProgress] = useState(0);

    async function handleChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const sizeMB = file.size / 1024 ** 2;
            const extension = file.name.split('.')[file.name.split('.').length - 1];

            setUploading(true);

            setFilename(file.name);
            setFileSize(file.size);

            toBase64(file, setProgress).then((value) => {
                setFormState({
                    ...formState,
                    values: {
                        ...formState.values,
                        [name]: {
                            file: value,
                            base64: value.split('base64,')[1],
                            name: file.name,
                            size: file.size,
                            sizeMB: sizeMB,
                            extension: extension
                        }
                    },
                    touched: {
                        ...formState.touched,
                        [name]: true
                    }
                });
                setTimeout(() => setUploading(false), 350);
            });
        }
    }

    const inputID = uuid();

    const errorsKeys = [name, `${name}.sizeMB`, `${name}.extension`];

    const hasError = () =>
        formState.touched[name] &&
        errorsKeys
            .map((errorKey) => formState.errors[errorKey])
            .flat()
            .some((value) => value);

    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.button}>
                <input
                    className="hidden"
                    id={inputID}
                    name={name}
                    onChange={handleChange}
                    spellCheck={false}
                    accept={accept && accept.map((type) => config[type]).join(', ')}
                    type="file"
                    ref={inputRef}
                    {...rest}
                />
                <label
                    className={clsx({
                        [classes.label]: true,
                        [classes.flex]: true,
                        [classes.disabled]: uploading,
                        [classes.filled]: !!filename
                    })}
                    htmlFor={inputID}
                >
                    <Typography
                        semibold
                        color={labelColor}
                    >
                        {label}
                        {isRequired && (
                            <Typography
                                component="span"
                                color="errorRed"
                                semibold
                            >
                                {' '}
                                *
                            </Typography>
                        )}
                    </Typography>
                    <Box flexGrow={3} />
                    {(filename.length === 0 || hasError()) && (
                        <Icon
                            className={classes.icon}
                            name="axa-icons-add-circle"
                            color="blue"
                            size={20}
                        />
                    )}
                </label>
            </div>
            {filename && !hasError() && (
                <AcceptedFile
                    key={`file-${0}`}
                    filename={filename}
                    size={fileSize}
                    progress={progress}
                    handleDelete={(event) => {
                        event.preventDefault();
                        setFilename('');
                        setFormState({
                            ...formState,
                            values: {
                                ...formState.values,
                                [name]: ''
                            }
                        });
                        inputRef.current.value = '';
                    }}
                />
            )}

            {hasError() && (
                <Box className={clsx(classes.flex, classes.container)}>
                    <Icon
                        className={classes.iconError}
                        name="axa-icons-danger"
                        size={24}
                        color="red"
                        flex
                    />
                    <Typography
                        color="errorRed"
                        gutterBottom={false}
                    >
                        {
                            errorsKeys
                                .map((errorKey) => formState.errors[errorKey])
                                .flat()
                                .filter((value) => value)[0]
                        }
                    </Typography>
                </Box>
            )}
        </div>
    );
}

FileProgressBar.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    accept: PropTypes.arrayOf(PropTypes.oneOf(['pdf', 'png', 'jpg', 'jpeg']).isRequired)
};

export default FileProgressBar;
