import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Typography} from 'components';
import {modal} from 'utils';

import {Backdrop as MuiBackdrop, Box, Modal as MuiModal, Slide as MuiSlide} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 16px',
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            bottom: 0
        }
    },
    scrollInside: {
        overflow: 'hidden !important'
    },
    scrollInsidePaper: {
        overflowX: 'hidden',
        overflowY: 'auto',
        maxHeight: `calc(100vh - 24px)`
    },
    paper: {
        position: 'relative',
        backgroundColor: theme.palette.white,
        maxWidth: 1500,
        padding: theme.spacing(3, 3),
        boxShadow: theme.shadows[1],
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            bottom: 0,
            borderRadius: '5px 5px 0 0 ',
            padding: theme.spacing(3, 2)
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 50,
        background: theme.palette.blue,
        padding: theme.spacing(3, 2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2, 3),
            '& > h4': {
                fontSize: 18
            }
        }
    },
    padding: {
        height: '100%',
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2, 1)
        }
    },
    close: {
        marginLeft: theme.spacing(1),
        border: 'none',
        cursor: 'pointer'
    }
}));

function Modal(props) {
    const {
        hash,
        title,
        className,
        classNameHeader,
        children,
        disableHeader,
        disablePadding,
        icon,
        variant,
        onCloseTriggered,
        titleVariant,
        ...rest
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    const [open, setOpen] = useState(modal.isOpen(hash));

    useEffect(() => {
        const onHashChange = () => setOpen(modal.isOpen(hash));
        window.addEventListener('hashchange', onHashChange);
        return () => window.removeEventListener('hashchange', onHashChange);

        // eslint-disable-next-line
    }, []);

    const handleClose = () => {
        if (onCloseTriggered && typeof onCloseTriggered === 'function') onCloseTriggered();
        modal.close(hash);
    };

    return (
        <MuiModal
            className={clsx(classes.root, classes.scrollInside)}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={MuiBackdrop}
            BackdropProps={{
                timeout: 250
            }}
            disableRestoreFocus
            {...rest}
        >
            <MuiSlide
                in={open}
                mountOnEnter
                unmountOnExit
            >
                <div
                    className={clsx(
                        classes.paper,
                        className,
                        variant === 'white' && 'white',
                        classes.scrollInsidePaper
                    )}
                >
                    <Box
                        mb={2}
                        display="flex"
                        alignItems={'flex-start'}
                        justifyContent={'space-between'}
                    >
                        {title &&
                            (typeof title === 'string' ? (
                                <Typography
                                    variant={titleVariant ? titleVariant : 'h3'}
                                    color="blue"
                                    semibold
                                    gutterBottom={false}
                                >
                                    {title}
                                </Typography>
                            ) : (
                                title
                            ))}

                        <button
                            title={t('buttons.close')}
                            className={classes.close}
                            onClick={() => modal.close(hash)}
                        >
                            <CloseIcon />
                        </button>
                    </Box>

                    {children}
                </div>
            </MuiSlide>
        </MuiModal>
    );
}

Modal.propTypes = {
    hash: PropTypes.string.isRequired,
    title: PropTypes.node,
    className: PropTypes.string,
    classNameHeader: PropTypes.string,
    children: PropTypes.node,
    disableHeader: PropTypes.bool,
    disablePadding: PropTypes.bool,
    icon: PropTypes.node, // Override default cross
    variant: PropTypes.oneOf(['white']) // Override default cross
};

export default Modal;
