import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {api} from 'api';
import {Icon, Modal, Typography} from 'components';
import {modal} from 'utils';

import {makeStyles} from '@material-ui/styles';
import {Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 500,
        padding: `${theme.spacing('30px', '12px', '0px', '24px')} !important`,
        '& > div': {
            marginBottom: '20px !important'
        },
        '& h3': {
            textAlign: 'left !important'
        }
    },
    button: {
        display: 'block',
        width: 248,
        padding: 8,
        marginTop: 10,
        marginBottom: 10,
        marginRight: 20,
        border: `solid 1.5px ${theme.palette.blue}`,
        color: theme.palette.white,
        userSelect: 'none',
        cursor: 'pointer',
        '&:hover, &:focus': {
            border: `solid 1px ${theme.palette.blue}`
        }
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: theme.spacing(1),
        margin: theme.spacing(1, -2, -2, -2),
        background: theme.palette.grayLight
    },
    list: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        color: theme.palette.blue
    }
}));

const AddAccountButton = ({label, ...rest}) => {
    const classes = useStyles();

    return (
        <button
            className={classes.button}
            {...rest}
        >
            <Typography
                variant="h5"
                color="blue"
                gutterBottom={false}
                center
            >
                {label}
            </Typography>
        </button>
    );
};

const FabiusAccounts = ({accounts}) => {
    const classes = useStyles();

    const [expand, setExpand] = useState(false);

    return (
        <>
            <div
                className={classes.wrapper}
                onClick={() => setExpand((expand) => !expand)}
            >
                <Typography
                    variant="h5"
                    color="blue"
                    gutterBottom={false}
                    semibold
                >
                    Voir mes comptes épargne salariale hors AXA&nbsp;&nbsp;
                </Typography>
                <Icon
                    name={`axa-icons-arrow-${expand ? 'up' : 'down'}`}
                    color="blue"
                    size={20}
                    flex
                />
            </div>

            {expand && (
                <ul className={classes.list}>
                    {accounts.map((label) => (
                        <li key={`fabius-${label}`}>
                            <Typography
                                variant="h5"
                                color="blue"
                                gutterBottom={false}
                            >
                                {label}
                            </Typography>
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

function AddAccountModal({filter}) {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation();

    const [fabiusAccounts, setFabiusAccounts] = useState(null);

    const hash = `add_account_${filter}`;
    const open = modal.isOpen(hash);
    useEffect(() => {
        open &&
            fabiusAccounts === null &&
            api.post('/fabius/initialize').then((response) => setFabiusAccounts(response.data.accounts));

        // eslint-disable-next-line
    }, [open]);

    return (
        <Modal
            hash={hash}
            className={classes.root}
            title={t('home.addScheme')}
        >
            <Typography
                localization={'home.contractsList'}
                values={{
                    list: filter === 'retirement' ? 'PER, PERCO, Article 83, Madelin, PERP' : 'PEE, Assurance Vie'
                }}
            />

            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <AddAccountButton
                    tabIndex="0"
                    label="AXA"
                    aria-label="AXA"
                    onClick={() => history.push('/attachment/mosaic')}
                />
                <AddAccountButton
                    tabIndex="0"
                    label={t('buttons.outsideAXA')}
                    aria-label={t('buttons.outsideAXA')}
                    onClick={() => history.push('/aggregation')}
                />
            </Box>

            {fabiusAccounts instanceof Array && fabiusAccounts.length > 0 && (
                <FabiusAccounts accounts={fabiusAccounts} />
            )}
        </Modal>
    );
}

AddAccountModal.propTypes = {
    filter: PropTypes.string.isRequired
};

export default AddAccountModal;
