import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Typography} from 'components';

import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary
} from '@material-ui/core';

function isInt(value) {
    return (
        !isNaN(value) &&
        (function (x) {
            return (x | 0) === x;
        })(parseFloat(value))
    );
}

function Accordion(props) {
    const {children, initialSlide, current, ...rest} = props;

    useEffect(() => {
        initialSlide && setTimeout(() => setStep(initialSlide), 100);

        // eslint-disable-next-line
    }, [initialSlide]);

    const [step, setStep] = useState(initialSlide || 0);

    useEffect(() => {
        isInt(current) && setStep(current);

        // eslint-disable-next-line
    }, [current]);

    return (
        <div {...rest}>
            {React.Children.map(children, (child, index) => (
                <MuiAccordion
                    key={`accordion-${index}`}
                    expanded={index === step}
                    onChange={() => (index === step ? -1 : setStep(index))}
                >
                    <MuiAccordionSummary>
                        <Typography variant="h6">{child.props.name}</Typography>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                        {React.cloneElement(child, {
                            step: step,
                            setStep: setStep
                        })}
                    </MuiAccordionDetails>
                </MuiAccordion>
            ))}
        </div>
    );
}

Accordion.propTypes = {
    children: PropTypes.node.isRequired,
    current: PropTypes.number,
    initialSlide: PropTypes.number
};

export default Accordion;
