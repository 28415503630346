import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/fr';
import {Icon, Typography} from 'components';

import {Modal as MuiModal, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';

import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify(window);

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    container: {
        width: '100%',
        maxWidth: 900,
        maxHeight: '100vh',
        outline: 'none',
        boxShadow: theme.shadows[5],
        overflow: 'auto'
    },
    paper: {
        position: 'relative',
        backgroundColor: theme.palette.white
    },
    icon: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 50,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.blueLight,
        cursor: 'pointer'
    },
    content: {
        position: 'relative',
        top: -80,
        margin: theme.spacing(0, 4),
        padding: theme.spacing(2, 3),
        background: theme.palette.white
    },
    reduced: {
        position: 'initial !important',
        margin: '0 !important',
        top: '0 !important'
    },
    img: {
        width: '100%',
        height: 300,
        objectFit: 'cover'
    }
}));

function ArticleModal({article, open, setOpen, ...rest}) {
    const classes = useStyles();
    const theme = useTheme();

    const handleClose = () => setOpen(false);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <MuiModal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            {...rest}
        >
            <div className={classes.container}>
                <div className={classes.paper}>
                    <Icon
                        className={classes.icon}
                        name="axa-icons-cross"
                        color="white"
                        size={18}
                        onClick={handleClose}
                    />
                    <img
                        className={classes.img}
                        src={`${process.env.PUBLIC_URL}/content/articles/images/${article.date}.jpeg`}
                        alt={article.title}
                    />
                    <div
                        className={clsx({
                            [classes.content]: true,
                            [classes.reduced]: isMobile
                        })}
                    >
                        <Typography
                            variant="h3"
                            color="blue"
                            center
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(article.title)
                            }}
                        />
                        <Typography color="grayDark">{moment(article.date).format('DD MMMM YYYY')}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: article.content}} />
                    </div>
                </div>
            </div>
        </MuiModal>
    );
}

ArticleModal.propTypes = {
    article: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default ArticleModal;
