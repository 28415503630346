import mock from 'api/mockFastApi';

import {
    ais_abondment_simulation,
    ais_accounts,
    ais_bo_gauge,
    ais_bo_permissions,
    ais_profile,
    ais_transactions,
    ais_bank_account,
    ais_vv_accounts,
    ais_global_vv_amount
} from './data';

mock.onPost('/services/api-ais/profile').reply(() => {
    return [200, {ais_profile}];
});

mock.onPost('/services/api-ais/accounts').reply(() => {
    return [200, {ais_accounts}];
});

mock.onGet('/services/api-ais/authorized-acts').reply(() => {
    return [200, {isVoluntaryPaymentAllowed: true}];
});

mock.onGet('/services/api-ais/global-vv-amount').reply(() => {
    return [200, ais_global_vv_amount];
});

mock.onGet('/services/api-ais/payment-type-authorization').reply(() => {
    return [200, ais_bank_account];
});

mock.onGet('/services/api-ais/profile/bank-coordinates').reply(() => {
    return [200, ais_bank_account];
});

mock.onPost('/services/api-ais/accounts?filter=arbitration_source').reply(() => {
    return [200, {ais_accounts: ais_accounts}];
});

mock.onPost('/services/api-ais/accounts?filter=voluntary_payment').reply(() => {
    return [200, {ais_accounts: ais_vv_accounts}];
});

mock.onPost('/services/api-ais/transactions').reply(() => {
    return [200, ais_transactions];
});

mock.onGet('/services/api-ais/transactional/option-bulletin/permissions/INTERESSEMENT').reply(() => {
    return [200, ais_bo_permissions];
});
mock.onGet('/services/api-ais/transactional/option-bulletin/permissions/PARTICIPATION').reply(() => {
    return [200, ais_bo_permissions];
});
mock.onGet('/services/api-ais/transactional/option-bulletin/contribution-gauge/INTERESSEMENT').reply(() => {
    return [200, ais_bo_gauge];
});
mock.onGet('/services/api-ais/transactional/option-bulletin/contribution-gauge/PARTICIPATION').reply(() => {
    return [200, ais_bo_gauge];
});

mock.onPost('/services/api-ais/transactional/option-bulletin/abondment-simulator').reply(() => {
    return [200, ais_abondment_simulation];
});

mock.onPut('/services/api-ais/cancel-periodic-voluntary-payment').reply(() => {
    return [200, ''];
});
