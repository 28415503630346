import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {useForm, useUser} from 'hooks';
import {Typography} from 'components';
import {accounts, amount} from 'utils';

import {useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 0, 3)
    },
    table: {
        width: '100%',
        margin: '4px 0',
        tableLayout: 'fixed',
        borderCollapse: 'collapse',
        borderSpacing: '0',

        '& th:not(:first-child), tbody td': {
            padding: theme.spacing(0, 1),
            background: theme.palette.blue,
            border: 'solid 3px white',
            height: 45
        },
        '& tfoot td': {
            padding: theme.spacing(0, 1),
            background: theme.palette.blue,
            border: 'solid 3px white',
            height: 45,
            '&:nth-last-child(2), &:last-child': {
                padding: '0 25px'
            }
        }
    }
}));

function TotalDistribution() {
    const classes = useStyles();
    const theme = useTheme();

    const {withdrawCase = 'retirement'} = useParams();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const {formState} = useForm();

    const {user} = useUser();

    const total_capital = useMemo(
        () =>
            Object.entries(formState.values)
                .filter(([key]) => key.startsWith('capital'))
                .map(([, value]) => amount.parse(value))
                .reduce((acc, val) => acc + val, 0),
        [formState.values]
    );

    const total_annuity = useMemo(
        () =>
            Object.entries(formState.values)
                .filter(([key]) => key.startsWith('annuity'))
                .map(([, value]) => amount.parse(value))
                .reduce((acc, val) => acc + val, 0),
        [formState.values]
    );

    const total_rest = useMemo(
        () =>
            accounts
                .filter(
                    user.accounts,
                    'ais',
                    withdrawCase === 'retirement' ? ['PER', 'PERO', 'PERECO', 'PERU', 'A83'] : ['PER', 'PERECO', 'PERU']
                )
                .map((account) => account.balance)
                .reduce((acc, val) => acc + val, 0) -
            total_annuity -
            total_capital,
        [user.accounts, total_capital, total_annuity, withdrawCase]
    );

    return (
        <div className={classes.root}>
            {isDesktop ? (
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th colSpan={3} />
                            <th>
                                <Typography
                                    variant="h5"
                                    color="white"
                                    gutterBottom={false}
                                >
                                    {withdrawCase === 'retirement' ? 'Capital' : 'Déblocage'}
                                </Typography>
                            </th>
                            {withdrawCase === 'retirement' && (
                                <th>
                                    <Typography
                                        variant="h5"
                                        color="white"
                                        gutterBottom={false}
                                    >
                                        Rente&nbsp;
                                    </Typography>
                                </th>
                            )}
                        </tr>
                    </thead>

                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <Typography
                                    color="white"
                                    variant="h5"
                                    semibold
                                    gutterBottom={false}
                                >
                                    Synthèse
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    color="white"
                                    variant="h5"
                                    semibold
                                    gutterBottom={false}
                                    right
                                >
                                    {amount.format(total_capital)}
                                </Typography>
                            </td>
                            {withdrawCase === 'retirement' && (
                                <td>
                                    <Typography
                                        color="white"
                                        variant="h5"
                                        semibold
                                        gutterBottom={false}
                                        right
                                    >
                                        {amount.format(total_annuity)}
                                    </Typography>
                                </td>
                            )}
                        </tr>
                    </tfoot>
                </table>
            ) : (
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th />
                            <th>
                                <Typography
                                    color="white"
                                    variant="h5"
                                    semibold
                                    gutterBottom={false}
                                >
                                    Synthèse
                                </Typography>
                            </th>
                        </tr>
                    </thead>

                    <tfoot>
                        <tr>
                            <td>
                                <Typography
                                    variant="h5"
                                    color="white"
                                    gutterBottom={false}
                                >
                                    {withdrawCase === 'retirement' ? 'Capital' : 'Déblocage'}
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    color="white"
                                    variant="h5"
                                    semibold
                                    gutterBottom={false}
                                    right
                                >
                                    {amount.format(total_capital)}
                                </Typography>
                            </td>
                        </tr>
                        {withdrawCase === 'retirement' && (
                            <tr>
                                <td>
                                    <Typography
                                        variant="h5"
                                        color="white"
                                        gutterBottom={false}
                                    >
                                        Rente
                                    </Typography>
                                </td>
                                <td>
                                    <Typography
                                        color="white"
                                        variant="h5"
                                        semibold
                                        gutterBottom={false}
                                        right
                                    >
                                        {amount.format(total_annuity)}
                                    </Typography>
                                </td>
                            </tr>
                        )}
                    </tfoot>
                </table>
            )}

            {total_rest > 0.01 && (
                <Typography
                    variant="h5"
                    right
                >
                    Vous avez un solde restant de&nbsp;
                    <Typography
                        component="span"
                        semibold
                    >
                        {amount.format(total_rest)}
                    </Typography>
                </Typography>
            )}
        </div>
    );
}

export default TotalDistribution;
