import React from 'react';
import clsx from 'clsx';
import {Form, Icon, Inputs, Typography} from 'components';
import {useTaxSimulator} from 'hooks';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: 20,
        width: 160,
        height: 160
    }
}));

function StepMaritalStatus({setStep}) {
    const classes = useStyles();

    const {taxSimulator, setTaxSimulator, variant} = useTaxSimulator();

    return (
        <Form
            dynamic
            submit={(formState) => {
                setTaxSimulator({
                    ...taxSimulator,
                    inputs: {
                        ...taxSimulator.inputs,
                        ...formState.values
                    }
                });
                setStep(3);
            }}
        >
            <Typography
                variant={variant === 'public' ? 'h1' : variant === 'standalone' ? 'h2' : 'h4'}
                color="blue"
                center
            >
                Au niveau de votre foyer fiscal, vous êtes...
            </Typography>
            <Inputs.RadioButton
                name="marital_status"
                classNameLabel={clsx(variant === 'public' && classes.label)}
                center
                options={[
                    {
                        label: 'Célibataire',
                        value: 'CE',
                        icon: (
                            <Icon
                                name="axa-icons-man-o"
                                size={variant === 'contained' ? 40 : 60}
                            />
                        )
                    },
                    {
                        label: 'En couple',
                        value: 'MA',
                        icon: (
                            <Icon
                                name="axa-icons-employee-o"
                                size={variant === 'contained' ? 40 : 60}
                            />
                        )
                    }
                ]}
            />
        </Form>
    );
}

export default StepMaritalStatus;
