import React, {useReducer} from 'react';
import moment from 'moment';
import clsx from 'clsx';
import {withRouter} from 'react-router-dom';
import {Buttons, Grid, Structure, Typography} from 'components';
import {TabAnnuity, TabEconomy, TabProposal} from './components';
import {StepAge, StepChildren, StepGender, StepMaritalStatus, StepMonthlyIncome} from '../../components';
import {useTaxSimulator, useUser} from 'hooks';

import {Box, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(1, 0)
    },
    wrapper: {
        padding: 5,
        '&:first-child': {
            paddingLeft: 0
        },
        '&:last-child': {
            paddingRight: 0
        }
    },
    border: {
        height: '100%',
        minHeight: 150,
        border: `solid 2px ${theme.palette.grayLight}`,
        padding: theme.spacing(2, 1)
    },
    borderH: {
        borderTop: `solid 1px ${theme.palette.gray}`,
        borderBottom: `solid 1px ${theme.palette.gray}`
    },
    borderV: {
        borderLeft: `solid 1px ${theme.palette.gray}`,
        borderRight: `solid 1px ${theme.palette.gray}`
    },
    button: {
        margin: theme.spacing(1)
    },
    padding: {
        padding: theme.spacing(1, 0)
    }
}));

function reducer(state, periodicity) {
    switch (periodicity) {
        case 'one_shot':
            return '€';
        case 'yearly':
            return '€/an';
        default:
            return '€/mois';
    }
}

function StepTaxResult({history, setStep}) {
    const {user} = useUser();
    const {setTaxSimulator, variant} = useTaxSimulator();

    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const [adornment, setAdornment] = useReducer(reducer, '€/mois');

    return (
        <>
            {variant !== 'contained' && (
                <Box pb={0.5}>
                    <Typography
                        variant="h2"
                        color="blue"
                        semibold
                        center
                    >
                        Les chiffres clefs de votre simulation
                    </Typography>
                </Box>
            )}

            {variant === 'standalone' && (
                <Buttons.Chevron
                    label="Refaire une simulation"
                    color="blue"
                    center
                    onClick={() => {
                        setTaxSimulator({
                            inputs: {
                                gender: user.ais_profile?.gender,
                                client_age:
                                    user.ais_profile?.birth_date &&
                                    moment().diff(user.ais_profile?.birth_date, 'years', false),
                                allocated_amount: undefined
                            },
                            response: {}
                        });
                        setStep(user.ais_profile?.gender && user.ais_profile?.birth_date ? 2 : 0);
                    }}
                />
            )}

            <Grid
                className={classes.container}
                container
            >
                <Grid
                    className={clsx({
                        [classes.wrapper]: variant === 'contained' && isDesktop,
                        [classes.padding]: variant === 'contained' && !isDesktop
                    })}
                    item
                    md={4}
                    xs={12}
                >
                    {variant === 'contained' ? (
                        <Structure.Carousel
                            adaptiveHeight
                            className={classes.border}
                            initialSlide={user.ais_profile?.gender && user.ais_profile.birth_date && 2}
                            smooth
                        >
                            <StepGender />
                            <StepAge />
                            <StepMaritalStatus />
                            <StepChildren />
                            <StepMonthlyIncome />
                            <TabProposal
                                adornment={adornment}
                                setAdornment={setAdornment}
                            />
                        </Structure.Carousel>
                    ) : (
                        <TabProposal
                            adornment={adornment}
                            setAdornment={setAdornment}
                        />
                    )}
                </Grid>
                <Grid
                    className={clsx({
                        [classes.borderV]: variant !== 'contained' && isDesktop,
                        [classes.borderH]: variant !== 'contained' && !isDesktop,
                        [classes.wrapper]: variant === 'contained' && isDesktop,
                        [classes.padding]: variant === 'contained' && !isDesktop
                    })}
                    item
                    md={4}
                    xs={12}
                >
                    <TabEconomy />
                </Grid>
                <Grid
                    className={clsx({
                        [classes.wrapper]: variant === 'contained' && isDesktop,
                        [classes.padding]: variant === 'contained' && !isDesktop
                    })}
                    item
                    md={4}
                    xs={12}
                >
                    <TabAnnuity />
                </Grid>
            </Grid>

            <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction={isDesktop ? 'row' : 'column'}
            >
                {variant === 'public' && (
                    <Buttons.Default
                        label="Refaire une simulation"
                        color="blueAlpha"
                        next
                        onClick={() => {
                            setTaxSimulator({
                                inputs: {
                                    gender: user.ais_profile?.gender,
                                    client_age:
                                        user.ais_profile?.birth_date &&
                                        moment().diff(user.ais_profile?.birth_date, 'years', false),
                                    allocated_amount: undefined
                                },
                                response: {}
                            });
                            setStep(user.ais_profile?.gender && user.ais_profile?.birth_date ? 2 : 0);
                        }}
                    />
                )}
                {variant === 'public' && (
                    <Buttons.Default
                        label="Verser sur mon compte"
                        className={classes.button}
                        next
                        color="orange"
                        onClick={() =>
                            (window.location.href = 'https://www.axa.fr/pro/epargne-retraite-entreprise.html?r=1')
                        }
                    />
                )}
                {variant === 'standalone' && (
                    <Buttons.Default
                        label="Retour à la page d'accueil"
                        className={classes.button}
                        color="blueAlpha"
                        previous
                        onClick={() => history.push('/epargne')}
                    />
                )}
                {variant === 'standalone' && (
                    <Buttons.Default
                        label="J'épargne"
                        className={classes.button}
                        color="orange"
                        next
                        onClick={() => history.push('/transactional/payment')}
                    />
                )}
            </Grid>
        </>
    );
}

export default withRouter(StepTaxResult);
