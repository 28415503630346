import mock from 'api/mockFastApi';

mock.onGet('/b2v/initialize').reply(() => {
    return [
        200,
        {
            consent: false,
            last_name: 'Dupont',
            first_name: 'Jean',
            birth_year: 1965,
            balance: 4256,
            cotinet: 1235,
            date: '31/12/2022',
            annuity: {
                60: 80,
                61: 80,
                62: 80,
                63: 80,
                64: 80,
                65: 80,
                66: 90,
                67: 100
            }
        }
    ];
});

mock.onPost('/consents/b2v').reply(() => [200, 'OK']);
