import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {Typography} from 'components';
import {useForm} from 'hooks';

import {TextField, useMediaQuery} from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {makeStyles, useTheme} from '@material-ui/styles';
import frLocale from 'date-fns/locale/fr';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormControl-root': {
            height: 36,
            '& > div': {
                height: '100%'
            }
        },
        '& fieldset': {
            border: 'none'
        },
        '& .MuiInputAdornment-root': {
            paddingRight: 0,
            zIndex: 100
        }
    },
    hideCalendar: {
        '& .MuiInputAdornment-root': {
            display: 'none !important'
        }
    },
    input: {
        position: 'relative',
        margin: '5px 0px',
        width: '100%',
        height: 36,
        background: '#ededed',
        border: 'none',
        borderRadius: '0.25em',
        outline: 'none',
        '& svg': {
            color: theme.palette.blue
        },
        '& input': {
            zIndex: 100
        }
    }
}));

const TextFieldComp = ({name, inputProps, ...props}) => {
    return (
        <TextField
            {...props}
            inputProps={{
                ...inputProps,
                name
            }}
        />
    );
};

function Datepicker(props) {
    const {name, label, labelColor, disableSpacing, hideCalendar = false, openTo = 'year', ...rest} = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();
    const {formState, setFormState} = useForm();

    const handleDateChange = (date) => {
        let value = date && new Date(date.getTime() - date.getTimezoneOffset() * 60000);

        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [name]: value
            },
            touched: {
                ...formState.touched,
                [name]: true
            }
        });
    };

    const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.hideCalendar]: hideCalendar,
                'margin-0': disableSpacing
            })}
        >
            <Typography
                color={labelColor}
                variant="caption"
            >
                {label}
            </Typography>
            <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={frLocale}
            >
                <KeyboardDatePicker
                    autoOk
                    className={classes.input}
                    disableToolbar
                    variant={isMobile ? 'dialog' : 'inline'}
                    format="dd/MM/yyyy"
                    label={null}
                    name={name}
                    value={formState.values[name] || null}
                    onChange={handleDateChange}
                    openTo={openTo}
                    minDateMessage=""
                    maxDateMessage=""
                    invalidDateMessage=""
                    inputVariant="outlined"
                    TextFieldComponent={TextFieldComp}
                    {...rest}
                />
            </MuiPickersUtilsProvider>
            <Typography
                className="error-message"
                color="redLight"
            >
                {hasError(name) ? formState.errors[name][0] : null}
            </Typography>
        </div>
    );
}

Datepicker.propTypes = {
    label: PropTypes.string,
    labelColor: PropTypes.string,
    name: PropTypes.string,
    disableSpacing: PropTypes.bool
};

export default Datepicker;
