import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Icon, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        right: 0
    },
    button: {
        width: 38,
        height: 38,
        padding: 1,
        background: theme.palette.blue,
        cursor: 'pointer',
        border: 'none',
        userSelect: 'none',
        boxShadow: theme.shadows[2]
    },
    container: {
        top: 0,
        right: 0,
        position: 'absolute',
        padding: theme.spacing(2),
        width: 360,
        height: 190,
        border: `solid 1px ${theme.palette.grayDark}`,
        background: theme.palette.white,
        transform: 'translateX(100%)',
        transition: 'transform 0.3s ease-out',
        zIndex: 0
    },
    show: {
        right: 38,
        transform: 'translateX(0)'
    },
    list: {
        marginLeft: theme.spacing(3),
        color: theme.palette.blue,
        listStyle: 'square'
    },
    link: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));

const ToggleCallBox = ({setOpen}) => {
    const classes = useStyles();

    return (
        <button
            className={classes.button}
            onClick={() => setOpen((open) => !open)}
        >
            <Icon
                name="axa-icons-phone"
                color="white"
                size={20}
                flex
            />
        </button>
    );
};

function CallBox({className, top}) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    return (
        <div
            className={clsx(classes.root, className)}
            style={{top: top ? `${top}px` : 0}}
        >
            <ToggleCallBox setOpen={setOpen} />

            <div className={clsx(classes.container, open && classes.show)}>
                <Typography
                    variant="h3"
                    color="blue"
                    semibold
                >
                    Besoin d'aide&nbsp;?
                </Typography>
                <Typography
                    color="blue"
                    semibold
                >
                    Pour toute question, le service est à votre disposition du lundi au vendredi de 8h30 à 18h00 :
                </Typography>
                <ul className={classes.list}>
                    <li>par téléphone au 0&nbsp;970&nbsp;808&nbsp;057&nbsp;*</li>
                    <li>
                        par e-mail au&nbsp;
                        <a
                            href="mailto:service.clientsERE@axa.fr"
                            style={{visibility: !open && 'hidden'}}
                        >
                            <Typography
                                className={classes.link}
                                color="black"
                                component="span"
                                semibold
                            >
                                service.clientsERE@axa.fr
                            </Typography>
                        </a>
                    </li>
                </ul>
                <Typography>(*) Appel local non surtaxé</Typography>
            </div>
        </div>
    );
}

CallBox.propTypes = {
    className: PropTypes.string,
    top: PropTypes.number
};

export default CallBox;
