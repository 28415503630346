import React from 'react';
import {useParams} from 'react-router-dom';
import {Typography} from 'components';
import {AnnuityCapitalDistribution, TotalDistribution, UnlockingDistribution} from './components';
import {useUser} from 'hooks';
import {accounts} from 'utils';

function StepDistribution() {
    const {user} = useUser();

    const {withdrawCase = 'retirement'} = useParams();

    const accounts_retirement = accounts.filter(
        user.accounts,
        'ais',
        withdrawCase === 'retirement' ? ['PER', 'PERO', 'PERECO', 'PERU', 'A83'] : ['PER', 'PERO', 'PERECO', 'PERU']
    );

    return (
        <>
            <Typography
                variant="h3"
                color="blue"
                semibold
                center
            >
                {withdrawCase === 'retirement'
                    ? 'Comment souhaiteriez-vous disposer de votre retraite ?'
                    : 'Quelles sommes voulez-vous débloquer ?'}
            </Typography>

            {accounts_retirement.map((account, index) =>
                withdrawCase === 'retirement' ? (
                    <AnnuityCapitalDistribution
                        key={`accountDistribution-${index}`}
                        account={account}
                        index={index}
                    />
                ) : (
                    <UnlockingDistribution
                        key={`accountDistribution-${index}`}
                        account={account}
                        index={index}
                    />
                )
            )}

            <TotalDistribution />
        </>
    );
}

export default StepDistribution;
