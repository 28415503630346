import React, {useState} from 'react';
import {SubStepForgotPasswordOne, SubStepForgotPasswordResult, SubStepForgotPasswordTwo} from './components';
import {Structure, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(2)
        },
        padding: theme.spacing(3, 3, 2),
        margin: theme.spacing(2, 0, 1),
        background: theme.palette.white
    }
}));

function StepForgotPassword({setStep, sessionId}) {
    const classes = useStyles();
    const [stepChildren, setStepChildren] = useState(0);
    const [secretQuestion, setSecretQuestion] = useState('');

    return (
        <div className="full-width">
            <div className={classes.container}>
                {stepChildren !== 2 && (
                    <Typography variant="h2">
                        Vous avez oublié votre mot de passe de l'Info Retraite&nbsp;&nbsp;{stepChildren + 1}/2
                    </Typography>
                )}

                {stepChildren !== 2 && (
                    <Typography variant="h5">
                        Pour réinitialiser votre mot de passe, suivez les instructions du formulaire.
                    </Typography>
                )}

                <Structure.Carousel
                    adaptiveHeight
                    onChange={(index) => setStepChildren(index)}
                >
                    <SubStepForgotPasswordOne
                        setStepParent={setStep}
                        setSecretQuestion={setSecretQuestion}
                        sessionId={sessionId}
                    />
                    <SubStepForgotPasswordTwo
                        setStepParent={setStep}
                        secretQuestion={secretQuestion}
                        sessionId={sessionId}
                    />
                    <SubStepForgotPasswordResult setStepParent={setStep} />
                </Structure.Carousel>
            </div>
        </div>
    );
}

export default StepForgotPassword;
