import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import Cleave from 'cleave.js/react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Icon, Typography} from 'components';
import {useForm} from 'hooks';
import {amount} from 'utils';

import {makeStyles} from '@material-ui/styles';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 0)
    },
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        height: 36,
        padding: '0px 14px',
        background: '#ededed',
        border: 'none',
        borderRadius: '0.25em',
        outline: 'none',
        '&::placeholder': {
            color: 'silver'
        },
        '&:focus::placeholder': {
            color: 'transparent'
        },
        '&:focus': {
            border: `solid 1px ${theme.palette.blue} !important`
        }
    },
    right: {
        textAlign: 'right',
        paddingRight: 25
    },
    wrapper: {
        position: 'absolute',
        height: '100%',
        top: 0,
        right: 14,
        display: 'flex',
        alignItems: 'center',
        fontWeight: '600',
        pointerEvents: 'none'
    },
    error: {
        border: `solid 1px ${theme.palette.errorRed} !important`
    },
    clear: {
        width: 56,
        height: 36,
        border: `solid 2px ${theme.palette.blue}`,
        userSelect: 'none',
        cursor: 'pointer',
        transition:
            'color .5s cubic-bezier(0, 0.99, 0.865, 1.005), background-color .6s cubic-bezier(0, 0.99, 0.865, 1.005)',
        color: theme.palette.blue,
        backgroundColor: theme.palette.transparent,
        '&:hover, &:focus': {
            color: theme.palette.white,
            backgroundColor: theme.palette.blue,
            '& i:before': {
                color: theme.palette.white
            }
        }
    },
    required: {
        marginLeft: theme.spacing(0.5)
    },
    variant: {
        border: `solid 1px ${theme.palette.blue} !important`
    }
}));

function Text(props) {
    const {
        name,
        className,
        classNameRoot,
        label,
        labelColor,
        right,
        adornment,
        defaultValue,
        updateDefaultValue = false,
        sup,
        disableSpacing,
        size,
        format,
        hidden,
        submitOnBlur,
        disableCleave,
        hiddenError = false,
        clearable = false,
        isRequired = false,
        variant,
        ...rest
    } = props;

    const classes = useStyles();

    const {formState, setFormState} = useForm();

    useEffect(() => {
        if (defaultValue) {
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    [name]: defaultValue
                }
            });
            if (updateDefaultValue) {
                format === 'money' && setFormatted(amount.format(defaultValue || '0', 0, ''));
                format === 'money-2' && setFormatted(amount.format(defaultValue || '0', 2, ''));
            }
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    const [formatted, setFormatted] = useState(
        (formState.values[name] && formState.values[name].replace instanceof Function
            ? formState.values[name].replace('.', ',')
            : formState.values[name]?.toString()?.replace('.', ',')) ||
            defaultValue ||
            ''
    );

    const handleBlur = (event) => {
        format === 'money' && setFormatted(amount.format(event.target.rawValue || '0', 0, ''));
        format === 'money-2' && setFormatted(amount.format(event.target.rawValue || '0', 2, ''));

        if (event.target.rawValue === '.') return;

        if (format === 'money-2' && event.target.rawValue === '')
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    [name]: '0'
                },
                touched: {
                    ...formState.touched,
                    [name]: true
                }
            });
        else if (submitOnBlur)
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    [name]: event.target.rawValue.replace('.', ',')
                },
                touched: {
                    ...formState.touched,
                    [name]: true
                }
            });
    };

    const handleChange = (event) => {
        let value = event.target.value;
        let rawValue = event.target.rawValue;

        if (rawValue === '.') return;

        if (!submitOnBlur)
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    [name]: disableCleave ? value : rawValue
                },
                touched: {
                    ...formState.touched,
                    [name]: true
                }
            });
    };

    const reset = () => {
        const {[name]: value, ...rest} = formState.values;
        const {[name]: touchedValue, ...restTouched} = formState.touched;

        setFormState({
            ...formState,
            values: rest,
            touched: restTouched
        });

        if (format === 'money') setFormatted('0');
        else if (format === 'money-2') setFormatted('0,00');
        else setFormatted('');
    };

    useEffect(() => {
        if (formState.values[name] === undefined) {
            format === 'money' && setFormatted('0');
            format === 'money-2' && setFormatted('0,00');
        }

        // eslint-disable-next-line
    }, [formState.values[name], setFormatted, name, format]);

    const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

    const hasValue = (field) => formState.values[field];

    const adornmentRef = useRef();
    const [paddingRight, setPaddingRight] = useState(0);

    const computePadding = (ref) =>
        setPaddingRight(ref.current && ref.current.clientWidth > 0 ? ref.current.clientWidth + 18 : 25);
    const handleFocus = () => {
        computePadding(adornmentRef);

        format === 'money-2' && amount.parse(formatted) === 0 && setFormatted('');
    };
    useLayoutEffect(() => {
        computePadding(adornmentRef);
    }, [adornment]);

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classNameRoot]: classNameRoot,
                'margin-0': disableSpacing,
                hidden: hidden
            })}
        >
            <Typography
                color={labelColor}
                variant="caption"
            >
                {label}
                {sup && <sup>{sup}</sup>}
                {isRequired && (
                    <Typography
                        component="span"
                        color="errorRed"
                        semibold
                        className={classes.required}
                    >
                        *
                    </Typography>
                )}
            </Typography>
            <div className={classes.container}>
                <Box
                    position="relative"
                    width="100%"
                >
                    {!disableCleave ? (
                        <Cleave
                            className={clsx({
                                [classes.input]: true,
                                [classes.right]: right,
                                [classes.error]: hasError(name),
                                [className]: className,
                                [classes.variant]: variant && hasValue(name)
                            })}
                            name={name}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            spellCheck={false}
                            style={{
                                paddingRight: paddingRight,
                                fontSize: size || 14
                            }}
                            type="text"
                            placeholder=" "
                            value={formatted}
                            {...rest}
                        />
                    ) : (
                        <input
                            className={clsx({
                                [classes.input]: true,
                                [classes.right]: right,
                                [classes.error]: hasError(name),
                                [className]: className,
                                [classes.variant]: variant && hasValue(name)
                            })}
                            name={name}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            spellCheck={false}
                            style={{
                                paddingRight: paddingRight,
                                fontSize: size || 14
                            }}
                            type="text"
                            placeholder=" "
                            value={formState.values[name] || ''}
                            {...rest}
                        />
                    )}
                    {adornment && (
                        <div
                            className={classes.wrapper}
                            ref={adornmentRef}
                        >
                            <Typography
                                gutterBottom={false}
                                size={size || 14}
                            >
                                {adornment}
                            </Typography>
                        </div>
                    )}
                </Box>
                {clearable && (['money', 'money-2'].includes(format) ? amount.parse(formatted) > 0 : true) && (
                    <button
                        className={classes.clear}
                        onClick={reset}
                        type="button"
                    >
                        <Icon
                            name="axa-icons-cross"
                            color="blue"
                            size={size || 16}
                            flex
                        />
                    </button>
                )}
            </div>
            {!hiddenError && hasError(name) && (
                <Box
                    display="flex"
                    alignItems="center"
                >
                    <Box mr={0.5}>
                        <Icon
                            name="axa-icons-cross"
                            color="errorRed"
                            size={11}
                        />
                    </Box>

                    <Typography
                        className="error-message"
                        color="errorRed"
                        gutterBottom={false}
                    >
                        {formState.errors[name][0]}
                    </Typography>
                </Box>
            )}
        </div>
    );
}

Text.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    classNameRoot: PropTypes.string,
    label: PropTypes.node,
    labelColor: PropTypes.string,
    right: PropTypes.bool,
    hidden: PropTypes.bool,
    adornment: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sup: PropTypes.string,
    disableSpacing: PropTypes.bool,
    size: PropTypes.number,
    submitOnBlur: PropTypes.bool,
    disableCleave: PropTypes.bool,
    hiddenError: PropTypes.bool,
    isRequired: PropTypes.bool,
    variant: PropTypes.oneOf(['blue'])
};

export default Text;
