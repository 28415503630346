import React from 'react';
import moment from 'moment';
import {Grid, Icon, Inputs, Typography} from 'components';
import {useForm} from 'hooks';

const MarelSimulatorWarn = ({children}) => (
    <Typography color="orangeLight">
        <Icon
            name="axa-icons-danger"
            color="orangeLight"
        />
        &nbsp;{children}
    </Typography>
);

const Category = ({values, children, category}) => values.includes(category) && <>{children}</>;

function Categories({category, situation}) {
    const {formState} = useForm();

    if (situation.startsWith('INA')) return null;

    return (
        <>
            {/* Warning messages */}
            <Category
                category={category}
                values={['ACI_CONJ']}
            >
                <MarelSimulatorWarn>
                    Vos cotisations issues de votre statut de conjoint collaborateur déclaré sont calculées par défaut
                    sur une assiette de cotisation correspondant à un tiers du plafond annuel de la Sécurité sociale
                    (PASS).
                </MarelSimulatorWarn>
            </Category>

            <Category
                category={category}
                values={['ART_AUT']}
            >
                <MarelSimulatorWarn>
                    Le simulateur calcule vos droits sans prise en compte de la part de vos revenus issus de revenus
                    d’artiste-auteur compositeur lyrique, dramatique, auteur de spectacle vivant, auteur de film,
                    dialoguiste de doublage.
                </MarelSimulatorWarn>
            </Category>

            <Category
                category={category}
                values={['ASS', 'ASS_MAJ']}
            >
                <MarelSimulatorWarn>
                    Si vous êtes agent gérant minoritaire, merci de vous rapprocher de la CAVAMAC.
                </MarelSimulatorWarn>
            </Category>

            <Category
                category={category}
                values={['ASS_CAJ']}
            >
                <MarelSimulatorWarn>
                    Le simulateur calcule vos droits sur 25% des commissions et rémunérations de votre conjoint.
                    Attention: la CAVAMAC calcule vos droits sur 25% ou 50% selon le choix que vous avez fait.
                </MarelSimulatorWarn>
            </Category>

            <Category
                category={category}
                values={['FTA', 'FTB']}
            >
                <MarelSimulatorWarn>
                    Dans sa situation actuelle, le simulateur ne gère pas les départs anticipés pour invalidité (au
                    titre du handicap, lié au risque amiante, ou réforme), les pensions père/mère de famille,
                    proportionnelles ainsi que les majorations pour enfants handicapés.
                </MarelSimulatorWarn>
            </Category>

            <Category
                category={category}
                values={['MIC_VHR', 'MIC_BIC', 'MIC_BNC']}
            >
                <MarelSimulatorWarn>
                    Dans sa situation actuelle, le simulateur ne permet pas de calculer votre pension pour vos périodes
                    si vous êtes micro entrepreneur CIPAV.
                </MarelSimulatorWarn>
            </Category>

            <Category
                category={category}
                values={['PHARM', 'BIOL_CONV', 'BIOL_NCONV', 'COTI']}
            >
                <MarelSimulatorWarn>
                    Dans sa situation actuelle, le simulateur ne prend pas en compte les réductions de cotisations.
                </MarelSimulatorWarn>
            </Category>

            {/* Inputs related to select parent */}
            <Category
                category={category}
                values={['CDSPT', 'PUET', 'INFT', 'INFH', 'CDSPH', 'PUEH', 'PDRH', 'MEMH']}
            >
                <Inputs.Checkbox
                    name="exercise_option_right"
                    options={[
                        {
                            label: "J'ai exercé mon droit d'option lors de mon changement de corps ou de cadre d'emploi",
                            name: 'exercise_right_option'
                        }
                    ]}
                />
            </Category>

            <Category
                category={category}
                values={[
                    'NS',
                    'NG',
                    'AGENT',
                    'FSA',
                    'FSB',
                    'DAPO',
                    'DAPE',
                    'FCA',
                    'FCB',
                    'SPP',
                    'APM',
                    'CDSPT',
                    'PUET',
                    'INFT',
                    'FHA',
                    'FHB',
                    'AS',
                    'INFH',
                    'CDSPH',
                    'PUEH',
                    'PDRH',
                    'MEMH',
                    'C_NOT',
                    'FTA',
                    'FTB',
                    'AC',
                    'AB',
                    'AO',
                    'AL',
                    'CCPA',
                    'TADA',
                    'TSDA',
                    'CAA',
                    'INT',
                    'ENS',
                    'JOU',
                    'AC',
                    'AB',
                    'AO',
                    'AL',
                    'CCPA',
                    'TADA',
                    'TSDA',
                    'CAA'
                ]}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={7}
                    >
                        <Inputs.Select
                            name="activity_rate"
                            label={<span style={{whiteSpace: 'nowrap'}}>Quel est mon taux d'activité annuel ?</span>}
                            disableSpacing
                            options={[
                                {
                                    label: 'Temps plein',
                                    value: 'full'
                                },
                                {
                                    label: 'Temps partiel',
                                    value: 'partial'
                                }
                            ]}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={5}
                    >
                        <Inputs.Number
                            name="activity_rate_percentage"
                            label="&nbsp;"
                            disableSpacing
                            adornment="%"
                            right
                            options={{
                                blocks: [3]
                            }}
                        />
                    </Grid>
                </Grid>
            </Category>

            <Category
                category={category}
                values={[
                    'NS',
                    'NG',
                    'AGENT',
                    'PLCI',
                    'APL',
                    'C_NOT',
                    'AC',
                    'AB',
                    'AO',
                    'AL',
                    'CCPA',
                    'TADA',
                    'TSDA',
                    'CAA',
                    'INT',
                    'ENS',
                    'JOU'
                ]}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={7}
                    >
                        <Inputs.Number
                            name="salary"
                            format="money"
                            label={
                                <span style={{whiteSpace: 'nowrap'}}>
                                    Quels sont mes revenus bruts mensuels actuels ?
                                </span>
                            }
                            disableSpacing
                            adornment="€"
                            right
                        />
                    </Grid>
                    <Grid
                        item
                        xs={5}
                    >
                        <Inputs.Number
                            name="month_number"
                            label="&nbsp;"
                            disableSpacing
                            adornment="mois"
                            right
                        />
                    </Grid>
                </Grid>
            </Category>

            <Category
                category={category}
                values={[
                    'NSA',
                    'ACI',
                    'FHA',
                    'AUX',
                    'AUX_NC',
                    'CDC',
                    'CD',
                    'MCS1',
                    'MCS2',
                    'MNC',
                    'NOT',
                    'PHARM',
                    'BIOL_CONV',
                    'BIOL_NCONV',
                    'COTI',
                    'SFC',
                    'SF'
                ]}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={7}
                    >
                        <Inputs.Number
                            name="salary"
                            format="money"
                            label={
                                <span style={{whiteSpace: 'nowrap'}}>
                                    Quels sont mes revenus bruts annuels actuels ?
                                </span>
                            }
                            disableSpacing
                            adornment="€"
                            right
                        />
                    </Grid>
                </Grid>
            </Category>

            <Category
                category={category}
                values={['MIC_VHR', 'MIC_BIC', 'MIC_BNC']}
            >
                <Inputs.Number
                    name="salary"
                    format="money"
                    label="Quel est votre chiffre d'affaire annuel&nbsp;?"
                    disableSpacing
                    adornment="€"
                    right
                />
            </Category>

            <Category
                category={category}
                values={[
                    'FSA',
                    'FSB',
                    'FCA',
                    'FCB',
                    'APM',
                    'CDSPT',
                    'PUET',
                    'INFT',
                    'FHA',
                    'FHB',
                    'AS',
                    'INFH',
                    'CDSPH',
                    'PUEH',
                    'PDRH',
                    'MEMH',
                    'DAPO',
                    'DAPE',
                    'SPP'
                ]}
            >
                <Inputs.Number
                    name="current_increased_index"
                    label="Indice majoré estimé en fin de carrière"
                />
            </Category>

            <Category
                category={category}
                values={['PLCI']}
            >
                <Inputs.Checkbox
                    name="complementary_scheme_premium_class_contribution"
                    options={[
                        {
                            label: 'Je cotise dans la classe supérieure à mon revenu pour le régime complémentaire',
                            name: 'complementary_scheme_premium_class_contribution'
                        }
                    ]}
                />
            </Category>

            <Category
                category={category}
                values={['NOT']}
            >
                <Inputs.Number
                    name="salary"
                    format="money"
                    label="Revenus annuels"
                    disableSpacing
                    adornment="€"
                    right
                />
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Inputs.Number
                            name="contribution_class_past_year"
                            label={`Classe de cotisation ${new Date().getFullYear() - 1}`}
                            disableSpacing
                            right
                            options={{
                                blocks: [1]
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Inputs.Number
                            name="assignment_class_past_year"
                            label={`Classe d'affectation ${new Date().getFullYear() - 1}`}
                            disableSpacing
                            right
                            options={{
                                blocks: [1]
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Inputs.Number
                            name="assignment_class_current_year"
                            label="Classe d'affectation courante"
                            disableSpacing
                            right
                            options={{
                                blocks: [1]
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Inputs.Datepicker
                            name="oath_date"
                            label="Quand avez-vous démarré votre activité de notaire libérale&nbsp;?"
                            disableSpacing
                            maxDate={moment.utc().toDate()}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Inputs.Datepicker
                            name="class_change_date"
                            label="Quand avez-vous changé de classe pour la dernière fois&nbsp;?"
                            disableSpacing
                            maxDate={moment.utc().toDate()}
                        />
                    </Grid>
                </Grid>
            </Category>

            <Category
                category={category}
                values={['PHARM', 'BIOL_CONV', 'BIOL_NCONV', 'COTI', 'MCS1', 'MCS2', 'MNC', 'MAV']}
            >
                <Inputs.Number
                    name="salary"
                    format="money"
                    label="Revenus non salariés annuels"
                    disableSpacing
                    adornment="€"
                    right
                />
            </Category>

            <Category
                category={category}
                values={['MCS1', 'MCS2']}
            >
                <Inputs.Number
                    name="salary_component_1"
                    format="money"
                    label="Revenus conventionnels annuels"
                    disableSpacing
                    adornment="€"
                    right
                />
            </Category>

            <Category
                category={category}
                values={['MCCM']}
            >
                <Inputs.Number
                    name="salary"
                    format="money"
                    label="Assiette de cotisation"
                    disableSpacing
                    adornment="€"
                    right
                />
            </Category>

            <Category
                category={category}
                values={['ART_AUT']}
            >
                <Inputs.Number
                    name="salary"
                    format="money"
                    label="Revenus annuels en droit d'auteur bruts"
                    disableSpacing
                    adornment="€"
                    right
                />
                <Inputs.Number
                    name="salary_component_1"
                    format="money"
                    label="Part des revenus d'artiste-auteur compositeur lyrique ou dialoguiste de doublage"
                    disableSpacing
                    adornment="€"
                    right
                />
                <Inputs.Number
                    name="salary_component_2"
                    format="money"
                    label="Part des revenus d'artiste-auteur compositeur dramatique, auteur de spectacle vivant, auteur de film"
                    disableSpacing
                    adornment="€"
                    right
                />
            </Category>

            <Category
                category={category}
                values={['FTA', 'FTB']}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Inputs.Number
                            name="final_position"
                            label="Position"
                            disableSpacing
                            right
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Inputs.Number
                            name="final_grade"
                            label="Echelon"
                            disableSpacing
                            right
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Inputs.Number
                            name="final_prime_code"
                            label="Code prime"
                            disableSpacing
                            right
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Inputs.Datepicker
                            name="grade_date"
                            label="A quelle date avez-vous obtenu cet échelon&nbsp;?"
                            disableSpacing
                        />
                    </Grid>
                </Grid>
            </Category>

            <Category
                category={category}
                values={['FTB']}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Inputs.Number
                            name="premium_traction"
                            format="money"
                            label="Veuillez renseigner votre prime de traction moyenne mensuelle"
                            disableSpacing
                            adornment="€"
                            right
                        />
                    </Grid>
                </Grid>
            </Category>

            <Category
                category={category}
                values={['ASS', 'ASS_MAJ']}
            >
                <Inputs.Number
                    name="salary"
                    format="money"
                    label="Montant annuel brut de vos rémunérations (hors commissions)"
                    disableSpacing
                    adornment="€"
                    right
                />

                <Inputs.Number
                    name="salary_component_1"
                    format="money"
                    label="Montant annuel brut de vos commissions"
                    disableSpacing
                    adornment="€"
                    right
                />
            </Category>

            <Category
                category={category}
                values={['ASS_CAJ']}
            >
                <Inputs.Number
                    name="salary"
                    format="money"
                    label="Montant annuel brut des rémunérations (hors commissions) de votre conjoint(e)"
                    disableSpacing
                    adornment="€"
                    right
                />

                <Inputs.Number
                    name="salary_component_1"
                    format="money"
                    label="Montant annuel brut des commissions de votre conjoint(e)"
                    disableSpacing
                    adornment="€"
                    right
                />
            </Category>

            <Category
                category={category}
                values={['IEGA', 'IEGB', 'IEGMNC', 'IEGMC', 'IEGI']}
            >
                <Inputs.Select
                    name="activity_rate"
                    label={<span style={{whiteSpace: 'nowrap'}}>Quel est mon taux d'activité annuel ?</span>}
                    disableSpacing
                    options={[
                        {
                            label: 'Temps plein',
                            value: 'full'
                        },
                        {
                            label: 'Temps partiel',
                            value: 'partial'
                        }
                    ]}
                />
                {category !== 'IEGA' && (
                    <Inputs.Number
                        name="active_services_rate_percentage"
                        label={<span style={{whiteSpace: 'nowrap'}}>Taux de services actifs ?</span>}
                        disableSpacing
                        adornment="%"
                        right
                        options={{
                            blocks: [3]
                        }}
                    />
                )}
                <Inputs.Number
                    name="contribution_rate_percentage"
                    label={<span style={{whiteSpace: 'nowrap'}}>Taux de cotisation ?</span>}
                    disableSpacing
                    adornment="%"
                    right
                    options={{
                        blocks: [3]
                    }}
                />
                <Inputs.Radio
                    name="out_of_category"
                    label="Êtes-vous hors catégorie ?"
                    options={[
                        {
                            label: 'Non',
                            value: 'false'
                        },
                        {
                            label: 'Oui',
                            value: 'true'
                        }
                    ]}
                    raw
                />
                {formState.values.out_of_category === 'false' ? (
                    <Grid container>
                        <Grid item>
                            <Inputs.Select
                                name="remuneration_rate"
                                label={
                                    <span style={{whiteSpace: 'nowrap'}}>Quel est votre niveau de rémunération ?</span>
                                }
                                disableSpacing
                                options={['', 30, 35, 40, 45, 50, 55].map((remuneration_rate) => ({
                                    label: remuneration_rate ? remuneration_rate : '-- niveau de rémunération --',
                                    value: remuneration_rate
                                }))}
                            />
                        </Grid>
                        <Grid item>
                            <Inputs.Select
                                name="final_grade"
                                label={<span style={{whiteSpace: 'nowrap'}}>Quel est votre échelon ?</span>}
                                disableSpacing
                                options={['', 4, 5, 6, 7, 8, 9].map((final_grade) => ({
                                    label: final_grade ? final_grade : '-- Selectionner votre échelon --',
                                    value: final_grade
                                }))}
                            />
                        </Grid>
                        <Grid item>
                            <Inputs.Datepicker
                                name="grade_date"
                                label="A quelle date l'avez-vous obtenu ?"
                                disableSpacing
                                maxDate={moment.utc().toDate()}
                            />
                        </Grid>
                        <Grid item>
                            <Grid item>
                                <Inputs.Number
                                    name="conduct_rate"
                                    label="Quels est votre taux de conduite ? (facultatifs)"
                                    disableSpacing
                                />
                            </Grid>
                            <Grid item>
                                <Inputs.Select
                                    name="markup_resident"
                                    label="Quel est votre taux de majoration résidentielle ? (facultatifs)"
                                    disableSpacing
                                    options={['', 24, 24.5, 25].map((markup_resident) => ({
                                        label: markup_resident ? markup_resident : '-- Sélectionner votre taux --',
                                        value: markup_resident
                                    }))}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        item
                        xs={7}
                    >
                        <Inputs.Number
                            name="salary"
                            format="money"
                            label={
                                <span style={{whiteSpace: 'nowrap'}}>
                                    Quels sont mes revenus bruts mensuels actuels ?
                                </span>
                            }
                            disableSpacing
                            adornment="€"
                            right
                        />
                    </Grid>
                )}
            </Category>
            <Category
                category={category}
                values={['FRBA1', 'FRBA2', 'FRBB', 'FRA']}
            >
                <Inputs.Number
                    name="current_reference_rate"
                    format="percentage"
                    label={<span style={{whiteSpace: 'nowrap'}}>Coefficient de référence actuel ?</span>}
                    disableSpacing
                    right
                />
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={7}
                    >
                        <Inputs.Select
                            name="activity_rate"
                            label={<span style={{whiteSpace: 'nowrap'}}>Quel est mon taux d'activité annuel ?</span>}
                            disableSpacing
                            options={[
                                {
                                    label: 'Temps plein',
                                    value: 'full'
                                },
                                {
                                    label: 'Temps partiel',
                                    value: 'partial'
                                }
                            ]}
                        />
                    </Grid>
                    {formState.values.activity_rate === 'partial' && (
                        <Grid
                            item
                            xs={5}
                        >
                            <Inputs.Number
                                name="activity_rate_percentage"
                                label="&nbsp;"
                                disableSpacing
                                adornment="%"
                                right
                                options={{
                                    blocks: [3]
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Category>
        </>
    );
}

export default Categories;
