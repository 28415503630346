import {json} from 'utils';

const profileURL = ({journey, scope, action} = {}) => {
    const pathname = window.location.pathname;
    const params = pathname.split('/').slice(2);

    // eslint-disable-next-line
    const [currentScope, currentJourney, currentAction] = params;

    const user = json.parse(localStorage.getItem('EP_user')) || {};

    const defaultScope = (user.scopes || []).includes('s2e') ? 's2e' : 'ais';

    const targetScope = scope ? scope : ['ais', 's2e'].includes(currentScope) ? currentScope : defaultScope;
    const targetJourney = journey || currentJourney || null;
    const targetAction = action || null;

    const targetPath = [targetScope, targetJourney, targetAction].filter((part) => part).join('/');

    return `/profile/${targetPath}`;
};

export default profileURL;
