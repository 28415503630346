import mock from 'api/mockFastApi';

import {bi_accounts, bi_investments, bi_transactions} from './data';

mock.onPost('/services/budget-insight/accounts').reply(() => {
    return [200, {bi_accounts}];
});

mock.onPost('/services/budget-insight/investments').reply(() => {
    return [200, bi_investments];
});

mock.onPost('/services/budget-insight/transactions').reply(() => {
    return [200, bi_transactions];
});
