import React, {useState} from 'react';
import {json} from 'utils';

export const TransmissionContext = React.createContext({
    transmission: {},
    setTransmission: () => {
    }
});


export const TransmissionProvider = props => {

    const setTransmission = transmission => {
        setState(state => ({...state, transmission: transmission}));
        localStorage.setItem('EP_TRANSMISSION', JSON.stringify(transmission));
    };


    const initState = {
        transmission: json.parse(localStorage.getItem('EP_TRANSMISSION')) || {},
        setTransmission: setTransmission,
    };

    const [state, setState] = useState(initState);

    return (
        <TransmissionContext.Provider value={state}>
            {props.children}
        </TransmissionContext.Provider>
    )
};