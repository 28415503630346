import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from 'components';
import {useStepper, useUser} from 'hooks';
import {amount, wording} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 0)
    },
    wrapper: {
        background: '#e8e9ff',
        padding: theme.spacing(1, 0)
    },
    container: {
        maxWidth: 600,
        margin: 'auto'
    },
    title: {
        fontSize: '29px !important'
    },
    subtitle: {
        marginBottom: '1em'
    }
}));

function AnnuityTypeResult({annuity_type, policy_id}) {
    const classes = useStyles();

    const {user} = useUser();
    const {stepper} = useStepper();

    const account = user.accounts.find((account) => account.policy_id === policy_id);

    let data = null;
    if (stepper.response?.find((response) => response.policy_id === policy_id).response)
        data = stepper.response?.find((response) => response.policy_id === policy_id).response.resultatPourChaqueTaux
            .resultatTauxTechnique[0];

    if (!data || !account) return null;

    return (
        <div className={classes.root}>
            {stepper.response?.length > 1 ? (
                <Typography>
                    <Typography
                        component="span"
                        semibold
                    >
                        {account.title}{' '}
                    </Typography>
                    - Contrat n°{policy_id}
                </Typography>
            ) : (
                <Typography
                    variant="h4"
                    color="blue"
                >
                    Pour une{' '}
                    <Typography
                        component="span"
                        semibold
                    >
                        {wording.translateAnnuity(annuity_type, false)}
                    </Typography>
                    , le montant est estimé à :
                </Typography>
            )}

            <div className={classes.wrapper}>
                {(annuity_type === 'RV_R' || annuity_type === 'RV_NR') && (
                    <Typography
                        variant="h3"
                        color="blue"
                        center
                        gutterBottom={false}
                    >
                        <Typography
                            className={classes.title}
                            component="span"
                            semibold
                            gutterBottom={false}
                        >
                            {annuity_type === 'RV_R' &&
                                amount.format(data.optionReversion.rentesParAge.renteParAge[0].rente)}
                            {annuity_type === 'RV_NR' &&
                                amount.format(data.horsOption.rentesParAge.renteParAge[0].rente)}
                        </Typography>{' '}
                        bruts par an
                    </Typography>
                )}

                {annuity_type === 'RV_RAG' && (
                    <Grid
                        container
                        className={classes.container}
                        spacing={2}
                    >
                        {data.optionReversion.rentesAnnuitesGaranties.renteAnnuitesGaranties.map((result, index) => (
                            <Grid
                                key={`annuity-result-${index}`}
                                item
                                sm={6}
                                xs={12}
                            >
                                <Typography
                                    className={classes.subtitle}
                                    variant="h5"
                                    center
                                    color="grayDark"
                                >
                                    Garantie {5 * (index + 2)} ans
                                </Typography>
                                <Typography
                                    variant="h2"
                                    color="blue"
                                    center
                                    semibold
                                    gutterBottom={false}
                                >
                                    {amount.format(result.rente)}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    color="blue"
                                    center
                                    gutterBottom={false}
                                >
                                    bruts par an
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                )}

                {annuity_type === 'RV_NRAG' && (
                    <Grid
                        container
                        className={classes.container}
                        spacing={2}
                    >
                        {data.horsOption.rentesAnnuitesGaranties.renteAnnuitesGaranties.map((result, index) => (
                            <Grid
                                key={`annuity-result-${index}`}
                                item
                                sm={4}
                                xs={12}
                            >
                                <Typography
                                    className={classes.subtitle}
                                    variant="h5"
                                    center
                                    color="grayDark"
                                >
                                    Garantie {5 * (index + 1)} ans
                                </Typography>
                                <Typography
                                    variant="h2"
                                    color="blue"
                                    center
                                    semibold
                                    gutterBottom={false}
                                >
                                    {amount.format(result.rente)}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    color="blue"
                                    center
                                    gutterBottom={false}
                                >
                                    bruts par an
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                )}

                {annuity_type === 'RV_CPP' && (
                    <Grid
                        container
                        className={classes.container}
                        spacing={2}
                    >
                        {data.horsOption.rentesParPalier.renteParPalier.map((result, index) => (
                            <Grid
                                key={`annuity-result-${index}`}
                                item
                                sm={4}
                                xs={12}
                            >
                                <Typography
                                    className={classes.subtitle}
                                    variant="h5"
                                    center
                                    color="grayDark"
                                >
                                    {result.palier}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    color="blue"
                                    center
                                    semibold
                                    gutterBottom={false}
                                >
                                    {amount.format(
                                        amount.parse(data.horsOption.rentesParPalier.renteParPalier[0].rente) *
                                            Math.pow(1.2, index)
                                    )}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    color="blue"
                                    center
                                    gutterBottom={false}
                                >
                                    bruts par an
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </div>
        </div>
    );
}

AnnuityTypeResult.propTypes = {
    annuity_type: PropTypes.string,
    policy_id: PropTypes.string
};

export default AnnuityTypeResult;
