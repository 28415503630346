import React from 'react';
import PropTypes from 'prop-types';
import {fastapi} from 'api';
import {useUser} from 'hooks';
import {Form, Grid, Icon, Structure, Typography} from 'components';
import {
    StepImprovementComment,
    StepIntro,
    StepResultOpinion,
    StepSatisfaction,
    StepSatisfactionComment
} from './components';

import {makeStyles} from '@material-ui/styles';
import createPersistedState from 'use-persisted-state';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        zIndex: 1002,
        right: 7,
        bottom: 20,
        width: '100%',
        maxWidth: 350,
        height: 390,
        borderRadius: 3,
        overflow: 'hidden',
        background: 'white',
        boxShadow: theme.shadows[3]
    },
    header: {
        display: 'flex',
        background: '#00008f',
        padding: theme.spacing(1)
    },
    step: {
        padding: theme.spacing(2)
    }
}));

const useOpinion = createPersistedState('EP_opinion');

function OpinionBox({setOpen, setVisible}) {
    const classes = useStyles();
    const {user} = useUser();

    const [, setShowOpinion] = useOpinion(false);

    const handleClose = () => {
        setOpen(false);
        setVisible(true);
    };

    return (
        <div className={classes.root}>
            <Grid
                item
                className={classes.header}
            >
                <Typography
                    color="white"
                    gutterBottom={false}
                >
                    Donnez nous votre avis !
                </Typography>
                <div className="flexGrow" />
                <Icon
                    role={'button'}
                    tabIndex={0}
                    alt="fermez la fenêtre"
                    aria-label="fermez la fenêtre"
                    className={classes.icon}
                    name="axa-icons-cross"
                    color="white"
                    size={18}
                    onClick={handleClose}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleClose();
                        }
                    }}
                />
            </Grid>
            <Form
                schema={{
                    satisfaction: {
                        presence: {
                            allowEmpty: false
                        }
                    }
                }}
                submit={(formState) =>
                    fastapi
                        .post('/resources/opinion/update-opinion', {
                            user_id: user.id,
                            opinion: formState.values
                        })
                        .then(() => {
                            setOpen(false);
                            setShowOpinion(false);
                            setVisible(false);
                        })
                }
            >
                <Structure.Carousel smooth>
                    <StepIntro />
                    <StepSatisfaction />
                    <StepSatisfactionComment />
                    <StepImprovementComment />
                    <StepResultOpinion />
                </Structure.Carousel>
            </Form>
        </div>
    );
}

OpinionBox.propTypes = {
    setOpen: PropTypes.func.isRequired
};

export default OpinionBox;
