const regroupByAvailability = (accounts) => {
    let now_accounts = [];
    let withConditions_accounts = [];
    let retirement_accounts = [];
    accounts.forEach((account) => {
        (account.investments || []).forEach((investment) => {
            if (account.scope === 's2e') {
                (investment.availabilities || []).forEach((availability) => {
                    if (availability?.disponibilite?.condition.toUpperCase() === 'DISPONIBLE') {
                        now_accounts.push(account);
                    } else if (availability?.disponibilite?.condition.toUpperCase() === 'INDISPONIBLE') {
                        withConditions_accounts.push(account);
                    } else if (availability?.disponibilite?.condition.toUpperCase() === 'RETRAITE') {
                        retirement_accounts.push(account);
                    }
                });
            } else if (['ais', 'b2v'].includes(account.scope)) {
                retirement_accounts.push(account);
            }
        });
    });

    return {
        now: [...new Set(now_accounts)],
        withConditions: [...new Set(withConditions_accounts)],
        retirement: [...new Set(retirement_accounts)]
    };
};

export default regroupByAvailability;
