import React from 'react';
import {Buttons, Typography} from 'components';
import {useTaxSimulator} from 'hooks';
import {amount, modal} from 'utils';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    amount: {
        whiteSpace: 'nowrap'
    }
}));

const SubStepResult = () => {
    const classes = useStyles();
    const {taxSimulator} = useTaxSimulator();

    return (
        <>
            <Typography
                variant="h5"
                color="blue"
            >
                Vous pouvez économiser jusqu'à&nbsp;
                <Typography
                    className={classes.amount}
                    component="span"
                    semibold
                >
                    {amount.format(taxSimulator.response.tax_reduction_ceiling, 0, '€')}
                </Typography>
                &nbsp;en&nbsp;{new Date().getFullYear() + 1}&nbsp;en épargnant jusqu'à&nbsp;
                <Typography
                    className={classes.amount}
                    component="span"
                    semibold
                >
                    {amount.format(taxSimulator.response.vv_envelope_monthly, 0, '€/mois')}
                </Typography>
                &nbsp;en&nbsp;{new Date().getFullYear()}
            </Typography>
            <Buttons.Default
                label="Terminer"
                color="blueAlpha"
                center
                onClick={() => modal.close('refine')}
            />
        </>
    );
};

export default SubStepResult;
