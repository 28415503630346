import React, {useState} from 'react';

export const FilibContext = React.createContext({
    filibURL: null,
    setFilibUrl: () => {}
});

export const FilibProvider = (props) => {
    const setFilibUrl = (filibUrl) => {
        setState({...state, filibUrl: filibUrl});
    };

    const initState = {
        filibUrl: null,
        setFilibUrl: setFilibUrl
    };

    const [state, setState] = useState(initState);

    return <FilibContext.Provider value={state}>{props.children}</FilibContext.Provider>;
};
