import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';

import {makeStyles} from '@material-ui/styles';
import {Trans} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    button: {
        border: `1px solid ${theme.palette.grayDark}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        minWidth: '14rem',
        width: '100%',
        maxWidth: '22rem',
        maxHeight: '170px',
        color: theme.palette.white,
        cursor: 'pointer',
        userSelect: 'none',
        outline: 'none',
        transition: 'border 50ms',
        marginRight: '10px',
        '&:after': {
            content: '""',
            display: 'block',
            paddingBottom: '100%'
        },
        '&:hover, &:focus': {
            border: `solid 2px ${theme.palette.blue}`,
            '& *': {
                color: theme.palette.blue,
                fontWeight: 600
            }
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '12rem',
            maxWidth: '14rem'
        }
    },
    disabled: {
        border: '1px solid #999 !important',
        opacity: '0.5'
    },
    label: {
        fontWeight: '400',
        fontSize: '18px',
        color: theme.palette.dark
    },
    icon: {
        width: '80px',
        height: '80px'
    },
    subLabel: {
        fontSize: '14px',
        color: theme.palette.dark,
        marginTop: theme.spacing(0.5)
    }
}));

function Icon(props) {
    const {label, subLabel, className, classLabel, iconName, iconAlt, classIcon, disabled, ...rest} = props;
    const classes = useStyles();

    return (
        <button
            className={clsx({
                [classes.button]: true,
                [classes.disabled]: disabled,
                [className]: className
            })}
            disabled={disabled}
            {...rest}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                {iconName && (
                    <img
                        className={clsx(classes.icon, classIcon && classIcon)}
                        src={iconName}
                        alt={iconAlt || ''}
                    />
                )}

                {label && (
                    <span className={clsx(classes.label, classLabel && classLabel)}>
                        <Trans i18nKey={label} />
                    </span>
                )}

                {subLabel && (
                    <span className={clsx(classes.subLabel)}>
                        <Trans i18nKey={subLabel} />
                    </span>
                )}
            </Box>
        </button>
    );
}

Icon.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    classLabel: PropTypes.string,
    iconName: PropTypes.string.isRequired,
    iconAlt: PropTypes.string,
    classIcon: PropTypes.string,
    disabled: PropTypes.bool
};

export default Icon;
