const obfuscateIban = (string, group_length = 4, obfuscator = 'X') => {
    if (!string) return '';

    let stringWithoutSpace = string.split(' ').join('');

    let stringFormatted = '';

    for (let i = 0; i <= parseInt(stringWithoutSpace.length / group_length); i++) {
        let start = group_length * i;
        let end = group_length * (i + 1);

        if (i === 0 || i === parseInt(stringWithoutSpace.length / group_length))
            stringFormatted += `${stringWithoutSpace.slice(start, end)} `;
        else stringFormatted += `${obfuscator.repeat(group_length)} `;
    }

    stringFormatted = stringFormatted.trim();

    return stringFormatted;
};

export default obfuscateIban;
