import React from 'react';
import {Icon, Inputs, Typography} from 'components';
import moment from 'moment';

const Warn = ({children}) => (
    <Typography color="yellow">
        <Icon
            name="axa-icons-danger"
            color="yellow"
        />
        &nbsp;{children}
    </Typography>
);

const Situation = ({values, children, situation}) => values.includes(situation) && <>{children}</>;

function Situations({situation}) {
    return (
        <>
            <Situation
                situation={situation}
                values={['INA_MAL']}
            >
                <Warn>
                    Vous êtes concerné si vous êtes en arrêt maladie. Sélectionnez « Invalidité » si vous êtes en
                    invalidité ou en arrêt maladie de plus de 3 ans.
                </Warn>
            </Situation>

            <Situation
                situation={situation}
                values={['INA_INV']}
            >
                <Warn>
                    Vous êtes concerné si vous êtes en invalidité ou en arrêt maladie de plus de 3 ans. Sélectionnez «
                    Maladie » si vous êtes en arrêt maladie de moins de 3 ans.
                </Warn>
            </Situation>

            <Situation
                situation={situation}
                values={[
                    'ACT_MAL',
                    'ACT_CHO_IND',
                    'ACT_INV',
                    'ACT_DIS',
                    'INA_MAL',
                    'INA_CHO_IND',
                    'INA_CHO_NON_IND',
                    'INA_INV',
                    'INA_DIS',
                    'INA_CPA',
                    'INA_DISPO_GEN'
                ]}
            >
                <Inputs.Datepicker
                    name="situation_date"
                    label="Depuis quand suis-je dans cette situation&nbsp;?"
                    format="MMM yyyy"
                    views={['year', 'month']}
                    maxDate={moment.utc().toDate()}
                />
            </Situation>

            <Situation
                situation={situation}
                values={['INA_MAL']}
            >
                <Inputs.Datepicker
                    name="situation_end_date"
                    label="Quelle est la date de fin estimée de ma période de maladie&nbsp;?"
                />
            </Situation>

            <Situation
                situation={situation}
                values={['ACT_MAL', 'INA_MAL']}
            >
                <Inputs.Number
                    name="situation_indemnity_amount"
                    format="money"
                    label="Quel est le montant de mes indemnités journalières&nbsp;?"
                    adornment="€/mois"
                    right
                    submitOnBlur
                />
            </Situation>

            <Situation
                situation={situation}
                values={['ACT_CHO_IND', 'INA_CHO_IND']}
            >
                <Inputs.Number
                    name="situation_indemnity_amount"
                    format="money"
                    label="Quel est le montant de mes allocations chômage&nbsp;?"
                    adornment="€/mois"
                    right
                    submitOnBlur
                />
            </Situation>

            <Situation
                situation={situation}
                values={['ACT_INV', 'INA_INV']}
            >
                <Inputs.Number
                    name="situation_indemnity_amount"
                    format="money"
                    label="Quel est le montant de ma pension d'invalidité&nbsp;?"
                    adornment="€/mois"
                    right
                    submitOnBlur
                />
            </Situation>

            <Situation
                situation={situation}
                values={['ACT_DIS', 'INA_DIS']}
            >
                <Inputs.Number
                    name="situation_indemnity_amount"
                    format="money"
                    label="Quel est le montant de mes prestations familiales (CAF par mois)&nbsp;?"
                    adornment="€/mois"
                    right
                    submitOnBlur
                />
            </Situation>
        </>
    );
}

export default Situations;
