import React, {useState} from 'react';

export const FormContext = React.createContext({
    formState: {
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    },
    setFormState: () => {}
});

export const FormProvider = (props) => {
    const setFormState = (formState) => {
        setState({...state, formState: formState});
    };

    const initState = {
        formState: {
            isValid: false,
            values: props.values || {},
            touched: {},
            errors: {}
        },
        setFormState: setFormState
    };

    const [state, setState] = useState(initState);

    return <FormContext.Provider value={state}>{props.children}</FormContext.Provider>;
};

export const FormConsumer = FormContext.Consumer;