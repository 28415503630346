import React from 'react';
import PropTypes from 'prop-types';
import {useUser} from 'hooks';
import {Buttons, Modal, Typography} from 'components';
import {accounts} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        width: '100%'
    },
    wrapper: {
        padding: '5px 0',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    }
}));

function PaperProcessModal({withdrawCase}) {
    const classes = useStyles();

    const {user} = useUser();

    return (
        <Modal
            hash="paper_process"
            className={classes.root}
            title="Demande par courrier"
        >
            <Typography
                variant="h5"
                center
            >
                Je fais la démarche pour :
            </Typography>

            <div className={classes.wrapper}>
                {withdrawCase === 'retirement' &&
                    accounts.filter(user.accounts, 'ais', ['PER', 'PERECO', 'PERU']).length > 0 && (
                        <Buttons.Default
                            label="Le PER"
                            color="blueAlpha"
                            next
                            onClick={() =>
                                window.open(`${process.env.PUBLIC_URL}/docs/withdraw_manual_retirement_per_2020.pdf`)
                            }
                        />
                    )}
                {withdrawCase === 'retirement' && accounts.filter(user.accounts, 'ais', ['A83']).length > 0 && (
                    <Buttons.Default
                        label="L'article 83"
                        color="blueAlpha"
                        next
                        onClick={() =>
                            window.open(`${process.env.PUBLIC_URL}/docs/withdraw_manual_retirement_a83_2020.pdf`)
                        }
                    />
                )}

                {withdrawCase === 'owner' &&
                    accounts.filter(user.accounts, 'ais', ['PER', 'PERECO', 'PERU']).length > 0 && (
                        <Buttons.Default
                            label="Le PER"
                            color="blueAlpha"
                            next
                            onClick={() => window.open(`${process.env.PUBLIC_URL}/docs/withdraw_manual_owner_per.pdf`)}
                        />
                    )}

                {withdrawCase === 'hardblows' &&
                    accounts.filter(user.accounts, 'ais', ['PER', 'PERECO', 'PERU']).length > 0 && (
                        <Buttons.Default
                            label="Le PER"
                            color="blueAlpha"
                            next
                            onClick={() =>
                                window.open(`${process.env.PUBLIC_URL}/docs/withdraw_manual_hardblows_per.pdf`)
                            }
                        />
                    )}
                {withdrawCase === 'hardblows' && accounts.filter(user.accounts, 'ais', ['A83']).length > 0 && (
                    <Buttons.Default
                        label="L'article 83"
                        color="blueAlpha"
                        next
                        onClick={() => window.open(`${process.env.PUBLIC_URL}/docs/withdraw_manual_hardblows_a83.pdf`)}
                    />
                )}
            </div>
        </Modal>
    );
}

PaperProcessModal.propTypes = {
    withdrawCase: PropTypes.oneOf(['retirement', 'owner', 'hardblows']).isRequired
};

export default PaperProcessModal;
