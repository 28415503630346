import React, {useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Typography} from 'components';
import {useForm} from 'hooks';

import {FormControlLabel, Radio as RadioInput, RadioGroup} from '@material-ui/core';

function Radio(props) {
    const {
        options,
        className,
        name,
        defaultValue,
        label,
        labelColor,
        labelBold = false,
        disabled,
        size,
        hiddenLabel = false,
        hiddenError = false,
        ...rest
    } = props;
    const {formState, setFormState} = useForm();

    const handleChange = (event) => {
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [name]: true
            }
        });
    };

    useEffect(() => {
        if (defaultValue)
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    [name]: defaultValue
                }
            });

        // eslint-disable-next-line
    }, [formState.isValid]);

    const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

    return (
        <>
            <RadioGroup
                className={clsx(className)}
                defaultValue={defaultValue}
                name={name}
                onChange={handleChange}
                value={formState.values[name] || defaultValue || ''}
                {...rest}
            >
                {!hiddenLabel && (
                    <Typography
                        className="full-width"
                        color={labelColor}
                        gutterBottom={false}
                        variant="caption"
                    >
                        {label}
                    </Typography>
                )}
                {options.map((option, index) => (
                    <FormControlLabel
                        control={<RadioInput color="primary" />}
                        key={`formControl-${index}`}
                        value={option.value}
                        label={
                            typeof option.label === 'string' ? (
                                <Typography
                                    size={size}
                                    color={labelColor}
                                    gutterBottom={false}
                                    semibold={labelBold}
                                >
                                    {option.label}
                                </Typography>
                            ) : (
                                option.label
                            )
                        }
                        disabled={disabled || option.disabled}
                    />
                ))}
            </RadioGroup>
            {!hiddenError && (
                <Typography
                    className="error-message"
                    color="redLight"
                >
                    {hasError(name) ? formState.errors[name][0] : null}
                </Typography>
            )}
        </>
    );
}

Radio.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    label: PropTypes.node,
    labelColor: PropTypes.string,
    labelBold: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.number,
    hiddenLabel: PropTypes.bool,
    hiddenError: PropTypes.bool
};

export default Radio;
