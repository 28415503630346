import React, {useState} from 'react';
import axiosMockAdapter from "../api/mock";
import axiosFastMockAdapter from "../api/mockFastApi";
import {api, fastapi} from "../api";

export const IsMockedContext = React.createContext({
    isMocked: localStorage.getItem('EP_isAppMocked', false),
    setIsMocked: () => {}
});

export const IsMockedProvider = (props) => {
    const setIsMocked = (isMocked) => {
        setState({...state, isMocked: isMocked});
        localStorage.setItem('EP_isAppMocked', isMocked);
    };

    const initState = {
        isMocked:
            process.env.REACT_APP_ENVIRONMENT === 'production'
                ? false
                : localStorage.getItem('EP_isAppMocked') === null
                ? false
                : localStorage.getItem('EP_isAppMocked') === 'true',
        setIsMocked: setIsMocked
    };

    const [state, setState] = useState(initState);


    const axiosMockApi = api.defaults.adapter;
    const axiosMockFastApi = fastapi.defaults.adapter;

    if (state.isMocked) {
        api.defaults.adapter = axiosMockApi;
        fastapi.defaults.adapter = axiosMockFastApi;
    } else {
        axiosMockAdapter.restore();
        axiosFastMockAdapter.restore();
    }

    return <IsMockedContext.Provider value={state}>{props.children}</IsMockedContext.Provider>;
};
