import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Divider, Grid} from '@material-ui/core';
import Typography from '../Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 230
    },
    wrapper: {
        borderLeft: `solid 2px ${theme.palette.gray}`,
        boxShadow: `0px 0 0 ${theme.palette.transparent}`,
        paddingLeft: 14,
        listStyle: 'none',
        transition: 'border-color 0.25s linear, box-shadow 0.25s linear'
    },
    items: {
        color: theme.palette.dark,
        fontSize: '16px !important'
    },
    activeMenu: {
        paddingLeft: 14,
        borderLeft: `solid 4px ${theme.palette.blue}`,
        boxShadow: `-3px 0px 0 ${theme.palette.blue}`,
        transition: 'border-color 0.25s linear, box-shadow 0.25s linear'
    },
    activeText: {
        color: theme.palette.blue
    },
    bar: {
        width: '100%',
        height: 6,
        borderRadius: 5,
        backgroundColor: theme.palette.gray
    },
    activeBar: {
        width: '100%',
        height: 6,
        borderRadius: 5,
        backgroundColor: theme.palette.blue,

        transition: 'border-color 0.5s linear'
    },
    link: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        '&:last-child': {
            marginRight: 0
        }
    }
}));

const checkBottomPage = () => {
    let documentHeight = document.body.scrollHeight;
    let currentScroll = window.scrollY + window.innerHeight;
    if (currentScroll >= documentHeight) {
        return true;
    }
    return false;
};

const getActiveItems = (menu) => {
    let activeItems = {};
    const currentScrollYPos = window.scrollY;
    const hashPositions = (menu || []).map((elt) => (document.getElementById(elt?.href) || {})?.offsetTop + 30);
    let startingPosY = Math.min(...hashPositions);
    (menu || []).forEach(({href}, index) => {
        const itemPosY = document.getElementById(href)?.offsetTop;
        if (itemPosY) {
            if (itemPosY <= currentScrollYPos + startingPosY) activeItems[href] = itemPosY;
            else if (checkBottomPage()) activeItems[href] = itemPosY;
            else delete activeItems[href];
        }
    });
    return activeItems;
};

const useVisibleSection = (menu) => {
    const [visibleSectionIds, setVisibleSectionIds] = React.useState([]);

    useEffect(() => {
        if (menu) {
            window.addEventListener('scroll', () => setVisibleSectionIds(getActiveItems(menu)));
        }

        if (window.scrollY === 0 && menu) {
            setVisibleSectionIds({[menu[0].href]: 0});
        } else {
            setVisibleSectionIds(getActiveItems(menu));
        }

        return () => window.removeEventListener('scroll', () => setVisibleSectionIds(getActiveItems(menu)));
        // eslint-disable-next-line
    }, [window.scrollY]);
    return visibleSectionIds;
};

const MenuHash = ({menu}) => {
    const classes = useStyles();
    const visibleSections = useVisibleSection(menu);

    return (
        <nav
            role="navigation"
            aria-label="Menu de la page"
            className={classes.root}
        >
            <Grid component="ul">
                {(menu || []).map((item, index) => (
                    <Grid
                        key={index}
                        component="li"
                        className={clsx(
                            classes.wrapper,
                            Object.keys(visibleSections).includes(item.href) && classes.activeMenu
                        )}
                        aria-current={Object.keys(visibleSections).slice(-1)[0] === item.href}
                    >
                        <a href={`#${item.href}`}>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={clsx(
                                    classes.items,
                                    Object.keys(visibleSections).includes(item.href) && classes.activeText
                                )}
                                p={0.5}
                            >
                                {item.icon}
                                <Box ml={1}>
                                    <Typography localization={item.name}></Typography>
                                </Box>
                            </Box>
                            {index < menu.length - 1 && <Divider />}
                        </a>
                    </Grid>
                ))}
            </Grid>
        </nav>
    );
};

export default MenuHash;
