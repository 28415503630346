import React, {useRef, useState} from 'react';
import clsx from 'clsx';
import {Icon, Loaders, Typography} from 'components';
import PropTypes from 'prop-types';
import {useForm} from 'hooks';
import {toBase64} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative'
    },
    wrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    },
    icon: {
        '&::before': {
            position: 'absolute',
            top: 14,
            right: 14,
            fontSize: 16
        }
    },
    label: {
        position: 'relative',
        display: 'block',
        minWidth: '15rem',
        maxWidth: '18rem',
        margin: theme.spacing(1, 0),
        padding: 12,
        marginRight: theme.spacing(2),
        userSelect: 'none',
        cursor: 'pointer',
        transition:
            'color .5s cubic-bezier(0, 0.99, 0.865, 1.005), background-color .5s cubic-bezier(0, 0.99, 0.865, 1.005)',
        textAlign: 'left',
        outline: 'none',
        color: theme.palette.white,
        backgroundColor: theme.palette.blue,
        border: `solid 1px ${theme.palette.blue}`,
        '&:hover, &:focus': {
            color: theme.palette.blue,
            backgroundColor: theme.palette.white
        }
    },
    disabled: {
        backgroundColor: theme.palette.white,
        color: `${theme.palette.grayDark} !important`,
        border: `solid 2px ${theme.palette.grayLight}`,
        cursor: 'initial !important'
    },
    filled: {
        border: `solid 2px ${theme.palette.grayLight}`,
        color: theme.palette.blue,
        backgroundColor: theme.palette.white,
        '&:hover, &:focus': {
            border: `solid 2px ${theme.palette.gray}`
        }
    },
    filename: {
        marginRight: theme.spacing(3),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    loader: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 4,
        alignItems: 'center'
    }
}));

const maxSizeMB = 3;

const config = {
    pdf: 'application/pdf',
    png: 'image/png',
    jpg: 'image/jpeg, image/jpg'
};

function File(props) {
    const {name, className, label, accept, ...rest} = props;

    const classes = useStyles();

    const {formState, setFormState} = useForm();

    const inputRef = useRef();

    const [filename, setFilename] = useState('');

    const [uploading, setUploading] = useState(false);

    async function handleChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const sizeMB = file.size / 1024 ** 2;
            const extension = file.name.split('.')[file.name.split('.').length - 1];

            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    [name]: ''
                }
            });

            if (sizeMB > maxSizeMB) {
                setFormState({
                    ...formState,
                    errors: {
                        ...formState.errors,
                        [name]: [`Votre fichier dépasse la taille maximum autorisée (${maxSizeMB}Mo)`]
                    },
                    touched: {
                        ...formState.touched,
                        [name]: true
                    },
                    isValid: false
                });
                return;
            }

            if (accept && file.name.length > 0 && !accept.includes(extension)) {
                setFormState({
                    ...formState,
                    errors: {
                        ...formState.errors,
                        [name]: [`Seuls des fichiers au format ${accept.map((type) => ` .${type}`)} sont acceptés.`]
                    },
                    touched: {
                        ...formState.touched,
                        [name]: true
                    },
                    isValid: false
                });
                return;
            }

            setUploading(true);

            setFilename(file.name);

            toBase64(file).then((value) => {
                setFormState({
                    ...formState,
                    values: {
                        ...formState.values,
                        [name]: value.split('base64,')[1]
                    },
                    touched: {
                        ...formState.touched,
                        [name]: true
                    }
                });
                setTimeout(() => setUploading(false), 350);
            });
        }
    }

    const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.wrapper}>
                <input
                    className="hidden"
                    id={name}
                    name={name}
                    onChange={handleChange}
                    spellCheck={false}
                    accept={accept && accept.map((type) => config[type]).join(', ')}
                    type="file"
                    ref={inputRef}
                    {...rest}
                />
                <label
                    className={clsx({
                        [classes.label]: true,
                        [classes.disabled]: uploading,
                        [classes.filled]: !!filename
                    })}
                    htmlFor={name}
                >
                    <Typography
                        className={classes.filename}
                        gutterBottom={false}
                        semibold={!filename}
                        color={filename ? 'blue' : 'white'}
                    >
                        {filename ? filename : label ? label : 'Parcourir...'}
                    </Typography>

                    {filename ? (
                        <Icon
                            className={classes.icon}
                            name="axa-icons-cross"
                            onClick={(event) => {
                                event.preventDefault();
                                setFilename('');
                                setFormState({
                                    ...formState,
                                    values: {
                                        ...formState.values,
                                        [name]: null
                                    }
                                });
                                inputRef.current.value = '';
                            }}
                        />
                    ) : (
                        <Icon
                            className={classes.icon}
                            name="axa-icons-upload"
                        />
                    )}

                    {uploading && (
                        <Loaders.Circular
                            className={classes.loader}
                            open
                            transparent
                        />
                    )}
                </label>
            </div>
            <Typography
                className="error-message"
                color="redLight"
            >
                {hasError(name) ? formState.errors[name][0] : null}
            </Typography>
        </div>
    );
}

File.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    accept: PropTypes.arrayOf(PropTypes.oneOf(['pdf', 'png', 'jpg']).isRequired)
};

export default File;
