import React from 'react';
import PropTypes from 'prop-types';
import {Form, Info, Modal, Typography} from 'components';
import {MultiDevicesGauge} from './components';

import {makeStyles} from '@material-ui/styles';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 750,
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            maxHeight: 600,
            overflow: 'hidden',
            overflowY: 'auto'
        }
    },
    container: {
        marginTop: -10
    },
    logo: {
        width: '80px !important',
        margin: 'auto'
    }
}));

function ContributionGaugeModal({contribution_gauge}) {
    const classes = useStyles();

    const account_types = Object.keys(contribution_gauge).filter(
        (account_type) => contribution_gauge[account_type] !== null
    );

    if (!contribution_gauge) return null;

    const multi = Boolean(account_types.length > 1);

    return (
        <Modal
            className={classes.root}
            hash="contribution_gauge"
            title={''}
        >
            <>
                <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                >
                    <img
                        className={classes.logo}
                        src={`${process.env.PUBLIC_URL}/images/abondement-logo.png`}
                        alt="abondement"
                        size="20"
                    />
                    <Box
                        flexDirection="row"
                        display="flex"
                        alignItems="center"
                        paddingTop="40px"
                    >
                        <Typography
                            semibold
                            variant="h4"
                            center
                            color="blue"
                        >
                            Le coup de pouce de mon entreprise
                        </Typography>
                        <Box
                            component="span"
                            ml={0.5}
                        >
                            <Info
                                hash="abondment-gauge-info"
                                color="blue"
                                tooltip
                            >
                                L'abondement est une aide financière facultative versée par l'entreprise pour vous
                                encourager à investir votre prime d'intéressement et/ou participation.
                            </Info>
                        </Box>
                    </Box>
                </Box>
            </>
            <div className={classes.container}>
                {account_types.filter(
                    (account_type) =>
                        contribution_gauge[account_type].ceiling && contribution_gauge[account_type].consumed
                ).length > 0 && (
                    <Typography
                        variant="h5"
                        center
                    >
                        {multi
                            ? "Vous bénéficiez d'un abondement sur les dispositifs suivants :"
                            : `Vous bénéficiez d'un abondement sur votre ${account_types[0].toUpperCase()}`}
                    </Typography>
                )}
                <Form
                    dynamic
                    values={{
                        account_type: account_types[0]
                    }}
                >
                    <MultiDevicesGauge contribution_gauge={contribution_gauge} />
                </Form>
            </div>
        </Modal>
    );
}

ContributionGaugeModal.propTypes = {
    contribution_gauge: PropTypes.object
};

export default ContributionGaugeModal;
