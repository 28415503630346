import mock from 'api/mockFastApi';

import {axa_accounts, axa_advisor, axa_investments, axa_profile, axa_transactions} from './data';

mock.onPost('/services/api-client/profile').reply(() => {
    return [200, {axa_profile}];
});

mock.onPost('/services/api-client/accounts').reply(() => {
    return [200, {axa_accounts}];
});

mock.onPost('/services/api-client/advisor').reply(() => {
    return [200, {axa_advisor}];
});

mock.onPost('/services/api-client/investments').reply(() => {
    return [200, axa_investments];
});

mock.onPost('/services/api-client/transactions').reply(() => {
    return [200, axa_transactions];
});
