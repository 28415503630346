import React, {useState} from 'react';
import {json} from 'utils';

export const ProjectionsContext = React.createContext({
    projections: {},
    setProjections: () => {}
});

export const ProjectionsProvider = (props) => {
    const setProjections = (projections) => {
        setState({...state, projections: projections});
        localStorage.setItem('EP_projections', JSON.stringify(projections));
    };

    const initState = {
        projections: json.parse(localStorage.getItem('EP_projections')) || {},
        setProjections: setProjections
    };

    const [state, setState] = useState(initState);

    return <ProjectionsContext.Provider value={state}>{props.children}</ProjectionsContext.Provider>;
};
