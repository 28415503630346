import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Buttons, Grid, Typography} from 'components';
import {ArticleModal} from 'macros';

import createDOMPurify from 'dompurify';
import {sendTag} from 'store/Tags';
import {useUser} from 'hooks';

const DOMPurify = createDOMPurify(window);

function ArticleReduced({article}) {
    const {user} = useUser();
    const [open, setOpen] = useState(false);

    return (
        <Grid
            item
            md={4}
            sm={6}
        >
            <Typography color="grayDark">{moment(article.date).format('DD MMMM YYYY')}</Typography>
            <Typography
                variant="h5"
                semibold
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(article.title)
                }}
            />
            <Typography
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${article.subtitle.slice(0, 90)}...`)
                }}
            />
            <Buttons.Chevron
                label="Lire la suite"
                color="blue"
                onClick={() => {
                    const title = article.title.split(' ').join('_');

                    sendTag(user || {}, `article::${title}`, {nom_etape: null}, false);
                    setOpen(true);
                }}
            />
            <ArticleModal
                article={article}
                open={open}
                setOpen={setOpen}
            />
        </Grid>
    );
}

ArticleReduced.propTypes = {
    article: PropTypes.object.isRequired
};

export default ArticleReduced;
