import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {bytesToSize, toBase64} from 'utils';

import {Alert, Buttons, Icon, Typography} from 'components';
import {useForm} from 'hooks';
import {makeStyles} from '@material-ui/styles';
import {Box, Button, Dialog, DialogTitle, Hidden, IconButton} from '@material-ui/core';
import {Visibility} from '@material-ui/icons';
import FileViewer from 'react-file-viewer';

const useStyles = makeStyles((theme) => ({
    root: {},
    dropZone: {
        background: '#f8f8ff',
        border: `2px dashed ${theme.palette.blue}`,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    dragActive: {
        backgroundColor: theme.palette.grayLight,
        opacity: 0.5
    },
    size: {
        whiteSpace: 'nowrap',
        marginLeft: theme.spacing(0.5)
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(1, 0, 0),
        background: '#f0f0f0'
    },
    filename: {
        maxWidth: 260
    },
    buttonPreview: {
        textTransform: 'none',
        color: theme.palette.blue,
        margin: theme.spacing(0, 0.5)
    },
    buttonDelete: {
        textTransform: 'none',
        color: theme.palette.errorRed
    },
    button: {
        textAlign: 'center'
    }
}));

const CONFIG = {
    pdf: 'application/pdf',
    png: 'image/png',
    jpg: 'image/jpeg, image/jpg'
};

const ERRORS = {
    'file-too-large': 'La taille du fichier ne peut excéder 10 Mo.',
    'file-invalid-type': 'Seuls des fichiers au format .pdf, .jpg, .jpeg sont acceptés.'
};

const AcceptedFile = ({file, handleDelete}) => {
    const classes = useStyles();

    const [base64, setBase64] = useState(null);

    const handlePreview = async () => {
        const fileBase64 = await toBase64(file);
        setBase64(fileBase64);
    };

    const handleClose = () => setBase64(null);

    return (
        <div className={classes.container}>
            <Box mr={1}>
                <Icon
                    name="axa-icons-document-o"
                    color="blue"
                    size={30}
                    flex
                />
            </Box>

            <Typography
                className={classes.filename}
                variant="h6"
                gutterBottom={false}
                noWrap
            >
                {file.name}
            </Typography>

            <Box flexGrow={1} />

            <Typography
                className={classes.size}
                gutterBottom={false}
            >
                {bytesToSize(file.size)}
            </Typography>

            <Hidden smDown>
                <Button
                    className={classes.buttonPreview}
                    disableRipple
                    endIcon={<Visibility />}
                    onClick={handlePreview}
                >
                    Visualiser
                </Button>
                <Button
                    className={classes.buttonDelete}
                    disableRipple
                    endIcon={
                        <Icon
                            name="axa-icons-clear"
                            color="errorRed"
                            size={18}
                            flex
                        />
                    }
                    onClick={handleDelete}
                >
                    Supprimer
                </Button>
            </Hidden>
            <Hidden mdUp>
                <IconButton onClick={handlePreview}>
                    <Visibility color="primary" />
                </IconButton>
                <IconButton onClick={handleDelete}>
                    <Icon
                        name="axa-icons-clear"
                        color="errorRed"
                        size={18}
                        flex
                    />
                </IconButton>
            </Hidden>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={base64 !== null}
                onClose={handleClose}
            >
                <DialogTitle disableTypography>
                    <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            variant="h5"
                            color="blue"
                            gutterBottom={false}
                        >
                            {file.name}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <Icon
                                name="axa-icons-cross"
                                size={14}
                            />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <div style={{height: '100%'}}>
                    <FileViewer
                        fileType={file.type.split('/')[1]}
                        filePath={base64}
                        errorComponent={<div>Error!</div>}
                        onError={(e) => console.log(e)}
                    />
                </div>
            </Dialog>
        </div>
    );
};

function FileDrop(props) {
    const {
        name,
        className,
        maxSizeInMB = 2,
        accept = ['jpg', 'png', 'pdf'],
        maxFiles = 5,
        totalMaxSizeInMB = 25,
        withDetails = false,
        fileNameDuplicationCheck = false
    } = props;

    const classes = useStyles();

    const {formState, setFormState} = useForm();

    const [files, setFiles] = useState([]);

    const [totalSize, setTotalSize] = useState(0);

    const handleDrop = useCallback(
        (acceptedFiles) => {
            setFiles([...files, ...acceptedFiles]);
        },
        [files]
    );

    const {getRootProps, getInputProps, isDragActive, fileRejections, open} = useDropzone({
        accept: accept.map((type) => CONFIG[type]).join(', '),
        onDrop: handleDrop,
        minSize: 0,
        maxSize: maxSizeInMB * 1000 * 1024,
        noClick: true,
        noKeyboard: true,
        maxFiles: maxFiles
    });

    function hasDuplicatedFileName(fileList) {
        const fileNames = fileList.map((file) => file.name);
        const uniqueFileNames = new Set(fileNames);

        return fileNames.length !== uniqueFileNames.size;
    }

    const handleChange = useCallback(async () => {
        const newFiles = [];
        const errors = [];

        const totalSize = files.map((file) => file.size).reduce((acc, val) => acc + val, 0);
        setTotalSize(totalSize);
        if (files.length > maxFiles) errors.push(`Trop de fichiers (max: ${maxFiles} fichiers)`);
        else if (totalSize > totalMaxSizeInMB * 1000 * 1024) errors.push(`Trop lourd (max: ${maxSizeInMB}Mo)`);
        else if (withDetails && hasDuplicatedFileName(files) && fileNameDuplicationCheck)
            errors.push(`Fichiers en doublon`);
        else {
            for (const file of files) {
                let fileBase64 = await toBase64(file, false);
                if (withDetails)
                    newFiles.push({
                        name: file.name,
                        size: bytesToSize(file.size),
                        type: file.type,
                        fileBase64: fileBase64,
                        file: file
                    });
                else newFiles.push(fileBase64);
            }
        }
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [name]: newFiles
            },
            touched: {
                ...formState.touched,
                [name]: true
            },
            errors: {
                ...formState.errors,
                [name]: errors
            }
        });

        // eslint-disable-next-line
    }, [files, name]);

    useEffect(() => {
        handleChange();
    }, [handleChange]);

    return (
        <div className={clsx(classes.root, className)}>
            <div
                className={clsx({
                    [classes.dropZone]: true,
                    [classes.dragActive]: isDragActive
                })}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <Hidden xsDown>
                    <Typography
                        variant="h5"
                        center
                    >
                        Glisser et déposer un fichier
                    </Typography>
                    <Typography
                        variant="h5"
                        center
                    >
                        ou
                    </Typography>
                </Hidden>
                <Buttons.Default
                    className={classes.button}
                    color="blue"
                    label="Importer"
                    center
                    onClick={open}
                />
            </div>
            {files.map((file, index) => (
                <AcceptedFile
                    key={`file-${index}`}
                    file={file}
                    handleDelete={() => setFiles((files) => files.filter((_, currentIndex) => currentIndex !== index))}
                />
            ))}
            <Box>
                {fileRejections.length > 0 && fileRejections[0].errors[0].code !== 'too-many-files' && (
                    <Alert
                        message={
                            fileRejections[0].errors[0].code === 'file-too-large'
                                ? `La taille du fichier ne peut excéder ${maxSizeInMB} Mo.`
                                : ERRORS[fileRejections[0].errors[0].code]
                        }
                        severity="error"
                    />
                )}
                {files.length > maxFiles && (
                    <Alert
                        message={`Vous ne pouvez pas transmettre plus de ${maxFiles} fichiers.`}
                        severity="error"
                    />
                )}
                {totalSize > totalMaxSizeInMB * 1000 * 1024 && (
                    <Alert
                        message={`La taille maximale pour l'ensemble des fichiers ne peut excéder ${totalMaxSizeInMB}Mo.`}
                        severity="error"
                    />
                )}
                {withDetails && hasDuplicatedFileName(files) && fileNameDuplicationCheck && (
                    <Alert
                        message={`Vous ne pouvez pas transmettre des fichiers ayant des noms identiques.`}
                        severity="error"
                    />
                )}
            </Box>
        </div>
    );
}

FileDrop.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    maxSizeInMB: PropTypes.number,
    maxFiles: PropTypes.number,
    totalMaxSize: PropTypes.number,
    withDetails: PropTypes.bool
};

export default FileDrop;
