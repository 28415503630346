import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

function SessionBuilder() {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        // Press key `²` to open Builder !
        document.onkeypress = (event) => {
            if (event.key === '<') {
                if (location.pathname === '/developer') {
                    let previousPath = localStorage.getItem('EP_target_pathname');
                    if (previousPath) {
                        localStorage.removeItem('EP_target_pathname');
                        history.push(previousPath);
                    }
                } else {
                    localStorage.setItem('EP_target_pathname', location.pathname);
                    history.push('/developer');
                }
            }
        };

        // Carousels are navigable with Tab !
        document.onkeydown = (event) => {
            if (event.key === 'Tab') {
                document.querySelectorAll('.slick-slide:not(.slick-active)').forEach((el) => {
                    el.style.visibility = 'hidden';
                });
            }
        };
        document.onkeyup = (event) => {
            if (event.key === 'Tab') {
                document.querySelectorAll('.slick-slide:not(.slick-active)').forEach((el) => {
                    el.style.visibility = 'initial';
                });
            }
        };

    }, [history, location.pathname]);

    return null;
}

export default SessionBuilder;
