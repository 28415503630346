import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import {useUser} from 'hooks';

import {makeStyles} from '@material-ui/styles';
import {FooterCollective, FooterIndividuel} from './components';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative'
    }
}));

const Footer = ({setFooterHeight, className, ...rest}) => {
    const classes = useStyles();

    const {user} = useUser();

    const ref = useRef();

    return (
        <footer
            role="contentinfo"
            className={classes.root}
            ref={ref}
            {...rest}
        >
            {(user.logged === undefined || user.origin === 'collective') && (
                <FooterCollective setFooterHeight={setFooterHeight} />
            )}

            {['individual', 'individual_transmission'].includes(user.origin) && (
                <FooterIndividuel setFooterHeight={setFooterHeight} />
            )}
        </footer>
    );
};

Footer.propTypes = {
    setFooterHeight: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default Footer;
