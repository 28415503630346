import React from 'react';
import {Chip as MuiChip} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'white',
        fontWeight: 600,
        '& :first-letter': {
            textTransform: 'capitalize'
        }
    },
    purple: {
        background: '#7800b2'
    },
    green: {
        background: '#118635'
    },
    blue: {
        background: theme.palette.blue
    },
    blueGreen: {
        background: '#015A66'
    },
    blueGrey: {
        background: '#3A5E94'
    },
    outlinedBlue: {
        color: theme.palette.blue,
        border: `solid 1px ${theme.palette.blue}`,
        borderRadius: 5,
        backgroundColor: 'white',
        textTransform: 'uppercase'
    }
}));

const Chip = (props) => {
    const classes = useStyles();
    const {variant, label, size = 'medium'} = props;
    const {t} = useTranslation();
    return (
        <MuiChip
            className={clsx(classes.root, variant && classes[variant])}
            label={t(label)}
            size={size}
        />
    );
};

export default Chip;
