import 'moment/locale/fr';
import {amount} from 'utils';

const getAllocated = (account, distribution, column) => {
    if (!distribution) return 0;

    return Object.entries(distribution)
        .filter(([key]) => key.startsWith(column) && key.includes(account.policy_id))
        .map(([, balance]) => amount.parse(balance))
        .reduce((acc, val) => acc + val, 0);
};

const getContractsDistribution = (accounts, distribution) =>
    accounts.map((account) => {
        const capital = getAllocated(account, distribution, 'capital');
        const annuity = getAllocated(account, distribution, 'annuity');

        return {
            policy_id: account.policy_id,
            capital_total: capital,
            annuity_total: annuity,
            rest:
                account.balance -
                getAllocated(account, distribution, 'capital') -
                getAllocated(account, distribution, 'annuity')
        };
    });

function withdrawRedemptionProperties(payload, accounts) {
    return {
        user: payload.user,
        contracts: getContractsDistribution(accounts, payload.distribution)
    };
}

export default withdrawRedemptionProperties;
