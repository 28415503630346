import React, {useState} from 'react';

export const OperationContext = React.createContext({
    operations: {},
    setOperations: () => {}
});

export const OperationProvider = (props) => {
    const setOperation = (operation) => {
        setState({...state, operation});
    };

    const initState = {
        operation: {},
        setOperation: setOperation
    };

    const [state, setState] = useState(initState);
    return <OperationContext.Provider value={state}>{props.children}</OperationContext.Provider>;
};

export const OperationConsumer = OperationContext.Consumer;
