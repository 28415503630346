import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {fastapi} from 'api';
import {Buttons, Typography} from 'components'; // for secondary actions of withdraw mode
import {PaperProcessModal} from 'macros';
import {
    StepAccountsReminder,
    StepAnnuityType,
    StepDistribution,
    StepMrmResult,
    StepRetirementAge,
    StepReversible
} from './components';
import {useUser} from 'hooks';
import {sendTag} from 'store/Tags';
import {accounts, amount, modal} from 'utils';

const schemaTemplate = (other, max) => ({
    presence: {
        allowEmpty: true,
        message: 'Champ requis'
    },
    equality: {
        attribute: other,
        message: 'Champ erroné',
        comparator: (v1, v2) => amount.parse(v1) + amount.parse(v2) < max + 0.01
    }
});

export const AdvisesContract = () => {
    const {user} = useUser();

    return (
        <>
            <Typography
                color="blue"
                gutterBottom={false}
            >
                Capital ou rente, en savoir plus :
            </Typography>

            {accounts.filter(user.accounts, 'ais', ['PER', 'PERECO', 'PERU']).length > 0 && (
                <Buttons.Chevron
                    label="pour le PER"
                    color="blue"
                    onClick={() => window.open(`${process.env.PUBLIC_URL}/docs/withdraw_advise_per_2023.pdf`)}
                />
            )}
            {accounts.filter(user.accounts, 'ais', ['A83']).length > 0 && (
                <Buttons.Chevron
                    label="pour l'Article 83"
                    color="blue"
                    onClick={() => window.open(`${process.env.PUBLIC_URL}/docs/withdraw_advise_a83_2023.pdf`)}
                />
            )}
        </>
    );
};

function MrmSteps({withdraw}) {
    const {user} = useUser();

    const accounts_retirement = useMemo(
        () => accounts.filter(user.accounts, 'ais', ['PER', 'PERECO', 'PERU', 'A83']),
        [user.accounts]
    );

    const {schema, values} = useMemo(() => {
        let _schema = {};
        let _values = {};
        for (const account of accounts_retirement) {
            if (account.type === 'A83') {
                _schema[`capital_${account.policy_id}`] = schemaTemplate(
                    `annuity_${account.policy_id}`,
                    account.balance
                );
                _schema[`annuity_${account.policy_id}`] = schemaTemplate(
                    `capital_${account.policy_id}`,
                    account.balance
                );
                _values[`capital_${account.policy_id}`] = '0.00';
                _values[`annuity_${account.policy_id}`] = account.balance.toFixed(2);
            } else {
                _schema[`capital_${account.policy_id}_co`] = schemaTemplate(
                    `annuity_${account.policy_id}_co`,
                    account.balance_co
                );
                _schema[`capital_${account.policy_id}_vv`] = schemaTemplate(
                    `annuity_${account.policy_id}_vv`,
                    account.balance_vv
                );
                _schema[`capital_${account.policy_id}_es`] = schemaTemplate(
                    `annuity_${account.policy_id}_es`,
                    account.balance_es
                );
                _schema[`annuity_${account.policy_id}_co`] = schemaTemplate(
                    `capital_${account.policy_id}_co`,
                    account.balance_co
                );
                _schema[`annuity_${account.policy_id}_vv`] = schemaTemplate(
                    `capital_${account.policy_id}_vv`,
                    account.balance_vv
                );
                _schema[`annuity_${account.policy_id}_es`] = schemaTemplate(
                    `capital_${account.policy_id}_es`,
                    account.balance_es
                );
                _values[`capital_${account.policy_id}_co`] = '0.00';
                _values[`capital_${account.policy_id}_vv`] = '0.00';
                _values[`capital_${account.policy_id}_es`] = '0.00';
                _values[`annuity_${account.policy_id}_co`] = account.balance_co.toFixed(2);
                _values[`annuity_${account.policy_id}_vv`] = account.balance_vv.toFixed(2);
                _values[`annuity_${account.policy_id}_es`] = account.balance_es.toFixed(2);
            }
        }
        return {schema: _schema, values: _values};
    }, [accounts_retirement]);

    const fetchMRM = (stepper, setStepper) =>
        fastapi
            .post('/simulators/mrm/simulation', {
                distribution: Object.fromEntries(
                    Object.entries(stepper.payload.distribution).map(([key, value]) => [key, amount.parse(value)])
                ),
                retirement_age: stepper.payload.retirement_age,
                annuity_type: stepper.payload.annuity_type,
                birth_date_spouse: stepper.payload.birth_date_spouse,
                reversion_percentage: stepper.payload.reversion_percentage,
                reversible: stepper.payload.reversible
            })
            .then((response) => {
                setStepper({
                    payload: stepper.payload,
                    response: response.data
                });
                sendTag(user, 'mrm::simulationcomplete');
            });

    return (
        <>
            <StepAccountsReminder
                stepAction={
                    withdraw && (
                        <>
                            <Buttons.Chevron
                                label="J'effectue ma demande par courrier"
                                color="blue"
                                onClick={() => modal.open('paper_process')}
                            />
                            <PaperProcessModal withdrawCase="retirement" />
                        </>
                    )
                }
            />

            <StepRetirementAge
                showIf={() => !withdraw}
                schema={{
                    retirement_age: {
                        presence: {
                            allowEmpty: false,
                            message: 'Veuillez renseigner votre âge de départ'
                        },
                        numericality: {
                            greaterThanOrEqualTo: 60,
                            lessThanOrEqualTo: 69,
                            message: 'Votre âge de départ doit être compris entre 60 et 69ans'
                        }
                    }
                }}
            />

            <StepDistribution
                schema={schema}
                values={values}
                nestedKey="distribution"
                variant="white"
                stepAction={withdraw && <AdvisesContract />}
            />

            <StepReversible
                schema={{
                    reversible: {
                        presence: {allowEmpty: true}
                    },
                    birth_date_spouse: function (value, attributes) {
                        if (!attributes.reversible) return null;
                        return {
                            presence: {allowEmpty: false},
                            datetime: {
                                latest: moment.utc().subtract(18, 'years'),
                                message: 'Votre conjoint doit être majeur'
                            }
                        };
                    },
                    reversion_percentage: function (value, attributes) {
                        if (!attributes.reversible) return null;
                        return {
                            presence: {allowEmpty: false}
                        };
                    }
                }}
                submit={(stepper, setStepper) => {
                    if (accounts.filter(user.accounts, 'ais', ['PER', 'PERO', 'PERECO', 'PERU', 'A83']).length > 1) {
                        return new Promise((resolve) => resolve());
                    } else return fetchMRM(stepper, setStepper);
                }}
            />

            <StepAnnuityType
                showIf={(stepper) =>
                    accounts.filter(user.accounts, 'ais', ['PER', 'PERO', 'PERECO', 'PERU', 'A83']).length > 1
                }
                schema={{
                    annuity_type: {
                        presence: {
                            allowEmpty: false,
                            message: 'Veuillez sélectionner une option de rente'
                        }
                    }
                }}
                submit={(stepper, setStepper) => fetchMRM(stepper, setStepper)}
                stepAction={withdraw && <AdvisesContract />}
            />

            <StepMrmResult />
        </>
    );
}

MrmSteps.propTypes = {
    withdraw: PropTypes.bool
};

export default MrmSteps;
