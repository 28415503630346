import React, {useEffect} from 'react';
import {Alert, Buttons, Info, Inputs, Typography} from 'components';
import {makeStyles} from '@material-ui/styles';
import {useForm, useUser} from 'hooks';
import {sendTag} from 'store/Tags';
import {modal} from 'utils';

const useStyles = makeStyles((theme) => ({
    root: {},
    columnItems: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:not(:last-child)': {
            marginRight: 10
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            '& > *': {
                width: '100%'
            },
            '& .flexGrow': {
                display: 'none !important'
            }
        }
    },
    rowItems: {
        display: 'flex',
        flexDirection: 'column'
    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        maxWidth: 480,
        border: `1px solid ${theme.palette.blue}`,
        padding: theme.spacing(1),
        background: `${theme.palette.blue}`
    },
    flexWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:not(:last-child)': {
            marginRight: 10
        }
    }
}));

function StepHeirs({isStepper}) {
    const classes = useStyles();
    const {user, setUser} = useUser();
    const {formState, setFormState} = useForm();

    useEffect(() => {
        let values = formState.values;

        if (values?.family_situation !== 'spouse') {
            delete values.has_spouse_donation;
        }

        if (values?.children === '0') {
            delete values.has_same_parents;
        }

        if (!values.has_same_parents && !values.has_spouse_donation) {
            delete values.spouse_age;
        }

        if (values?.children !== '0' || (values?.has_spouse_donation && values?.family_situation === 'spouse')) {
            delete values.parents;
        } else {
            if (typeof values?.parents === 'undefined') {
                values.parents = '2';
            }
        }

        if (typeof values?.parents === 'undefined') {
            delete values.siblings;
        }

        setFormState({
            ...formState,
            values: values
        });

        // eslint-disable-next-line
    }, [formState.values]);

    return (
        <div>
            <Typography
                variant="h3"
                semibold
                color="blue"
            >
                J'identifie mes héritiers {isStepper ? '- 1/3' : ''}
            </Typography>
            <div className={classes.flexWrapper}>
                <Typography>J'ai rédigé un testament</Typography>
                <div className="flexGrow" />
                <Inputs.Switch name="has_testament" />
            </div>
            {formState.values.has_testament === true && (
                <Alert
                    message={
                        <>
                            Le simulateur ne prend pas en compte les dispositions mises en place dans le cadre d’un
                            testament.
                            <br />
                            <b>
                                Il est important de s’assurer que votre testament est toujours en adéquation avec vos
                                souhaits : contactez dès maintenant votre Conseiller AXA qui saura vous accompagner dans
                                toutes les étapes de la préparation de votre succession.
                            </b>
                            <br />
                            <br />
                            <Buttons.Default
                                center
                                label="Je contacte mon conseiller"
                                color="blueAlpha"
                                next
                                onClick={() => {
                                    setUser({...user, actions: user.actions ? user.actions + 1 : 1});
                                    sendTag(user, 'ct_transmission360::clic::conseiller', {
                                        transmission360_testament: true
                                    });
                                    modal.close('modifier_heritiers');
                                    modal.open('advisor_transmission');
                                }}
                            />
                        </>
                    }
                    severity="info"
                />
            )}
            {formState.values.has_testament !== true && (
                <>
                    <div className={classes.rowItems}>
                        <div className={classes.columnItems}>
                            <Typography>Quelle est ma situation familiale actuelle ?</Typography>
                            <div className="flexGrow" />
                            <Inputs.Select
                                name="family_situation"
                                options={[
                                    {
                                        label: '-- Sélectionner un statut --',
                                        value: '',
                                        disabled: true
                                    },
                                    {
                                        label: 'Célibataire',
                                        value: 'single'
                                    },
                                    {
                                        label: 'Marié(e)',
                                        value: 'spouse'
                                    },
                                    {
                                        label: 'Pacsé(e)',
                                        value: 'pacs'
                                    },
                                    {
                                        label: 'Autre situation',
                                        value: 'other'
                                    }
                                ]}
                            />
                        </div>
                        {formState.values.family_situation === 'pacs' && (
                            <Alert
                                message="Si vous êtes PACSé(e), votre partenaire ne sera pas automatiquement héritier de vos biens. Pour qu'il puisse en recevoir une partie, vous pouvez rédiger un testament ou le nommer bénéficiaire de votre contrat d’assurance-vie, en utilisant la mention « Mon partenaire de PACS » ou en le désignant nominativement. "
                                severity="info"
                            />
                        )}
                    </div>
                    {formState.values.family_situation === 'spouse' && (
                        <div className={classes.flexWrapper}>
                            <Typography>J’ai fait une donation au conjoint survivant ? </Typography>
                            <Info
                                hash="has_spouse_donation"
                                title="Informations"
                                color="blue"
                                tooltip
                            >
                                La donation au conjoint survivant permet de protéger et favoriser le conjoint marié(e)
                                survivant en lui donnant une part plus importante de son patrimoine (en respectant la
                                réserve héréditaire). Vous devez faire appel à un notaire pour effectuer une donation au
                                dernier vivant. Le conjoint marié(e) survivant aura alors lors du décès le choix entre
                                trois options dans la succession en présence de descendant(s) :
                                <br />- Usufruit de la totalité de la succession (option déjà prévue sans donation au
                                conjoint survivant),
                                <br />- Un quart en pleine propriété et les trois quarts en usufruit (le survivant n’a
                                droit qu’au quart en pleine propriété sans donation au conjoint survivant),
                                <br />- Pleine propriété de la quotité disponible.
                            </Info>
                            <div className="flexGrow" />
                            <Inputs.Switch name="has_spouse_donation" />
                        </div>
                    )}
                    <div className={classes.columnItems}>
                        <div className={classes.flexWrapper}>
                            <Typography>Combien ai-je d'enfant(s) ? </Typography>
                            <Info
                                hash="birth_document"
                                title="Informations"
                                color="blue"
                                tooltip
                            >
                                Le simulateur dans sa version actuelle ne prend pas en compte les cas d’adoption simple.
                                Indiquez le nombre d’enfants que vous avez vivants ou représentés (les enfants de votre
                                conjoint ne sont pas considérés comme vos héritiers)
                            </Info>
                        </div>
                        <div className="flexGrow" />
                        <Inputs.Number
                            name="children"
                            adornment="enfant(s)"
                            right
                        />
                    </div>
                    {formState.values.children > 0 && formState.values.family_situation === 'spouse' && (
                        <div className={classes.flexWrapper}>
                            <Typography>Tous communs au couple ? </Typography>
                            <Info
                                hash="has_same_parents"
                                title="Informations"
                                color="blue"
                                tooltip
                            >
                                Lorsque le défunt ne laisse que des enfants issus du couple, l’époux survivant a le
                                choix entre deux options dans la succession en présence de descendant(s) :<br />
                                - Usufruit de la totalité de la succession (L'usufruit est le droit d'utiliser un bien
                                et d'en percevoir les revenus, sans en être propriétaire)
                                <br />- Pleine propriété du quart de la succession
                            </Info>
                            <div className="flexGrow" />
                            <Inputs.Switch name="has_same_parents" />
                        </div>
                    )}
                    {formState.values.family_situation === 'spouse' &&
                        formState.values.children > 0 &&
                        (formState.values.has_same_parents === true ||
                            formState.values.has_spouse_donation === true) && (
                            <div className={classes.columnItems}>
                                <Typography>Quel est l'âge de mon conjoint ? </Typography>
                                <Info
                                    hash="spouse_age"
                                    title="Informations"
                                    color="blue"
                                    tooltip
                                >
                                    La valeur de l'usufruit dépend de l'âge du conjoint survivant
                                </Info>
                                <div className="flexGrow" />
                                <Inputs.Number
                                    name="spouse_age"
                                    adornment={'ans'}
                                    right
                                />
                            </div>
                        )}

                    {formState.values.children === '0' &&
                        !(formState.values.has_spouse_donation && formState.values.family_situation === 'spouse') && (
                            <>
                                <div className={classes.columnItems}>
                                    <Typography>L'un de mes parents est-il toujours vivant ?</Typography>
                                    <div className="flexGrow" />
                                    <Inputs.Select
                                        name="parents"
                                        options={[
                                            {
                                                label: '0',
                                                value: '0'
                                            },
                                            {
                                                label: '1',
                                                value: '1'
                                            },
                                            {
                                                label: '2',
                                                value: '2'
                                            }
                                        ]}
                                    />
                                </div>
                            </>
                        )}
                    {formState.values.children === '0' && formState.values.family_situation !== 'spouse' && (
                        <>
                            <div className={classes.columnItems}>
                                <Typography>Combien ai-je de frère(s) et soeur(s) ?</Typography>
                                <div className="flexGrow" />
                                <Inputs.Number
                                    name="siblings"
                                    right
                                />
                            </div>
                        </>
                    )}
                    {formState.values.children === '0' &&
                        formState.values.family_situation !== 'spouse' &&
                        formState.values.siblings === '0' && (
                            <Alert
                                message={
                                    <>
                                        Dans sa version actuelle le simulateur ne gère pas cette situation.
                                        <br />
                                        Vos biens iront à vos ascendants : parents, grands-parents,
                                        arrière-grands-parents. D'abord, la succession est partagée en 2 parts égales
                                        qui vont à chaque branche parentale (maternelle et paternelle). Ensuite, les
                                        héritiers sont déterminés par branche en allant de la 1re génération aux
                                        générations les plus éloignées. Si aucun ascendant n'est vivant, la succession
                                        va à ses oncles, tantes, cousins et cousines.
                                        <br />
                                        L’assurance-vie permet de transmettre un capital aux personnes de son choix dans
                                        un cadre fiscal avantageux.
                                    </>
                                }
                                severity="info"
                            />
                        )}
                </>
            )}
        </div>
    );
}

export default StepHeirs;
