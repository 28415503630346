import {useContext, useEffect} from 'react';
import {FilibContext} from 'store/Filib';
import {fastapi} from 'api';
import {useUser} from 'hooks';

const getFilibURL = (accounts, callback) => {
    fastapi.get('/services/filib/iframe').then((response) => callback(response));
};

function useFilib() {
    const {user} = useUser();
    const context = useContext(FilibContext);

    useEffect(() => {
        getFilibURL(user.accounts, (response) => context.setFilibUrl(response.data));

        // eslint-disable-next-line
    }, []);

    return context;
}

export default useFilib;
