import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useUser} from 'hooks';

import {makeStyles, useTheme} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        padding: '0 12px'
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        margin: 'auto',
        maxWidth: theme.breakpoints.values.md - 16
    }
}));

function Center(props) {
    const {className, children, maxWidth, ...rest} = props;

    const classes = useStyles();
    const theme = useTheme();

    const {user} = useUser();

    return (
        <div
            className={classes.root}
            {...rest}
        >
            <div
                className={clsx(classes.wrapper, className)}
                style={{
                    maxWidth:
                        maxWidth ||
                        (["individual", "individual_transmission"].includes(user.origin)
                            ? theme.breakpoints.values.md + 32
                            : theme.breakpoints.values.md - 16)
                }}
            >
                {children}
            </div>
        </div>
    );
}

Center.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    maxWidth: PropTypes.number
};

export default Center;
