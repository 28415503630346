import React from 'react';
import {Form, Icon, Inputs, Typography} from 'components';
import {useTaxSimulator} from 'hooks';

function StepGender({setStep}) {
    const {taxSimulator, setTaxSimulator, variant} = useTaxSimulator();

    return (
        <Form
            dynamic
            submit={(formState) => {
                setTaxSimulator({
                    ...taxSimulator,
                    inputs: {
                        ...taxSimulator.inputs,
                        ...formState.values
                    }
                });
                setStep(1);
            }}
        >
            <Typography
                variant={variant === 'public' ? 'h1' : 'h2'}
                color="blue"
                center
            >
                Vous êtes...
            </Typography>
            <Inputs.RadioButton
                name="gender"
                center
                options={[
                    {
                        label: 'Une femme',
                        value: 'F',
                        icon: (
                            <Icon
                                name="axa-icons-woman-o"
                                size={60}
                            />
                        )
                    },
                    {
                        label: 'Un homme',
                        value: 'M',
                        icon: (
                            <Icon
                                name="axa-icons-man-o"
                                size={60}
                            />
                        )
                    }
                ]}
            />
        </Form>
    );
}

export default StepGender;
