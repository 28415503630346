import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {fastapi} from 'api';
import {Buttons, Form, Grid, Inputs, Modal, Typography} from 'components';
import {modal} from 'utils';

import {useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/styles';

function SubStepForgotPasswordOne({step, setStep, setStepParent, setSecretQuestion, sessionId}) {
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [error, setError] = useState('');

    return (
        <>
            <Modal
                hash="marel_error_password"
                title="Erreur"
            >
                {error}
            </Modal>

            <Form
                schema={{
                    identifier: {
                        presence: {
                            allowEmpty: false,
                            message: 'Veuillez renseigner votre numéro de sécurité sociale (13 chiffres)'
                        },
                        format: {
                            pattern: '[12][0-9]{2}[0-1][0-9](2[AB]|[0-9]{2})[0-9]{3}[0-9]{3}',
                            message: 'Votre numéro de sécurité sociale est invalide'
                        }
                    }
                }}
                submit={(formState) =>
                    fastapi
                        .post('/simulators/marel/forgot-password/first', {...formState.values, session_id: sessionId})
                        .then((response) => {
                            sessionStorage.setItem('EP_M@REL_password', JSON.stringify(formState.values));
                            setSecretQuestion(response.data.valeur);
                            setStep(1); // SubStepForgotPasswordTwo
                        })
                        .catch((error) => {
                            setError(error.response.data.message);
                            modal.open('marel_error_password');
                        })
                }
                variant="transparent"
            >
                <Grid
                    container
                    spacing={2}
                    alignItems="flex-end"
                >
                    <Grid
                        item
                        sm={6}
                        xs={12}
                    >
                        <Inputs.Text
                            name="identifier"
                            label="Numéro de sécurité sociale (13 chiffres) *"
                        />
                    </Grid>
                </Grid>
                <Typography>
                    Les champs avec un astérisque sont
                    <Typography
                        semibold
                        component="span"
                    >
                        {' '}
                        obligatoires.
                    </Typography>
                </Typography>
                <Buttons.Default
                    className={clsx(isDesktop && 'toLeft')}
                    label="Étape précédente"
                    color="blueAlpha"
                    previous
                    center
                    onClick={() => setStepParent(1)} // StepLogin
                />
                <Buttons.Default
                    className={clsx(isDesktop && 'toRight')}
                    label="Étape suivante"
                    color="green"
                    next
                    center
                    submit
                />
            </Form>
        </>
    );
}

SubStepForgotPasswordOne.propTypes = {
    setSecretQuestion: PropTypes.func.isRequired
};

export default SubStepForgotPasswordOne;
