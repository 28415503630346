import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Icon, Typography} from 'components';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        margin: theme.spacing(1, 0),
        border: `solid 2px ${theme.palette.gray}`,
        cursor: 'pointer',
        '&:hover': {
            border: `solid 2px ${theme.palette.blue}`
        }
    },
    label: {
        paddingRight: 10,
        wordBreak: 'break-word'
    },
    size: {
        whiteSpace: 'nowrap',
        paddingRight: 10
    }
}));

function Download(props) {
    const {label, size, url} = props;
    const classes = useStyles();
    return (
        <div
            className={classes.root}
            role="button"
            aria-label={`Télécharger le fichier ${label}`}
            onClick={() => {
                window.open(url);
                props.handleClick();
            }}
        >
            <Typography
                className={classes.label}
                variant="h6"
                color="blue"
                gutterBottom={false}
                semibold
            >
                {label}
            </Typography>
            <Typography
                className={classes.size}
                variant="h6"
                gutterBottom={false}
                semibold
            >
                - {size}
            </Typography>
            <div className={'flexGrow'} />
            <Icon
                size={20}
                className={classes.icon}
                color="blue"
                name="axa-icons-download"
            />
        </div>
    );
}

export default Download;
