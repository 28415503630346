import {wording} from 'utils';

const tab_retirement = {
    name: 'retirement',
    label: 'Projet retraite',
    items: [
        {
            name: 'complementary',
            label: 'Complémentaire',
            title: 'Retraite de base et complémentaire',
            text:
                '• Les retraites de base et complémentaires constituent 2 régimes de retraite obligatoires. Ces ' +
                "régimes fonctionnent par répartition, c'est-à-dire que les retraites versées sont financées par les " +
                'cotisations des actifs.<br/><br/>' +
                "• Vous cotisez sur vos revenus simultanément auprès d'un régime de base et d'un régime complémentaire. " +
                'Vos cotisations sont reversées à des caisses de retraite, qui varient en fonction de votre statut ' +
                '(salarié, agent de la fonction publique, non salarié, ...).<br/><br/>' +
                '• Les droits accumulés au cours de votre carrière professionnelle sont reversés sous forme de pension, ' +
                'lors de votre départ à la retraite. Vous percevez alors une retraite de base et une retraite complémentaire. '
        },
        {
            name: 'supplementary',
            label: 'Supplémentaire',
            title: 'Retraite supplémentaire',
            text:
                "La retraite supplémentaire est une forme de retraite que l'on peut percevoir en plus des régimes " +
                'obligatoires (base et complémentaires). Elle peut aussi être appelée retraite "surcomplémentaire", ' +
                "fonctionne par capitalisation et recouvre un ensemble varié de dispositifs d'épargne " +
                '(Art 83, PERCO, PERP, Madelin, PER…)'
        },
        {
            name: 'perco',
            label: 'PERCO',
            title: 'PERCO',
            text:
                'Le Plan d’Épargne pour la Retraite Collectif (PERCO) mis en place par votre entreprise vous ' +
                'permet de vous constituer une épargne supplémentaire pour votre retraite dans des conditions ' +
                'fiscales avantageuses. Cette épargne est disponible lors de votre départ à la retraite, sauf ' +
                'en cas de déblocage anticipé.<br/><br/>' +
                'Un compte individuel est ouvert à votre nom et peut être alimenté par :<br/>' +
                '    · l’intéressement<br/>' +
                '    · la participation<br/>' +
                '    · les droits issus du Compte Epargne-Temps (CET)*<br/>' +
                '    · les jours de repos non pris (maximum 10 jours)<br/>' +
                '    · vos éventuels versements volontaires<br/><br/>' +
                '<span class="semibold">Bon à savoir</span><br/>' +
                'Chaque année, vous pouvez effectuer des versements volontaires (y compris les droits issus du ' +
                'CET affectés au PERCO) jusqu’à 25% de votre rémunération brute annuelle, tous plans d’épargne ' +
                'salariale confondus.<br/>' +
                'Le transfert de sommes provenant d’un autre PERCO. Si l’accord le prévoit, vous pouvez ' +
                'également bénéficier d’une aide financière de votre entreprise appelée « abondement ». ' +
                'Au moment de partir à la retraite vous pourrez choisir capital et/ou de rente selon l’accord ' +
                'PERCO.<br/>' +
                '* si l’accord relatif au CET le prévoit'
        },
        {
            name: 'per',
            label: 'PER',
            title: 'PER',
            text:
                "Le PER (Plan Épargne Retraite) est un nouveau produit d'épargne retraite disponible depuis le 1er " +
                "octobre 2019 et qui remplacera progressivement les autres plans d'épargne retraite. Le PER se " +
                "décline sous 4 formes : un PER individuel, et trois PER d'entreprise. Le PER individuel succède au " +
                "PERP et au contrat Madelin. Le PER d'entreprise collectif (PERECO) succède au PERCO (Plan d'Epargne pour la " +
                "Retraite Collectif). Le PER d'entreprise obligatoire (PERO) succède au contrat Article 83. Ces dernier " +
                '(PERECO et PERO) peuvent également être regroupés sous forme de PER Unique (PERU). Vous pouvez ' +
                "transférer l'épargne des anciens plans sur votre nouveau PER.<br/><br/>" +
                'Quel que soit le type de PER, l’épargne est répartie en 3 compartiments constitués de la manière suivante&nbsp;: ' +
                'Les versements volontaires du salarié,&nbsp;' +
                'l’épargne salariale&nbsp;: participation, intéressement, abondement et épargne temps et&nbsp;' +
                "les versements de l’employeur dans le cadre d’un PER Obligatoire ou d'un PER Unique.&nbsp;" +
                'La fiscalité est différente en fonction des compartiments.'
        },
        {
            name: 'PERP',
            label: 'PERP/Madelin',
            title: 'PERP/Madelin',
            text:
                "Le Plan d'Épargne Retraite Populaire (PERP) est un produit d'épargne à long terme qui permet " +
                "d'obtenir, à partir de l'âge de la retraite, un revenu régulier supplémentaire. Le capital " +
                "constitué est reversé sous forme d'une rente viagère.<br/><br/> " +
                '<div class="semibold">Madelin</div><br/> ' +
                "Réservé aux libéraux, commerçants et artisans, le contrat Madelin permet d'épargner en vue de la " +
                'retraite, puis de toucher une rente viagère.'
        },
        {
            name: 'A82',
            label: 'Article 82',
            title: 'Article 82',
            text:
                'Ce contrat collectif souscrit par l’entreprise, est apparenté à un contrat en sur-salaire ' +
                'car le montant de la prime versée s’ajoute au salaire brut du salarié. L’entreprise choisit ' +
                'les salariés qui bénéficient de ce contrat. Ces salariés disposent alors d’un compte individuel ' +
                'alimenté par des cotisations de l’entreprise et leurs éventuels versements volontaires. Cette ' +
                'épargne est définitivement acquise au salarié. <br/><br/>' +
                'En fonction du type de contrat Article 82 choisi par l’entreprise, le salarié a la possibilité ' +
                'de récupérer son épargne à sa convenance, ou uniquement, au moment du départ à la retraite.<br/><br/> ' +
                'À la retraite, le salarié choisit entre le versement d’une rente viagère ou le versement d’un capital.'
        },
        {
            name: 'A83',
            label: 'Article 83',
            title: 'Article 83',
            text:
                'Le contrat de retraite supplémentaire (Article 83) mis en place par votre entreprise, vous ' +
                'permet de vous constituer un complément de revenu à la retraite, avec l’aide de votre entreprise ' +
                'dans des conditions fiscales avantageuses.<br/><br/>' +
                'Un compte individuel ouvert à votre nom est alimenté par :<br/>' +
                '    · des cotisations obligatoires (versées par l’entreprise et éventuellement par vous-même)<br/>' +
                '    · des jours issus de votre Compte Epargne Temps (CET) ou des jours de repos non pris<br/>' +
                '    · des éventuels versements volontaires<br/>' +
                '    · le transfert de votre épargne issue d’un contrat de même nature (PERP…).<br/><br/>' +
                'L’épargne constituée est disponible au moment de votre départ à la retraite sous forme d’une rente à vie.'
        }
    ]
};

const tab_projects = {
    name: 'project',
    label: 'Autres projets',
    items: [
        {
            name: 'PEE',
            label: 'PEE',
            title: 'PEE',
            text:
                'Le Plan d’Épargne d’Entreprise (PEE) mis en place par votre entreprise vous permet de vous ' +
                'constituer une épargne pour vos projets dans des conditions fiscales avantageuses.' +
                'Cette épargne est disponible au bout de 5 ans, sauf cas de déblocage anticipé.<br/><br/>' +
                'Un compte individuel est ouvert à votre nom et peut être alimenté par :<br/>' +
                '    · l’intéressement<br/>' +
                '    · la participation<br/>' +
                '    · les droits issus du Compte Epargne-Temps (CET)*<br/>' +
                '    · les jours de repos non pris (maximum 10 jours)<br/>' +
                '    · vos éventuels versements volontaires<br/><br/>' +
                '<span class="semibold">Bon à savoir</span><br/>' +
                'Chaque année, vous pouvez effectuer des versements volontaires (y compris les droits issus du ' +
                'CET affectés au PEE) jusqu’à 25% de votre rémunération brute annuelle, tous plans d’épargne ' +
                'salariale confondus. Le transfert de sommes provenant d’un autre PEE.<br/>' +
                'Si l’accord le prévoit, vous pouvez également bénéficier d’une aide financière de votre ' +
                'entreprise appelée « abondement ».<br/>' +
                '* si l’accord relatif au CET le prévoit'
        },
        {
            name: 'lifeinsurance',
            label: 'Assurance Vie',
            title: 'Assurance Vie',
            text:
                "L'assurance-vie est un placement financier qui permet au souscripteur d'épargner de l'argent " +
                "dans l'objectif de le transmettre à un bénéficiaire lorsque survient un événement lié à " +
                "l'assuré : son décès ou sa survie. Ce produit d’épargne permet au souscripteur de percevoir des " +
                'intérêts sur son contrat en fonction du capital investi.<br/><br/>' +
                '    · En cas de vie du souscripteur, il reste le bénéficiaire et titulaire des fonds et peut ' +
                '      récupérer librement le capital et les intérêts<br/>' +
                '    · En cas de décès du souscripteur, le contrat sera dénoué et le capital et les intérêts ' +
                '      seront transmis à le (ou les) bénéficiaire(s) de son choix (enfants, conjoints, concubin, ' +
                '      frères et sœurs, etc...)'
        }
    ]
};

const tab_annuity = {
    name: 'Rente',
    label: 'Rentes',
    items: [
        {
            name: 'RVR',
            label: 'Réversible',
            title: wording.translateAnnuity('RV_R'),
            text: 'La rente avec réversion pour protéger aussi votre conjoint. Elle garantit à votre conjoint en cas de décès de recevoir 50%, 60% ou 100% de la rente en fonction du taux de réversion choisi.'
        },
        {
            name: 'RVNR',
            label: 'Non réversible',
            title: wording.translateAnnuity('RV_NR'),
            text: "La rente simple pour vous protéger. Aussi appelée « rente viagère non réversible », elle vous garantit un complément de revenu tout au long de votre retraite. Elle est versée jusqu'à votre décès."
        },
        {
            name: 'RVRAG',
            label: 'Réversible avec annuités garanties',
            title: wording.translateAnnuity('RV_RAG'),
            text: "La rente viagère avec réversion et annuités garanties pour protéger votre conjoint et vos proches. Si vous décédez pendant la période de garantie choisie (10 ou 15 ans, choix effectué à la liquidation), une rente est d'abord versée en réversion au conjoint survivant. Si ce dernier décède durant la période de garantie, cette rente est versée au(x) bénéficiaire(s) que vous aurez désigné(s) jusqu'à la fin de la période de garantie."
        },
        {
            name: 'RVNRAG',
            label: 'Non réversible avec annuités garanties',
            title: wording.translateAnnuity('RV_NRAG'),
            text: 'La rente viagère avec annuités garanties pour protéger la personne de votre choix. Si vous décédez pendant la période de garantie choisie (5, 10 ou 15 ans, choix effectué à la liquidation), la rente continue d’être versée au(x) bénéficiaire(s) que vous aurez désigné(s)jusqu’à la fin de la période de garantie.'
        },
        {
            name: 'RVCPP',
            label: 'Croissant par paliers',
            title: wording.translateAnnuity('RV_CPP'),
            text: 'La rente croissante par palier pour anticiper vos dépenses de santé. Votre rente augmente de 20% à votre 70ème anniversaire et à nouveau de 20% lors de vos 75 ans.'
        }
    ]
};

const lexicons = {
    home: [tab_retirement, tab_projects],
    mrm: [tab_annuity, tab_retirement]
};

export default lexicons;
