import React, {useState} from 'react';
import {Structure} from 'components';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {StepInformation, StepIntro, StepProfile} from './components';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg-transmission.jpg)`,
        backgroundPosition: 'center right',
        backgroundSize: 'cover',
        backgroundColor: 'transparent',
        transition: 'background 0.25s ease-out'
    },
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 100
    },
    wrapper: {
        padding: '0px !important'
    },
    scroll: {
        height: '90vh',
        overflow: 'hidden',
        overflowY: 'auto'
    }
}));

function TransmissionStepper() {
    const classes = useStyles();
    const [step, setStep] = useState(0);

    const transmissionContent = (
        <div className={classes.scroll}>
            <Structure.Center
                className={clsx({
                    [classes.wrapper]: true
                })}
            >
                <Structure.Carousel
                    initialSlide={step}
                    onChange={(index) => setStep(index)}
                    smooth
                    adaptiveHeight
                >
                    <StepIntro
                        step={step}
                        setStep={setStep}
                    />
                    <StepInformation
                        step={step}
                        setStep={setStep}
                    />
                    <StepProfile
                        step={step}
                        setStep={setStep}
                    />
                </Structure.Carousel>
            </Structure.Center>
        </div>
    );

    return <div className={classes.root}>{transmissionContent}</div>;
}

export default TransmissionStepper;