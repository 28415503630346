import React, {useEffect} from 'react';
import {Buttons, Grid, Icon, Typography} from 'components';
import {makeStyles} from '@material-ui/styles';
import {sendTag} from 'store/Tags';
import {useUser} from "hooks";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        margin: theme.spacing(2, 0, 0),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
}));

function SubStepInfos({step, setStep, setStepParent}) {
    const classes = useStyles();
    const {user} = useUser();

    useEffect(() => {
        if (step === 1) sendTag(user,'cnav::intro');
        // eslint-disable-next-line
    }, [step]);

    return (
        <>
            <Grid
                container
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <Icon
                        name="axa-icons-help-o"
                        size={50}
                        color="blue"
                    />
                </Grid>
                <Grid item>
                    <Typography
                        variant="h4"
                        semibold
                    >
                        Informations
                    </Typography>
                </Grid>
            </Grid>
            <Typography>
                Votre simulation est réalisée à partir des données connues de vos régimes de retraite. L'absence de
                certaines données peut influer sur le résultat de votre simulation. Nous vous invitons donc à vérifier
                et compléter, si nécessaire, votre carrière.
            </Typography>
            <Typography>
                Attention, les données ajoutées seront uniquement utilisées dans ce simulateur et ne seront ni
                conservées, ni transmises à vos régimes de retraite.
            </Typography>
            <Typography>
                Le résultat de cette simulation est délivré en l'état de la règlementation et des informations détenues
                : il présente un caractère indicatif, provisoire et non contractuel. Il n'engage pas les régimes de
                retraite (Art. D161-2-1-4 et D161-2-1-7 CSS).
            </Typography>
            <div className={classes.wrapper}>
                <Buttons.Default
                    label="Étape précédente"
                    color="blueAlpha"
                    previous
                    onClick={() => setStep(0)} // SubStepDisclaimer
                />
                <div className="flexGrow" />
                <Buttons.Default
                    color="green"
                    label="J'ai compris"
                    next
                    onClick={() => {
                        sendTag(user, 'cnav::disclaimer');
                        setStepParent();
                        setTimeout(() => setStepParent(1), 100);
                    }} // StepLogin
                />
            </div>
        </>
    );
}

export default SubStepInfos;
