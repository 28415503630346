const config = {
    HP_18_MOIS_MIN: '18 mois',
    HP_1_AN_MIN: '1 an',
    HP_1_JR_3_MOIS: '1 jour à 3 mois',
    HP_1_JR_MIN: '1 jour',
    HP_1_SEM_MIN: '1 semaine',
    HP_2_ANS_MIN: '2 ans',
    HP_2_MOIS_MIN: '2 mois',
    HP_3_ANS_MIN: '3 ans',
    HP_3_MOIS_MIN: '3 mois',
    HP_4_ANS_MIN: '4 ans',
    HP_5_ANS_MIN: '5 ans',
    HP_6_ANS_MIN: '6 ans',
    HP_6_MOIS_MIN: '6 mois',
    HP_8_ANS_MIN: '8 ans',
    HP_PAS_DUREE_MIN: 'Pas de durée minimum',
    HP_RETRAITE: 'Retraite',
    HP_NC: ''
};

const translateFundHorizon = (horizon) => {
    if (Object.keys(config).includes(horizon)) return config[horizon];
    return horizon;
};

export default translateFundHorizon;
