import {Chevron, Default, Download, Expand, Icon, Link, SSO} from './components';

export default {
    Default,
    Download,
    Chevron,
    Expand,
    Icon,
    Link,
    SSO
};
