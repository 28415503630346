import React from 'react';
import {Buttons, Form, Inputs, Typography} from 'components';
import {useTaxSimulator} from 'hooks';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 280,
        margin: 'auto'
    }
}));

function StepAge({setStep}) {
    const classes = useStyles();

    const {taxSimulator, setTaxSimulator, variant} = useTaxSimulator();

    return (
        <Form
            className={classes.root}
            schema={{
                client_age: {
                    presence: {
                        allowEmpty: false,
                        message: 'Veuillez renseigner votre âge'
                    },
                    numericality: {
                        greaterThanOrEqualTo: 18,
                        lessThanOrEqualTo: 69,
                        message: 'Votre âge doit être compris entre 18 et 69 ans'
                    }
                }
            }}
            submit={(formState) => {
                setTaxSimulator({
                    ...taxSimulator,
                    inputs: {
                        ...taxSimulator.inputs,
                        ...formState.values
                    }
                });
                setStep(2);
                return new Promise((resolve) => resolve());
            }}
            variant="blue"
        >
            <Typography
                variant={variant === 'public' ? 'h1' : 'h2'}
                color="blue"
                center
            >
                Vous avez...
            </Typography>
            <Inputs.Number
                name="client_age"
                adornment="ans"
                right
            />
            <Buttons.Default
                label="Étape suivante"
                color="green"
                center
                next
                submit
            />
        </Form>
    );
}

export default StepAge;
