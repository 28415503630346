import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {Backdrop, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    backdrop: {
        position: 'absolute',
        color: '#fff',
        zIndex: 10
    },
    transparent: {
        background: 'transparent'
    },
    full: {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        zIndex: 10000
    }
}));

function Circular(props) {
    const {open, className, transparent, full, disableShrink, ...rest} = props;
    const classes = useStyles();

    return (
        <Backdrop
            className={clsx({
                [classes.backdrop]: true,
                [className]: className,
                [classes.transparent]: transparent,
                [classes.full]: full
            })}
            open={open}
        >
            <CircularProgress
                color={transparent ? 'primary' : 'inherit'}
                disableShrink={disableShrink}
                {...rest}
            />
        </Backdrop>
    );
}

Circular.propTypes = {
    open: PropTypes.bool.isRequired,
    className: PropTypes.string,
    transparent: PropTypes.bool,
    full: PropTypes.bool,
    disableShrink: PropTypes.bool
};

export default Circular;
