import {createTheme} from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

export const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 980,
        lg: 1280,
        xl: 1920
    }
};

const theme = createTheme({
    spacing: 12,
    breakpoints,
    palette,
    typography,
    overrides
});

export default theme;
