import React from 'react';
import {Buttons, Form, Inputs, Typography} from 'components';
import {useTaxSimulator} from 'hooks';

const SubStepTaxCeilingSpouse = ({setStep}) => {
    const {taxSimulator, setTaxSimulator} = useTaxSimulator();

    return (
        <Form
            schema={{
                tax_ceiling_spouse: {
                    presence: {
                        allowEmpty: false,
                        message: "Veuillez renseigner le plafond d'épargne retraite de votre conjoint"
                    },
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: "Le plafond d'épargne retraite de votre conjoint doit être positif"
                    }
                }
            }}
            values={{
                tax_ceiling_spouse: 0
            }}
            submit={(formState) => {
                setTaxSimulator({
                    ...taxSimulator,
                    inputs: {
                        ...taxSimulator.inputs,
                        ...formState.values
                    }
                });
                setStep(3);
                return new Promise((resolve) => resolve());
            }}
        >
            <Typography
                variant="h5"
                color="blue"
                center
            >
                Votre plafond d'épargne retraite en {new Date().getFullYear()} de votre conjoint
            </Typography>
            <Typography>
                Ce montant figure sur votre avis d’impôt sur les revenus à la rubrique PLAFOND EPARGNE RETRAITE et à la
                ligne « Plafond pour les cotisations versées en {new Date().getFullYear()} ». Un mail à l’administration
                fiscale sur impots.gouv.fr vous permettra également de l’obtenir.
            </Typography>
            <Inputs.Number
                name="tax_ceiling_spouse"
                format="money"
                adornment="€"
                right
            />
            <Buttons.Default
                label="Suivant"
                color="green"
                next
                center
                submit
            />
        </Form>
    );
};

export default SubStepTaxCeilingSpouse;
