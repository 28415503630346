import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Structure} from 'components';

import {Box, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    img: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    wrapperTitle: {
        padding: theme.spacing(6, 0),
        textShadow: `-1px -1px 1px ${theme.palette.grayLight}`
    },
    intro: {
        padding: theme.spacing(4),
        backgroundColor: theme.palette.headerPale
    },
    content: {
        background: theme.palette.white,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
        boxShadow: theme.shadows[2]
    }
}));

function Folio(props) {
    const {children, className, title, intro, image, position, height} = props;

    const classes = useStyles();
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const img_height = height ? height : 500;
    return (
        <div className={classes.root}>
            <div
                className={classes.img}
                style={{
                    backgroundImage: image.startsWith('http')
                        ? `url(${image})`
                        : `url(${process.env.PUBLIC_URL}/images/${image}.jpg)`,
                    backgroundPosition: (isDesktop && position) || 'center',
                    height: img_height
                }}
            >
                {title && <Structure.Center className={classes.wrapperTitle}>{title}</Structure.Center>}
            </div>

            {intro && (
                <Box className={classes.intro}>
                    <Structure.Center>{intro}</Structure.Center>
                </Box>
            )}

            <Box marginTop={intro ? 5 : -15}>
                 <Structure.Center className={clsx(classes.content, className)}>{children}</Structure.Center>
            </Box>

        </div>
    );
}

Folio.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.node,
    intro:  PropTypes.node,
    image: PropTypes.string.isRequired, // e.g: 'bg-accounts'
    position: PropTypes.string, // e.g: `center top`
    height: PropTypes.number,
    disableMargin: PropTypes.bool
};

export default Folio;
