import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {Typography} from 'components';

import {
    Hidden,
    Step as MuiStep,
    StepConnector as MuiStepConnector,
    StepLabel as MuiStepLabel,
    Stepper as MuiStepper,
    useMediaQuery,
    useTheme,
    withStyles
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {position: 'relative'},
    stepper: {
        background: theme.palette.pale,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
            background: theme.palette.blue
        },
        '& .MuiStepLabel-root': {
            flexDirection: 'column-reverse'
        }
    },
    navItem: {
        '& .MuiStepLabel-label, & .MuiStepLabel-label *:not(sup)': {
            ...theme.typography.h5,
            color: theme.palette.gray,
            margin: 0,
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        '&.MuiStep-completed': {
            '& .MuiStepLabel-label , & .MuiStepLabel-label *:not(sup)': {
                color: `${theme.palette.blue} !important`
            }
        },
        '&.active': {
            '& .MuiStepLabel-label, & .MuiStepLabel-label *:not(sup)': {
                color: `${theme.palette.blue} !important`
            }
        }
    },
    title: {
        background: theme.palette.blue,
        paddingTop: theme.spacing(1)
    },
    showIf: {
        position: 'absolute',
        bottom: 0,
        right: 10
    },
    label: {
        [theme.breakpoints.up('md')]: {
            height: 52,
            justifyContent: 'space-between'
        }
    }
}));

const CustomConnector = withStyles((theme) => ({
    alternativeLabel: {
        flexDirection: 'column-reverse',
        top: 40,
        left: 'calc(-60% + 20px)',
        right: 'calc(40% + 20px)',
        [theme.breakpoints.down('sm')]: {
            top: 10
        }
    },
    active: {
        '& $line': {
            borderColor: theme.palette.blue,
            [theme.breakpoints.down('sm')]: {
                borderColor: 'white'
            }
        }
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.blue,
            [theme.breakpoints.down('sm')]: {
                borderColor: 'white'
            }
        }
    },
    line: {
        borderColor: theme.palette.silver,
        borderTopWidth: 3,
        borderRadius: 1
    }
}))(MuiStepConnector);

const useCustomStepIconStyles = makeStyles((theme) => ({
    root: {
        zIndex: 3,
        color: '#c5c5c5',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            color: 'white'
        },
        '& .circle': {
            width: 15,
            height: 15,
            background: 'white',
            borderRadius: '50%',
            border: `solid 3px ${theme.palette.silver}`,
            '&.completed': {
                background: theme.palette.blue,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `solid 3px ${theme.palette.blue}`,
                '&:before': {
                    color: 'white',
                    fontSize: 11
                }
            }
        }
    },
    active: {
        color: theme.palette.blue,
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.blue
        },
        '& .circle': {
            border: 'solid 2px white',
            background: theme.palette.blue,
            boxShadow: theme.shadows[5]
        }
    }
}));

function CustomStepIcon(props) {
    const classes = useCustomStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}
        >
            <span className={clsx('circle', completed && 'completed axa-icons-check-1')} />
        </div>
    );
}

CustomStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool
};

const StepperNavigation = ({navigation, stepParent, step, currentSubStepsCounts, hasShowIf}) => {
    const classes = useStyles();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const ratio =
        (step - currentSubStepsCounts.slice(0, stepParent).reduce((acc, val) => acc + val, 0)) /
        currentSubStepsCounts[stepParent];

    const useWidthStyles = makeStyles((theme) => ({
        root: {
            '& .MuiStep-root.active + .MuiStep-root': {
                '& .MuiStepConnector-root': {
                    background: theme.palette.silver,
                    [theme.breakpoints.down('sm')]: {
                        background: theme.palette.silver
                    }
                },
                '& .MuiStepConnector-line': {
                    transition: 'width 300ms ease-out',
                    width: `${ratio * 100}%`,
                    borderColor: theme.palette.blue,
                    [theme.breakpoints.down('sm')]: {
                        borderColor: theme.palette.silver
                    }
                }
            }
        }
    }));

    const classesWidth = useWidthStyles();

    if (navigation.length === 0) return null;

    return (
        <div className={classes.root}>
            <Hidden mdUp>
                <Typography
                    className={classes.title}
                    variant="h6"
                    color="white"
                    center
                    semibold
                    gutterBottom={false}
                >
                    {navigation[stepParent]}
                </Typography>
            </Hidden>
            <nav>
                <MuiStepper
                    activeStep={stepParent}
                    alternativeLabel
                    className={clsx(classes.stepper, classesWidth.root)}
                    component={'ol'}
                >
                    {navigation.map((name, index) => (
                        <MuiStep
                            key={`navigation-${index}`}
                            className={clsx(classes.navItem, stepParent === index && 'active')}
                            connector={<CustomConnector />}
                        >
                            <MuiStepLabel
                                className={classes.label}
                                StepIconComponent={CustomStepIcon}
                            >
                                <li>{name}</li>
                            </MuiStepLabel>
                        </MuiStep>
                    ))}
                    {hasShowIf && !isMobile && (
                        <Typography
                            className={classes.showIf}
                            color="scorpion"
                        >
                            <sup style={{fontSize: '10px'}}>(1)</sup> selon le cas
                        </Typography>
                    )}
                </MuiStepper>
            </nav>
        </div>
    );
};

StepperNavigation.propTypes = {
    navigation: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    stepParent: PropTypes.number.isRequired
};

export default StepperNavigation;
