import React, {useState} from 'react';
import {json} from 'utils';

export const UserContext = React.createContext({
    user: {},
    setUser: () => {}
});

export const UserProvider = (props) => {
    const setUser = (user) => {
        setState({...state, user: user});
        localStorage.setItem('EP_user', JSON.stringify(user));
    };

    const initState = {
        user: json.parse(localStorage.getItem('EP_user')) || {},
        setUser: setUser
    };

    const [state, setState] = useState(initState);

    return <UserContext.Provider value={state}>{props.children}</UserContext.Provider>;
};
