import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Typography} from 'components';
import {NavButton, NavButtonExpand} from './components';

import {makeStyles} from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 58,
        height: 'calc(58px - 27px)',
        padding: '0 8px',
        margin: '8px 0',
        cursor: 'pointer'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    bar: {
        width: 'calc(100% - 16px)',
        height: 3,
        marginTop: 3,
        marginBottom: 3,
        background: theme.palette.blue,
        transition: 'all 0.25s ease-out'
    },
    open: {
        opacity: 0,
        '&:first-child': {
            opacity: 1,
            transform: 'rotate(-45deg) translate(-6.5px, 6.5px)'
        },
        '&:last-child': {
            opacity: 1,
            transform: 'rotate(45deg) translate(-6.5px, -6.5px)'
        }
    },
    caption: {
        fontSize: 10
    },
    menu: {
        position: 'absolute',
        top: 58,
        left: -theme.spacing(2),
        width: `calc(100% + ${theme.spacing(4)}px)`,
        transition: 'all 0.25s ease-out',
        opacity: 0,
        zIndex: -1,
        height: 'calc(100vh - 58px)',
        backgroundColor: 'white',
        overflow: 'hidden',
        overflowY: 'auto',
        '&>*:first-child': {
            marginTop: 2
        }
    },
    menuOpen: {
        boxShadow: '0 3px 5px -1px #00000038',
        transform: 'translateY(0px) !important',
        opacity: 1
    },
    profileTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 45,
        padding: theme.spacing(0, 2),
        background: theme.palette.white,
        borderBottom: `solid 1px ${theme.palette.grayLight}`
    },
    icon: {
        color: theme.palette.blue
    }
}));

function MenuMobile({nav, name, activeMenu, setActiveMenu, className, semibold, children}) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)}>
            <div
                tabIndex={0}
                className={classes.container}
                onClick={() => setActiveMenu(activeMenu === name ? '' : name)}
                onKeyDown={(e) => {
                    if(e.target.tagName !== 'A' && e.key === 'Tab')
                        if(e.shiftKey)
                            setActiveMenu('')
                        else
                            setActiveMenu(name);

                }}
            >
                {children ? (
                    children
                ) : (
                    <>
                        <div
                            className={clsx({
                                [classes.bar]: true,
                                [classes.open]: activeMenu === name
                            })}
                        />
                        <div
                            className={clsx({
                                [classes.bar]: true,
                                [classes.open]: activeMenu === name
                            })}
                        />
                        <div
                            className={clsx({
                                [classes.bar]: true,
                                [classes.open]: activeMenu === name
                            })}
                        />
                    </>
                )}
            </div>

            <div
                className={clsx({
                    [classes.menu]: true,
                    [classes.menuOpen]: activeMenu === name,
                    hide: activeMenu !== name
                })}
                style={{transform: `translateY(-100%)`}}
            >
                {name === 'profile' && (
                    <div
                        tabIndex={0}
                        className={classes.profileTitle}
                        onClick={() => setActiveMenu('')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setActiveMenu('');
                            }
                        }}
                    >
                        <Typography
                            color="blue"
                            semibold
                            gutterBottom={false}
                            size={16}
                            localization={'navigation.profileMobile'}
                        />
                        <CloseIcon className={classes.icon} />
                    </div>
                )}
                {nav.map((item, index) =>
                    item.children ? (
                        <NavButtonExpand
                            key={`navButton-${item.label}`}
                            label={item.label}
                            items={item.children}
                            semibold={semibold}
                            onMouseDown={item.action}
                            icon={item.icon}
                        />
                    ) : (
                        <NavButton
                            key={`navButton-${item.label}`}
                            label={item.label}
                            url={item.url}
                            semibold={semibold}
                            onMouseDown={item.action}
                            external={item.external}
                            icon={item.icon}
                            onKeyDown={(e) => {
                                e.key === 'Tab' && !e.shiftKey && index === nav.length - 1 && setActiveMenu('');
                            }}
                        />
                    )
                )}
            </div>
        </div>
    );
}

MenuMobile.propTypes = {
    nav: PropTypes.array.isRequired,
    className: PropTypes.string,
    semibold: PropTypes.bool,
    children: PropTypes.node // Override close icon
};

export default MenuMobile;
