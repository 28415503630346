import React from 'react';
import clsx from 'clsx';
import {Buttons, Info, Typography} from 'components';
import {RefineModal} from './../../components';
import {useTaxSimulator} from 'hooks';
import {amount, modal} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    disabled: {
        background: '#c2c2c2'
    },
    border: {
        height: '100%',
        minHeight: 150,
        border: `solid 2px ${theme.palette.grayLight}`,
        padding: theme.spacing(2, 1)
    },
    amount: {
        padding: theme.spacing(2, 0, 1)
    }
}));

function TabEconomy() {
    const classes = useStyles();

    const {taxSimulator, variant} = useTaxSimulator();

    const enabled = variant === 'contained' ? !!taxSimulator.response.tax_reduction : true;

    return (
        <div
            className={clsx({
                [classes.border]: variant === 'contained',
                [classes.disabled]: !enabled
            })}
        >
            <Typography
                variant="h4"
                color={enabled ? 'blue' : 'black'}
                center
            >
                Vous économiserez&nbsp;
                {enabled && (
                    <Info
                        hash="disclaimer_tax_2"
                        title="Information"
                        color="blue"
                    >
                        L'estimation ne prend pas en compte votre enveloppe fiscale non utilisée ni celle de votre
                        conjoint. La simulation ne tient pas compte des éventuels versements et cotisations sur vos
                        contrats de retraite supplémentaire Article 83, contrats PERP, PREFON, PER et abondement perçu
                        au titre d'un PERCO ou d'un PER. Ces éléments figurent dans votre feuille d'impôts. Cliquez sur
                        "Affiner" pour les intégrer.
                    </Info>
                )}
            </Typography>
            <Typography
                className={classes.amount}
                variant="h2"
                color={enabled ? 'blue' : 'black'}
                semibold
                center
            >
                {amount.format(taxSimulator.response.tax_reduction, 0, "€ d'impôts")}
            </Typography>
            {enabled ? (
                <Typography
                    variant="h5"
                    color="blue"
                    center
                >
                    en versant&nbsp;
                    {amount.format(
                        taxSimulator.inputs.allocated_periodicity === 'monthly'
                            ? 12 * parseInt(taxSimulator.inputs.allocated_amount)
                            : parseInt(taxSimulator.inputs.allocated_amount),
                        0,
                        '€'
                    )}
                    &nbsp; en {new Date().getFullYear()} uniquement pour l'Article 83 et le PER
                </Typography>
            ) : (
                <Typography
                    variant="h5"
                    center
                >
                    en {new Date().getFullYear()}
                </Typography>
            )}
            {enabled && (
                <Buttons.Chevron
                    label="Affiner"
                    color="blue"
                    center
                    onClick={() => modal.open('refine')}
                />
            )}
            {enabled && <RefineModal />}
        </div>
    );
}

export default TabEconomy;
