function resize() {
    if (window.location.pathname === '/presentation') return null;

    // Trigger resize (IE compatible)
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        let event = document.createEvent('UIEvents');
        event.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(event);
    } else {
        window.dispatchEvent(new Event('resize'));
    }
}

export default resize;
