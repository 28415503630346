export const REFUND_URLS = {
    ONE_SCOPE: {
        ais: '/savings/withdraw/ais',
        s2e: '/savings/withdraw'
    },
    TWO_SCOPES: '/savings/withdraw'
};

export const TRANSFER_URLS = {
    ONE_SCOPE: {
        ais: '/savings/transfer/ais',
        s2e: '/savings/transfer/s2e'
    },
    TWO_SCOPES: '/savings/transfer'
};

export const HOME_URLS = {
    CONNECTED: '/epargne',
    NOT_CONNECTED: '/'
};
