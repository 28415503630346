function parse(data) {
    let parsed;

    try {
        parsed = JSON.parse(data);
    } catch (e) {
        parsed = {};
    }

    return parsed;
}

export default parse;
