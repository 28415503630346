import React from 'react';
import {Icon, Structure, Typography} from 'components';
import {Link} from 'react-router-dom';
import config from 'config';

import {Toolbar} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useUser} from "hooks";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: 76,
        minHeight: 'initial',
        borderTop: `2px solid ${theme.palette.blue}`
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        width: 55,
        height: 55
    },
    navButton: {
        position: 'relative',
        height: 76,
        display: 'flex',
        alignItems: 'center',
        '&:hover&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 2,
            background: theme.palette.blueLight
        }
    },
    navLabel: {
        padding: theme.spacing(0, 2),
        color: theme.palette.blueLight,
        textTransform: 'uppercase'
    },
    profileButton: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    profileLabel: {
        padding: theme.spacing(0, 1),
        color: theme.palette.blueLight
    },
    icon: {
        color: theme.palette.blueLight
    },
    logout: {
        color: theme.palette.blueLight,
        marginLeft: theme.spacing(1)
    }
}));

const NavButton = (props) => {
    const {url, label} = props;
    const classes = useStyles();

    return (
        <a
            className={classes.navButton}
            href={url}
            rel="noopener noreferrer"
        >
            <Typography
                className={classes.navLabel}
                variant="h5"
                semibold
            >
                {label}
            </Typography>
        </a>
    );
};

const ProfileButton = () => {
    const classes = useStyles();

    return (
        <a
            className={classes.profileButton}
            href={`${config.AXA_CLIENT_SPACE_URL[process.env.REACT_APP_ENVIRONMENT]}/profil`}
            rel="noopener noreferrer"
        >
            <Typography
                className={classes.profileLabel}
                semibold
            >
                MON PROFIL
            </Typography>
            <Icon
                className={classes.icon}
                name="axa-icons-person"
                size={20}
            />
        </a>
    );
};

function Desktop() {
    const classes = useStyles();
    const {user} = useUser();
    /*const application = user.origin === 'individual' ? 'maretraite360' : 'matransmission360';*/

    return (
        <>
            <Toolbar
                className={classes.toolbar}
                disableGutters
            >
                <Structure.Center
                    className={classes.wrapper}
                    maxWidth={1300}
                >
                    <Link
                        to={ user.origin === 'individual' ? "/epargne" : "/transmission"}
                        style={{marginRight: 16}}
                    >
                        <img
                            className={classes.logo}
                            src={`${process.env.PUBLIC_URL}/logos/axa.svg`}
                            alt="logo"
                        />
                    </Link>
                    <NavButton
                        label="Mes contrats"
                        url={`${config.AXA_CLIENT_SPACE_URL[process.env.REACT_APP_ENVIRONMENT]}`}
                    />
                    <NavButton
                        label="Mes documents"
                        url={`${
                            config.AXA_CLIENT_SPACE_URL[process.env.REACT_APP_ENVIRONMENT]
                        }/documents/epargne-et-placements`}
                    />
                    <div className="flexGrow" />
                    <ProfileButton />
                    {/*<Link to={`/logout/${application}`}>
                        <Icon
                            className={classes.logout}
                            name="axa-icons-power-button"
                            size={20}
                        />
                    </Link>*/}
                </Structure.Center>
            </Toolbar>
        </>
    );
}

export default Desktop;
