import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Typography as MuiTypography} from '@material-ui/core';

import {makeStyles, useTheme} from '@material-ui/styles';
import {Trans} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: '700 !important'
    },
    semibold: {
        fontWeight: '600 !important'
    },
    italic: {
        fontStyle: 'italic !important'
    },
    underlined: {
        textDecoration: 'underline !important'
    },
    center: {
        textAlign: 'center !important',
        marginLeft: 'auto !important',
        marginRight: 'auto !important'
    },
    capitalizeSentense: {
        '&:first-letter': {
            textTransform: 'capitalize'
        }
    },
    caps: {
        textTransform: 'uppercase !important'
    },
    right: {
        textAlign: 'right !important'
    },
    clickable: {
        cursor: 'pointer !important'
    },
    amount: {
        whiteSpace: 'nowrap',
        paddingLeft: theme.spacing(0.5)
    }
}));

function Typography(props) {
    const {
        className,
        bold,
        semibold,
        italic,
        underlined,
        justify,
        center,
        right,
        caps,
        size,
        color = 'dark',
        width,
        clickable,
        isAmount = false,
        localization,
        values,
        components,
        capitalizeSentense = true,
        ...rest
    } = props;
    const classes = useStyles();
    const theme = useTheme();

    return (
        <MuiTypography
            className={clsx({
                [className]: className,
                [classes.bold]: bold,
                [classes.semibold]: semibold,
                [classes.italic]: italic,
                [classes.underlined]: underlined,
                [classes.center]: center,
                [classes.justify]: justify,
                [classes.caps]: caps,
                [classes.right]: right,
                [classes.clickable]: clickable,
                [classes.amount]: isAmount,
                [classes.capitalizeSentense]: capitalizeSentense
            })}
            gutterBottom
            style={{
                color: color && theme.palette[color],
                fontSize: size,
                maxWidth: width && width
            }}
            {...rest}
        >
            {localization ? (
                <Trans
                    i18nKey={localization}
                    values={values}
                    components={components}
                />
            ) : (
                props.children
            )}
        </MuiTypography>
    );
}

Typography.propTypes = {
    className: PropTypes.string,
    bold: PropTypes.bool,
    semibold: PropTypes.bool,
    italic: PropTypes.bool,
    underlined: PropTypes.bool,
    center: PropTypes.bool,
    justify: PropTypes.bool,
    right: PropTypes.bool,
    caps: PropTypes.bool,
    size: PropTypes.number,
    color: PropTypes.string,
    width: PropTypes.number,
    clickable: PropTypes.bool,
    isAmount: PropTypes.bool
};

export default Typography;
