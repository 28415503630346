import * as React from 'react';
import {cloneElement} from 'react';

import {Typography} from 'components';

import {DataGrid, GridToolbarContainer} from '@material-ui/data-grid';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useScreenWidth} from '../../hooks';

const useStyles = makeStyles((theme) => ({
    root: {},
    mobileTitle: {
        border: `solid 1px ${theme.palette.mercury}`,
        borderLeft: `solid 5px ${theme.palette.blue}`,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        padding: theme.spacing(0, 1, 1)
    }
}));

function Table({rows = [], columns, title = null, MobileTile = null, footer = null, breakpoint = 980, ...rest}) {
    const classes = useStyles();

    const isMobile = useScreenWidth(breakpoint);

    if (isMobile && MobileTile)
        return (
            <>
                <Box className={classes.mobileTitle}>{title}</Box>

                {rows.map((row, index) => (
                    <Box key={`row-${index}`}>{cloneElement(MobileTile, {row})}</Box>
                ))}

                <Box>{footer}</Box>
            </>
        );

    return (
        <DataGrid
            className={classes.root}
            density="comfortable"
            scrollbarSize={0}
            autoHeight
            disableVirtualization
            headerHeight={40}
            rows={rows}
            onCellKeyDown={(params, event) => {
                event.defaultMuiPrevented = true;
            }}
            onCellClick={(params, event) => {
                event.defaultMuiPrevented = true;
            }}
            columns={columns
                .filter(({hideMobile}) => (isMobile ? !hideMobile : true))
                .filter(({hideDesktop}) => (!isMobile ? !hideDesktop : true))
                .map(({name, hideHeader, hideMobile, hideDesktop, ...column}) => ({
                    headerName: (
                        <Typography
                            semibold
                            size={16}
                            gutterBottom={false}
                        >
                            {hideHeader ? '' : name}
                        </Typography>
                    ),
                    align: 'left',
                    disableColumnMenu: true,
                    disableReorder: true,
                    sortable: false,
                    flex: 1,
                    ...column
                }))}
            components={{
                Footer: () =>
                    footer &&
                    (typeof footer === 'string' ? (
                        <Box sx={{display: 'flex', alignItems: 'center', height: 50}}>
                            <Typography
                                variant="h4"
                                color="blue"
                                semibold
                                gutterBottom={false}
                            >
                                {footer}
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={{display: 'flex', alignItems: 'center', height: 50}}>{footer}</Box>
                    )),
                Pagination: () => null,
                Toolbar: () =>
                    title &&
                    (typeof title === 'string' ? (
                        <GridToolbarContainer>
                            <Typography
                                variant="h4"
                                color="blue"
                                semibold
                                gutterBottom={false}
                            >
                                {title}
                            </Typography>
                        </GridToolbarContainer>
                    ) : (
                        <GridToolbarContainer style={{alignItems: 'stretch'}}>{title}</GridToolbarContainer>
                    ))
            }}
            {...rest}
        />
    );
}

export default Table;
