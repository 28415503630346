import React from 'react';
import {Buttons, Grid, Inputs} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 350,
        height: 320,
        padding: theme.spacing(2)
    }
}));

function StepSatisfactionComment({setStep}) {
    const classes = useStyles();

    return (
        <Grid
            item
            xs={12}
            className={classes.root}
        >
            <Inputs.Textarea
                label="Commentaires"
                name="satisfaction_comment"
                rows="4"
                style={{minHeight: 180, resize: 'none'}}
            />
            <Buttons.Default
                label="Suivant"
                center
                color="blue"
                next
                onClick={() => setStep(3)}
            />
        </Grid>
    );
}

export default StepSatisfactionComment;
