import mock from 'api/mockFastApi';

import {age_full_rate, careers, date, family_situation, professional_categories, professional_situation, result} from './data';

mock.onPost('/simulators/marel/initialize').replyOnce(() => {
    return [404, "Not found"];
});

mock.onPost('/simulators/marel/initialize').reply(() => {
    return [200, {age_full_rate, careers, date, result}];
});
mock.onPost('/simulators/marel/login/dgfip').reply(() => {
    return [200, {family_situation, professional_situation, professional_categories}];
});

mock.onPost('/simulators/marel/login/cnav').reply(() => {
    return [200, {family_situation, professional_situation, professional_categories}];
});

mock.onPost('/simulators/marel/login/ameli').reply(() => {
    return [200, 'OK'];
});

mock.onPost('/simulators/marel/two-factor').reply(() => {
    return [200, {family_situation, professional_situation, professional_categories}];
});

mock.onPost('/simulators/marel/simulation').reply(() => {
    return [200, {age_full_rate, careers, date, result}];
});

mock.onPost('/simulators/marel/save').reply(() => [200, 'OK']);

mock.onPost('/simulators/marel/cap').reply(() => {
    return [200, {age_full_rate, careers, date, result}];
});

mock.onPost('/simulators/marel/register/first').reply(() => {
    return [200, {}];
});

mock.onPost('/simulators/marel/register/second').reply(() => {
    return [200, {}];
});

mock.onPost('/simulators/marel/forgot-password/first').reply(() => {
    return [200, {valeur: 'Quel est votre livre préféré ?'}];
});

mock.onPost('/simulators/marel/forgot-password/second').reply(() => {
    return [200, {}];
});
