import React, {useState} from 'react';
import {json} from 'utils';

export const B2VContext = React.createContext({
    B2V: null,
    setB2V: () => {}
});

export const B2VProvider = (props) => {
    const setB2V = (B2V) => {
        setState({...state, B2V: B2V});
        localStorage.setItem('EP_b2v', JSON.stringify(B2V));
    };

    const initState = {
        B2V: json.parse(localStorage.getItem('EP_b2v')) || null,
        setB2V: setB2V
    };

    const [state, setState] = useState(initState);

    return <B2VContext.Provider value={state}>{props.children}</B2VContext.Provider>;
};
