import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {Buttons, Charts, Grid, Icon, Modal, Typography} from 'components';
import config from 'config';
import {amount, modal} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    account: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 230,
        height: '100%',
        padding: theme.spacing(2, 0),
        border: `solid 1px ${theme.palette.gray}`,
        borderRadius: 3
    },
    icon: {
        width: 60,
        height: 60,
        margin: theme.spacing(2, 0),
        alignItems: 'center',
        background: theme.palette.grayLight,
        borderRadius: '50%'
    },
    amount: {
        padding: theme.spacing(3, 0)
    },
    disabled: {
        background: theme.palette.grayLight
    }
}));

const AccountMrmDetail = ({hash, account}) => {
    const [reload, setReload] = useState('');

    const open = modal.isOpen(hash);
    useEffect(() => {
        setTimeout(() => setReload(open ? 'value' : ''), 100);
    }, [open]);

    return (
        <Modal
            hash={hash}
            title={account.title}
            account={account}
            keepMounted
        >
            <Charts.Doughnut
                data={[
                    {
                        name: 'Versements volontaires',
                        value: account.balance_vv,
                        color: config.COLORS.axa.projects,
                        highlight_color: config.HIGHLIGHT_COLORS.axa.projects
                    },
                    {
                        name: 'CET, Participation et Intéressement',
                        value: account.balance_es,
                        color: config.COLORS.external.projects,
                        highlight_color: config.HIGHLIGHT_COLORS.external.projects
                    },
                    {
                        name: 'Versements obligatoires',
                        value: account.balance_co,
                        color: config.COLORS.axa.retirement,
                        highlight_color: config.HIGHLIGHT_COLORS.axa.retirement
                    }
                ]}
                dataKey={reload}
                withTotal
            />
        </Modal>
    );
};

function AccountMrm({index, account, disabled}) {
    const classes = useStyles();

    return (
        <Grid
            className={classes.root}
            item
            md={3}
            sm={4}
            xs={12}
        >
            <div className={clsx(classes.account, disabled && classes.disabled)}>
                <Icon
                    name="axa-icons-coins-euro-o"
                    className={classes.icon}
                    color={disabled ? 'black' : 'blue'}
                    size={40}
                    flex
                />
                <Typography
                    variant="h5"
                    color={disabled ? 'black' : 'blue'}
                    center
                >
                    {account.title}
                </Typography>
                <Typography
                    center
                    color={disabled ? 'grayDark' : 'black'}
                >
                    Contrat : {account.policy_id}
                </Typography>
                <Typography
                    className={classes.amount}
                    variant="h3"
                    color={disabled ? 'black' : 'blue'}
                    semibold
                >
                    {amount.format(account.balance)}
                </Typography>
                {account.type !== 'A83' && (
                    <>
                        <Buttons.Chevron
                            label="Voir le détail"
                            color="blueLight"
                            center
                            onClick={() => modal.open(`mrm_account_${index}`)}
                        />
                        <AccountMrmDetail
                            hash={`mrm_account_${index}`}
                            account={account}
                        />
                    </>
                )}
            </div>
        </Grid>
    );
}

AccountMrm.propTypes = {
    index: PropTypes.number.isRequired,
    account: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};

export default AccountMrm;
