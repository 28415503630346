import {Grid} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import {Typography} from 'components';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'left',
        borderCollapse: 'separate',
        borderSpacing: '0px 16px'
    },
    roundedFirst: {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
    },
    roundedLast: {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
    },
    th: {
        padding: '10px',
        background: theme.palette.blue,
        color: 'white'
    },
    td: {
        padding: '10px',
        borderTop: 'solid 1px #E5E5E5',
        borderBottom: 'solid 1px #E5E5E5',
        '&:first-child': {
            borderLeft: 'solid 1px #E5E5E5'
        },
        '&:last-child': {
            borderRight: 'solid 1px #E5E5E5'
        }
    },
    title: {
        padding: '8px 0'
    },
    white: {backgroundColor: 'white !important'}
}));

function TableGrid({rows, subRowsKey, columns, emptyDataMessage, className}) {
    const classes = useStyles();
    return (
        <Grid
            component="table"
            className={clsx(classes.root, className)}
        >
            <Grid component="thead">
                <Grid component="tr">
                    {columns.map((column, index) => (
                        <Grid
                            key={`head-${index}`}
                            component="th"
                            className={clsx(
                                classes.th,
                                index === 0 && classes.roundedFirst,
                                index === columns.length - 1 && classes.roundedLast
                            )}
                        >
                            {column.title}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid component="tbody">
                {rows &&
                    !subRowsKey &&
                    rows.length > 0 &&
                    rows.map((row, rindex) => (
                        <Grid
                            component="tr"
                            key={`row-${rindex}`}
                        >
                            {columns.map((column, cindex) => (
                                <Grid
                                    key={`column-${rindex}-${cindex}`}
                                    component="td"
                                    className={clsx(
                                        classes.td,
                                        cindex === 0 && classes.roundedFirst,
                                        cindex === columns.length - 1 && classes.roundedLast,
                                        classes.white
                                    )}
                                >
                                    {column.renderCell({row: row, column: column, index: rindex})}
                                </Grid>
                            ))}
                        </Grid>
                    ))}

                {rows &&
                    rows.length > 0 &&
                    subRowsKey &&
                    rows.map((row, rindex) => (
                        <React.Fragment key={rindex}>
                            <Grid
                                component="tr"
                                key={`row-spacer-${rindex}`}
                                colSpan={columns.length}
                                aria-colspan={columns.length}
                            >
                                <Typography
                                    semibold
                                    color={'blue'}
                                    component="td"
                                    className={classes.title}
                                >
                                    {row.title}
                                </Typography>
                            </Grid>
                            {row[subRowsKey].map((subRow, index) => (
                                <React.Fragment key={index}>
                                    <Grid
                                        component="tr"
                                        key={`row-${rindex}`}
                                    >
                                        {columns.map((column, cindex) => (
                                            <Grid
                                                key={`column-${rindex}-${cindex}`}
                                                component="td"
                                                className={clsx(
                                                    classes.td,
                                                    cindex === 0 && classes.roundedFirst,
                                                    cindex === columns.length - 1 && classes.roundedLast,
                                                    classes.white
                                                )}
                                            >
                                                {column.renderCell({
                                                    row: row,
                                                    subRow: subRow,
                                                    column: column,
                                                    index: index
                                                })}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                {(!rows || rows.length === 0) && (
                    <Grid
                        component="tr"
                        key={`row-no-data`}
                    >
                        <Grid
                            component="td"
                            key={`column-no-data`}
                            colspan={columns.length}
                        >
                            {emptyDataMessage}
                            {!emptyDataMessage && 'No data'}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

TableGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array,
    emptyDataMessage: PropTypes.string
};

export default TableGrid;
