import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Structure} from 'components';
import {MenuMobile} from 'layouts/_components';
import {ConsentB2VModal} from '../Desktop/components';
import {useB2V, useUser} from 'hooks';
import {navProfile} from 'layouts/Collective/Header/Desktop/Desktop';
import {modal} from 'utils';
import {useTranslation} from 'react-i18next';

import {Box, Toolbar} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ModalSso} from 'components/Buttons/components/SSO/SSO';
import {LangSelect} from 'macros';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

const useStyles = makeStyles((theme) => ({
    navigation: {
        height: 58,
        minHeight: 'initial'
    },
    wrapper: {
        display: 'flex',
        maxWidth: '1200px !important',
        alignItems: 'center'
    },
    logo: {
        width: 42,
        height: 42
    },
    badge: {
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            display: 'block',
            bottom: -2,
            right: -3,
            width: 8,
            height: 8,
            borderRadius: '50%',
            border: 'solid 2px white',
            backgroundColor: '#4da467'
        }
    },
    icon: {
        display: 'flex',
        marginBottom: -3,
        color: theme.palette.blue
    },
    caption: {
        fontSize: 10,
        whiteSpace: 'noWrap'
    },
    border: {
        borderRight: `solid 1px ${theme.palette.silver}`,
        borderLeft: `solid 1px ${theme.palette.silver}`
    }
}));

const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

function Mobile({nav}) {
    const {user} = useUser();
    const {B2V} = useB2V();
    const [activeMenu, setActiveMenu] = useState('');
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Toolbar
            className={classes.navigation}
            component={'nav'}
            disableGutters
        >
            <Structure.Center className={classes.wrapper}>
                <Link
                    to="/epargne"
                    aria-label={t('navigation.logo')}
                >
                    <img
                        className={classes.logo}
                        src={`${process.env.PUBLIC_URL}/logos/axa.svg`}
                        alt={t('navigation.logo')}
                    />
                </Link>

                <Box flexGrow={1} />
                {process.env.REACT_APP_ENVIRONMENT !== 'production' && <LangSelect />}

                <MenuMobile
                    className={classes.border}
                    name="profile"
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    nav={
                        B2V && !B2V.error
                            ? insert(navProfile(user, t), navProfile(user, t).length - 1, {
                                  label: t('navigation.consent'),
                                  icon: <SettingsOutlinedIcon style={{color: '#00008f', marginRight: 5}} />,
                                  action: () => modal.open('consent_b2v_mobile')
                              })
                            : navProfile(user, t)
                    }
                >
                    <Box className={classes.badge}>
                        {activeMenu ? (
                            <AccountCircleIcon
                                fontSize={'large'}
                                className={classes.icon}
                            />
                        ) : (
                            <AccountCircleOutlinedIcon
                                fontSize={'large'}
                                className={classes.icon}
                            />
                        )}
                    </Box>
                </MenuMobile>

                <MenuMobile
                    nav={nav}
                    name="navigation"
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                />
            </Structure.Center>

            <ConsentB2VModal hash="consent_b2v_mobile" />
            <ModalSso
                scope="ais"
                hash="sso_ais"
            />
        </Toolbar>
    );
}

Mobile.propTypes = {
    nav: PropTypes.array.isRequired
};

export default Mobile;
