import React, {useMemo} from 'react';
import moment from 'moment';
import {Typography} from 'components';
import {AnnuityTypeResult} from './components';
import {useStepper} from 'hooks';
import {amount, wording} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2, 0)
    },
    list: {
        marginLeft: theme.spacing(3),
        listStyle: 'square'
    }
}));

function StepMrmResult() {
    const classes = useStyles();

    const {stepper} = useStepper();

    const total_annuity = useMemo(
        () =>
            stepper.payload.distribution
                ? Object.entries(stepper.payload.distribution)
                      .filter(([key]) => key.startsWith('annuity'))
                      .map(([, value]) => amount.parse(value))
                      .reduce((acc, val) => acc + val, 0)
                : 0,
        [stepper.payload.distribution]
    );

    const reversible = stepper.payload.reversible;

    return (
        <>
            <Typography
                variant="h3"
                color="blue"
                center
                semibold
            >
                La simulation* présente toutes les options de rente
            </Typography>
            <Typography
                variant="h5"
                center
                italic
            >
                Effectuée le {moment(new Date()).format('DD/MM/YYYY')}
            </Typography>
            <Typography
                variant="h4"
                color="blue"
                center
            >
                Pour rappel, vous souhaitez bénéficier de&nbsp;
                <Typography
                    component="span"
                    semibold
                >
                    {amount.format(total_annuity)}
                </Typography>
                &nbsp;à convertir en rente
            </Typography>

            <div className={classes.container}>
                {stepper.response?.length > 1 && (
                    <Typography
                        variant="h4"
                        color="blue"
                    >
                        Pour une&nbsp;
                        <Typography
                            component="span"
                            semibold
                        >
                            {wording.translateAnnuity(stepper.payload.annuity_type, false)}
                        </Typography>
                        , le montant est estimé à :
                    </Typography>
                )}

                {stepper.response?.length > 0 &&
                    (stepper.response?.length > 1
                        ? stepper.response?.map((response, index) => (
                              <AnnuityTypeResult
                                  key={`result-${index}`}
                                  annuity_type={stepper.payload.annuity_type}
                                  policy_id={response.policy_id}
                              />
                          ))
                        : reversible
                        ? ['RV_R', 'RV_RAG'].map((annuity_type) => (
                              <AnnuityTypeResult
                                  key={`result-${annuity_type}`}
                                  annuity_type={annuity_type}
                                  policy_id={stepper.response[0].policy_id}
                              />
                          ))
                        : ['RV_NR', 'RV_NRAG', 'RV_CPP'].map((annuity_type) => (
                              <AnnuityTypeResult
                                  key={`result-${annuity_type}`}
                                  annuity_type={annuity_type}
                                  policy_id={stepper.response[0].policy_id}
                              />
                          )))}
            </div>

            {stepper.response?.length > 0 && (
                <>
                    <Typography
                        variant="h5"
                        color="blue"
                    >
                        Hypothèses techniques retenues :
                        <ul className={classes.list}>
                            <li>Tables de mortalité réglementaires en vigueur.</li>
                            <li>Taux de rendement de l'épargne : 2%** net.</li>
                        </ul>
                    </Typography>
                    <Typography color="blue">
                        * Les montants indiqués ne constituent en aucun cas un engagement contractuel de l'assureur. Le
                        montant de la rente effectivement versée dépendra de l'évolution des paramètres, des montants
                        versés pendant la vie du contrat et des tables de mortalité en vigueur au moment du départ à la
                        retraite.
                        <br />
                        ** Le taux d'intérêt retenu par hypothèse ne préjuge pas du taux réellement applicable pendant
                        la vie du contrat.
                    </Typography>
                </>
            )}
        </>
    );
}

export default StepMrmResult;
