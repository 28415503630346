import React from 'react';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dot: {
        margin: '0 5px',
        width: 15,
        height: 15,
        borderRadius: '50%',
        background: theme.palette.white,
        animationDuration: '1.2s',
        animationName: 'reveal',
        animationTimingFunction: 'ease-out',
        animationIterationCount: 'infinite',

        '&:first-child': {
            animationDelay: '-0.2s'
        },

        '&:last-child': {
            animationDelay: '0.2s'
        }
    }
}));

function Dots({...rest}) {
    const classes = useStyles();

    return (
        <div
            className={classes.root}
            {...rest}
        >
            <div className={classes.dot} />
            <div className={classes.dot} />
            <div className={classes.dot} />
        </div>
    );
}

export default Dots;
