import React from 'react';

import {Buttons, Grid, Icon, Structure, Typography} from 'components';
import {useTransmission, useUser} from 'hooks';

import {Box, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {fastapi} from 'api';
import {sendTag} from 'store/Tags';

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
        background: theme.palette.pinkDark,
        zIndex: -10
    },
    container: {
        padding: theme.spacing(2, 0)
    },
    button: {
        minWidth: '100% !important'
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    justify: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    modal: {
        width: '100%',
        maxWidth: 700
    },
    label: {
        fontSize: 18
    }
}));

function TapeTransmission() {
    const classes = useStyles();
    const {user} = useUser();
    const {setTransmission} = useTransmission();

    if (window.location.pathname !== '/transmission') return null;

    return (
        <div className={classes.root}>
            <Structure.Center>
                <Grid
                    className={classes.container}
                    container
                    justifyContent="space-between"
                >
                    <Grid
                        item
                        sm={9}
                        xs={12}
                    >
                        <div className={classes.flex}>
                            <Hidden smDown>
                                <Box mr={2}>
                                    <Icon
                                        name="axa-icons-help-o"
                                        color="white"
                                        size={40}
                                    />
                                </Box>
                            </Hidden>

                            <div>
                                <Typography
                                    variant="h5"
                                    color="white"
                                    bold
                                >
                                    Votre situation a changé,
                                </Typography>
                                <Typography
                                    variant="h6"
                                    color="white"
                                >
                                    vous pouvez dès maintenant la modifier.
                                </Typography>
                            </div>
                            <div className="flexGrow" />
                            <Buttons.Default
                                label="Modifier"
                                color="white"
                                next
                                onClick={() => {
                                    fastapi
                                        .delete('/simulators/transmission/simulation', {
                                            data: {
                                                user_id: user.id
                                            }
                                        })
                                        .then((response) => {
                                            setTransmission({});
                                            sendTag(user, 'modifier::simulation');
                                        });
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Structure.Center>
        </div>
    );
}

export default TapeTransmission;
