import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import {makeStyles} from '@material-ui/styles';

import {api} from 'api';
import {Alert, Buttons, Icon, Typography} from 'components';
import {useLoading, useUser} from 'hooks';
import {profileURL} from '../../utils';
import {hasBothCollectiveScopes} from '../../utils/hasBothCollectiveScopes';
import {findCollectiveScope} from '../../utils/findCollectiveScope';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        margin: theme.spacing(1, 0)
    },
    info: {
        color: theme.palette.blue
    },
    error: {
        color: theme.palette.errorRed,
        borderLeft: `solid 4px ${theme.palette.errorRed}`
    },
    icon: {
        color: 'inherit',
        marginRight: theme.spacing(1.5),
        border: `solid 1px ${theme.palette.blue}`,
        borderRadius: '50%',
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}));

const AlertMissingMobile = () => {
    const history = useHistory();

    return (
        <Alert
            message={
                <>
                    <Typography
                        color="dark"
                        variant="h6"
                    >
                        Votre numéro de téléphone mobile est nécessaire à la modification de vos informations
                        personnelles en ligne.
                    </Typography>
                    <Typography
                        color="dark"
                        variant="h6"
                    >
                        Veuillez contacter le service client pour mettre à jour cette information.
                    </Typography>
                </>
            }
            linklabel="Nous contacter"
            action={() => history.push(profileURL({journey: 'contact'}))}
            severity="warning"
        />
    );
};

const AlertOTP = ({children, label, alert = null}) => {
    const classes = useStyles();

    const {setLoading} = useLoading();
    const {user} = useUser();

    let scope = hasBothCollectiveScopes(user) ? 's2e' : findCollectiveScope(user);

    const s2ePhone = user.s2e_profile?.mobile || null;
    const aisPhone = user.ais_profile?.mobile || null;

    const handleOTP = useCallback(async () => {
        setLoading(true);

        const state = uuid();

        localStorage.setItem(
            'EP_otp',
            JSON.stringify({
                state,
                endpoint: window.location.pathname + window.location.search + window.location.hash
            })
        );

        await api
            .get('/login/oauth/otp/collective', {params: {state}})
            .then((response) => (window.location.href = response.data))
            .catch(setLoading(false));
    }, [setLoading]);

    if (user.has_validate_acon_otp) return children;

    return (
        <div className={clsx(classes.root, classes.info)}>
            <Icon
                className={classes.icon}
                name="axa-icons-mobile-check-o"
                size={26}
                flex
            />

            {(scope === 's2e' && !s2ePhone) || (scope === 'ais' && !aisPhone) ? (
                <AlertMissingMobile />
            ) : (
                <div>
                    <Typography
                        variant="h6"
                        color="blue"
                    >
                        {label}
                    </Typography>

                    {alert}

                    <Buttons.Default
                        label="Modifier en ligne"
                        color="blueAlpha"
                        next
                        onClick={handleOTP}
                    />
                </div>
            )}
        </div>
    );
};

AlertOTP.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired
};

export default AlertOTP;
