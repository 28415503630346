import moment from 'moment';

const FORMAT = {
    en: 'MM/DD/YYYY',
    fr: 'DD/MM/YYYY'
};

const FORMAT_CALENDAR = {
    en: 'dddd, MMMM DD, YYYY',
    fr: 'dddd DD MMMM, YYYY'
};

const FORMAT_WITHOUT_DAY = {
    en: ' MMMM DD, YYYY',
    fr: 'DD MMMM YYYY'
};

const YEAR = 'YYYY';

function formatDate(date, lang = 'fr', isCalendar = false, isWithoutDay = false, isYearly = false) {
    return isCalendar
        ? moment(date).locale(lang).format(FORMAT_CALENDAR[lang])
        : isWithoutDay
        ? moment(date).locale(lang).format(FORMAT_WITHOUT_DAY[lang])
        : isYearly
        ? moment(date).format(YEAR)
        : moment(date).format(FORMAT[lang]);
}

export default formatDate;
