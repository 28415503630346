import mock from 'api/mockFastApi';

const OPERATIONS = [
    {
        id: '1-1XITEXN',
        scope: 's2e',
        type: 'Prime de participation',
        amount: 185.763,
        abondment: 1000.0,
        date: '2022-11-15T00:00:00Z',
        status: 'En cours'
    },
    {
        id: '1-1XHND4P',
        scope: 's2e',
        type: 'Arbitrage',
        amount: 185.766,
        abondment: 0.0,
        date: '2022-10-24T00:00:00Z',
        status: 'En cours'
    },
    {
        id: '1-1XGFB8R',
        scope: 's2e',
        type: 'Arbitrage',
        amount: 0.0,
        abondment: 0.0,
        date: '2022-08-31T00:00:00Z',
        status: 'En cours'
    },
    {
        id: '1-1XFZ0TD',
        scope: 's2e',
        type: 'Arbitrage',
        amount: 0.0,
        abondment: 0.0,
        date: '2022-08-17T00:00:00Z',
        status: 'En cours'
    },
    {
        id: '1-1XFZ0TX',
        scope: 's2e',
        type: 'Arbitrage',
        amount: 0.0,
        abondment: 0.0,
        date: '2022-08-17T00:00:00Z',
        status: 'En cours'
    },
    {
        id: 'O-137075584',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2021-09-19T00:00:00Z',
        status: 'En cours'
    },
    {
        id: 'O-136428788',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2021-08-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-136243113',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2021-07-20T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-135664209',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2021-06-19T00:00:00Z',
        status: 'En cours'
    },
    {
        id: 'O-135078337',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2021-05-19T00:00:00Z',
        status: 'En cours'
    },
    {
        id: 'O-134921100',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2021-04-19T00:00:00Z',
        status: 'En cours'
    },
    {
        id: 'O-133565836',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2021-03-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: '1-1VRFD6N',
        scope: 's2e',
        type: 'Arbitrage',
        amount: 0.0,
        abondment: 0.0,
        date: '2021-03-04T00:00:00Z',
        status: 'Traité'
    },
    {
        id: '1-1VRGVY1',
        scope: 's2e',
        type: 'Arbitrage',
        amount: 0.0,
        abondment: 0.0,
        date: '2021-03-04T00:00:00Z',
        status: 'Traité'
    },
    {
        id: '1-1VRHFJL',
        scope: 's2e',
        type: 'Arbitrage',
        amount: 0.0,
        abondment: 0.0,
        date: '2021-03-04T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-132464974',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2021-02-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-131941623',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2021-01-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-131788305',
        scope: 's2e',
        type: 'Versement par Carte Bancaire',
        amount: 100.0,
        abondment: 0.0,
        date: '2021-01-08T00:00:00Z',
        status: 'Annulé'
    },
    {
        id: 'O-131793644',
        scope: 's2e',
        type: 'Versement par Carte Bancaire',
        amount: 100.0,
        abondment: 0.0,
        date: '2021-01-08T00:00:00Z',
        status: 'Annulé'
    },
    {
        id: 'O-130831608',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-12-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-130006838',
        scope: 's2e',
        type: 'Versement par prélèvement',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-11-30T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-129654563',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-11-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-128448759',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-10-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-127894660',
        scope: 's2e',
        type: 'Réallocation',
        amount: 0.0,
        abondment: 0.0,
        date: '2020-09-30T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-127098271',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-09-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-126226664',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-08-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-125658315',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-07-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-123251274',
        scope: 's2e',
        type: 'Intéressement',
        amount: 3460.04,
        abondment: 0.0,
        date: '2020-07-10T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-123289389',
        scope: 's2e',
        type: 'Participation',
        amount: 2777.47,
        abondment: 677.25,
        date: '2020-07-10T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-124414620',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-06-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-123340293',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-05-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-122356977',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-04-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-120988509',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-03-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-119741136',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-02-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-119222060',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2020-01-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-118129151',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-12-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-116955069',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-11-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-116104163',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-10-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-114786518',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-09-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-114127490',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-08-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-114032191',
        scope: 's2e',
        type: 'Versement entreprise',
        amount: 1431.67,
        abondment: 0.0,
        date: '2019-08-01T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-113567449',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-07-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-110806356',
        scope: 's2e',
        type: 'Participation',
        amount: 2480.36,
        abondment: 677.25,
        date: '2019-07-12T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-110846400',
        scope: 's2e',
        type: 'Intéressement',
        amount: 4021.64,
        abondment: 0.0,
        date: '2019-07-12T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-112307695',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-06-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-112958520',
        scope: 's2e',
        type: 'Versement entreprise',
        amount: 953.45,
        abondment: 161.35,
        date: '2019-06-13T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-110985052',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-05-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-109739307',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-04-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: '1-1AOEFA7',
        scope: 's2e',
        type: 'Arbitrage',
        amount: 0.0,
        abondment: 0.0,
        date: '2019-04-05T00:00:00Z',
        status: 'Traité'
    },
    {
        id: '1-1AOEN6D',
        scope: 's2e',
        type: 'Arbitrage',
        amount: 0.0,
        abondment: 0.0,
        date: '2019-04-05T00:00:00Z',
        status: 'Traité'
    },
    {
        id: '1-1AOBQYZ',
        scope: 's2e',
        type: 'Arbitrage',
        amount: 0.0,
        abondment: 0.0,
        date: '2019-04-05T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-107808495',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-03-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-106712610',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-02-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-106164719',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2019-01-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-105368639',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2018-12-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-104366217',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2018-11-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-103393851',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2018-10-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-102962394',
        scope: 's2e',
        type: 'Versement par prélèvement',
        amount: 100.0,
        abondment: 0.0,
        date: '2018-10-09T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-102333063',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2018-09-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-101778439',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2018-08-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-101279628',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2018-07-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-99001168',
        scope: 's2e',
        type: 'Participation',
        amount: 2607.6,
        abondment: 677.25,
        date: '2018-07-13T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-98968572',
        scope: 's2e',
        type: 'Intéressement',
        amount: 2910.53,
        abondment: 0.0,
        date: '2018-07-13T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-100335635',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2018-06-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-99098625',
        scope: 's2e',
        type: 'Versement Volontaire Programmé',
        amount: 100.0,
        abondment: 0.0,
        date: '2018-05-19T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-96927525',
        scope: 's2e',
        type: 'Versement par prélèvement',
        amount: 100.0,
        abondment: 0.0,
        date: '2018-03-29T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-96927521',
        scope: 's2e',
        type: 'Versement par prélèvement',
        amount: 100.0,
        abondment: 0.0,
        date: '2018-03-29T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-94057527',
        scope: 's2e',
        type: 'Arbitrage',
        amount: 0.0,
        abondment: 0.0,
        date: '2017-12-27T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-87912399',
        scope: 's2e',
        type: 'Participation',
        amount: 2465.22,
        abondment: 690.0,
        date: '2017-07-13T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-87956788',
        scope: 's2e',
        type: 'Intéressement',
        amount: 3441.53,
        abondment: 0.0,
        date: '2017-07-13T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-87406569',
        scope: 's2e',
        type: 'Versement par prélèvement',
        amount: 200.0,
        abondment: 0.0,
        date: '2017-05-02T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-86582484',
        scope: 's2e',
        type: 'Versement par prélèvement',
        amount: 100.0,
        abondment: 0.0,
        date: '2017-04-14T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-86013625',
        scope: 's2e',
        type: 'Versement par prélèvement',
        amount: 100.0,
        abondment: 0.0,
        date: '2017-03-31T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-84851148',
        scope: 's2e',
        type: 'Versement par prélèvement',
        amount: 100.0,
        abondment: 0.0,
        date: '2017-03-03T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-79407199',
        scope: 's2e',
        type: 'Participation',
        amount: 1000.0,
        abondment: 690.0,
        date: '2016-07-08T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-73709194',
        scope: 's2e',
        type: 'Versement par Carte Bancaire',
        amount: 1.0,
        abondment: 0.0,
        date: '2016-01-05T00:00:00Z',
        status: 'Annulé'
    },
    {
        id: 'O-72489902',
        scope: 's2e',
        type: 'Versement par Carte Bancaire',
        amount: 0.01,
        abondment: 0.0,
        date: '2015-11-13T00:00:00Z',
        status: 'Annulé'
    },
    {
        id: 'O-72489940',
        scope: 's2e',
        type: 'Versement par Carte Bancaire',
        amount: 0.01,
        abondment: 0.0,
        date: '2015-11-13T00:00:00Z',
        status: 'Annulé'
    },
    {
        id: 'O-72489922',
        scope: 's2e',
        type: 'Versement par Carte Bancaire',
        amount: 0.01,
        abondment: 0.0,
        date: '2015-11-13T00:00:00Z',
        status: 'Annulé'
    },
    {
        id: 'O-72489917',
        scope: 's2e',
        type: 'Versement par prélèvement',
        amount: 0.01,
        abondment: 0.0,
        date: '2015-11-13T00:00:00Z',
        status: 'Traité'
    },
    {
        id: 'O-69956699',
        scope: 's2e',
        type: 'Participation',
        amount: 700.0,
        abondment: 644.0,
        date: '2015-07-10T00:00:00Z',
        status: 'Traité'
    }
];

const PERIODIC_VVS = [
    {
        account_payment: 355007596,
        payment_type: 'PLT',
        amount: 8700,
        company_name: 'RS-396277216',
        contract_number: '2726002000300',
        creation_date: '2023-10-23T15:30:45',
        start_date: '2023-12-05T00:00:00',
        end_date: '2023-12-06T00:00:00',
        periodicity: 'Mensuel',
        id: '890430736',
        status: 'En cours',
        tax_choice: 'DEDUCTIBLE',
        repartitions: [
            {
                id: '150020056',
                title: 'PERU Versements volontaires déductibles Libre ',
                amount: null,
                investments: [
                    {
                        id: 'FR0014001H85',
                        title: 'AXA GEN TEM SOL 2',
                        amount: 0
                    },
                    {
                        id: 'FR0014006OJ3',
                        title: 'AXA Géné Euro Obl 2M',
                        amount: 0
                    }
                ]
            },
            {
                id: '150021296',
                title: 'PERU Versements volontaires déductibles piloté ',
                amount: null,
                investments: []
            }
        ],
        scope: 'ais',
        plan_type: 'P_VP',
        next_investment_date: '2023-12-05T00:00:00',
        iban: 'FR 89 0192 8374 6512 34A5 6789 B40',
        bic: 'XXXXXXXX',
        policy_id: '2726002000300/01580'
    },
    {
        account_payment: 355007596,
        payment_type: 'PLT',
        amount: 125.55,
        company_name: 'RS-396277216',
        contract_number: '2726002000000',
        creation_date: '2023-10-18T11:36:48',
        start_date: '2024-01-05T00:00:00',
        end_date: null,
        periodicity: 'Mensuel',
        id: '890429648',
        status: 'En cours',
        tax_choice: 'DEDUCTIBLE',
        repartitions: [
            {
                id: '150020056',
                title: 'PERU Versements volontaires déductibles Libre ',
                amount: null,
                investments: [
                    {
                        id: 'FR0014006OJ3',
                        title: 'AXA Géné Euro Obl 2M',
                        amount: 0
                    }
                ]
            }
        ],
        scope: 'ais',
        plan_type: 'P_VP',
        next_investment_date: '2024-01-05T00:00:00',
        iban: 'FR 89 0192 8374 6512 34A5 6789 B40',
        bic: 'XXXXXXXX',
        policy_id: '2726002000000/02470'
    },
    {
        id: '143553410',
        company_id: '821',
        company_name: 'AXA GROUP OPERATIONS SAS',
        iban: '10207000192019082375522',
        bic: 'CCBPFRPPMTG',
        start_date: '2023-11-01T00:00:00Z',
        creation_date: '2023-10-27T15:10:22Z',
        end_date: '',
        periodicity: 'MENSUELLE',
        repartitions: [
            {
                id: '0000311463',
                title: 'PEEG AXA REFLEX',
                amount: 100,
                investments: [
                    {
                        id: '169',
                        title: 'AXA HORIZON ISR',
                        amount: 100
                    }
                ]
            }
        ],
        scope: 's2e',
        next_investment_date: '2023-11-30T00:00:00Z',
        status: 'En cours',
        amount: 100
    },
    {
        id: '1-1WP4XC9',
        company_id: '821',
        company_name: 'AXA GROUP OPERATIONS SAS',
        iban: '10207000192019082375522',
        bic: 'CCBPFRPPMTG',
        start_date: '',
        creation_date: '2021-04-07T16:43:08Z',
        end_date: '2023-12-29T00:00:00Z',
        periodicity: 'MENSUELLE',
        repartitions: [
            {
                id: '0000446125',
                title: 'PERECO VERSEMENTS NON DEDUCTIBLES Libre',
                amount: 100,
                investments: [
                    {
                        id: '167',
                        title: 'AXA ES LONG TERME 2M',
                        amount: 100
                    }
                ]
            }
        ],
        scope: 's2e',
        next_investment_date: '2021-10-31T00:00:00Z',
        status: 'En cours',
        amount: 100
    }
];

mock.onGet('/services/operations/').reply((config) => {
    const {query} = config.params;
    if (query === 'progress') {
        return [200, {operations: OPERATIONS.filter(({status}) => status === 'En cours'), error_scope: null}];
    } else return [200, {operations: OPERATIONS.filter(({status}) => status !== 'En cours'), error_scope: null}];
});

mock.onGet('/services/operations/periodic-vv').reply((config) => {
    return [200, {periodic_vv: PERIODIC_VVS, error_scope: null}];
});
