import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import {Typography} from 'components';
import {useForm} from 'hooks';

function CaptchaGoogle(props) {
    const {name, ...rest} = props;
    const {formState, setFormState} = useForm();

    const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

    const handleChange = (value) => {
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [name]: value
            },
            touched: {
                ...formState.touched,
                [name]: true
            }
        });
    };

    const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

    return (
        <>
            <ReCAPTCHA
                onChange={handleChange}
                sitekey={
                    ENVIRONMENT === 'development'
                        ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
                        : '6LddYNwUAAAAAN4tQxCaHoILL_6U9t-6CZ7AyOTT'
                }
                {...rest}
            />
            <Typography
                className="error-message"
                color="redLight"
            >
                {hasError(name) ? formState.errors[name][0] : null}
            </Typography>
        </>
    );
}

CaptchaGoogle.propTypes = {
    name: PropTypes.string
};

export default CaptchaGoogle;
