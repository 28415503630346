const booleanToString = (boolean) => {
    return boolean === true ? 'oui' : 'non';
};
export const getTransmissionDatalayer = (data) => {
    return {
        transmission360_testament: [undefined, null].includes(data?.heirs?.has_testament)
            ? ''
            : booleanToString(data?.heirs?.has_testament),
        transmission360_situation_maritale: data?.heirs?.family_situation,
        transmission360_donation_conjoint: [undefined, null].includes(data?.heirs?.has_spouse_donation)
            ? ''
            : booleanToString(data?.heirs?.has_spouse_donation),
        transmission360_nb_enfants: data?.heirs?.children?.toString(),
        transmission360_donation_moins_15_ans: [undefined, null].includes(data?.patrimony?.has_donate)
            ? ''
            : booleanToString(data?.patrimony?.has_donate),
        transmission360_proprietaire: [undefined, null].includes(data?.patrimony?.owner_home)
            ? ''
            : booleanToString(data?.patrimony?.owner_home),
        transmission360_biens_succession: [undefined, null].includes(data?.patrimony?.has_other_patrimony)
            ? ''
            : booleanToString(data?.patrimony?.has_other_patrimony),
        transmission360_assurance_vie: [undefined, null].includes(data?.life_insurances?.has_life_insurance)
            ? ''
            : booleanToString(data?.life_insurances?.has_life_insurance),
        transmission360_type_beneficiaires: data?.life_insurances?.life_insurances_list
            ?.map((lifeInsurance) => lifeInsurance.beneficiary)
            .filter((v, i, a) => a.indexOf(v) === i)
            .join()
    };
};

export const mergeBeneficiaries = (results) => {
    if (!results || results.length === 0) return {};
    return results?.reduce((benefiaries, beneficiary) => {
        const resultOptions = Object.keys(beneficiary.results);
        let results = {};
        resultOptions.map(
            (resultOption) =>
                (results = {
                    ...results,
                    ...{
                        [resultOption]: {
                            life_insurance_amount:
                                (benefiaries?.results?.[resultOption]?.life_insurance_amount || 0) +
                                beneficiary.results[resultOption].life_insurance_amount,
                            life_insurance_tax:
                                (benefiaries?.results?.[resultOption]?.life_insurance_tax || 0) +
                                beneficiary.results[resultOption].life_insurance_tax,
                            patrimony_amount:
                                (benefiaries?.results?.[resultOption]?.patrimony_amount || 0) +
                                beneficiary.results[resultOption].patrimony_amount,
                            total_tax:
                                (benefiaries?.results?.[resultOption]?.total_tax || 0) +
                                beneficiary.results[resultOption].total_tax
                        }
                    }
                })
        );
        return {
            beneficiary: 'beneficiary',
            number: 3,
            results: results
        };
    });
};

export const getHeirResultFromSpouseOption = (transmission, spouseOption, heir) => {
    return spouseOption in heir.results &&
        transmission?.heirs?.family_situation === 'spouse' &&
        (transmission?.heirs?.has_same_parents || transmission?.heirs?.has_spouse_donation)
        ? heir.results[spouseOption]
        : Object.values(heir.results)[0];
};
