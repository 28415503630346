import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {browser} from 'utils';

import {Tooltip, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme, withStyles} from '@material-ui/styles';

const DEFAULT_CONFIG = [
    ['#e15556', 10],
    ['#fccf5f', 4],
    ['#a5c54d', 3],
    ['#21b11a', 3]
];

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        height: 8,
        margin: theme.spacing(8, 0),
        [theme.breakpoints.down('md')]: {
            margin: '70px'
        }
    },
    vertical: {
        width: 8,
        height: 350,
        flexDirection: 'column',
        transform: 'translateX(50px) scaleY(-1)',
        margin: '0px auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 70,
            height: 250
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 60
        },

        '& > div:first-child': {
            width: 10,
            maxWidth: browser.isIE11() ? 'none' : 'initial',
            maxHeight: '100%',
            transition: 'height 1.2s ease-in-out',

            '& > svg': {
                top: 'auto',
                bottom: -10
            }
        },

        '& > svg': {
            top: 'auto',
            bottom: -10,
            right: -11
        }
    },
    rect: {
        position: 'absolute',
        top: -1,
        left: -1,
        width: 0,
        maxWidth: '100%',
        height: 10,
        transition: 'width 1.2s ease-in-out'
    },
    svg: {
        width: 30,
        height: 30
    },
    circle: {
        strokeWidth: 1,
        animation: '1.5s linear 0s opacity infinite'
    },
    container: {
        position: 'absolute',
        top: -10,
        right: browser.isIE11() ? -12 : -10
    }
}));

const GaugeTooltip = withStyles((theme) => ({
    popper: {
        zIndex: '800 !important'
    },
    tooltip: {
        backgroundColor: theme.palette.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[3]
    },
    arrow: {
        fontSize: 20,
        color: theme.palette.white,
        '&:before': {
            boxShadow: theme.shadows[3]
        }
    }
}))(Tooltip);

const CirclesDot = ({color, tooltip, ...rest}) => {
    const classes = useStyles();

    return (
        <GaugeTooltip
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open
            title={tooltip}
            className={classes.container}
            arrow
            {...rest}
        >
            <svg className={classes.svg}>
                <circle
                    cx="15"
                    cy="15"
                    r="7"
                    fill={color}
                    stroke="none"
                />
                <circle
                    className={classes.circle}
                    cx="15"
                    cy="15"
                    r="10"
                    fill="none"
                    stroke={color}
                />
                <circle
                    className={classes.circle}
                    cx="15"
                    cy="15"
                    r="14"
                    fill="none"
                    stroke={color}
                />
            </svg>
        </GaugeTooltip>
    );
};

function Gauge({ratio, config = DEFAULT_CONFIG, label: Label, endLabel: EndLabel, vertical = false}) {
    const classes = useStyles();
    const theme = useTheme();

    const [width, setWidth] = useState(0);
    const [color, setColor] = useState(config[0][0]);

    const wrapperRef = useRef();
    const rectRef = useRef();

    const [current, setCurrent] = useState(0);

    const total = config.map(([, value]) => parseFloat(value)).reduce((acc, val) => acc + val, 0);
    const getColor = (ratio) => {
        if (!config) return config[0][0];

        let index = config
            .map(([, flex]) => flex)
            .map(
                (
                    (sum) => (value) =>
                        (sum += value)
                )(0)
            ) // cumulative sum
            .findIndex((number) => number > ratio * total);

        return config[index >= 0 ? index : config.length - 1][0];
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setWidth(ratio * 100);

        let interval;
        interval = setInterval(() => {
            if (!rectRef.current || !wrapperRef.current) return;

            let current_ratio = vertical
                ? rectRef.current.offsetHeight / wrapperRef.current.offsetHeight
                : rectRef.current.offsetWidth / wrapperRef.current.offsetWidth;
            if (current_ratio < 0) current_ratio = 0;
            if (current_ratio > 1) current_ratio = 1;

            setColor(getColor(current_ratio));
            setCurrent(current_ratio);
        }, 30);
        setTimeout(() => {
            clearInterval(interval);

            if (!rectRef.current || !wrapperRef.current) return;
            let current_ratio = vertical
                ? rectRef.current.offsetHeight / wrapperRef.current.offsetHeight
                : rectRef.current.offsetWidth / wrapperRef.current.offsetWidth;
            setCurrent(null);
            setColor(getColor(current_ratio));
        }, 1250);

        return () => clearInterval(interval);

        // eslint-disable-next-line
    }, [ratio]);

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.vertical]: vertical
            })}
            ref={wrapperRef}
        >
            <div
                className={classes.rect}
                ref={rectRef}
                style={{
                    background: color,
                    [vertical ? 'height' : 'width']: `${width}%`
                }}
            >
                <CirclesDot
                    color={color}
                    tooltip={<Label ratio={current > 0 && current < 1 ? current : undefined} />}
                    placement={vertical ? 'left' : 'bottom'}
                />
            </div>

            {config.map(([color, flex], index) => (
                <div
                    key={`${color}-${flex}-${index}`}
                    style={{background: color, flex}}
                />
            ))}
            <CirclesDot
                color={config[config.length - 1][0]}
                tooltip={<EndLabel ratio={current > 0 && current < 1 ? current : undefined} />}
                placement={vertical && !isMobile ? 'right' : 'top'}
            />
        </div>
    );
}

Gauge.propTypes = {
    ratio: PropTypes.number.isRequired,
    config: PropTypes.arrayOf(PropTypes.array),
    label: PropTypes.func,
    endLabel: PropTypes.func,
    vertical: PropTypes.bool
};

export default Gauge;
