import React from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import {Icon, Structure, Typography} from 'components';
import {ConsentMirrorModal} from './components';
import {useUser} from 'hooks';
import {AdvisorModal, AdvisorTransmissionModal} from 'macros';
import {modal} from 'utils';

import {useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';
import {sendTag} from 'store/Tags';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 45,
        background: theme.palette.pale,
        borderTop: `solid 1px ${theme.palette.grayLight}`,
        zIndex: -10
    },
    wrapper: {
        maxWidth: 1300
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        height: '100%'
    },
    back: {
        '&:hover h5, &:focus h5': {
            textDecoration: 'underline !important'
        }
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: theme.spacing(0, 1),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2)
        },
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
        '&:hover, &:focus': {
            opacity: 0.9
        }
    },
    label: {
        padding: '0 5px'
    }
}));

const SubHeaderBack = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <button
            className={clsx(classes.button, classes.back)}
            onClick={() => history.push('/epargne')}
        >
            <Icon
                name="axa-icons-arrow-left"
                color="grayDark"
                flex
                size={16}
            />
            &nbsp;
            <Typography
                variant="h5"
                color="grayDark"
                gutterBottom={false}
            >
                {isDesktop ? 'Retour au tableau de bord' : 'Retour'}
            </Typography>
        </button>
    );
};

const SubHeaderButton = (props) => {
    const {label, background, iconName, ...rest} = props;

    const classes = useStyles();

    return (
        <button
            className={classes.button}
            style={{background: background}}
            {...rest}
        >
            <Icon
                name={iconName}
                color="white"
                flex
                size={22}
            />
            {label && (
                <Typography
                    className={classes.label}
                    variant="caption"
                    color="white"
                    gutterBottom={false}
                >
                    {label}
                </Typography>
            )}
        </button>
    );
};

function SubHeader() {
    const classes = useStyles();
    const theme = useTheme();

    const {user, setUser} = useUser();

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <div className={classes.root}>
            <Structure.Center
                className={classes.wrapper}
                style={{padding: 0}}
            >
                <div className={classes.container}>
                    {['/epargne', '/transmission'].indexOf(window.location.pathname) < 0 && <SubHeaderBack />}

                    <div className="flexGrow" />

                    {/* FIXME : Enable individual mirror feature in production */}
                    {user.origin === 'individual' &&
                        !user.is_mirror &&
                        process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                            <SubHeaderButton
                                label={isDesktop && 'Gérer mon consentement'}
                                background={theme.palette.cyan}
                                iconName="axa-icons-padlock"
                                onClick={() => modal.open('consent_mirror')}
                            />
                        )}

                    {user.origin === 'individual' && (
                        <SubHeaderButton
                            label={isDesktop && 'Contacter mon conseiller'}
                            background={theme.palette.blue}
                            iconName="axa-icons-email-1"
                            onClick={() => {
                                sendTag(user, 'ct_maretraite360::clic::conseiller');
                                modal.open('advisor');
                            }}
                        />
                    )}

                    {user.origin === 'individual_transmission' && (
                        <SubHeaderButton
                            label={isDesktop && 'Contacter mon conseiller'}
                            background={theme.palette.blue}
                            iconName="axa-icons-email-1"
                            onClick={() => {
                                setUser({...user, actions: user.actions ? user.actions + 1 : 1});
                                sendTag(user, 'ct_transmission360::clic::conseiller');
                                modal.open('advisor_transmission');
                            }}
                        />
                    )}
                </div>
            </Structure.Center>

            <AdvisorModal hash="advisor" />
            <AdvisorTransmissionModal hash="advisor_transmission" />

            <ConsentMirrorModal />
        </div>
    );
}

export default SubHeader;
