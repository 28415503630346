import React, {useEffect} from 'react';
import {fastapi} from 'api';
import {Structure, Typography} from 'components';
import {SubStepDisclaimer, SubStepInfos} from './components';
import {useUser} from 'hooks';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        padding: theme.spacing(3, 2, 2),
        margin: theme.spacing(1, 0),
        background: theme.palette.white
    }
}));

function StepIntro({setStep}) {
    const classes = useStyles();
    const {user} = useUser();

    // Send email to advisor
    useEffect(() => {
        if (user.origin === 'individual' && user.axa_advisor) {
            fastapi.post('/services/mail-handler/load-marel', {
                user_id: user.id,
                advisor_email: user.axa_advisor.email
            });
        }

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Typography
                variant="h1"
                color="blue"
            >
                Ma retraite de base et complémentaire
            </Typography>
            <Typography
                variant="h6"
                semibold
            >
                Le simulateur, ergonomique et facile d’utilisation, est disponible à tout âge.
                <br />
                Il est entièrement gratuit et repose sur les données connues par mes régimes de retraite.
            </Typography>
            <Structure.Carousel
                adaptiveHeight
                className={classes.container}
                smooth
            >
                <SubStepDisclaimer />
                <SubStepInfos setStepParent={setStep} />
            </Structure.Carousel>
        </>
    );
}

export default StepIntro;
