import {default as translateAccountType} from './translateAccountType';
import {default as translateAnnuity} from './translateAnnuity';
import {default as translateCompartment} from './translateCompartment';
import {default as translateFundHorizon} from './translateFundHorizon';
import {default as translateLifeProject} from './translateLifeProject';
import {default as translateRetirementSlider} from './translateRetirementSlider';
import {default as translateWithdrawCase} from './translateWithdrawCase';
import {default as translateProfileRisk} from './translateProfileRisk';

export default {
    translateAccountType,
    translateAnnuity,
    translateCompartment,
    translateFundHorizon,
    translateRetirementSlider,
    translateLifeProject,
    translateWithdrawCase,
    translateProfileRisk
};
