import React from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {Buttons, Icon, Loaders, Modal, Typography} from 'components';
import {OptionBulletinChoice} from 'macros';
import {useUser} from 'hooks';
import {amount, date, modal, profileURL} from 'utils';
import {sendTag} from 'store/Tags';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        borderRadius: 8,
        padding: theme.spacing(1, 2)
    },
    wrapperRed: {
        background: '#FDF8F6',
        border: `solid 1px ${theme.palette.warningDark}`
    },
    wrapperBlue: {
        background: theme.palette.pale,
        border: `solid 1px ${theme.palette.blue}`
    },
    icon: {
        marginRight: theme.spacing(2),
        marginTop: 3
    },
    iconRed: {
        color: theme.palette.warningDark
    },
    iconBlue: {
        color: theme.palette.blue
    },
    button: {
        minWidth: '180px',
        textAlign: 'left',
        borderRadius: 0
    },
    modal: {
        width: '100%',
        maxWidth: 700
    },
    link: {
        fontSize: 16,
        fontWeight: 700,
        cursor: 'pointer',
        color: theme.palette.blue,
        textDecoration: 'underline'
    },
    amount: {
        whiteSpace: 'nowrap',
        fontWeight: 700
    }
}));

function TapeAlerts() {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const {user} = useUser();

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    if (!user.s2e_transactions) return null;

    const option_bulletins_in_progress = user.s2e_transactions
        .filter((transaction) => ['BO_PARTICIPATION', 'BO_INTERESSEMENT'].includes(transaction.type))
        .filter((transaction) => transaction.status === 'En cours de traitement')
        .filter((transaction) => moment(transaction.deadline_date).add(1, 'days') > moment.utc());

    if (window.location.pathname !== '/epargne') return null;

    const bank_account_withdraw =
        user.s2e_profile?.bank_accounts.find((bank_account) => bank_account.type === 'VIREMENT') || null;

    const payerCompany = user.s2e_profile?.companies.some((company) => company.main_company && company.payor === true);

    const handleClick = () => {
        sendTag(user, 'bandeau_plus_i_mon_epargne::clic::faire_mon_choix', {nom_etape: null}, true);

        if (user.s2e_alerts.length > 1) modal.open('option_bulletins');
        else history.push(`/transactional/bulletin-option/${user.s2e_alerts[0].id_transaction}`);
    };

    if (!user.s2e_alerts || (user.s2e_alerts.length === 0 && option_bulletins_in_progress.length === 0)) return null;

    return (
        <Box>
            <Box
                className={clsx(classes.wrapper, user.s2e_alerts.length > 0 ? classes.wrapperRed : classes.wrapperBlue)}
                mt={3}
            >
                <Box display="flex">
                    <Icon
                        className={clsx(classes.icon, user.s2e_alerts.length > 0 ? classes.iconRed : classes.iconBlue)}
                        name={user.s2e_alerts.length > 0 ? 'axa-icons-danger' : 'axa-icons-info'}
                        size={24}
                        aria-hidden={true}
                    />
                    <Box
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        justifyContent="space-between"
                        alignItems={isMobile ? 'flex-start' : 'center'}
                        minWidth="100%"
                        pr={4}
                    >
                        <Box>
                            <Typography
                                size={18}
                                color={user.s2e_alerts.length > 0 ? 'warningDark' : 'blue'}
                                semibold
                                localization={
                                    user.s2e_alerts.length > 0
                                        ? `home.optionBulletinChoice`
                                        : option_bulletins_in_progress.length > 1
                                        ? 'home.optionBulletinChoicesMade'
                                        : 'home.optionBulletinChoiceMade'
                                }
                            />

                            {user.s2e_alerts.map((alert, index) =>
                                user.s2e_option_bulletins.find(
                                    (option_bulletin) => option_bulletin.id === alert.id_transaction
                                ).type === 'PARTICIPATION' ? (
                                    <Typography
                                        size={16}
                                        key={`alert-${index}`}
                                        color="dark"
                                        gutterBottom={false}
                                        localization={'home.optionBulletinProfitShare'}
                                        values={{
                                            amount: amount.formatCurrency(alert.amount, user.localization),
                                            date: date.formatDate(alert.date, user.localization)
                                        }}
                                        components={{
                                            amount: (
                                                <Typography
                                                    className={classes.amount}
                                                    component="span"
                                                />
                                            ),
                                            date: <Typography component="span" />
                                        }}
                                    />
                                ) : (
                                    <Typography
                                        size={16}
                                        key={`alert-${index}`}
                                        color="dark"
                                        gutterBottom={false}
                                        localization={'home.optionBulletinIncentive'}
                                        values={{
                                            amount: amount.formatCurrency(alert.amount, user.localization),
                                            date: date.formatDate(alert.date, user.localization)
                                        }}
                                        components={{
                                            amount: (
                                                <Typography
                                                    className={classes.amount}
                                                    component="span"
                                                />
                                            ),
                                            date: <Typography component="span" />
                                        }}
                                    />
                                )
                            )}

                            {(option_bulletins_in_progress || []).map((transaction) => (
                                <Box mt={1}>
                                    <Typography
                                        size={16}
                                        gutterBottom={false}
                                        key={transaction.id}
                                        color="dark"
                                        localization={
                                            transaction.type === 'BO_PARTICIPATION'
                                                ? 'home.optionBulletinProfitShareModify'
                                                : 'home.optionBulletinIncentiveModify'
                                        }
                                        values={{
                                            amount: amount.formatCurrency(transaction.amount, user.localization),
                                            date: date.formatDate(transaction.accounting_date, user.localization)
                                        }}
                                        components={{
                                            amount: (
                                                <Typography
                                                    className={classes.amount}
                                                    component="span"
                                                />
                                            ),
                                            date: <Typography component="span" />,
                                            link: (
                                                <Typography
                                                    component="a"
                                                    clickable
                                                    tabIndex={0}
                                                    gutterBottom={false}
                                                    capitalizeSentense={false}
                                                    onClick={() => {
                                                        history.push(`/transactional/operations/${transaction.id}`);
                                                        sendTag(
                                                            user,
                                                            'bandeau_plus_i_mon_epargne::clic::modifier_mon_choix',
                                                            {nom_etape: null}
                                                        );
                                                    }}
                                                    className={classes.link}
                                                    localization={'home.optionBulletinModifyLink'}
                                                />
                                            )
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                        {user.s2e_alerts.length > 0 && (
                            <Buttons.Default
                                className={classes.button}
                                label={'home.optionBulletinButtonChoice'}
                                color="transparent_border_blue"
                                onClick={handleClick}
                                loading={user.s2e_option_bulletins === null}
                                next
                            />
                        )}
                    </Box>
                </Box>

                <Modal
                    className={classes.modal}
                    hash="option_bulletins"
                    title="Faites votre choix !"
                >
                    {user.s2e_option_bulletins ? (
                        user.s2e_option_bulletins
                            .filter(
                                (option_bulletin) =>
                                    !(user.s2e_transactions || [])
                                        .map((transaction) => transaction.id)
                                        .includes(option_bulletin.id)
                            )
                            .map((option_bulletin) => (
                                <OptionBulletinChoice
                                    key={option_bulletin.id}
                                    option_bulletin={option_bulletin}
                                />
                            ))
                    ) : (
                        <Loaders.Circular
                            open
                            transparent
                        />
                    )}
                </Modal>
            </Box>
            {!payerCompany && bank_account_withdraw === null && (
                <Box
                    className={clsx(classes.wrapper, classes.wrapperRed)}
                    mt={2}
                >
                    <Box display="flex">
                        <Icon
                            className={clsx(classes.icon, classes.iconRed)}
                            name="axa-icons-danger"
                            size={24}
                            aria-hidden={true}
                        />
                        <Box>
                            <Typography
                                size={18}
                                color="warningDark"
                                semibold
                                localization={'home.bankCoordinatesTitle'}
                            />

                            <Typography
                                size={16}
                                gutterBottom={false}
                                localization={'home.bankCoordinates'}
                                components={{
                                    link: (
                                        <Typography
                                            clickable
                                            tabIndex={0}
                                            component="a"
                                            onClick={() => history.push(profileURL({journey: 'coordonnees-bancaires'}))}
                                            className={classes.link}
                                            localization={'home.bankCoordinatesLink'}
                                        />
                                    )
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default TapeAlerts;
