import React from 'react';
import clsx from 'clsx';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/styles';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'relative',
        display: 'block',
        width: 140,
        height: 42,
        margin: theme.spacing(1, 0),
        padding: 12,
        userSelect: 'none',
        cursor: 'pointer',
        textAlign: 'left',
        transition:
            'color .5s cubic-bezier(0, 0.99, 0.865, 1.005), background-color .5s cubic-bezier(0, 0.99, 0.865, 1.005)',
        color: theme.palette.blue,
        background: theme.palette.white,
        border: '1px solid transparent',
        '&:hover, &:focus': {
            color: theme.palette.white,
            backgroundColor: theme.palette.blue
        },
        '&:hover i, &:focus i': {
            background: theme.palette.white
        }
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        display: 'inherit'
    },
    label: {
        fontWeight: '600'
    },
    chevron: {
        position: 'absolute',
        top: 0,
        right: 2
    },
    h_bar: {
        position: 'absolute',
        top: 9,
        right: 2,
        width: 14,
        height: 2,
        background: theme.palette.blue,
        transition: 'transform 0.25s ease-out',
        transform: 'rotate(0deg)'
    },
    h_barSelected: {
        transform: 'rotate(180deg)'
    },
    v_bar: {
        position: 'absolute',
        top: 3,
        right: 8,
        width: 2,
        height: 14,
        background: theme.palette.blue,
        transition: 'transform 0.25s ease-out',
        transform: 'rotate(0deg)'
    },
    v_barSelected: {
        transform: 'rotate(90deg)'
    }
}));

function Expand(props) {
    const {label, className, selected, setSelected, index, variant = 'cross', ...rest} = props;
    const classes = useStyles();

    return (
        <button
            className={clsx({
                [classes.button]: true,
                [className]: className
            })}
            onClick={() => setSelected instanceof Function && setSelected(selected !== index ? index : null)}
            tabIndex="0"
            type="button"
            {...rest}
        >
            <div className={classes.wrapper}>
                <span className={classes.label}>{label}</span>
                {variant === 'cross' && (
                    <>
                        <i
                            className={clsx({
                                [classes.h_bar]: true,
                                [classes.h_barSelected]: selected === index
                            })}
                        />
                        <i
                            className={clsx({
                                [classes.v_bar]: true,
                                [classes.v_barSelected]: selected === index
                            })}
                        />
                    </>
                )}
                {variant === 'chevron' && (
                    <Box className={classes.chevron}>
                        {selected !== index && <KeyboardArrowDownIcon fontSize="medium" />}
                        {selected === index && <KeyboardArrowUpIcon fontSize="medium" />}
                    </Box>
                )}
            </div>
        </button>
    );
}

Expand.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default Expand;
