import React from 'react';
import {Buttons, Grid, Icon, Typography} from 'components';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2.5, 0, 3.5)
    },
    container: {
        alignItems: 'center',
        flexWrap: 'nowrap'
    }
}));

function SubStepDisclaimer({setStep}) {
    const classes = useStyles();

    return (
        <>
            <Grid
                className={classes.container}
                container
                spacing={1}
            >
                <Grid item>
                    <Icon
                        name="axa-icons-time-o"
                        color="blue"
                        size={50}
                        flex
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5">
                        En moins de 2 minutes, je fais le point
                        <br />
                        sur l’âge et le montant de ma retraite
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
            >
                <Grid item>
                    <Buttons.Default
                        className={classes.button}
                        label="Accéder au simulateur"
                        color="orangeDark"
                        next
                        onClick={() => setStep(1)} // SubStepInfos
                    />
                </Grid>
            </Grid>
            <Typography
                italic
                color="dark"
            >
                Dans sa version actuelle, le simulateur ne prend pas en compte les régimes suivants : Avocats (CNBF),
                Officiers ministériels (CAVOM), Vétérinaires (CARPV), Experts comptables (CAVEC), ATF, Industries
                Electriques et Gazières, Banque de France, Mines, Comédie Française, Port autonome de Strasbourg,
                Marins, Personnels navigants de l’aéronautique.
            </Typography>
        </>
    );
}

export default SubStepDisclaimer;
