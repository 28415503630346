import React from 'react';
import clsx from 'clsx';

import {Box} from '@material-ui/core';

import {Icon, Typography} from 'components';
import {modal} from 'utils';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:before': {
            verticalAlign: 'middle'
        }
    },
    pointer: {
        cursor: 'pointer'
    },
    label: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 300,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 250
        }
    },
    hover: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));

export const FundLabel = React.forwardRef(({investment, policy_id, scope, ...rest}, ref) => {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            alignItems="center"
            onClick={(event) => {
                event.stopPropagation();
                modal.open(`fund-${policy_id}_${investment.id}`);
            }}
            ref={ref}
        >
            <Icon
                className={clsx([classes.icon, classes.pointer])}
                name="axa-icons-newspepper"
                color="blue"
                size={18}
            />

            <Typography
                className={clsx([classes.label, investment?.label && classes.hover])}
                variant="h6"
                caps
                clickable={!!investment}
                title={investment?.title}
                gutterBottom={false}
            >
                {investment.label}
            </Typography>
        </Box>
    );
});

export default FundLabel;
