import React from 'react';
import {Inputs, Typography} from 'components';
import {ReversibleInputs} from './components';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    label: {
        minWidth: 120,
        fontSize: 16,
        minHeight: 30,
        fontWeight: '600',
        borderRadius: 5
    }
}));

function StepReversible() {
    const classes = useStyles();

    return (
        <>
            <Typography
                variant="h3"
                color="blue"
                semibold
                center
            >
                Souhaitez-vous une rente réversible&nbsp;?
            </Typography>
            <Inputs.RadioButton
                name="reversible"
                options={[
                    {
                        label: 'OUI',
                        value: true
                    },
                    {
                        label: 'NON',
                        value: null
                    }
                ]}
                classNameLabel={classes.label}
                center
            />
            <ReversibleInputs />
        </>
    );
}

export default StepReversible;
