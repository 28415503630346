import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {Chip} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    menu: {
        color: theme.palette.gray,
        background: 'white',
        margin: theme.spacing(0, 0.4, 1, 0),
        fontSize: 17
    },
    selected: {
        color: theme.palette.blue,
        border: `solid 1px ${theme.palette.blue}`
    }
}));

function Menu({item, itemSelected, setItemSelected}) {
    const classes = useStyles();

    return (
        <Chip
            label={item.label}
            className={clsx({
                [classes.menu]: true,
                [classes.selected]: item.name === itemSelected.name
            })}
            onClick={() => setItemSelected(item)}
            variant="outlined"
        />
    );
}

Menu.propTypes = {
    item: PropTypes.object.isRequired,
    itemSelected: PropTypes.object.isRequired,
    setItemSelected: PropTypes.func.isRequired
};

export default Menu;
