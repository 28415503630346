import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Icon, Structure, Typography} from 'components';
import {ConsentB2VModal, NavButton, NavButtonExpand} from './components';
import {useB2V, useUser} from 'hooks';
import {modal} from 'utils';

import {Box, Grid, Toolbar} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ModalSso} from 'components/Buttons/components/SSO/SSO';
import {LangSelect} from 'macros';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    menu: {
        height: 32,
        background: theme.palette.headerPale
    },
    profile: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 180,
        minWidth: 130,
        height: 40,
        background: theme.palette.blue,
        color: theme.palette.white,
        opacity: 0.9,
        transition: 'opacity 0.1s linear',
        '&:hover, &:focus-visible': {
            opacity: 1,
            background: theme.palette.blueDark
        },
        cursor: 'pointer',
        float: 'right',
        border: 'none'
    },
    wrapperProfile: {
        '&:focus button': {
            opacity: 1,
            background: theme.palette.blueDark
        }
    },
    icon: {
        color: 'white',
        marginRight: theme.spacing(1)
    },
    wrapperTooltipMenu: {
        paddingTop: theme.spacing(3),
        background: theme.palette.transparent,
        position: 'absolute',
        top: 55,
        right: 0,
        width: 'max-content'
    },
    tooltip: {
        position: 'relative',
        width: 'max-content',
        background: theme.palette.white,
        zIndex: 100,
        transition: 'top 0.3s ease-out',
        boxShadow: '0px 4px 16px 0px #00008F33',
        borderRadius: 8,
        padding: theme.spacing(1),
        '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: 10,
            top: -9,
            right: 71,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '0 7px 9px 7px',
            borderColor: 'transparent transparent white transparent'
        }
    },
    tooltipButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.white,
        border: 'none',
        padding: theme.spacing(1, 1),
        '&:hover': {
            background: theme.palette.pale
        },
        cursor: 'pointer'
    },
    tooltipIcon: {
        display: 'flex',
        marginRight: theme.spacing(0.5)
    },
    toolbar: {
        height: 76,
        minHeight: 'initial',
        borderTop: `2px solid ${theme.palette.blue}`
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '1440px !important'
    },
    logo: {
        width: 55,
        height: 55,
        marginRight: 25
    },
    navButton: {
        position: 'relative',
        height: 76,
        display: 'flex',
        alignItems: 'center',
        '&:hover&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 2,
            background: theme.palette.blueLight
        }
    },
    navLabel: {
        padding: theme.spacing(0, 2),
        color: theme.palette.blue
    },
    profileButton: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    profileLabel: {
        padding: theme.spacing(0, 1),
        color: theme.palette.blue
    },
    logout: {
        color: theme.palette.blue,
        marginLeft: theme.spacing(1)
    },
    selected: {
        paddingTop: 4,
        borderBottom: `solid 4px #C94E14`,
        '&:before': {
            height: '0 !important'
        }
    },
    ul: {
        height: '100%',
        justifyContent: 'space-evenly',
        listStyleType: 'none'
    },
    chevron: {marginLeft: theme.spacing(1)}
}));

export const navProfile = (user, t) => [
    ...(user.s2e_profile || user.ais_profile
        ? [
              {
                  label: t('navigation.personal'),
                  url: '/profile',
                  icon: <AccountCircleOutlinedIcon style={{color: '#00008f', marginRight: 5}} />
              }
          ]
        : []),
    {
        label: t('navigation.logout'),
        url: '/logout/easyprojets',
        icon: <PowerSettingsNewIcon style={{color: '#00008f', marginRight: 5}} />
    }
];

const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

const ProfileButton = () => {
    const {user} = useUser();
    const {B2V} = useB2V();

    const classes = useStyles();
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);

    const profileNavigation =
        B2V && !B2V.error
            ? insert(navProfile(user, t), navProfile(user, t).length - 1, {
                  label: t('navigation.consent'),
                  icon: <SettingsOutlinedIcon style={{color: '#00008f', marginRight: 5}} />,
                  action: () => modal.open('consent_b2v')
              })
            : navProfile(user, t);

    return (
        <div
            className={classes.wrapperProfile}
            onMouseEnter={() => setOpen(true)}
            onMouseMove={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            onKeyDown={(e) => {
                e.target.tagName !== 'A' && e.key === 'Tab' && e.shiftKey && setOpen(false);
            }}
        >
            <Box
                component={'button'}
                className={classes.profile}
                tabIndex={0}
                aria-expanded={open}
                name="profile"
            >
                <AccountCircleOutlinedIcon
                    fontSize={'medium'}
                    className={classes.icon}
                />

                <Typography
                    gutterBottom={false}
                    title="Profil"
                    noWrap
                    localization={'navigation.profile'}
                    color={'white'}
                />

                {open ? <ExpandLessIcon className={classes.chevron} /> : <ExpandMoreIcon className={classes.chevron} />}
            </Box>
            <div
                className={clsx({[classes.wrapperTooltipMenu]: true, hide: !open})}
                aria-hidden={!open}
                onMouseEnter={() => setOpen(true)}
                onMouseMove={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                onFocus={() => setOpen(true)}
            >
                <div className={clsx(classes.tooltip)}>
                    <ul className={classes.ul}>
                        {profileNavigation.map((button, index) => (
                            <li key={index}>
                                <Link
                                    className={classes.tooltipButton}
                                    to={button.url || window.location.pathname}
                                    tabIndex={open ? '0' : '-1'}
                                    key={`button-${button.label}`}
                                    onMouseDown={button.action}
                                    onKeyDown={(e) => {
                                        e.key === 'Tab' &&
                                            !e.shiftKey &&
                                            index === profileNavigation.length - 1 &&
                                            setOpen(false);
                                    }}
                                >
                                    {typeof button.icon === 'string' ? (
                                        <Icon
                                            name={button.icon}
                                            color="blue"
                                        />
                                    ) : (
                                        button.icon
                                    )}
                                    <Typography
                                        color="blue"
                                        gutterBottom={false}
                                        size={16}
                                    >
                                        {button.label}
                                    </Typography>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <ConsentB2VModal hash="consent_b2v" />
        </div>
    );
};

function Desktop({nav}) {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <Toolbar
                className={classes.toolbar}
                component={'nav'}
                disableGutters
            >
                <Structure.Center className={classes.wrapper}>
                    <Link
                        to="/epargne"
                        aria-label={t('navigation.logo')}
                    >
                        <img
                            className={classes.logo}
                            src={`${process.env.PUBLIC_URL}/logos/axa.svg`}
                            alt={t('navigation.logo')}
                            title={t('navigation.logo')}
                        />
                    </Link>
                    <Grid
                        component={'ul'}
                        container
                        className={classes.ul}
                    >
                        {nav.map((item, index) =>
                            item.children ? (
                                <Grid
                                    key={index}
                                    component={'li'}
                                    item
                                >
                                    <NavButtonExpand
                                        key={`navButton-${item.label}`}
                                        label={item.label}
                                        items={item.children}
                                    />
                                </Grid>
                            ) : (
                                <Grid
                                    key={index}
                                    component={'li'}
                                    item
                                    className={clsx({[classes.selected]: window.location.pathname === item.url})}
                                >
                                    <NavButton
                                        key={`navButton-${item.label}`}
                                        label={item.label}
                                        url={item.url}
                                    />
                                </Grid>
                            )
                        )}
                    </Grid>

                    <div className="flexGrow" />
                    {process.env.REACT_APP_ENVIRONMENT !== 'production' && <LangSelect />}

                    <ProfileButton />
                </Structure.Center>
                <ModalSso
                    scope="ais"
                    hash="sso_ais"
                />
            </Toolbar>
        </>
    );
}

Desktop.propTypes = {
    nav: PropTypes.array.isRequired
};

export default Desktop;
