import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {Buttons, Form, Typography} from 'components';
import {useLoading, useStepper} from 'hooks';
import {FormConsumer} from 'store/Form';

import {makeStyles} from '@material-ui/styles';
import {Box, Dialog, DialogContent, Slide} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="down"
            mountOnEnter
            unmountOnExit
            ref={ref}
            {...props}
        />
    );
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        }
    },
    icon: {
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        cursor: 'pointer'
    },
    close: {
        cursor: 'pointer'
    }
}));

const StepperActions = ({
    indexes,
    step,
    setStep,
    isValid,
    firstAction,
    lastAction,
    stepActionComponent,
    stepSubmit,
    subStepsModals,
    previousLabel,
    nextLabel,
    hiddenPrevious = false
}) => {
    const classes = useStyles();

    const {setLoading} = useLoading();
    const {stepper, setStepper, setResponse} = useStepper();

    const [open, setOpen] = useState(false);

    const computePreviousStepIndex = () => {
        let previousStepIndex = 0;

        for (let i = step - 1; i >= 0; i--) {
            if (indexes[i] !== null) {
                previousStepIndex = i;
                break;
            }
        }

        return previousStepIndex;
    };

    const computeNextStepIndex = () => {
        let nextStepIndex = 0;

        for (let i = step + 1; i < indexes.length; i++) {
            if (indexes[i] !== null) {
                nextStepIndex = i;
                break;
            }
        }

        return nextStepIndex;
    };

    const modals = subStepsModals[step] instanceof Function ? subStepsModals[step](stepper) : null;

    const submit = (goToNextStep = true) => {
        setLoading(true);

        stepSubmit(stepper, setStepper, setResponse)
            .then(() => {
                if (modals instanceof Array && modals.find((element) => element.trigger(stepper))) {
                    setOpen(true);
                    return;
                }
                let nextStepIndex = computeNextStepIndex();
                goToNextStep && setStep(nextStepIndex);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className={clsx(classes.root, 'stepperActions')}>
            {step === 0 && firstAction}
            {step > 0 && !hiddenPrevious && (
                <Buttons.Default
                    className={classes.button}
                    color="blueAlpha"
                    label={previousLabel}
                    previous
                    onClick={() => setStep(computePreviousStepIndex())}
                />
            )}

            <Box flexGrow={1} />

            <div className={classes.container}>
                <Box mr={2}>{stepActionComponent}</Box>

                {step < indexes[indexes.length - 1] && (
                    <Buttons.Default
                        className={classes.button}
                        disabled={!isValid}
                        color="green"
                        label={nextLabel}
                        next
                        onClick={() => submit(true)}
                    />
                )}

                {lastAction &&
                    (step === indexes[indexes.length - 1] ||
                        (step === 0 && indexes.filter((index) => index !== null).length === 1)) &&
                    React.cloneElement(lastAction, {
                        onClick: () => {
                            if (lastAction.props.onClick instanceof Function) lastAction.props.onClick();
                            else submit(false);
                        },
                        disabled: !isValid
                    })}
            </div>

            <Dialog
                className={classes.modal}
                open={open}
                onClose={() => setOpen(false)}
                TransitionComponent={Transition}
                fullWidth
                maxWidth="sm"
            >
                <Box
                    p={2}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    {modals && modals.find((element) => element.trigger(stepper))?.title && (
                        <Typography
                            variant="h3"
                            color="blue"
                            semibold
                            left
                            gutterBottom={false}
                        >
                            {modals.find((element) => element.trigger(stepper)).title}
                        </Typography>
                    )}
                    <CloseIcon
                        tabIndex={0}
                        className={classes.close}
                        onClick={() => setOpen(false)}
                    />
                </Box>

                {modals && (
                    <Form {...modals.find((element) => element.trigger(stepper)).formProps}>
                        <DialogContent>
                            {modals.find((element) => element.trigger(stepper))?.component}

                            <div
                                className={classes.container}
                                style={{justifyContent: 'space-between'}}
                            >
                                {modals.find((element) => element.trigger(stepper))?.previousLabel ? (
                                    <Buttons.Default
                                        className={classes.button}
                                        label={modals.find((element) => element.trigger(stepper)).previousLabel}
                                        color="gray"
                                        previous
                                        onClick={() => setOpen(false)}
                                    />
                                ) : (
                                    <div />
                                )}
                                {modals.find((element) => element.trigger(stepper))?.nextLabel && (
                                    <FormConsumer>
                                        {(formContext) => (
                                            <Buttons.Default
                                                className={classes.button}
                                                label={modals.find((element) => element.trigger(stepper)).nextLabel}
                                                disabled={!formContext.formState.isValid}
                                                color="green"
                                                next
                                                onClick={() => {
                                                    setOpen(false);
                                                    setStep(computeNextStepIndex());
                                                }}
                                            />
                                        )}
                                    </FormConsumer>
                                )}
                            </div>
                        </DialogContent>
                    </Form>
                )}
            </Dialog>
        </div>
    );
};

StepperActions.propTypes = {
    step: PropTypes.number.isRequired,
    setStep: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    firstAction: PropTypes.node,
    lastAction: PropTypes.node,
    stepActionComponent: PropTypes.node,
    stepSubmit: PropTypes.func,
    previousLabel: PropTypes.string,
    nextLabel: PropTypes.string,
    hiddenPrevious: PropTypes.bool
};

export default StepperActions;
