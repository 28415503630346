import mock from 'api/mockFastApi';

mock.onPost('/simulators/transmission/simulation').reply((config) => {
    const payload = JSON.parse(config.data);
    if (
        payload.heirs === {} ||
        payload?.heirs?.has_testament ||
        (payload?.heirs?.children === '0' &&
            payload?.heirs?.family_situation !== 'spouse' &&
            payload?.heirs?.siblings === '0')
    ) {
        return [
            200,
            {
                heirs_result: [],
                life_insurances_amount: 0,
                patrimonies_amount: 0
            }
        ];
    } else {
        return [
            200,
            {
                heirs_result: [
                    {
                        beneficiary: 'spouse',
                        number: 1,
                        results: {
                            quarter_bare_property: {
                                total_tax: 14000.35,
                                life_insurance_tax: 10000.35,
                                patrimony_amount: 1000000.12,
                                life_insurance_amount: 8967.48
                            },
                            usufruct: {
                                total_tax: 11000.35,
                                life_insurance_tax: 11000.35,
                                patrimony_amount: 900000.12,
                                life_insurance_amount: 8967.48
                            }
                        }
                    },
                    {
                        beneficiary: 'children',
                        number: 2,
                        results: {
                            quarter_bare_property: {
                                total_tax: 5500,
                                life_insurance_tax: 5000,
                                patrimony_amount: 10000000,
                                life_insurance_amount: 0
                            },
                            usufruct: {
                                total_tax: 8500.35,
                                life_insurance_tax: 8000.35,
                                patrimony_amount: 10100000.12,
                                life_insurance_amount: 0
                            }
                        }
                    }
                ],
                life_insurances_amount: 8967.48,
                patrimonies_amount: 0
            }
        ];
    }
});
mock.onDelete('/simulators/transmission/simulation').reply((config) => {
    return [200, 'OK'];
});

mock.onPost('/services/mail-handler/load-transmission').reply(() => {
    return [200, 'OK'];
});

mock.onPost('/services/mail-handler/share-simulation-transmission').reply(() => {
    return [200, 'OK'];
});

mock.onPost('/services/api-client/advisor/request').reply(() => {
    return [200, 'OK'];
});

mock.onPost('/simulators/transmission/initialize').reply(() => {
    return [404, '']
});
