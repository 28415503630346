import {useContext, useEffect} from 'react';
import {fastapi} from 'api';
import {useUser} from 'hooks';
import {TransmissionContext} from 'store/Transmission';

let memoized_user_id = '';

const getTransmission = (user_id, callback) => {
    if (memoized_user_id === user_id) return;
    memoized_user_id = user_id;

    fastapi
        .post('/simulators/transmission/initialize', {
            user_id: user_id
        })
        .then((response) => response.data && callback(response));
};

function useTransmission() {
    const {user} = useUser();

    const {transmission, setTransmission} = useContext(TransmissionContext);

    // Initial value in db : `transmission`
    useEffect(() => {
        user.id && user.origin==="individual_transmission" &&
            Object.keys(transmission).length === 0 &&
            getTransmission(user.id, (response) => {
                if (Object.keys(response.data).length === 0) return;
                let AXALifeInsurances = [];
                const externalLifeInsurances =
                    response.data?.life_insurances?.life_insurances_list.filter((account) => !account.is_axa) || [];

                user.accounts
                    .filter((account) => account.type === 'LIFE_INSURANCE')
                    // eslint-disable-next-line
                    .map((axaLifeInsurance) => {
                        AXALifeInsurances.push({
                            name: `${axaLifeInsurance.title}/${axaLifeInsurance.company_name}`,
                            amount: axaLifeInsurance?.balance,
                            beneficiary: 'spouse',
                            is_axa: true
                        });
                    });

                const transmissionPayload = {
                    user_id: user.id,
                    heirs: response.data?.heirs,
                    patrimony: response.data?.patrimony,
                    life_insurances: {
                        ...response.data?.life_insurances,
                        life_insurances_list: [...AXALifeInsurances, ...externalLifeInsurances]
                    }
                };
                fastapi.post('/simulators/transmission/simulation', transmissionPayload).then((response) => {
                    setTransmission({
                        ...response.data,
                        ...transmissionPayload
                    });
                });
            });
        // eslint-disable-next-line
    }, [user.id]);

    return {
        transmission: transmission,
        setTransmission: setTransmission
    };
}

export default useTransmission;
