import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Icon, Modal} from 'components';
import {modal} from 'utils';

import {IconButton, Tooltip as MuiTooltip, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline',
        verticalAlign: 'top'
    },
    wrapper: {
        margin: 0,
        padding: 0
    },
    icon: {
        cursor: 'pointer'
    },
    modal: {
        maxWidth: 500
    }
}));

const useStylesTooltip = makeStyles((theme) => ({
    tooltip: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.blue,
        fontSize: 14,
        '&.outlined': {
            color: theme.palette.scorpion,
            backgroundColor: theme.palette.pale,
            border: `solid 1px ${theme.palette.blue}`
        }
    },
    arrow: {
        color: theme.palette.blue
    }
}));

const Tooltip = ({children, variant, ...rest}) => {
    const classes = useStylesTooltip();

    return (
        <MuiTooltip
            arrow
            classes={{
                tooltip: clsx(classes.tooltip, variant === 'outlined' && 'outlined'),
                arrow: classes.arrow
            }}
            {...rest}
        >
            {children}
        </MuiTooltip>
    );
};

function Info(props) {
    const {
        hash,
        children,
        className,
        title,
        color,
        tooltip,
        placement,
        variant = 'contained',
        iconName,
        disableMobile,
        disableInteractive = false,
        ...rest
    } = props;

    const classes = useStyles();
    const theme = useTheme();

    let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    if (disableMobile) isMobile = false;

    const [open, setOpen] = useState(false);

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const InfoIcon = () => (
        <Icon
            name={iconName ? iconName : variant === 'outlined' ? 'axa-icons-info-outline' : 'axa-icons-info'}
            className={classes.icon}
            color={color}
            size={18}
            onClick={() => {
                if (hash && !(tooltip && !isMobile)) modal.open(hash);
            }}
            {...rest}
        />
    );

    return (
        <div
            className={clsx(classes.root, className)}
            tabIndex={tooltip ? -1 : 0}
            onKeyDown={(event) => {
                if (event.key === 'Enter') if (hash && !(tooltip && !isMobile)) modal.open(hash);
            }}
        >
            {tooltip && !isMobile ? (
                <Tooltip
                    title={children}
                    arrow
                    placement={placement}
                    interactive={!disableInteractive}
                    variant={variant}
                    open={open}
                    onClose={handleTooltipClose}
                    disableFocusListener
                    disableTouchListener
                    disableHoverListener
                >
                    <IconButton
                        className={classes.wrapper}
                        onMouseEnter={handleTooltipOpen}
                        onMouseLeave={handleTooltipClose}
                        onTouchStart={handleTooltipOpen}
                    >
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <InfoIcon />
            )}

            {hash && (
                <Modal
                    hash={hash}
                    className={classes.modal}
                    title={title || 'Informations'}
                >
                    {children}
                </Modal>
            )}
        </div>
    );
}

Info.propTypes = {
    hash: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
    color: PropTypes.string,
    tooltip: PropTypes.bool,
    placement: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
    variant: PropTypes.string,
    iconName: PropTypes.string,
    disableMobile: PropTypes.bool
};

export default Info;
