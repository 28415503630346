import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {LexiconBox, ToggleLexicon} from './components';
import lexicons from './config';

function Lexicon({config, className}) {
    const hash = `lexicon-${config}`;

    return (
        <div className={clsx(className)}>
            <ToggleLexicon hash={hash} />
            <LexiconBox
                hash={hash}
                config={lexicons[config]}
            />
        </div>
    );
}

Lexicon.propTypes = {
    config: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default Lexicon;
