import React, {useEffect, useState} from 'react';
import {Alert, Buttons, Info, Inputs, Typography} from 'components';
import {LifeInsuranceTile} from './components';
import {makeStyles} from '@material-ui/styles';
import {useForm, useTransmission, useUser} from 'hooks';
import {amount, events} from 'utils';

const useStyles = makeStyles((theme) => ({
    root: {},
    columnItems: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *': {
            marginRight: 20
        }
    },
    rowItems: {
        display: 'flex',
        flexDirection: 'column'
    },
    lifeInsurance: {
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
        borderTop: `1px solid ${theme.palette.gray}`,
        marginBottom: 5,
        '&:last-child': {
            borderBottom: `1px solid ${theme.palette.gray}`
        }
    },
    button: {
        cursor: 'pointer',
        border: 'none',
        padding: '2px'
    },
    icon: {
        verticalAlign: 'middle'
    }
}));

export const SanitizeLifeInsurances = (values) => {
    return {
        has_life_insurance: values.has_life_insurance,
        life_insurances_list: [
            ...new Set(
                Object.keys(values)
                    .filter((key) => key !== 'has_life_insurance')
                    .map((key) => parseInt(key.split('-')[0]))
            )
        ].map((eltId) => {
            return {
                amount: amount.parse(values[`${eltId}-lifeInsurance-amount`]),
                name: values[`${eltId}-lifeInsurance-name`],
                beneficiary: values[`${eltId}-lifeInsurance-beneficiary`],
                is_axa: values[`${eltId}-lifeInsurance-is_axa`]
            };
        })
    };
};

function StepLifeInsurance({isStepper, initialLifeInsurances}) {
    const classes = useStyles();
    const {user} = useUser();
    const {transmission} = useTransmission();
    const [lifeInsurances, setLifeInsurances] = useState(
        transmission?.life_insurances
            ? Object.assign(
                  {},
                  ...transmission?.life_insurances?.life_insurances_list.map((elt, index) => ({
                      [`${index}-lifeInsurance`]: {
                          amount: elt.amount,
                          name: elt.name,
                          beneficiary: elt.beneficiary,
                          is_axa: elt.is_axa
                      }
                  }))
              )
            : initialLifeInsurances
    );
    const {formState, setFormState} = useForm();

    const addLifeInsurance = (lifeInsuranceAXA) => {
        const lastIndex =
            Object.keys(lifeInsurances).length > 0
                ? Math.max(
                      ...Object.keys(lifeInsurances).map((index) => {
                          return parseInt(index.split('-')[0]);
                      })
                  ) + 1
                : 0;

        const newLifeInsurance = {
            amount: '0',
            name: '',
            beneficiary: 'spouse',
            is_axa: false
        };

        setLifeInsurances({...lifeInsurances, [`${lastIndex}-lifeInsurance`]: newLifeInsurance});

        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [`${lastIndex}-lifeInsurance-amount`]: newLifeInsurance.amount,
                [`${lastIndex}-lifeInsurance-name`]: newLifeInsurance.name,
                [`${lastIndex}-lifeInsurance-beneficiary`]: newLifeInsurance.beneficiary,
                [`${lastIndex}-lifeInsurance-is_axa`]: newLifeInsurance.is_axa
            }
        });
        events.resize();
    };

    useEffect(() => {
        if (!formState.values?.has_life_insurance) {
            setLifeInsurances({});

            let values = formState.values;
            Object.keys(formState.values).map((key) => {
                if (key !== 'has_life_insurance') delete values[key];
                return false;
            });
            setFormState({
                ...formState,
                values: values
            });
        }

        // eslint-disable-next-line
    }, [formState.values?.has_life_insurance]);

    return (
        <div>
            <Typography
                variant="h3"
                semibold
                color="blue"
            >
                Je renseigne mes contrats d’assurance vie {isStepper ? '- 3/3' : ''}
            </Typography>
            <div className={classes.columnItems}>
                <Typography>Je détiens au moins un contrat d’assurance-vie ?</Typography>
                <Info
                    hash="1"
                    title="Informations"
                    color="blue"
                    tooltip
                >
                    Le simulateur applique la fiscalité applicable aux versements réalisés après oct 98, en considérant
                    que vous êtes le souscripteur unique du contrat. Dans le cas d’un versement avant oct 98, les
                    montants ne sont pas soumis à la fiscalité sur l’assurance-vie.
                </Info>
                <div className="flexGrow" />
                <Inputs.Switch
                    name="has_life_insurance"
                    disabled={user.accounts.filter((account) => account.type === 'LIFE_INSURANCE').length > 0}
                />
            </div>
            {formState.values.has_life_insurance === true && (
                <Alert
                    message={
                        <>
                            Si une partie de votre épargne a été versée sur votre/vos contrat(s) d'Assurance vie avant
                            le 13 oct. 1998, cette part sera transmise sans fiscalité, dans sa version actuelle le
                            simulateur considère que vos versements ont été réalisés après cette date.
                        </>
                    }
                    severity="info"
                />
            )}
            {formState.values.has_life_insurance === true &&
                Object.keys(lifeInsurances)?.length > 0 &&
                Object.keys(lifeInsurances).map((key) => (
                    <LifeInsuranceTile
                        key={`${key}`}
                        index={key}
                        lifeInsurances={lifeInsurances}
                        setLifeInsurances={setLifeInsurances}
                    />
                ))}
            {formState.values.has_life_insurance === true && (
                <Buttons.Default
                    label="Ajouter"
                    iconName="axa-icons-more"
                    color="blue"
                    center
                    onClick={() => {
                        addLifeInsurance();
                    }}
                />
            )}
        </div>
    );
}

export default StepLifeInsurance;
