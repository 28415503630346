import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {makeStyles, useTheme} from '@material-ui/styles';
import './icons.scss';

const useStyles = makeStyles(() => ({
    root: {
        zIndex: 100,
        fontWeight: '600'
    },
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    circled: {
        '&:before': {
            border: '1px solid',
            borderRadius: '50%',
            padding: '1rem'
        }
    }
}));

function Icon(props) {
    const {name, className, color, size, flex, circled, ...rest} = props;
    const classes = useStyles();
    const theme = useTheme();

    return (
        <i
            className={clsx(classes.root, name, className, flex && classes.flex, circled && classes.circled)}
            style={{
                color: theme.palette[color],
                borderColor: theme.palette[color],
                fontSize: size
            }}
            {...rest}
        />
    );
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
    flex: PropTypes.bool,
    circled: PropTypes.bool
};

export default Icon;
