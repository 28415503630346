import React from 'react';

import {Typography} from 'components';
import {amount} from 'utils';

import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1.5)
    },
    account: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: theme.spacing(1.5, 1),
        background: theme.palette.veryLightGray,
        border: `solid 1px ${theme.palette.silver}`,
        borderLeft: `solid 5px ${theme.palette.blue}`,
        borderRadius: '4px 4px 0px 0px',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1, 1)
        }
    },
    wrapper: {
        border: `solid 1px ${theme.palette.silver}`,
        borderTop: 'none',
        borderRadius: '0 0 4px 4px'
    },
    investment: {
        padding: theme.spacing(0.5, 1),
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: `solid 1px ${theme.palette.gray}`,
        borderLeft: `solid 3px ${theme.palette.white}`,
        '&:first-child': {
            borderTop: 'none'
        }
    },
    amount: {
        textAlign: 'end'
    },
    totalInvestment: {
        whiteSpace: 'nowrap'
    }
}));

const AccountWithInvestments = ({account, investments}) => {
    const classes = useStyles();

    const totalInvestment = account.investments
        .map((investment) => amount.parse(investments[`${account.policy_id}|${investment.id}`]))
        .reduce((acc, val) => acc + val, 0);

    if (totalInvestment === 0) return null;

    return (
        <div className={classes.root}>
            <div className={classes.account}>
                <Box
                    display="flex"
                    flexDirection="column"
                    pr={1}
                >
                    <Typography
                        variant="h5"
                        color="blue"
                        semibold
                        caps
                        gutterBottom={false}
                    >
                        {account.title}
                    </Typography>

                    <Typography
                        variant="h6"
                        color="dark"
                        gutterBottom={false}
                    >
                        {account.company_name}
                    </Typography>
                </Box>

                <Box flexGrow={1} />

                <Typography
                    variant="h5"
                    color="blue"
                    semibold
                    caps
                    gutterBottom={false}
                    className={classes.totalInvestment}
                >
                    {amount.format(totalInvestment)}
                </Typography>
            </div>

            {account.investments.length > 0 && (
                <div className={classes.wrapper}>
                    {account.investments
                        .filter(
                            (investment) =>
                                investments[`${account.policy_id}|${investment.id}`] &&
                                amount.parse(investments[`${account.policy_id}|${investment.id}`]) > 0
                        )
                        .sort((a, b) => (a.order > b.order ? 1 : -1))
                        .map((investment, index) => (
                            <div
                                className={classes.investment}
                                key={`investment-${index}`}
                            >
                                <Typography
                                    caps
                                    size={14}
                                    gutterBottom={false}
                                >
                                    {!account.is_guided && investment.label}
                                </Typography>

                                <Box className={classes.amount}>
                                    <Typography
                                        gutterBottom={false}
                                        noWrap
                                        isAmount
                                    >
                                        {amount.format(
                                            amount.parse(investments[`${account.policy_id}|${investment.id}`])
                                        )}
                                    </Typography>
                                </Box>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default AccountWithInvestments;
