import React from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core';
import {amount} from 'utils';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 0,
        marginLeft: -8,
        marginBottom: -15
    }
}));

const Area = ({data, labels, height}) => {
    const classes = useStyles();
    const theme = useTheme();

    if (!data || data.length === 0) return null;

    return (
        <Chart
            className={classes.root}
            options={{
                colors: [theme.palette.blue],
                chart: {
                    animations: {enabled: false},
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    show: true,
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
                stroke: {
                    width: 3,
                    curve: 'straight'
                },
                tooltip: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    theme: theme.palette.type,
                    shared: true,
                    intersect: false,
                    onDatasetHover: {
                        highlightDataSeries: true
                    },
                    marker: {
                        show: false
                    },
                    custom: function ({series, seriesIndex, dataPointIndex, w}) {
                        return `<div class='apex-tooltip'>${amount.format(series[seriesIndex][dataPointIndex])}</div>`;
                    }
                },
                xaxis: {
                    type: 'datetime',
                    tooltip: {
                        enabled: true,
                        formatter: (value) => moment(value).format('DD MMM YYYY')
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    min: 0,
                    labels: {
                        formatter: (value) => amount.format(value, value > 0 ? 2 : 0),
                        offsetX: -12
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'vertical',
                        shadeIntensity: 0.1,
                        opacityFrom: 0.3,
                        opacityTo: 0.8,
                        stops: [0, 100]
                    }
                }
            }}
            series={[
                {
                    name: ' ',
                    data: data.map((el, index) => [labels[index], el])
                }
            ]}
            type="area"
            height={height || 300}
        />
    );
};

Area.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    height: PropTypes.number
};

export default Area;
