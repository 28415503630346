import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Footer, Header} from 'layouts/_components';
import {Desktop, Mobile, SubHeader} from './Header';

function Main({children, background, disableHeader, disableFooter}) {
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);

    return (
        <div
            className="full-height"
            style={{background: background}}
        >
            {!disableHeader && (
                <Header
                    desktop={() => <Desktop />}
                    mobile={() => <Mobile />}
                    subHeader={<SubHeader />}
                    setHeaderHeight={setHeaderHeight}
                />
            )}
            <main style={{marginTop: disableHeader ? 0 : headerHeight}}>
                <div
                    style={{
                        minHeight: `calc(100vh - ${
                            (disableHeader ? 0 : headerHeight) + (disableFooter ? 0 : footerHeight)
                        }px)`
                    }}
                >
                    {children}
                </div>
                {!disableFooter && <Footer setFooterHeight={setFooterHeight} />}
            </main>
        </div>
    );
}

Main.propTypes = {
    children: PropTypes.node.isRequired,
    breadcrumb: PropTypes.object,
    background: PropTypes.string,
    disableHeader: PropTypes.bool,
    disableFooter: PropTypes.bool
};

export default Main;
