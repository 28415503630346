const API_URIS = {
    development: `https://${window.location.hostname}:7000`,
    recette: 'https://api-rec.easyprojets.com',
    preproduction: 'https://api-pp.easyprojets.com',
    production: 'https://api.easyprojets.com'
};

const AXA_SCOPES = {
    ais: ['ais-fr-retirement-saving-customer-id'],
    s2e: ['axa-fr-salary-saving-customer-id'],
    axa: [
        'https://connect.axa.fr/individualOrProfessional.mainCustomerId',
        'https://connect.axa.fr/individualOrProfessional.customerId'
    ]
};

const AXA_CLIENT_SPACE_URL = {
    development: 'https://espaceclient-salt-recprj.axa.fr/#',
    recette: 'https://espaceclient-salt-recprj.axa.fr/#',
    preproduction: 'https://espaceclient-pp.axa.fr/#',
    production: 'https://espaceclient.axa.fr/#'
};

const OPINION_PATHS = ['/epargne', '/accounts', '/documents'];

const MAX_RETRY = 2;

const RETIREMENT_ACCOUNT_TYPES = [
    'A82',
    'A83',
    'PER',
    'PERO',
    'PERCO',
    'PERECO',
    'PERECOI',
    'PERU',
    'RETIREMENT_SAVINGS',
    'B2V'
];

const PROJECTS_ACCOUNT_TYPES = ['PEE', 'PEG', 'LIFE', 'LIFE_INSURANCE', 'PEI'];

const COMPARTMENTS_ACCOUNT_TYPES = ['PER', 'PERO', 'PERECO', 'PERU', 'PERECOI'];

const AVAILABILITIES_ACCOUNT_TYPES = ['PEE'];

const LIFE_PROJECT_ACCOUNT_TYPES = {
    retirement: ['A83', 'PER', 'PERO', 'PERECO', 'PERECOI', 'PERU', 'PERCO', 'PEE', 'RETIREMENT_SAVINGS', 'A82', 'B2V'],
    owner: ['PER', 'PERO', 'PERECO', 'PERCO', 'PERU', 'PERECOI', 'PEE', 'PEI', 'LIFE'],
    children: ['PEE', 'PEI', 'LIFE'],
    travel: ['PEE', 'PEI', 'LIFE'],
    company: ['PEE', 'PEI', 'LIFE'],
    housing: ['PEE', 'PEI', 'LIFE'],
    other: ['PEE', 'PEI', 'LIFE']
};

const LIFE_PROJECT_COMPARTMENTS = {
    retirement: ['CO', 'VV', 'ES'],
    owner: ['VV', 'ES']
};

const COLORS = {
    axa: {
        projects: 'rgba(108, 174, 36, 0.85)',
        retirement: 'rgba(36, 38, 170, 0.85)'
    },
    external: {
        projects: 'rgba(175, 211, 135, 0.75)',
        retirement: 'rgba(148, 190, 238, 0.75)'
    }
};

const HIGHLIGHT_COLORS = {
    axa: {
        projects: 'rgba(108, 174, 36, 1)',
        retirement: 'rgba(36, 38, 170, 1)'
    },
    external: {
        projects: 'rgba(175, 211, 135, 1)',
        retirement: 'rgba(148, 190, 238, 1)'
    }
};

export default {
    API_URIS,
    AXA_SCOPES,
    AXA_CLIENT_SPACE_URL,
    OPINION_PATHS,
    MAX_RETRY,
    RETIREMENT_ACCOUNT_TYPES,
    PROJECTS_ACCOUNT_TYPES,
    COMPARTMENTS_ACCOUNT_TYPES,
    AVAILABILITIES_ACCOUNT_TYPES,
    LIFE_PROJECT_ACCOUNT_TYPES,
    LIFE_PROJECT_COMPARTMENTS,
    COLORS,
    HIGHLIGHT_COLORS
};
