import {useContext, useEffect} from 'react';
import {fastapi} from 'api';
import {useUser} from 'hooks';
import {MarelContext} from 'store/Marel';

let memoized_user_id = '';

const getMarel = (user_id, callback) => {
    if (memoized_user_id === user_id) return;
    memoized_user_id = user_id;

    fastapi
        .post('/simulators/marel/initialize', {
            user_id: user_id
        })
        .then((response) => response.data && callback(response));
};


function useMarel() {
    const {user} = useUser();
    const {marel, setMarel, credentials, setCredentials, step, setStep, publicKey, setPublicKey} = useContext(MarelContext);
    // Initial value in db : `user.marel`
    useEffect(() => {
        user.id && Object.keys(marel).length === 0 && getMarel(user.id, (response) => setMarel(response.data));

        // eslint-disable-next-line
    }, [user.id]);

    return {
        marel: marel,
        setMarel: setMarel,
        credentials: credentials,
        setCredentials: setCredentials,
        step: step,
        setStep: setStep,
        publicKey: publicKey,
        setPublicKey: setPublicKey
    };
}

export default useMarel;
