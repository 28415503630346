import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Structure, Typography} from 'components';

import {AppBar} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1),
        height: 90,
        background: theme.palette.white
    },
    logo: {
        cursor: 'pointer'
    },
    subfooter: {
        height: 55,
        background: theme.palette.kerialis.main
    },
    copyright: {
        height: 35,
        background: theme.palette.kerialis.dark
    },
    links: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.white,
        '& a': {
            color: 'white',
            padding: theme.spacing(0, 2),
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: theme.palette.white
    }
}));

function Header() {
    const classes = useStyles();

    return (
        <AppBar
            className={classes.header}
            elevation={3}
        >
            <Structure.Center>
                <img
                    className={classes.logo}
                    src={`${process.env.PUBLIC_URL}/logos/kerialis.png`}
                    alt="Kerialis"
                    width={110}
                    height={70}
                    onClick={() => (window.location.href = 'https://kerialis.fr/')}
                />
            </Structure.Center>
        </AppBar>
    );
}

function Footer({className, ...rest}) {
    const classes = useStyles();

    return (
        <div
            className={classes.footer}
            {...rest}
        >
            <div className={classes.subfooter}>
                <Structure.Center className={classes.links}>
                    <Typography
                        variant="h5"
                        color="white"
                        semibold
                    >
                        <Link to="/kerialis/contacts">Contactez-nous</Link>
                        <Link to="/kerialis/cgu">CGU</Link>
                        <Link to="/kerialis/legal">Mentions légales</Link>
                    </Typography>
                </Structure.Center>
            </div>

            <div className={classes.copyright}>
                <Structure.Center className={classes.wrapper}>© KERIALIS 2021 Tous droits réservés</Structure.Center>
            </div>
        </div>
    );
}

function Main({children, background, disableHeader, disableFooter}) {
    const headerHeight = 90;
    const footerHeight = 90;

    return (
        <div
            className="full-height"
            style={{background: background}}
        >
            {!disableHeader && <Header />}
            <main style={{marginTop: disableHeader ? 0 : headerHeight}}>
                <div
                    style={{
                        minHeight: `calc(100vh - ${
                            (disableHeader ? 0 : headerHeight) + (disableFooter ? 0 : footerHeight)
                        }px)`
                    }}
                >
                    {children}
                </div>
                {!disableFooter && <Footer />}
            </main>
        </div>
    );
}

Main.propTypes = {
    children: PropTypes.node.isRequired,
    breadcrumb: PropTypes.object,
    background: PropTypes.string,
    disableHeader: PropTypes.bool,
    disableFooter: PropTypes.bool
};

export default Main;
