import config from 'config';

const getScopes = (oauth_profile) => {
    let scopes = [];

    if (!oauth_profile) return [];

    for (const [scope, values] of Object.entries(config.AXA_SCOPES)) {
        for (const value of values) {
            if (Object.keys(oauth_profile).includes(value)) scopes.push(scope);
        }
    }

    return scopes;
};

export default getScopes;
