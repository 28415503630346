import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {events} from 'utils';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import './carousel.scss';

import {makeStyles} from '@material-ui/styles';

function isInt(value) {
    return (
        !isNaN(value) &&
        (function (x) {
            return (x | 0) === x;
        })(parseFloat(value))
    );
}

const useStyles = makeStyles(() => ({
    withDots: {
        marginBottom: 30
    }
}));

function Carousel(props) {
    const {children, initialSlide, onChange, smooth, current, whiteDots, ...rest} = props;
    const classes = useStyles();

    const sliderRef = useRef(null);

    useEffect(() => {
        isInt(initialSlide) && setTimeout(() => setStep(initialSlide), 250);

        // eslint-disable-next-line
    }, [initialSlide]);

    useEffect(() => {
        isInt(current) && setStep(current);

        // eslint-disable-next-line
    }, [current]);

    const [step, setStep] = useState(initialSlide || 0);
    useEffect(() => {
        step < 0 && setStep(0);
        step > children.length && setStep(children.length);

        sliderRef.current.slickGoTo(step);
        // eslint-disable-next-line
    }, [step]);

    const settings = {
        accessibility: false,
        adaptiveHeight: false,
        arrows: false,
        className: clsx({
            [classes.withDots]: props.dots,
            smooth: process.env.REACT_APP_ENVIRONMENT === 'development' ? false : smooth,
            whiteDots
        }),
        dots: false,
        infinite: false,
        initialSlide: initialSlide || 0,
        slideIndex: initialSlide || 0,
        slidesToScroll: 1,
        slidesToShow: 1,
        speed: 450,
        swipe: false
    };

    const handleBeforeChange = (_, index) => {
        onChange && onChange(index);

        // This setTimeout is needed for adaptive height in nested Carousel
        setTimeout(() => {
            events.resize();
        }, props.speed || 500);
    };

    if (props.autoplay)
        window.addEventListener('resize', () => {
            if (sliderRef.current) sliderRef.current.slickPause();
            clearTimeout(window.resizedFinished);
            window.resizedFinished = setTimeout(() => {
                if (sliderRef.current) sliderRef.current.slickPlay();
            }, 1500);
        });

    return (
        <Slider
            beforeChange={!props.infinite && handleBeforeChange}
            afterChange={(index) => onChange && onChange(index)}
            ref={sliderRef}
            {...settings}
            {...rest}
        >
            {React.Children.map(children, (child, index) =>
                child ? (
                    <div key={`slide-${index}`}>
                        {React.cloneElement(child, {
                            step: step,
                            setStep: setStep
                        })}
                    </div>
                ) : (
                    () => {}
                )
            )}
        </Slider>
    );
}

Carousel.propTypes = {
    current: PropTypes.number,
    initialSlide: PropTypes.number,
    onChange: PropTypes.func,
    smooth: PropTypes.bool, // smooth hide other steps
    whiteDots: PropTypes.bool
};

export default Carousel;
