function downloadPDF(filename, base64_pdf) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        let byteCharacters = atob(base64_pdf);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], {type: 'application/pdf'});
        window.navigator.msSaveOrOpenBlob(blob, filename);
        return;
    }

    const linkSource = 'data:application/pdf;base64,' + base64_pdf;
    const link = document.createElement('a');
    link.href = linkSource;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();

    window.addEventListener(
        'focus',
        function () {
            URL.revokeObjectURL(link.href);
        },
        {once: true}
    );
}

export default downloadPDF;
