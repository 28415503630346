import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {fastapi} from 'api';
import {Buttons, Form, Grid, Icon, Modal, Typography, Warn} from 'components';
import {useMarel, useProjection, useUser} from 'hooks';
import createPersistedState from 'use-persisted-state';
import {modal, payloads} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 500
    },
    item: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const TitleWithIcon = () => (
    <>
        <Icon
            name="axa-icons-share-o"
            color="white"
            size={20}
        />{' '}
        Partager ma simulation
    </>
);

const useAgeIndex = createPersistedState('EP_ageIndex');

function ShareAdvisorModal({hash, ...rest}) {
    const {user, setUser} = useUser();
    const {marel} = useMarel();
    const {projection} = useProjection('retirement');
    const [ageIndex] = useAgeIndex(0);

    const classes = useStyles();

    const [error, setError] = useState('');

    if (!user.axa_profile || !user.axa_advisor) return null;

    const properties = payloads.shareSimulationProperties(user, marel, projection, ageIndex);

    return (
        <Modal
            hash={hash}
            className={classes.root}
            title={<TitleWithIcon />}
            keepMounted
            {...rest}
        >
            <Grid
                item
                xs={9}
            >
                <Typography
                    variant="h5"
                    color="blue"
                >
                    Voulez-vous partager votre simulation avec votre conseiller&nbsp;?
                </Typography>
            </Grid>

            <Grid
                container
                spacing={2}
            >
                <Grid
                    className={classes.item}
                    item
                    sm={6}
                    xs={12}
                >
                    <Form
                        submit={() =>
                            fastapi
                                .post('/services/mail-handler/share-simulation', {
                                    advisor_email: user.axa_advisor.email,
                                    properties: properties
                                })
                                .then(() => {
                                    setUser({...user, actions: user.actions ? user.actions + 1 : 1});
                                    modal.close(hash);
                                })
                                .catch((error) => setError(error.response.data.message))
                        }
                    >
                        <Buttons.Default
                            className={classes.button}
                            label="Oui"
                            color="blueAlpha"
                            next
                            submit
                        />
                    </Form>
                </Grid>
                <Grid
                    className={classes.item}
                    item
                    sm={6}
                    xs={12}
                >
                    <Buttons.Default
                        className={classes.button}
                        label="Non"
                        color="blueAlpha"
                        onClick={() => modal.close(hash)}
                        iconName="axa-icons-cross"
                    />
                </Grid>
            </Grid>

            <Warn
                message={error}
                if={error.length > 0}
                color="redLight"
            />
        </Modal>
    );
}

ShareAdvisorModal.propTypes = {
    hash: PropTypes.string.isRequired
};

export default ShareAdvisorModal;
