import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Typography} from 'components';
import {makeStyles} from '@material-ui/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {Box, Grid} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        background: 'inherit',
        border: 'none',
        cursor: 'pointer',
        overflow: 'visible',
        '&:hover&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 2,
            background: theme.palette.blue
        }
    },
    wrapper: {
        top: 74,
        background: 'transparent',
        position: 'absolute',
        left: 0,
        width: '100%',
        paddingTop: theme.spacing(2)
    },
    menuTooltip: {
        position: 'relative',
        background: 'white',
        boxShadow: '0px 4px 16px 0px #00008F33',
        borderRadius: 8,
        zIndex: 10,
        width: 'max-content',
        padding: theme.spacing(1, 1),
        '&': {
            width: '-moz-max-content'
        }
    },
    tooltip: {
        position: 'relative',
        background: theme.palette.white,
        zIndex: 100,
        transition: 'top 0.3s ease-out',
        '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: 10,
            top: -9,
            left: 'calc(25% - 50px)',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '0 7px 9px 7px',
            borderColor: 'transparent transparent white transparent'
        }
    },
    buttonExpand: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0px',
        '&:hover': {
            background: theme.palette.pale
        }
    },
    labelExpand: {
        lineHeight: 1,
        textAlign: 'left'
    },
    disabled: {
        cursor: 'not-allowed',
        userSelect: 'none',
        '& p': {
            color: `${theme.palette.gray} !important`
        },
        '&:hover': {
            background: 'white !important'
        }
    },
    chevron: {
        color: theme.palette.blue
    },
    grid: {
        display: 'flex',
        flexDirection: 'column'
    },
    ul: {listStyleType: 'none'}
}));

function NavButtonExpand({label, items}) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    return (
        <Grid
            name={label}
            className={classes.button}
            onMouseEnter={() => setOpen(true)}
            onMouseMove={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            onKeyDown={(e) => {
                e.target.tagName !== 'A' && e.key === 'Tab' && e.shiftKey && setOpen(false);
            }}
            container
        >
            <Box
                component={'button'}
                display={'flex'}
                alignItems={'center'}
                tabIndex={0}
                aria-expanded={open}
                sx={{border: '1px solid transparent'}}
                aria-label={label}
            >
                <Typography
                    size={18}
                    semibold
                    gutterBottom={false}
                    aria-describedby={label}
                    color={'blue'}
                    caps
                >
                    {label}
                </Typography>
                {open ? <ExpandLessIcon className={classes.chevron} /> : <ExpandMoreIcon className={classes.chevron} />}
            </Box>
            <div
                className={clsx({
                    [classes.wrapper]: true,
                    hide: !open
                })}
                id={label}
                onMouseEnter={() => setOpen(true)}
                onMouseMove={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                onFocus={() => setOpen(true)}
            >
                <ul className={clsx(classes.menuTooltip, classes.tooltip, classes.ul)}>
                    {items.map((item, index) =>
                        item.disabled ? (
                            <Grid
                                key={index}
                                item
                                component={'li'}
                            >
                                <div
                                    className={clsx(classes.buttonExpand, classes.disabled)}
                                    key={`navButtonExpand-${item.label}`}
                                    title="Fonctionnalité momentanément indisponible"
                                >
                                    <Typography
                                        className={classes.labelExpand}
                                        gutterBottom={false}
                                        size={16}
                                        color="blue"
                                    >
                                        {item.label}
                                    </Typography>
                                </div>
                            </Grid>
                        ) : (
                            <Grid
                                key={index}
                                item
                                component={'li'}
                            >
                                <Link
                                    className={classes.buttonExpand}
                                    key={`navButtonExpand-${item.label}`}
                                    to={item.url}
                                    onKeyDown={(e) => {
                                        e.key === 'Tab' && !e.shiftKey && index === items.length - 1 && setOpen(false);
                                    }}
                                    onClick={() => {
                                        if (item.action) item.action();
                                    }}
                                >
                                    {item.icon && item.icon}
                                    <Typography
                                        className={classes.labelExpand}
                                        semibold={window.location.pathname.startsWith(item.url)}
                                        gutterBottom={false}
                                        size={16}
                                        color={'blue'}
                                    >
                                        {item.label}
                                    </Typography>
                                </Link>
                            </Grid>
                        )
                    )}
                </ul>
            </div>
        </Grid>
    );
}

NavButtonExpand.propTypes = {
    label: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired
};

export default NavButtonExpand;
