import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import {Inputs, Typography} from 'components';
import {useForm} from 'hooks';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: 200,
        margin: 'auto'
    },
    label: {
        minWidth: 120,
        fontSize: 16,
        minHeight: 30,
        fontWeight: '600',
        borderRadius: 5
    }
}));

function ReversibleInputs() {
    const classes = useStyles();

    const {formState} = useForm();

    return (
        <div className={clsx(!formState.values.reversible && 'hidden')}>
            <Typography
                variant="h3"
                color="blue"
                semibold
                center
            >
                Indiquez la date de naissance de votre conjoint&middot;e (marié&middot;e ou pacsé&middot;e)
            </Typography>

            <div className={classes.wrapper}>
                <Inputs.Datepicker
                    name="birth_date_spouse"
                    maxDate={moment.utc().toDate()}
                />
            </div>

            <Typography
                variant="h3"
                color="blue"
                semibold
                center
            >
                Choisissez le pourcentage de réversion au profit de votre réversataire
                <br />
                (conjoint marié ou pacsé)
            </Typography>

            <Inputs.RadioButton
                name="reversion_percentage"
                options={[
                    {
                        label: '50%',
                        value: 50
                    },
                    {
                        label: '60%',
                        value: 60
                    },
                    {
                        label: '100%',
                        value: 100
                    }
                ]}
                center
                classNameLabel={classes.label}
            />
        </div>
    );
}

export default ReversibleInputs;
