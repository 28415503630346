import React, {useMemo} from 'react';
import {Inputs} from 'components';
import PropTypes from 'prop-types';

function reducer(format) {
    switch (format) {
        case 'money':
            return {
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                delimiter: ' '
            };
        case 'money-2':
            return {
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                delimiter: ' ',
                numeralDecimalMark: ',',
                numeralDecimalScale: 2
            };
        case 'nir':
            return {
                blocks: [1, 2, 2, 2, 3, 3]
            };
        case 'phone':
            return {
                blocks: [2, 2, 2, 2, 2]
            };
        case 'percentage':
            return {
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                delimiter: '',
                numeralDecimalMark: ',',
                numeralDecimalScale: 2
            };
        case 'iban':
            return {
                blocks: [4, 4, 4, 4, 4, 4, 4, 2]
            };
        default:
            return {};
    }
}

function Number(props) {
    const {format, variant, pattern ="[0-9 ,]*", ...rest} = props;

    const options = useMemo(() => reducer(format), [format]);

    const handleKeyDown = (event) => {
        if (event.ctrlKey || event.metaKey || event.altKey) return;

        if (format !== 'iban') {
            if (
                !RegExp(/^[\d, ]+$/).test(event.key) &&
                !['Backspace', 'Enter', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(event.key)
            ) {
                event.preventDefault();
            }
        }
    };

    return (
        <Inputs.Text
            onKeyDown={handleKeyDown}
            options={options}
            format={format}
            pattern={pattern}
            variant={variant}
            {...rest}
        />
    );
}

Number.propTypes = {
    format: PropTypes.oneOf([
        'money', // XX XXX
        'money-2', // XX XXX,XX
        'nir', // X XX XX XX XXX XXX
        'phone', // XX XX XX XX XX
        'percentage', // XXX,XX
        'iban'
    ])
};

export default Number;
