import React from 'react';
import {Buttons, Icon, Typography} from 'components';
import {makeStyles} from '@material-ui/styles';
import {sendTag} from 'store/Tags';
import {useUser} from 'hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(3),
        backgroundImage: ``,
        backgroundPosition: 'center right',
        backgroundSize: 'cover',
        backgroundColor: 'transparent'
    },
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 100
    },
    content: {
        maxWidth: 600,
        padding: 20,
        marginTop: 5,
        background: theme.palette.white
    },
    intro: {
        transition: 'background-color 0.2s ease-in'
    },
    flex: {
        display: 'flex'
    },
    padding: {
        padding: theme.spacing(0, 1)
    },
    padding2: {
        padding: theme.spacing(0, 2)
    }
}));

function StepIntro({step, setStep}) {
    const classes = useStyles();
    const {user} = useUser();

    return (
        <div className={classes.root}>
            <Typography
                variant="h3"
                color="blue"
                semibold
            >
                Ma Transmission 360
            </Typography>
            <div className={classes.content}>
                <div className="flex">
                    <Icon
                        className={classes.padding}
                        name="axa-icons-time-o"
                        color="blue"
                        size={50}
                        flex
                    />
                    <div>
                        <Typography
                            variant="h6"
                            className={classes.padding}
                        >
                            En moins de 5 minutes:
                        </Typography>
                        <ul className={classes.padding2}>
                            <li>
                                <Typography variant="h6">J’identifie qui héritera de mon patrimoine</Typography>
                            </li>
                            <li>
                                <Typography variant="h6">
                                    Je découvre dans quelle proportion mon patrimoine sera transmis
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="h6">
                                    AXA m’accompagne pour optimiser le cadre de ma succession
                                </Typography>
                            </li>
                        </ul>
                    </div>
                </div>
                <Buttons.Default
                    color="orange"
                    label="J’accède au simulateur"
                    next
                    onClick={() => {
                        setStep(step + 1);
                        sendTag(user, 'informations');
                    }}
                />
            </div>
        </div>
    );
}

export default StepIntro;
