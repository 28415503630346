import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    hidden: {
        height: '0 !important',
        margin: '0 !important',
        padding: '0 !important',
        opacity: '0 !important',
        position: 'absolute',
        bottom: 0
    }
}));

function Fade(props) {
    const {children} = props;
    const classes = useStyles();

    if (typeof props.if === 'boolean' && !props.if) return null;

    return (
        <div
            className={clsx({
                [classes.hidden]: !props.in
            })}
        >
            {children}
        </div>
    );
}

Fade.propTypes = {
    children: PropTypes.node.isRequired,
    in: PropTypes.bool,
    if: PropTypes.bool
};

export default Fade;
