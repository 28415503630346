import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Footer, Header} from 'layouts/_components';
import {Opinion} from 'macros';
import {useFilib, useRoboAdvisor, useUser} from 'hooks';
import {Desktop, Mobile} from './Header';
import {accounts, profileURL} from 'utils';
import {makeStyles} from '@material-ui/styles';
import modal from 'utils/modal';
import {useHistory} from 'react-router-dom';
import getRefundUrl from '../../utils/nav/getRefundUrl';
import getTransferUrl from '../../utils/nav/getTransferUrl';
import SkipLinks from '../_components/SkipLinks';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import {useTranslation} from 'react-i18next';
import {useMediaQuery, useTheme} from '@material-ui/core';

export const buildNav = (user, entities, history, filibUrl, t) => [
    {
        label: t('navigation.savings'),
        url: '/epargne'
    },
    {
        label: t('navigation.operations'),
        children: [
            {
                label: t('navigation.voluntary'),
                url: '/transactional/payment',
                icon: (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/navigation/credit_card.svg`}
                        alt={''}
                        style={{marginRight: '12px'}}
                    />
                )
            },
            {
                label: t('navigation.manageInvestments'),
                url: '/transactional/arbitration',
                icon: (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/navigation/move_down.svg`}
                        alt={''}
                        style={{marginRight: '12px'}}
                    />
                )
            },
            ...(user.scopes?.length > 0
                ? [
                      {
                          label: t('navigation.redemption'),
                          url: getRefundUrl(user?.scopes),
                          icon: (
                              <img
                                  src={`${process.env.PUBLIC_URL}/icons/navigation/downloading.svg`}
                                  alt={''}
                                  style={{marginRight: '12px'}}
                              />
                          )
                      }
                  ]
                : []),
            ...(user.scopes?.length > 0
                ? [
                      {
                          label: t('navigation.transfer'),
                          url: getTransferUrl(user?.scopes),
                          icon: (
                              <img
                                  src={`${process.env.PUBLIC_URL}/icons/navigation/currency_exchange-euro.svg`}
                                  alt={''}
                                  style={{marginRight: '12px'}}
                              />
                          )
                      }
                  ]
                : []),
            {
                label: t('navigation.transactions'),
                url: '/operations',
                icon: (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/navigation/list_alt.svg`}
                        alt={''}
                        style={{marginRight: '12px'}}
                    />
                )
            },
            {
                label: t('navigation.AXAContractLink'),
                url: '/connected-attachment/axa-attachment',
                icon: <SettingsOutlinedIcon style={{color: '#00008f', marginRight: 12}} />
            },
            {
                label: t('navigation.AXAIndividualLink'),
                url: '/attachment/mosaic',
                icon: <SettingsOutlinedIcon style={{color: '#00008f', marginRight: 12}} />
            },
            {
                label: t('navigation.nonAXAContractLink'),
                url: '/aggregation',
                icon: <SettingsOutlinedIcon style={{color: '#00008f', marginRight: 12}} />
            }
        ]
    },
    {
        label: t('navigation.simulations'),
        children: [
            ...(accounts.filter(user.accounts, 'ais', ['PER', 'PERO', 'PERECO', 'PERU', 'A83', 'A82']).length > 0
                ? [
                      {
                          label: t('navigation.AXARetirement'),
                          url: '/simulator/mrm',
                          icon: (
                              <img
                                  src={`${process.env.PUBLIC_URL}/icons/navigation/tune.svg`}
                                  alt={''}
                                  style={{marginRight: '12px'}}
                              />
                          )
                      }
                  ]
                : []),
            {
                label: t('navigation.marel'),
                url: '/epargne#marel',
                action: () => {
                    history.push('/epargne');
                    modal.open('marel');
                },
                icon: (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/navigation/tune.svg`}
                        alt={''}
                        style={{marginRight: '12px'}}
                    />
                )
            },
            {
                label: t('navigation.taxSavings'),
                url: '/simulateur/impots',
                icon: (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/navigation/tune.svg`}
                        alt={''}
                        style={{marginRight: '12px'}}
                    />
                )
            }
        ]
    },
    {
        label: t('navigation.help'),
        children: [
            ...(filibUrl
                ? [
                      {
                          label: t('navigation.filib'),
                          url: '/filib',
                          icon: (
                              <img
                                  src={`${process.env.PUBLIC_URL}/icons/navigation/person_pin.svg`}
                                  alt={''}
                                  style={{marginRight: '12px'}}
                              />
                          )
                      }
                  ]
                : []),
            ...(entities?.length > 0
                ? [
                      {
                          label: t('navigation.roboAdvisor'),
                          url: '/placements/advisor',
                          icon: (
                              <img
                                  src={`${process.env.PUBLIC_URL}/icons/navigation/reviews.svg`}
                                  alt={''}
                                  style={{marginRight: '12px'}}
                              />
                          )
                      }
                  ]
                : []),
            {
                label: t('navigation.contactAXA'),
                url: profileURL({journey: 'contact'}),
                icon: (
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/navigation/help_outline.svg`}
                        alt={''}
                        style={{marginRight: '12px'}}
                    />
                )
            }
        ]
    },
    {
        label: t('navigation.documents'),
        url: '/documents'
    }
];

const useStyles = makeStyles(() => ({
    collective: {
        backgroundColor: '#FAFAFA'
    }
}));

function Main({children, breadcrumb, background, disableHeader, disableFooter}) {
    const classes = useStyles();
    const {user} = useUser();
    const {entities} = useRoboAdvisor();
    const history = useHistory();
    const {filibUrl} = useFilib();
    const {t} = useTranslation();
    const nav = buildNav(user, entities, history, filibUrl, t);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [skipLinksFocus, setSkipLinksFocus] = useState(false);

    const skipLinksBox = document.getElementById('skip-links-box');
    const mainContent = document.getElementById('main-content');

    useEffect(() => {
        const events = ['focusin', 'focusout'];

        const handleSkipLinkFocus = (event) => {
            if (event.target.tagName === 'A') {
                setSkipLinksFocus(event.type === 'focusin');
            }
        };

        if (skipLinksBox) {
            events.forEach((eventType) => {
                skipLinksBox.addEventListener(eventType, handleSkipLinkFocus);
            });
        }

        return () => {
            if (skipLinksBox) {
                events.forEach((eventType) => {
                    skipLinksBox.removeEventListener(eventType, handleSkipLinkFocus);
                });
            }
        };
    }, [skipLinksBox]);

    const dynamicTop = skipLinksFocus && isDesktop ? '37px' : skipLinksFocus && isMobile ? '61px' : 0;
    const dynamicMarginTop = skipLinksFocus && isDesktop ? '100px' : skipLinksFocus && isMobile ? '120px' : '0px';

    return (
        <>
            {user.origin === 'collective' && !disableHeader && mainContent && <SkipLinks />}
            <div
                className="full-height"
                style={{background: background, marginTop: dynamicMarginTop}}
            >
                {!disableHeader && (
                    <Header
                        dynamicTop={dynamicTop}
                        desktop={() => <Desktop nav={nav} />}
                        mobile={() => <Mobile nav={nav} />}
                        setHeaderHeight={setHeaderHeight}
                        breadcrumb={breadcrumb}
                    />
                )}
                <main
                    id={'main-content'}
                    style={{marginTop: disableHeader ? 0 : headerHeight}}
                >
                    <div
                        style={{
                            minHeight: `calc(100vh - ${
                                (disableHeader ? 0 : headerHeight) + (disableFooter ? 0 : footerHeight)
                            }px)`
                        }}
                        className={clsx(
                            user.origin === 'collective' &&
                                window.location.pathname === '/epargne' &&
                                classes.collective
                        )}
                    >
                        {children}
                    </div>
                </main>
                {!disableFooter && (
                    <Footer
                        id="footer"
                        setFooterHeight={setFooterHeight}
                    />
                )}
                <Opinion />
            </div>
        </>
    );
}

Main.propTypes = {
    children: PropTypes.node.isRequired,
    breadcrumb: PropTypes.object,
    background: PropTypes.string,
    disableHeader: PropTypes.bool,
    disableFooter: PropTypes.bool
};

export default Main;
