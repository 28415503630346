import React, {useEffect} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import validate from 'validate.js';
import PropTypes from 'prop-types';
import {useForm, useLoading} from 'hooks';
import {FormProvider} from 'store/Form';
import {events} from 'utils';

// We need to add parse / format function to datetime validator
validate.extend(validate.validators.datetime, {
    parse: (value) => +moment.utc(value),
    format: (value) => moment.utc(value).format('YYYY-MM-DD')
});

function FormContent({children, className, schema, submit, dynamic, variant, name, debug, ...rest}) {
    const {formState, setFormState} = useForm();
    const {setLoading} = useLoading();

    useEffect(() => {
        const errors = validate(formState.values, schema instanceof Function ? schema(formState) : schema, {
            fullMessages: false
        });

        setFormState({
            ...formState,
            isValid: !errors,
            errors: errors || {}
        });

        events.resize();

        if (dynamic) submit instanceof Function && submit(formState, setFormState);

        // eslint-disable-next-line
    }, [formState.values]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (dynamic) return;

        setFormState({
            ...formState,
            errors: {}
        });

        if (formState.isValid && submit instanceof Function) {
            setLoading(true);
            submit(formState, setFormState)
                .catch((error) => {
                    throw error;
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={clsx(className, variant)}
            {...rest}
        >
            {children}
            {debug && <pre>{JSON.stringify(formState, null, 4)}</pre>}
        </form>
    );
}

FormContent.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    schema: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    submit: PropTypes.func,
    dynamic: PropTypes.bool, // dynamic Form submit on change
    variant: PropTypes.oneOf(['white', 'blue', 'transparent'])
};

function Form({children, values, step, setStep, ...rest}) {
    // ⚠️ never use step and setStep, dynamically set by Stepper

    return (
        <FormProvider values={values}>
            <FormContent {...rest}>{children}</FormContent>
        </FormProvider>
    );
}

Form.propTypes = {
    children: PropTypes.node.isRequired,
    values: PropTypes.object,
    debug: PropTypes.bool
};

export default Form;
