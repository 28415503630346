import React from 'react';
import theme from 'theme';

import {IsMockedProvider} from './store/IsMocked';
import {LoadingProvider} from './store/Loading';
import {HighlightProvider} from './store/Highlight';
import {ErrorProvider} from './store/Error';
import {UserProvider} from './store/User';
import {B2VProvider} from './store/B2V';
import {MarelProvider} from './store/Marel';
import {ProjectionsProvider} from './store/Projections';
import {FilibProvider} from './store/Filib';
import {TransmissionProvider} from './store/Transmission';
import {RoboAdvisorProvider} from './store/RoboAdvisor';
import {SettingsProvider} from './store/Settings';

import {ThemeProvider} from '@material-ui/styles';
import {OperationProvider} from './store/Operation';

const Providers = ({children}) => (
    <IsMockedProvider>
        <LoadingProvider>
            <HighlightProvider>
                <ErrorProvider>
                    <UserProvider>
                        <FilibProvider>
                            <B2VProvider>
                                <MarelProvider>
                                    <TransmissionProvider>
                                        <ProjectionsProvider>
                                            <RoboAdvisorProvider>
                                                <SettingsProvider>
                                                    <OperationProvider>
                                                        <ThemeProvider theme={theme}>{children}</ThemeProvider>
                                                    </OperationProvider>
                                                </SettingsProvider>
                                            </RoboAdvisorProvider>
                                        </ProjectionsProvider>
                                    </TransmissionProvider>
                                </MarelProvider>
                            </B2VProvider>
                        </FilibProvider>
                    </UserProvider>
                </ErrorProvider>
            </HighlightProvider>
        </LoadingProvider>
    </IsMockedProvider>
);

export default Providers;
