import React, {cloneElement} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Icon} from 'components/index';

import {makeStyles} from '@material-ui/styles';
import {Trans, useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'relative',
        display: 'block',
        minWidth: '14rem',
        width: '100%',
        maxWidth: '16rem',
        margin: theme.spacing(1, 0),
        padding: 12,
        border: '1px solid transparent',
        color: theme.palette.white,
        userSelect: 'none',
        cursor: 'pointer',
        transition:
            'color .5s cubic-bezier(0, 0.99, 0.865, 1.005), background-color .6s cubic-bezier(0, 0.99, 0.865, 1.005)',
        textAlign: 'left'
    },

    blue: {
        backgroundColor: theme.palette.blue,
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.blueDark
        }
    },
    transparent_border_blue: {
        minWidth: '141px',
        maxWidth: '141px',
        borderRadius: '4px',
        color: theme.palette.blue,
        backgroundColor: theme.palette.transparent,
        border: `1px solid ${theme.palette.blue}`,
        textAlign: 'center',
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.blueDark,
            color: 'white'
        }
    },
    white_border_blue: {
        backgroundColor: theme.palette.white,
        border: `1px solid ${theme.palette.blue}`,
        color: theme.palette.blue,
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.blueDark,
            color: theme.palette.white,
            border: `1px solid ${theme.palette.blueDark}`
        }
    },
    wrapper: {
        width: '100%',
        display: 'inherit'
    },
    right: {
        textAlign: 'right'
    },
    centerText: {
        textAlign: 'center'
    },
    center: {
        margin: `${theme.spacing(1)}px auto`
    },
    icon: {
        '&::before': {
            position: 'absolute',
            right: 14,
            fontSize: 16,
            top: '50%',
            transform: 'translateY(-50%)'
        }
    },
    muiIcon: {
        padding: 0,
        margin: 0,
        position: 'absolute',
        right: 12,
        top: 'calc(50%)',
        transform: 'translateY(-50%)'
    },
    alignLeft: {
        '&::before': {
            left: 16,
            right: 'unset'
        }
    },
    label: {
        letterSpacing: '0.22px',
        fontWeight: '600',
        whiteSpace: 'nowrap'
    }
}));

function Link(props) {
    const {
        href,
        label,
        className,
        classLabel,
        color,
        icon,
        iconName,
        center,
        previous,
        next,
        right,
        centerText,
        role,
        title,
        ariaLabel,
        ...rest
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <a
            href={href}
            className={clsx({
                [classes.button]: true,
                [classes[color]]: true,
                [classes.center]: center,
                [className]: className,
                previous: previous,
                next: next
            })}
            tabIndex="0"
            title={t(title ? title : label)}
            aria-label={t(ariaLabel ? ariaLabel : label)}
            {...rest}
        >
            <span
                className={clsx({
                    [classes.wrapper]: true,
                    [classes.right]: right || previous,
                    [classes.centerText]: centerText
                })}
            >
                <span className={clsx(classes.label, classLabel && classLabel)}>
                    <Trans i18nKey={label} />
                </span>
                {iconName || next || previous ? (
                    <Icon
                        className={clsx({
                            [classes.icon]: true,
                            [classes.alignLeft]: right || previous
                        })}
                        name={
                            iconName
                                ? iconName
                                : next
                                ? 'axa-icons-arrow-forward'
                                : previous
                                ? 'axa-icons-arrow-back'
                                : ''
                        }
                    />
                ) : (
                    icon && cloneElement(icon, {className: classes.muiIcon})
                )}
            </span>
        </a>
    );
}

Link.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    classLabel: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.node,
    iconName: PropTypes.string,
    center: PropTypes.bool,
    right: PropTypes.bool,
    centerText: PropTypes.bool,
    previous: PropTypes.bool,
    next: PropTypes.bool
};

export default Link;
