import React, {useState} from 'react';
import {Backdrop, Portal} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        zIndex: 10000
    }
}));

export const HighlightContext = React.createContext({
    highlight: null,
    setHighlight: () => {}
});

export const HighlightProvider = (props) => {
    const classes = useStyles();

    const setHighlight = (elementId, highlight) => {
        if (elementId) setState({...state, highlight: highlight, elementId: elementId});
        else setState({...state, highlight: highlight});

        const element = document.getElementById(elementId || state.elementId);
        if (highlight) {
            element.style.position = 'relative';
            element.style.zIndex = 10000000;
            if (element.closest('.slick-list'))
                setTimeout(() => (element.closest('.slick-list').style.transform = 'none'), 0);
            if (element.closest('.slick-track'))
                setTimeout(() => (element.closest('.slick-track').style.transform = 'none'), 0);
        } else {
            element.style.position = 'unset';
            element.style.zIndex = 'unset';
            const track = element.closest('.slick-track');
            if (track) track.style.left = '0px';

            if (typeof window.history.replaceState == 'function') {
                window.history.replaceState(null, null, window.location.pathname);
            }
        }
    };

    const initState = {
        highlight: false,
        elementId: null,
        setHighlight: setHighlight
    };

    const [state, setState] = useState(initState);

    return (
        <HighlightContext.Provider value={state}>
            {props.children}
            <Portal>
                <Backdrop
                    className={classes.root}
                    open={state.highlight}
                />
            </Portal>
        </HighlightContext.Provider>
    );
};
