import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Typography} from 'components';
import {useForm} from 'hooks';

import {withStyles} from '@material-ui/core/styles';
import {FormControlLabel, Switch as MuiSwitch} from '@material-ui/core';

export const AXASwitch = withStyles((theme) => ({
    root: {
        width: 50,
        height: 26,
        padding: 0,
        margin: theme.spacing(1)
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(24px)',
            color: theme.palette.white,
            '& + $track': {
                backgroundColor: theme.palette.blue,
                opacity: 1,
                border: 'none'
            }
        },
        '&$focusVisible $thumb': {
            color: theme.palette.blue,
            border: '6px solid #fff'
        }
    },
    thumb: {
        width: 24,
        height: 24
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grayLight}`,
        backgroundColor: theme.palette.pale,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border'])
    },
    checked: {},
    focusVisible: {}
}))(({classes, ...props}) => {
    return (
        <MuiSwitch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
            }}
            {...props}
        />
    );
});

function Switch(props) {
    const {name, label, defaultValue, ...rest} = props;

    const {formState, setFormState} = useForm();

    const handleChange = (event) => {
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [name]: event.target.checked
            },
            touched: {
                ...formState.touched,
                [name]: true
            }
        });
    };

    const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

    useEffect(() => {
        if (name) {
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    [name]: defaultValue
                },
                touched: {
                    ...formState.touched,
                    [name]: true
                }
            });
        }

        // eslint-disable-next-line
    }, [defaultValue]);

    return (
        <div>
            <FormControlLabel
                control={
                    <AXASwitch
                        checked={formState.values[name] || defaultValue || false}
                        onChange={handleChange}
                        name="checked"
                        {...rest}
                    />
                }
                label={
                    <Typography
                        color="blue"
                        gutterBottom={false}
                        variant="h5"
                    >
                        {label}
                    </Typography>
                }
            />
            <Typography
                className="error-message"
                color="redLight"
            >
                {hasError(name) ? formState.errors[name][0] : null}
            </Typography>
        </div>
    );
}

Switch.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    defaultValue: PropTypes.bool
};

export default Switch;
