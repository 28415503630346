import React from 'react';
import {Buttons, Form, Inputs, Typography} from 'components';
import {useTaxSimulator} from 'hooks';

const SubStepCurrentVV = ({setStep}) => {
    const {taxSimulator, setTaxSimulator} = useTaxSimulator();

    return (
        <Form
            schema={{
                current_vv: {
                    presence: {
                        allowEmpty: false,
                        message: `Veuillez renseigner vos versements en ${new Date().getFullYear()}`
                    },
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message: 'Vos versements doivent être positifs'
                    }
                }
            }}
            values={{
                current_vv: 0
            }}
            submit={(formState) => {
                setTaxSimulator({
                    ...taxSimulator,
                    inputs: {
                        ...taxSimulator.inputs,
                        ...formState.values
                    }
                });
                setStep(5);
                return new Promise((resolve) => resolve());
            }}
        >
            <Typography
                variant="h5"
                color="blue"
            >
                Avez-vous effectué en {new Date().getFullYear()} un versement sur un contrat retraite supplémentaire, un
                PERP, un Madelin ou un PER&nbsp;?
            </Typography>
            <Inputs.Number
                name="current_vv"
                format="money"
                adornment="€"
                right
            />
            <Buttons.Default
                label="Je valide !"
                color="orange"
                next
                center
                submit
            />
        </Form>
    );
};

export default SubStepCurrentVV;
