import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {Typography} from 'components/index';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {makeStyles} from '@material-ui/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'unset !important'
    },
    button: {
        background: 'inherit',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        '&:hover p, &:focus p': {
            textDecoration: 'underline',
            color: '#00005D'
        }
    },
    disabled: {
        cursor: 'initial !important',
        '& p': {
            color: `${theme.palette.grayDark} !important`
        },
        '&:before': {
            color: `${theme.palette.grayDark} !important`
        },
        '&:hover p, &:focus p': {
            textDecoration: 'initial !important'
        }
    },
    label: {
        margin: 0,
        paddingLeft: 3,
        textAlign: 'left',
        letterSpacing: '0.22px'
    },
    back: {
        color: theme.palette.scorpion,
        border: 'solid 2px #5F5F5F',
        borderRadius: '50%',
        marginRight: theme.spacing(1)
    },
    next: {
        marginLeft: theme.spacing(0.5)
    },
    white: {
        color: theme.palette.white
    },
    blue: {
        color: theme.palette.blue
    },
    redLight: {
        color: theme.palette.redLight
    },
    blueLight: {
        color: theme.palette.blueLight
    },
    blueAlpha: {
        color: theme.palette.blueAlpha
    },
    grayDark: {
        color: theme.palette.grayDark
    },
    orange: {
        color: theme.palette.orange
    },
    scorpion: {
        color: theme.palette.scorpion
    }
}));

function Chevron(props) {
    const {
        label,
        color,
        center,
        right,
        next,
        back,
        submit,
        noIcon,
        className,
        classLabel,
        disabled,
        ariaLabel,
        ...rest
    } = props;
    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <Box
            className={clsx(classes.container, className)}
            justifyContent={center ? 'center' : right && 'flex-end'}
        >
            <button
                className={clsx(classes.button, disabled && classes.disabled)}
                type={submit ? 'submit' : 'button'}
                disabled={disabled}
                tabIndex="0"
                aria-label={t(ariaLabel ? ariaLabel : label)}
                {...rest}
            >
                <Box
                    display="flex"
                    alignItems="center"
                >
                    {back && (
                        <ArrowBackIcon
                            fontSize={'medium'}
                            className={clsx(classes.back, disabled && classes.disabled)}
                        />
                    )}
                    {!next && !back && !noIcon && (
                        <ChevronRightIcon
                            fontSize={'medium'}
                            className={clsx(disabled && classes.disabled, color && classes[color])}
                        />
                    )}
                    <Typography
                        className={clsx(classes.label, classLabel)}
                        semibold
                        color={color}
                        gutterBottom={false}
                    >
                        {t(label)}
                    </Typography>
                    {next && (
                        <ChevronRightIcon
                            fontSize={'medium'}
                            className={clsx(
                                disabled && classes.disabled,
                                next && classes.next,
                                color && classes[color]
                            )}
                        />
                    )}
                </Box>
            </button>
        </Box>
    );
}

Chevron.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    center: PropTypes.bool,
    right: PropTypes.bool,
    submit: PropTypes.bool,
    disabled: PropTypes.bool
};

export default Chevron;
