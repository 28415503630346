import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Icon, Structure, Typography} from 'components';

import {Breadcrumbs, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#f0f0f0',
        height: 30,
        zIndex: -10000
    },
    breadcrumbs: {
        width: '100%',
        '& ol': {
            flexWrap: 'nowrap'
        },
        '& li:last-child': {
            width: '100%'
        }
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap'
    },
    icon: {
        '&:before': {
            verticalAlign: 'middle'
        }
    },
    link: {
        color: theme.palette.black,
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    label: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: 'calc(100% - 20px)',
        display: 'block',
        overflow: 'hidden'
    }
}));

function Breadcrumb({parentLabel, parentHref, childLabel, childHref, subChildLabel}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Structure.Center className={classes.wrapper}>
                <Hidden smDown>
                    <Typography
                        color="black"
                        gutterBottom={false}
                    >
                        Je suis ici :&nbsp;&nbsp;
                    </Typography>
                </Hidden>
                <Breadcrumbs
                    className={classes.breadcrumbs}
                    separator={
                        <Icon
                            name="axa-icons-arrow-right"
                            className={classes.icon}
                            color="black"
                            size={10}
                        />
                    }
                >
                    <Link
                        className={classes.link}
                        color="inherit"
                        to={parentHref}
                    >
                        {parentLabel}
                    </Link>
                    {!subChildLabel && (
                        <Typography
                            id="breadcrumbChildLabel"
                            className={classes.label}
                            color="black"
                            semibold
                            gutterBottom={false}
                        >
                            {childLabel}
                        </Typography>
                    )}
                    {subChildLabel && (
                        <Link
                            className={classes.link}
                            color="inherit"
                            to={childHref}
                        >
                            {childLabel}
                        </Link>
                    )}
                    {subChildLabel && (
                        <Typography
                            className={classes.label}
                            color="black"
                            semibold
                            gutterBottom={false}
                        >
                            {subChildLabel}
                        </Typography>
                    )}
                </Breadcrumbs>
            </Structure.Center>
        </div>
    );
}

Breadcrumb.propTypes = {
    parentLabel: PropTypes.string.isRequired,
    parentHref: PropTypes.string.isRequired,
    childLabel: PropTypes.string.isRequired,
    childHref: PropTypes.string,
    subChildLabel: PropTypes.string
};

export default Breadcrumb;
