import {useContext, useEffect} from 'react';
import {StepperContext} from 'store/Stepper';
import {useForm} from 'hooks';

function useStepper() {
    return useContext(StepperContext);
}

export const useResetKey = (key, deps) => {
    const {stepper, setStepper} = useStepper();
    const {formState, setFormState} = useForm();
    useEffect(() => {
        let values = formState.values;
        delete values[key];
        let payload = stepper.payload;
        delete payload[key];
        setTimeout(() => {
            setFormState({...formState, values, isValid: false});
            setStepper({...stepper, payload});
        }, 0);
        // eslint-disable-next-line
    }, [deps].flat());
};

export default useStepper;
