import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from 'components';
import {DistributionTable} from '../index';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .error-message': {
            display: 'none'
        }
    },
    spaceLeft: {
        paddingLeft: 20
    }
}));

function UnlockingDistribution({account, index}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography
                variant="h5"
                semibold
                gutterBottom={false}
            >
                {account.title}

                <Typography
                    className={classes.spaceLeft}
                    component="span"
                    color="grayDark"
                    size={13}
                    gutterBottom={false}
                >
                    N° de contrat&nbsp;{account.policy_id}
                </Typography>
            </Typography>

            <DistributionTable
                index={index}
                account={account}
                redemption
            />
        </div>
    );
}

UnlockingDistribution.propTypes = {
    account: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

export default UnlockingDistribution;
