function close(hash) {
    if (window.location.hash.split('#').includes(hash)) {
        window.location.hash = window.location.hash
            .split('#')
            .filter((part) => part !== hash)
            .join('#');

        setTimeout(() => {
            if (window.location.hash.split('#').includes(hash))
                window.history.back()
        }, 0);
    }
}

export default close;
