import React from 'react';
import {Buttons, Modal, Typography, Warn} from 'components';
import {modal} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 500
    }
}));

function ForbiddenMirrorModal() {
    const classes = useStyles();

    return (
        <Modal
            hash="forbidden_mirror"
            className={classes.root}
            title="Ressource protégée"
            icon={
                <Warn
                    message=""
                    color="white"
                />
            }
            disableBackdropClick
            disableEscapeKeyDown
        >
            <Typography>Vous ne pouvez effectuer cette action en vision miroir.</Typography>

            <Buttons.Default
                label="Retour"
                color="gray"
                center
                next
                onClick={() => modal.close('forbidden_mirror')}
            />
        </Modal>
    );
}

export default ForbiddenMirrorModal;
