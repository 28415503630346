import React from 'react';
import PropTypes from 'prop-types';

function Main({children}) {
    return (
        <div className="full-height">
            <main>{children}</main>
        </div>
    );
}

Main.propTypes = {
    children: PropTypes.node.isRequired
};

export default Main;
