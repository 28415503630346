import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {Tab as MuiTab, Tabs as MuiTabs} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 0, 2),
        maxWidth: '100% !important',
        '& > div > span:last-child': {
            background: theme.palette.blue
        },
        '& .MuiTabs-indicator': {
            height: 5
        }
    },
    tab: {
        textTransform: 'none',
        color: theme.palette.black,
        maxWidth: '50%',
        borderBottom: `solid 2px ${theme.palette.gray}`,
        '& > span': {
            fontSize: 18,
            '&:focus, &:focus-visible': {
                outline: `solid 2px ${theme.palette.blue}`
            }
        },
        '&.Mui-selected': {
            color: theme.palette.blue
        },
        '&.Mui-focusVisible': {
            border: `solid 2px ${theme.palette.blue}`
        }
    }
}));

function Tabs({labels, tab, setTab, className, ...rest}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const handleChange = (event, value) => setTab(value);

    return (
        <MuiTabs
            className={clsx(classes.root, className)}
            value={tab}
            onChange={handleChange}
            {...rest}
        >
            {labels.map((label) => (
                <MuiTab
                    disableFocusRipple
                    disableRipple
                    tabIndex={0}
                    key={`tab-${t(label)}`}
                    id={`tab-${t(label)}`}
                    className={classes.tab}
                    label={t(label)}
                    aria-controls={t(label)}
                />
            ))}
        </MuiTabs>
    );
}

Tabs.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    tab: PropTypes.number.isRequired,
    setTab: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default Tabs;
