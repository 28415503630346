import React from 'react';
import {Info, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    list: {
        '& li': {
            marginLeft: 30,
            display: 'list-item'
        }
    }
}));

const InfoProjection = () => {
    const classes = useStyles();

    return (
        <Info
            hash="disclaimer_retirement_computation"
            title="Modalités de calcul"
            color="blue"
        >
            Ce montant correspond à votre retraite de base augmentée de la rente issue de vos dispositifs de retraite
            supplémentaire. Retrouvez le détail de ces dispositifs dans l'onglet "Epargne éligible".
            <br />
            <br />
            Le calcul de votre simulation retraite est estimé sur la base :
            <br />
            <br />
            <ul className={classes.list}>
                <li>
                    Des données affichées sur votre tableau de bord (Article 82, Article 83, PERCO, PER, PERP, Madelin,
                    Régime de base et complémentaires, à l'exception du PEE et de l'Assurance Vie).
                </li>
                <li>Du choix de votre âge de départ.</li>
                <li>
                    Des cotisations et primes périodiques obligatoires projetées jusqu’à votre retraite (uniquement pour
                    un Plan d'Épargne Retraite d'entreprise, Art 83, Perp/Madelin à primes périodiques géré par AXA).
                </li>
                <li>De vos versements volontaires simulés.</li>
                <li>D’un rendement net de frais de 2%.</li>
            </ul>
            <br />
            <Typography>
                <Typography
                    component="span"
                    semibold
                >
                    Le calcul de votre future retraite est une approximation car elle ne peut pas prendre en compte
                    toutes les spécificités de votre parcours. Simulation à caractère indicatif&nbsp;
                </Typography>
                au regard des informations soumises, provisoire et non contractuelle. Le résultat de simulation délivrée
                n'engage pas les régimes de retraite (Art D161-2-4 et D161-2-1-7 du code de la sécurité sociale). Rente
                non réversible payable mensuellement à terme échu déterminée avec les tables de mortalité par génération
                et par sexe (TGH05 et TGF05). Frais d'arrérages : 1%. Taux technique : 0%. L'assurance retraite AXA est
                régie par le Code des Assurances.
            </Typography>
        </Info>
    );
};

export default InfoProjection;
