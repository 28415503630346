export default {
    black: '#000000',
    white: '#ffffff',
    whiteGauge: '#F0F0F0',
    pale: '#F8F8FF',
    headerPale: '#F1F1F1',
    alpha: 'rgba(0, 0, 0, 0.65)',
    whiteAlpha: 'rgba(255, 255, 255, 0.75)',
    transparent: 'rgba(0, 0, 0, 0)',
    blue: '#00008f',
    blueDark: '#00005D',
    blueLight: '#3b3fd6',
    indigo: '#494df4',
    cyan: '#1eb3bf',
    turquoise: '#24daac',
    orangeDark: '#ec4c0e',
    orangeRed: '#C84A02',
    crimson: '#A03B02',
    orange: '#b05000',
    orangeLight: '#fa5d03',
    orangeWarning: '#e65100',
    yellow: '#ffC107',
    green: '#118635',
    greenLight: '#4DA467',
    greenDark: '#0e6b2a',
    red: '#9b0f26',
    redLight: '#ff2e51',
    errorRed: '#C91432',
    pinkDark: '#B47888',
    pink: '#df8ea5',
    pinkPale: '#FDF6F7',
    purpleDark: '#8c8cd3',
    purple: '#cecdf1',
    purpleLight: '#f2f2fa',
    purpleAlpha: '#11042fb3',
    dark: '#333',
    grayDark: '#666',
    silver: '#CCCCCC',
    scorpion: '#5F5F5F',
    gray: '#bdbdbd',
    grayLight: '#d7d7d7',
    grayLightBO: '#999999', //TODO change name without feature specification
    graySuperLight: '#eeeeee',
    whiteSmoke: '#f5f5f5',
    veryLightGray: '#fafafa',
    mercury: '#E5E5E5',
    turquoiseDark: '#1d8a9e',
    warningDark: '#C94E14',
    kerialis: {
        light: '#85CCDA',
        main: '#018b9c',
        dark: '#015e69'
    },

    primary: {
        main: '#00008f'
    },

    text: {
        primary: '#333333'
    },
    background: {
        main: '#ffffff'
    }
};
