import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Typography} from 'components';
import {useForm} from 'hooks';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        margin: theme.spacing(1, 1)
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    right: {
        textAlignLast: 'right',
        '& select': {
            paddingRight: 36
        }
    },
    select: {
        width: '100%',
        height: 36,
        padding: theme.spacing(0, 3, 0, 1),
        marginRight: '6px',
        background: '#ededed',
        color: `${theme.palette.dark} !important`,
        outline: 'none',
        border: 'none',
        borderRadius: '0.25em',
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        backgroundPosition: 'center right 8px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 20,
        backgroundImage: `url(${process.env.PUBLIC_URL}/icons/arrow-down.svg)`,
        '&:focus': {
            backgroundImage: `url(${process.env.PUBLIC_URL}/icons/arrow-up.svg)`
        },
        '&:focus-visible': {
            border: '1px solid'
        }
    },
    required: {
        marginLeft: theme.spacing(0.5)
    },
    blue: {
        border: `solid 1px ${theme.palette.blue} !important`
    },
    rounded: {
        fontSize: '16px',
        background: theme.palette.pale,
        color: `${theme.palette.blue} !important`,
        borderRadius: '100px',
        backgroundSize: 24,
        padding: 16,
        paddingRight: 28,
        height: 'initial',
        border: '1px solid transparent',
        backgroundPosition: 'center right 8px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${process.env.PUBLIC_URL}/icons/keyboard_arrow_down.svg)`,
        '&:focus': {
            backgroundImage: `url(${process.env.PUBLIC_URL}/icons/keyboard_arrow_up.svg)`
        },
        '&:focus-visible': {
            border: '1px solid'
        }
    }
}));

function Select(props) {
    const {
        name,
        options,
        optgroup,
        className,
        label,
        labelColor,
        disableSpacing,
        center,
        right,
        isRequired,
        variant,
        ...rest
    } = props;
    const classes = useStyles();
    const {formState, setFormState} = useForm();

    const handleChange = (event) => {
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [name]: true
            }
        });
    };

    const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

    return (
        <div
            className={clsx({
                [classes.root]: true,
                'margin-0': disableSpacing,
                [classes.center]: center,
                [classes.right]: right
            })}
        >
            {label && (
                <Typography
                    color={labelColor}
                    variant="caption"
                >
                    {label}
                    {isRequired && (
                        <Typography
                            component="span"
                            color="errorRed"
                            semibold
                            className={classes.required}
                        >
                            *
                        </Typography>
                    )}
                </Typography>
            )}
            <select
                className={clsx(classes.select, className, variant && classes[variant])}
                onChange={handleChange}
                aria-required={isRequired}
                value={formState.values[name] || ''}
                {...rest}
            >
                {options.length > 0 &&
                    (optgroup
                        ? options.map((optgroup, index) =>
                              optgroup.options ? (
                                  <optgroup
                                      key={`optgroup-${index}`}
                                      label={optgroup.subcategory}
                                  >
                                      {optgroup.options.map((option, index) => (
                                          <option
                                              key={`option-${index}`}
                                              value={option.value}
                                              disabled={option.disabled}
                                          >
                                              {option.label}
                                          </option>
                                      ))}
                                  </optgroup>
                              ) : (
                                  <option
                                      key={`option-${index}`}
                                      value={optgroup.value}
                                      disabled={optgroup.disabled}
                                  >
                                      {optgroup.label}
                                  </option>
                              )
                          )
                        : options.map((option, index) => (
                              <option
                                  key={`option-${index}`}
                                  value={option.value}
                                  disabled={option.disabled}
                                  selected={formState.values[name] === option.value}
                              >
                                  {option.label}
                              </option>
                          )))}
            </select>
            {hasError(name) && (
                <Typography
                    className="error-message"
                    color="redLight"
                    gutturBottom={false}
                >
                    {hasError(name) ? formState.errors[name][0] : null}
                </Typography>
            )}
        </div>
    );
}

Select.propTypes = {
    // options is [{value: '', label: ''}, ...] or {optgroup: [{value: '', label: ''}, ...], ...}
    options: PropTypes.array.isRequired,
    optgroup: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.node,
    labelColor: PropTypes.string,
    name: PropTypes.string,
    disableSpacing: PropTypes.bool,
    center: PropTypes.bool,
    right: PropTypes.bool,
    isRequired: PropTypes.bool,
    variant: PropTypes.oneOf(['blue', 'rounded'])
};

export default Select;
