import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Buttons, Form, Grid, Info, Inputs, Typography} from 'components';
import {useProjection, useTaxSimulator} from 'hooks';
import {amount} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    amount: {
        padding: theme.spacing(2, 0, 1)
    }
}));

function TabProposal({history, adornment, setAdornment}) {
    const classes = useStyles();

    const {taxSimulator, setTaxSimulator, variant} = useTaxSimulator();
    const {projection, setProjection} = useProjection('retirement');

    return (
        <>
            <Typography
                variant="h4"
                color="blue"
                center
            >
                Proposition de versement&nbsp;
                <Info
                    hash="disclaimer_tax_1"
                    title="Information"
                    color="blue"
                >
                    Cette proposition vous permet de bénéficier du maximum de déduction d'impôt.
                </Info>
            </Typography>
            <Typography
                className={classes.amount}
                variant="h2"
                color="blue"
                semibold
                center
            >
                {amount.format(taxSimulator.response.vv_envelope_monthly, 0, '€ / mois')}
            </Typography>
            <Typography
                variant="h5"
                color="blue"
                center
            >
                Quel montant voulez-vous
                <br />
                verser ?
            </Typography>
            <Form
                dynamic
                values={{
                    allocated_periodicity: 'monthly'
                }}
                schema={{
                    allocated_amount: {
                        presence: {
                            allowEmpty: true,
                            message: 'Veuillez renseigner un montant'
                        },
                        numericality: {
                            greaterThanOrEqualTo: 0,
                            message: 'Le montant alloué au projet retraite doit être positif'
                        }
                    },
                    allocated_periodicity: {}
                }}
                submit={(formState) => {
                    setAdornment(formState.values.allocated_periodicity);
                    setTaxSimulator({
                        ...taxSimulator,
                        inputs: {
                            ...taxSimulator.inputs,
                            ...formState.values
                        }
                    });
                    variant === 'contained' &&
                        setProjection({
                            ...projection,
                            inputs: {
                                ...projection.inputs,
                                allocated_amount: formState.values.allocated_amount,
                                allocated_periodicity: formState.values.allocated_periodicity
                            }
                        });
                }}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={5}
                    >
                        <Inputs.Number
                            defaultValue={taxSimulator.inputs.allocated_amount || '0'}
                            updateDefaultValue
                            name="allocated_amount"
                            format="money"
                            adornment={adornment}
                            right
                            submitOnBlur
                        />
                    </Grid>
                    <Grid
                        item
                        xs={7}
                    >
                        <Inputs.Select
                            name="allocated_periodicity"
                            options={[
                                {
                                    label: 'Sélectionner votre fréquence',
                                    disabled: true
                                },
                                {
                                    label: 'Fréquence mensuelle',
                                    value: 'monthly'
                                },
                                {
                                    label: 'Fréquence annuelle',
                                    value: 'yearly'
                                },
                                {
                                    label: 'Versement unique',
                                    value: 'one_shot'
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
            </Form>
            {variant === 'contained' && (
                <Buttons.Chevron
                    label="Je verse"
                    color="blue"
                    center
                    onClick={() => window.open('/transactional/payment', '_blank').focus()}
                />
            )}
        </>
    );
}

TabProposal.propTypes = {
    adornment: PropTypes.string.isRequired,
    setAdornment: PropTypes.func.isRequired
};

export default withRouter(TabProposal);
