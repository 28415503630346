import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Grid, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 500,
        margin: 'auto',
        padding: theme.spacing(0, 2),
        cursor: 'pointer'
    },
    tab: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderBottom: `solid 1px ${theme.palette.gray}`,
        paddingBottom: theme.spacing(1)
    },
    selected: {
        fontWeight: '600',
        borderBottom: `solid 3px ${theme.palette.orangeLight} !important`
    }
}));

function PlanTabs(props) {
    const {selected, setSelected} = props;
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.root}
            spacing={1}
        >
            {['Régimes de base', 'Régimes complémentaires'].map((plan, index) => (
                <Grid
                    key={plan}
                    item
                    sm={6}
                    xs={12}
                    onClick={() => setSelected(index)}
                >
                    <Typography
                        className={clsx({
                            [classes.tab]: true,
                            [classes.selected]: selected === index
                        })}
                        variant="h5"
                        color={selected === index ? 'black' : 'gray'}
                        center
                        gutterBottom={false}
                    >
                        {plan}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
}

PlanTabs.propTypes = {
    selected: PropTypes.number.isRequired,
    setSelected: PropTypes.func.isRequired
};

export default PlanTabs;
