import React from 'react';
import {Buttons, Icon, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    list: {
        '& li': {
            ...theme.typography.h5,
            marginLeft: 30,
            display: 'list-item',
            padding: 5,
            '&:last-child': {
                paddingBottom: 20
            }
        }
    }
}));

function SubStepForgotPasswordResult({setStepParent}) {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h1">Votre demande de nouveau mot de passe a abouti</Typography>
            <Typography variant="h5">Nous avons bien pris en compte votre demande de nouveau mot de passe.</Typography>
            <Typography variant="h5">
                Vous allez recevoir un e-mail avec votre mot de passe provisoire. Consultez votre messagerie et cliquez
                sur le lien présent dans le message.
            </Typography>
            <Typography variant="h5">
                Après modification de votre mot de passe sur le site de l'Info Retraite, veuillez cliquer sur le bouton
                ci-dessous pour accéder à votre simulation.
            </Typography>

            <br />

            <div className={classes.container}>
                <Icon
                    name="axa-icons-danger-o"
                    size={40}
                    flex
                />
                <Typography
                    variant="h5"
                    caps
                    gutterBottom={false}
                >
                    &nbsp;Attention
                </Typography>
            </div>

            <ul className={classes.list}>
                <li>
                    Si vous ne recevez pas cet e-mail, vérifiez que votre messagerie n'a pas classé en spam l'e-mail de
                    info-retraite.fr
                </li>
                <li>
                    Si vous n'avez pas reçu d'e-mail sous 24 heures, nous vous invitons à renouveller votre demande.
                </li>
            </ul>

            <Buttons.Default
                className="toRight"
                label="Retour à la page"
                color="green"
                next
                onClick={() => setStepParent(1)} // StepLogin
            />
        </>
    );
}

export default SubStepForgotPasswordResult;
