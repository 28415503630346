import React, {useEffect} from 'react';
import {Box, List, ListItem, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Link} from 'react-router-dom';
import {Typography} from '../../../components';

const useStyles = makeStyles((theme) => ({
    skipLink: {
        listStyle: 'none',
        paddingBottom: 0,
        paddingTop: 0,
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            '& li': {
                paddingBottom: 0,
                paddingTop: 0
            }
        }
    },
    link: {
        minWidth: '200px',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5px',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: '1px'
        }
    }
}));

function SkipLinks() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        //Function to handle the skip link action and skip to the html element by its ID
        const handleSkipLinkClick = (targetId) => () => {
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                targetElement.tabIndex = -1;
                targetElement.focus();
            }
        };

        const htmlIds = [
            {id: 'header-skip-link', target: 'header'},
            {id: 'main-content-skip-link', target: 'main-content'},
            {id: 'footer-skip-link', target: 'footer'}
        ];

        //addEventListeners to every element

        // Loop to target the html skip links
        htmlIds.forEach(({id, target}) => {
            const htmlTarget = document.getElementById(id);
            htmlTarget.addEventListener('click', handleSkipLinkClick(target));
        });

        // Cleanup event listeners on component unmount
        return () => {
            // Loop to target the html skip links
            htmlIds.forEach(({id, target}) => {
                const htmlTarget = document.getElementById(id);
                htmlTarget.removeEventListener('click', handleSkipLinkClick(target));
            });
        };
    }, []);

    const linksList = [
        {id: 'header-skip-link', to: '#header', label: 'home.skipToHeader'},
        {id: 'main-content-skip-link', to: '#main-content', label: 'home.skipToMainLink'},
        {id: 'footer-skip-link', to: '#footer', label: 'home.skipToFooter'}
    ];

    return (
        <Box
            id="skip-links-box"
            tabIndex="-1"
            sx={{
                position: 'fixed',
                zIndex: 999,
                bgcolor: 'white',
                width: '100vw',
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-start',
                alignItems: 'center',
                paddingBottom: '8px'
            }}
        >
            <List className={classes.skipLink}>
                {linksList.map((link, index) => (
                    <ListItem
                        id={link.id}
                        key={index}
                        alignItems="center"
                        dense={!isMobile}
                    >
                        <Link
                            className={classes.link}
                            to={link.to}
                        >
                            <Typography
                                semibold
                                component="span"
                                size={isMobile ? 12 : 16}
                                gutterBottom={false}
                                color="blue"
                                center
                                localization={link.label}
                            ></Typography>
                        </Link>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default SkipLinks;
