import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {OpinionBox, ToggleOpinion} from './components';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        zIndex: 1002,
        right: 7,
        bottom: 20,
        padding: 8,
        cursor: 'pointer'
    }
}));

function Opinion() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);

    return (
        <div className={classes.root}>
            {open ? (
                <OpinionBox
                    setOpen={setOpen}
                    setVisible={setVisible}
                />
            ) : (
                <ToggleOpinion
                    open={open}
                    setOpen={setOpen}
                    visible={visible}
                    setVisible={setVisible}
                />
            )}
        </div>
    );
}

export default Opinion;
