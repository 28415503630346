import React from 'react';
import {Grid, Info, Inputs, Typography} from 'components';
import {useForm} from 'hooks';

const options = [
    {
        label: '0',
        value: 0
    },
    {
        label: '1',
        value: 1
    },
    {
        label: '2',
        value: 2
    },
    {
        label: '3',
        value: 3
    },
    {
        label: '4',
        value: 4
    },
    {
        label: '5',
        value: 5
    },
    {
        label: '6',
        value: 6
    },
    {
        label: '7',
        value: 7
    },
    {
        label: '8',
        value: 8
    },
    {
        label: '9',
        value: 9
    },
    {
        label: '10',
        value: 10
    }
];

function SubStepFamily({selects}) {
    const {formState} = useForm();

    return (
        <>
            <Typography variant="h5">Ma situation familiale</Typography>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <Inputs.Select
                        key="marel-family_situation"
                        name="family_situation"
                        label="Quelle est ma situation familiale actuelle&nbsp;?"
                        options={selects.family_situation.options}
                    />
                    <Inputs.Select
                        key="marel-children"
                        name="children"
                        label={
                            <>
                                Combien d'enfants ai-je (nés ou à naître) ?{' '}
                                <Info
                                    hash="children_disclaimer"
                                    title="Informations"
                                    color="blueLight"
                                    tooltip
                                >
                                    Avoir eu ou élévé des enfants peut avoir un impact important sur votre date de
                                    départ à la retraite et sur les pensions que vous percevrez de vos régimes de
                                    retraite.
                                </Info>
                            </>
                        }
                        options={options}
                    />
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    {[...Array(parseInt(formState.values.children)).keys()].map((index) => (
                        <Inputs.Datepicker
                            key={`marel-children-${index}`}
                            name={`children_birth_date_${index + 1}`}
                            label={`Date de naissance enfant ${index + 1}`}
                        />
                    ))}
                </Grid>
            </Grid>
        </>
    );
}

export default SubStepFamily;
