import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Cell, Pie, PieChart, ResponsiveContainer} from 'recharts';
import {Grid, Typography} from 'components';
import {amount} from 'utils';

import {useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    mobile: {
        flexDirection: 'column'
    },
    wrapper: {
        maxWidth: 250
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    dot: {
        width: 17,
        height: 17,
        marginTop: 2,
        borderRadius: '50%'
    },
    hover: {
        transform: 'scale(1.25)'
    }
}));

const Legend = ({entry, index, active, setActive, symbol}) => {
    const classes = useStyles();

    const handleMouseEnter = () => {
        setActive(index);
    };
    const handleMouseLeave = () => {
        setActive(null);
    };

    return (
        <Grid
            container
            spacing={1}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Grid item>
                <div
                    className={clsx({
                        [classes.dot]: true,
                        [classes.hover]: active === index
                    })}
                    style={{
                        background: active === index ? entry.highlight_color : entry.color
                    }}
                />
            </Grid>
            <Grid item>
                <Typography
                    semibold
                    gutterBottom={false}
                >
                    {entry.name}
                    {'  '}
                    <Typography
                        component="span"
                        color="grayDark"
                        size={12}
                    >
                        {entry.subtitle}
                    </Typography>
                </Typography>
                <Typography
                    color="blue"
                    variant="h5"
                    semibold
                >
                    {symbol ? entry.value + ' ' + symbol : amount.format(entry.value)}
                </Typography>
            </Grid>
        </Grid>
    );
};

function Doughnut({data, className, withTotal, symbol, ...rest}) {
    const classes = useStyles();
    const theme = useTheme();

    const [active, setActive] = useState(); // index of hovered cell

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const handleMouseEnter = (event, index) => {
        setActive(index);
    };
    const handleMouseLeave = () => {
        setActive(null);
    };

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [className]: className,
                [classes.mobile]: isMobile
            })}
        >
            <ResponsiveContainer
                className={classes.wrapper}
                width="100%"
                height={220}
            >
                <PieChart>
                    <Pie
                        data={data}
                        innerRadius={65}
                        outerRadius={100}
                        cx={100}
                        startAngle={90}
                        endAngle={-270}
                        fill="#8884d8"
                        paddingAngle={1}
                        dataKey="value"
                        animationDuration={750}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        {...rest}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={index === active ? entry.highlight_color : entry.color}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <div className={classes.container}>
                {data.map((entry, index) => (
                    <Legend
                        key={`legend-${index}`}
                        entry={entry}
                        index={index}
                        active={active}
                        setActive={setActive}
                        symbol={symbol}
                    />
                ))}
                {withTotal && (
                    <Typography
                        className="full-width"
                        variant="h4"
                        semibold
                    >
                        Total: {amount.format(data.map((entry) => entry.value).reduce((acc, val) => acc + val, 0))}
                    </Typography>
                )}
            </div>
        </div>
    );
}

Doughnut.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
            color: PropTypes.string,
            highlight_color: PropTypes.string
        })
    ),
    className: PropTypes.string,
    withTotal: PropTypes.bool
};

export default Doughnut;
