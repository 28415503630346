export const ais_profile = {
    first_name: 'P-DJGCIIBIG',
    last_name: 'N-DJGCIIBIG',
    birth_date: '1988-10-27',
    gender: 'M',
    mobile: null,
    personal_phone: null,
    zip_postal_code: '24000',
    street_line: '41-43 RUE DES RIGOLES',
    additional_address_1: '',
    additional_address_2: '',
    additional_address_3: '',
    locality: 'PERIGUEUX',
    country: 'FR',
    email: 'mail_personnel@nomail.com',
    bank_accounts: [],
    ais_account_details: [
        {
            enterprise_id: '2726002000300',
            product_code: 'P332',
            pacte_code: 'PERU',
            company_name: 'Peugeot',
            policy_id: '2726002000300/00507',
            status: 'REDUCTION'
        },
        {
            enterprise_id: '2726002000300',
            product_code: 'P332',
            pacte_code: 'PERU',
            company_name: 'Peugeot',
            policy_id: '2726002000300/00508',
            status: 'REDUCTION'
        },
        {
            enterprise_id: '2726002000301',
            product_code: 'P332',
            pacte_code: 'PERU',
            company_name: 'AXA',
            policy_id: '2446882030301/00103',
            status: 'ENCOURS'
        }
    ]
};

export const ais_accounts = [
    {
        type: 'MADELIN',
        balance: 25861.340000000004,
        scope: 'ais',
        policy_id: '2726002000300/00507',
        balance_co: 11362.490000000002,
        balance_vv: 2017.2399999999998,
        balance_vvd: 1919.2399999999998,
        balance_vvnd: 98.0,
        balance_es: 12481.61,
        title: 'PERU',
        status: 'ENCOURS',
        investments: [
            {
                id: '150002541',
                amount: 86.22,
                share_number: 0.50219,
                label: 'Horizon Très Long Terme',
                share_value: 171.6880065313925,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002542',
                amount: 679.96,
                share_number: 7.86899,
                label: 'AXA ES Long Terme 2M',
                share_value: 86.41007295726644,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002543',
                amount: 49.59,
                share_number: 50.46,
                label: 'Fonds Euro',
                share_value: 0.9827586206896552,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002544',
                amount: 208.28,
                share_number: 1.11245,
                label: 'AXA WF Framlington Euro ex-UK MicroCap F',
                share_value: 187.22639219740213,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002545',
                amount: 363.28,
                share_number: 97.39503,
                label: 'CCEP Actions Internationales',
                share_value: 3.729964455065109,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002546',
                amount: 5097.61,
                share_number: 58.99323,
                label: 'AXA ES Long Terme 2M',
                share_value: 86.41008468259832,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002547',
                amount: 3366.88,
                share_number: 902.64888,
                label: 'CCEP Actions Internationales',
                share_value: 3.7299996428290036,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002548',
                amount: 1153.95,
                share_number: 6.16326,
                label: 'AXA WF Framlington Euro ex-UK MicroCap F',
                share_value: 187.2304592050311,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002549',
                amount: 215.19,
                share_number: 57.69231,
                label: 'CCEP Actions Internationales',
                share_value: 3.729959850801606,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002550',
                amount: 73.85,
                share_number: 0.39441,
                label: 'AXA WF Framlington Euro ex-UK MicroCap F',
                share_value: 187.24170279658222,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002551',
                amount: 326.11,
                share_number: 3.77403,
                label: 'AXA ES Long Terme 2M',
                share_value: 86.40895806339643,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002552',
                amount: 38.35,
                share_number: 38.77,
                label: 'Fonds Euro',
                share_value: 0.9891668816094918,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002553',
                amount: 260.58,
                share_number: 69.86066,
                label: 'CCEP Actions Internationales',
                share_value: 3.7299962525404142,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002554',
                amount: 156.66,
                share_number: 0.83671,
                label: 'AXA WF Framlington Euro ex-UK MicroCap F',
                share_value: 187.23333054463316,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002555',
                amount: 509.15,
                share_number: 5.89222,
                label: 'AXA ES Long Terme 2M',
                share_value: 86.41055493515178,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002556',
                amount: 339.35,
                share_number: 15.45328,
                label: 'AXA Génération Euro Obligations 2M',
                share_value: 21.95973929159376,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002557',
                amount: 1498.46,
                share_number: 8.0033,
                label: 'AXA WF Framlington Euro ex-UK MicroCap F',
                share_value: 187.23026751465022,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002558',
                amount: 4365.26,
                share_number: 1170.31128,
                label: 'CCEP Actions Internationales',
                share_value: 3.7299990819536495,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002559',
                amount: 6617.89,
                share_number: 76.58709,
                label: 'AXA ES Long Terme 2M',
                share_value: 86.4099941648129,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002560',
                amount: 98.0,
                share_number: 99.55,
                label: 'Fonds Euro',
                share_value: 0.9844299347061778,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            }
        ],
        product_code: 'P311',
        company_name: 'RS-396277216',
        contract_number: '2726002000300'
    },
    {
        balance: 3465.34,
        balance_co: 2465.31,
        balance_vv: 1000.03,
        company_name: 'Loréal',
        contract_number: '2720625000000',
        policy_id: '2720625000000/00001',
        investments: [
            {
                id: '150002561',
                amount: 1257.56,
                share_number: 25.78,
                label: 'AXA Pension 2033-2035 (I)',
                share_value: 48.7804499612,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002562',
                amount: 2207.78,
                share_number: 5.78,
                label: 'AXA Pension 2031-2033 (P)',
                share_value: 381.968858131,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            }
        ],
        scope: 'ais',
        title: 'Article 83',
        type: 'A83',
        status: 'ENCOURS'
    },
    {
        balance: 3465.34,
        balance_co: 2465.31,
        balance_vv: 1000.03,
        company_name: 'Kerialis',
        contract_number: '2720625000020',
        policy_id: '2720625000020/00002',
        investments: [
            {
                id: '150002563',
                amount: 1257.56,
                share_number: 25.78,
                label: 'AXA Pension 2033-2035 (I)',
                share_value: 48.7804499612,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            },
            {
                id: '150002564',
                amount: 2207.78,
                share_number: 5.78,
                label: 'AXA Pension 2031-2033 (P)',
                share_value: 381.968858131,
                compartment: 'co',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            }
        ],
        scope: 'ais',
        title: 'Article 82',
        type: 'A82',
        status: 'REDUCTION'
    }
];

export const ais_vv_accounts = [
    {
        type: 'PERECO',
        balance: 0,
        scope: 'ais',
        policy_id: '2446882030301/00103_vvd_free',
        title: 'PERECO Versements volontaires déductibles libre',
        product_code: 'P331',
        compartment: 'vvd',
        company_name: 'AXA',
        contract_number: '2446882030301',
        is_guided: false,
        management_type: 'free',
        guided_management_type: null,
        is_switch_authorized: true,
        scheduled_payment: 'ABONDE_CALCULE',
        unsolicited_payment: 'ACTE_NON_ABONDE',
        scheduled_fund: true,
        investments: [
            {
                id: '150017450_FR0014001H77',
                amount: 0,
                share_number: 0,
                label: 'AXA GENERATION TEMPERE SOLIDAIRE 2M',
                share_value: 0,
                compartment: 'vvd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'FR0014001H77'
            },
            {
                id: '150017450_FR0013215266',
                amount: 0,
                share_number: 0,
                label: 'AXA PENSION FUTURE R\t',
                share_value: 0,
                compartment: 'vvd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'FR0013215266'
            },
            {
                id: '150017450_LU1937143664',
                amount: 0,
                share_number: 0,
                label: 'AXA WF Framlington Euro ex-UK MicroCap A',
                share_value: 0,
                compartment: 'vvd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'LU1937143664'
            },
            {
                id: '150017450_LU0503938366',
                amount: 0,
                share_number: 0,
                label: 'AXA WF Framlington Evolving Trends A EUR',
                share_value: 0,
                compartment: 'vvd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'LU0503938366'
            },
            {
                id: '150017450_LU1280195881',
                amount: 0,
                share_number: 0,
                label: 'AXA WF Global Green Bonds A',
                share_value: 0,
                compartment: 'vvd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'LU1280195881'
            },
            {
                id: '150017450_FR0010611293',
                amount: 0,
                share_number: 0,
                label: 'Echiquier Arty SRI A',
                share_value: 0,
                compartment: 'vvd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'FR0010611293'
            },
            {
                id: '150017450_FR0010863688',
                amount: 0,
                share_number: 0,
                label: 'Echiquier Positive Impact Europe A',
                share_value: 0,
                compartment: 'vvd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'FR0010863688'
            },
            {
                id: '150017450_FR0010642280',
                amount: 0,
                share_number: 0,
                label: 'ECOFI AGIR POUR LE CLIMAT C',
                share_value: 0,
                compartment: 'vvd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'FR0010642280'
            },
            {
                id: '150017450_EUR000000002',
                amount: 0,
                share_number: 0,
                label: 'Fonds Euro',
                share_value: 0,
                compartment: 'vvd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 40,
                fund_type: 'EURO',
                isin_code: 'EUR000000002'
            }
        ]
    },
    {
        type: 'PERECO',
        balance: 0,
        scope: 'ais',
        policy_id: '2446882030301/00103_vvnd_guided_balanced',
        title: 'PERECO Versements volontaires non déductibles piloté équilibre',
        product_code: 'P331',
        compartment: 'vvnd',
        company_name: 'AXA',
        contract_number: '2446882030301',
        is_guided: true,
        management_type: 'guided',
        guided_management_type: 'balanced',
        is_switch_authorized: true,
        scheduled_payment: null,
        unsolicited_payment: null,
        scheduled_fund: false,
        investments: [
            {
                id: '150022928_LU1937143664',
                amount: 0,
                share_number: 0,
                label: 'AXA WF Framlington Euro ex-UK MicroCap A',
                share_value: 0,
                compartment: 'vvnd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            }
        ]
    },
    {
        type: 'PERECO',
        balance: 0,
        scope: 'ais',
        policy_id: '2446882030301/00103_vvd_guided_balanced',
        title: 'PERECO Versements volontaires déductibles piloté équilibre',
        product_code: 'P331',
        compartment: 'vvd',
        company_name: 'AXA',
        contract_number: '2446882030301',
        is_guided: true,
        management_type: 'guided',
        guided_management_type: 'balanced',
        is_switch_authorized: true,
        scheduled_payment: null,
        unsolicited_payment: null,
        scheduled_fund: false,
        investments: [
            {
                id: '150022924_LU1937143664',
                amount: 0,
                share_number: 0,
                label: 'AXA WF Framlington Euro ex-UK MicroCap A',
                share_value: 0,
                compartment: 'vvd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: null,
                fund_type: null,
                isin_code: null
            }
        ]
    },
    {
        type: 'PERECO',
        balance: 0,
        scope: 'ais',
        policy_id: '2446882030301/00103_vvnd_free',
        title: 'PERECO Versements volontaires non déductibles libre',
        product_code: 'P331',
        compartment: 'vvnd',
        company_name: 'AXA',
        contract_number: '2446882030301',
        is_guided: false,
        management_type: 'free',
        guided_management_type: null,
        is_switch_authorized: true,
        scheduled_payment: null,
        unsolicited_payment: null,
        scheduled_fund: false,
        investments: [
            {
                id: '150017456_FR0014001H77',
                amount: 0,
                share_number: 0,
                label: 'AXA GENERATION TEMPERE SOLIDAIRE 2M',
                share_value: 0,
                compartment: 'vvnd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'FR0014001H77'
            },
            {
                id: '150017456_FR0013215266',
                amount: 0,
                share_number: 0,
                label: 'AXA PENSION FUTURE R\t',
                share_value: 0,
                compartment: 'vvnd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'FR0013215266'
            },
            {
                id: '150017456_LU1937143664',
                amount: 0,
                share_number: 0,
                label: 'AXA WF Framlington Euro ex-UK MicroCap A',
                share_value: 0,
                compartment: 'vvnd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'LU1937143664'
            },
            {
                id: '150017456_LU0503938366',
                amount: 0,
                share_number: 0,
                label: 'AXA WF Framlington Evolving Trends A EUR',
                share_value: 0,
                compartment: 'vvnd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'LU0503938366'
            },
            {
                id: '150017456_LU1280195881',
                amount: 0,
                share_number: 0,
                label: 'AXA WF Global Green Bonds A',
                share_value: 0,
                compartment: 'vvnd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'LU1280195881'
            },
            {
                id: '150017456_FR0010611293',
                amount: 0,
                share_number: 0,
                label: 'Echiquier Arty SRI A',
                share_value: 0,
                compartment: 'vvnd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'FR0010611293'
            },
            {
                id: '150017456_FR0010863688',
                amount: 0,
                share_number: 0,
                label: 'Echiquier Positive Impact Europe A',
                share_value: 0,
                compartment: 'vvnd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'FR0010863688'
            },
            {
                id: '150017456_FR0010642280',
                amount: 0,
                share_number: 0,
                label: 'ECOFI AGIR POUR LE CLIMAT C',
                share_value: 0,
                compartment: 'vvnd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 0,
                fund_type: 'UC',
                isin_code: 'FR0010642280'
            },
            {
                id: '150017456_EUR000000002',
                amount: 0,
                share_number: 0,
                label: 'Fonds Euro',
                share_value: 0,
                compartment: 'vvnd',
                management_type: null,
                guided_management_type: null,
                fund_max_rate: 40,
                fund_type: 'EURO',
                isin_code: 'EUR000000002'
            }
        ]
    }
];

export const ais_bank_account = {};

export const ais_transactions = [
    {
        date: '2022-06-16',
        amount: 461.5,
        status: 'Traitée',
        type: 'AFF_ABONDE',
        label: 'Abondement collectif'
    },
    {
        date: '2022-06-16',
        amount: 4615.0,
        status: 'Traitée',
        type: 'AFF_INTERESS',
        label: 'Intéressement'
    },
    {
        date: '2022-03-08',
        amount: 17229.89,
        status: 'Traitée',
        type: 'TR_ENT_C_E',
        label: 'Transfert entrant collectif externe'
    },
    {
        date: '2022-01-21',
        amount: 304.0,
        status: 'Traitée',
        type: 'ABONDEMENT',
        label: 'Abondement individuel'
    },
    {
        date: '2022-01-21',
        amount: 600.0,
        status: 'Traitée',
        type: 'VL',
        label: 'Versement libre'
    },
    {
        date: '2021-10-31',
        amount: 0.08,
        status: 'Traitée',
        type: 'PB',
        label: 'Participation aux bénéfices'
    },
    {date: '2021-10-13', amount: 41.07, status: 'Traitée', type: 'AFF_COT', label: 'Affectation de cotisation'}
];

export const ais_bo_permissions = {authorizedAct: true, companyTopUp: 'ABONDE_PERIOD'};

export const ais_bo_gauge = {ais_contribution_gauge: {per: {consumed: 99.91, ceiling: 1314}}};

export const ais_abondment_simulation = {
    companyTopUpGlobalConsumedBrutPS: 99.91,
    companyTopUpActConsumedBrutPS: 0,
    companyTopUpActSimulatorBrutPS: 718.18,
    socialContributions: 69.66
};

export const ais_global_vv_amount = {
    actType: 'VL+VP',
    fundType: 'EURO+UC',
    grossAmount: 160
};
