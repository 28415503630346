import {useUser} from 'hooks';
import {useParams} from 'react-router-dom';

function useOptionBulletin() {
    const {user} = useUser();
    const {idBulletin} = useParams();

    if (!user || !idBulletin) return null;

    if (!user.s2e_option_bulletins) return null;

    if (!user.s2e_option_bulletins instanceof Array) return null;

    return {
        option_bulletin: user.s2e_option_bulletins.find((option_bulletin) => option_bulletin.id === idBulletin)
    };
}

export default useOptionBulletin;
