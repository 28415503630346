import capitalize from './capitalize';

const SEPARATORS = [' ', '-']

const formatName = (string) => {
    if (!string)
        return '';

    const regex = new RegExp(SEPARATORS.join('|'));
    const parts = string.split(regex).map(part => capitalize(part));

    const space_split = string.split(SEPARATORS[0]);
    const dash_split = string.split(SEPARATORS[1]);

    if (space_split.length > 1)
        return parts.join(' ');


    if (dash_split.length > 1)
        return parts.join('-');

    return parts.join(' ');
};

export default formatName;