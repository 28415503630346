import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {Grid as MuiGrid} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    alignRight: {
        display: 'flex',
        flexWrap: 'wrap-reverse',
        flexDirection: 'column'
    }
}));

function Grid(props) {
    const {alignRight, className, children, ...rest} = props;
    const classes = useStyles();

    return (
        <MuiGrid
            className={clsx({
                [className]: className,
                [classes.alignRight]: alignRight
            })}
            {...rest}
        >
            {children}
        </MuiGrid>
    );
}

Grid.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    alignRight: PropTypes.bool
};

export default Grid;
