import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from 'components';
import {useForm} from 'hooks';

import {Checkbox as MuiCheckbox, FormControlLabel, FormGroup} from '@material-ui/core';

function Checkbox(props) {
    const {
        options,
        name,
        label,
        labelColor,
        disabled,
        hiddenError = false,
        disableRipple = false,
        disableLabel = false,
        ...rest
    } = props;

    const {formState, setFormState} = useForm();

    const handleChange = (event, option) => {
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [name]:
                    options.length > 1
                        ? event.target.checked
                            ? [...(formState.values[name] || []), option.name]
                            : formState.values[name].filter((element) => element !== option.name).length > 0
                            ? formState.values[name].filter((element) => element !== option.name)
                            : []
                        : event.target.checked
            },
            touched: {
                ...formState.touched,
                [name]: true
            }
        });
    };

    const hasError = (field) => !!formState.errors[field];

    return (
        <FormGroup
            name={name}
            row
            {...rest}
        >
            {!disableLabel && (
                <Typography
                    className="full-width"
                    color={labelColor}
                    gutterBottom={false}
                    variant="caption"
                >
                    {label}
                </Typography>
            )}
            {options.map((option, index) => (
                <FormControlLabel
                    disabled={disabled}
                    key={`formControl-${index}`}
                    control={
                        <MuiCheckbox
                            color="primary"
                            checked={
                                formState.values[name] instanceof Array
                                    ? formState.values[name].includes(option.name)
                                    : formState.values[name] === true
                            }
                            onChange={(event) => handleChange(event, option)}
                            name={option.name}
                            disableRipple={disableRipple}
                        />
                    }
                    label={
                        <Typography
                            color={labelColor}
                            gutterBottom={false}
                        >
                            {option.label}
                        </Typography>
                    }
                    onClick={(e) => e.stopPropagation()}
                />
            ))}
            {!hiddenError && (
                <Typography
                    className="error-message full-width"
                    color="redLight"
                    gutterBottom={false}
                >
                    {hasError(name) ? formState.errors[name][0] : null}
                </Typography>
            )}
        </FormGroup>
    );
}

Checkbox.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    labelColor: PropTypes.string,
    disabled: PropTypes.bool,
    disableRipple: PropTypes.bool,
    disableLabel: PropTypes.bool
};

export default Checkbox;
