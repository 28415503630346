import {useContext} from 'react';
import {fastapi} from 'api';
import {B2VContext} from 'store/B2V';

export const getB2V = (callback) => {
    return fastapi.get('/b2v/initialize').then((response) => callback(response));
};

function useB2V() {
    return useContext(B2VContext);
}

export default useB2V;
