import palette from './palette';

export default {
    fontFamily: 'Source Sans Pro,PublicoHeadline',
    h1: {
        color: palette.text.primary,
        display: 'block',
        fontWeight: 500,
        letterSpacing: '-0.24px',
        lineHeight: '40px',
        fontSize: 40,
        '@media (max-width: 600px)': {
            fontSize: 32
        },
        fontFamily: 'PublicoHeadline !important'
    },
    h2: {
        color: palette.text.primary,
        display: 'block',
        fontWeight: 500,
        letterSpacing: '-0.24px',
        lineHeight: '32px',
        fontSize: 24,
        '@media (max-width: 600px)': {
            fontSize: 20
        }
    },
    h3: {
        color: palette.text.primary,
        display: 'block',
        fontWeight: 500,
        letterSpacing: '-0.06px',
        lineHeight: '28px',
        fontSize: 24,
        '@media (max-width: 600px)': {
            fontSize: 22
        }
    },
    h3max: {
        color: palette.text.primary,
        display: 'block',
        fontWeight: 500,
        letterSpacing: '-0.06px',
        lineHeight: '28px',
        fontSize: 22,
        '@media (max-width: 600px)': {
            fontSize: 20
        }
    },
    h4: {
        color: palette.text.primary,
        display: 'block',
        fontWeight: 500,
        letterSpacing: '-0.06px',
        lineHeight: '24px',
        fontSize: 20,
        '@media (max-width: 600px)': {
            fontSize: 18
        }
    },
    h5: {
        color: palette.text.primary,
        display: 'block',
        fontWeight: 500,
        letterSpacing: '-0.05px',
        lineHeight: '22px',
        fontSize: 18,
        '@media (max-width: 600px)': {
            fontSize: 16
        }
    },
    h6: {
        color: palette.text.primary,
        display: 'block',
        fontWeight: 500,
        fontSize: '16px',
        letterSpacing: '-0.05px',
        lineHeight: '20px'
    },
    caption: {
        color: palette.text.primary,
        display: 'block',
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: '-0.05px',
        lineHeight: '20px'
    },
    subtitle1: {
        color: palette.text.primary,
        display: 'block',
        fontSize: '12px',
        letterSpacing: '-0.05px',
        lineHeight: '20px'
    }
};
