import React from 'react';
import {Modal, Structure} from 'components';
import {SubStepCurrentVV, SubStepResult, SubStepStart, SubStepTaxCeiling, SubStepTaxCeilingSpouse} from './components';
import {useTaxSimulator} from 'hooks';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600
    },
    carousel: {
        padding: theme.spacing(1)
    }
}));

function RefineModal() {
    const classes = useStyles();

    const {taxSimulator} = useTaxSimulator();

    return (
        <Modal
            hash="refine"
            className={classes.root}
            title="Affiner le montant"
        >
            <Structure.Carousel
                className={classes.carousel}
                adaptiveHeight
            >
                <SubStepStart />
                <SubStepTaxCeiling />
                {taxSimulator.inputs.marital_status === 'MA' && <SubStepTaxCeilingSpouse />}
                <SubStepCurrentVV />
                <SubStepResult />
            </Structure.Carousel>
        </Modal>
    );
}

export default RefineModal;
