import React from 'react';
import {Buttons, Form, Inputs, Typography} from 'components';
import {useTaxSimulator} from 'hooks';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        maxWidth: 280,
        margin: `${theme.spacing(2)}px auto`
    },
    input: {
        fontSize: '16px !important',
        '& + div p': {
            fontSize: '16px !important'
        }
    }
}));

function StepMonthlyIncome({setStep}) {
    const classes = useStyles();

    const {taxSimulator, setTaxSimulator, variant} = useTaxSimulator();

    return (
        <Form
            schema={{
                monthly_income: {
                    presence: {
                        allowEmpty: false,
                        message: 'Veuillez renseigner votre revenu'
                    },
                    numericality: {
                        greaterThan: 0,
                        message: 'Votre revenu doit être positif'
                    }
                }
            }}
            submit={(formState) => {
                setTaxSimulator({
                    ...taxSimulator,
                    inputs: {
                        ...taxSimulator.inputs,
                        ...formState.values
                    }
                });
                setStep(5);
                return new Promise((resolve) => resolve());
            }}
            variant="blue"
        >
            <Typography
                variant={variant === 'public' ? 'h1' : variant === 'standalone' ? 'h2' : 'h4'}
                color="blue"
                center
            >
                Le revenu net mensuel de votre foyer est de...
            </Typography>
            <div className={classes.wrapper}>
                <Inputs.Number
                    className={classes.input}
                    name="monthly_income"
                    format="money"
                    adornment="€/mois"
                    right
                />
            </div>
            <Buttons.Default
                label="Voir ma simulation"
                color="orange"
                center
                next
                submit
            />
        </Form>
    );
}

export default StepMonthlyIncome;
