import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Structure} from 'components';
import {MenuMobile} from 'layouts/_components';
import config from 'config';

import {Toolbar} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useUser} from "hooks";

const useStyles = makeStyles((theme) => ({
    navigation: {
        height: 58,
        minHeight: 'initial'
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        width: 42,
        height: 42
    },
    icon: {
        display: 'flex'
    },
    spaceLeft: {
        marginLeft: 15
    }
}));

function Mobile() {
    const classes = useStyles();
    const {user} = useUser();
    /*const application = user.origin === 'individual' ? 'maretraite360' : 'matransmission360';*/
    const [activeMenu, setActiveMenu] = useState();

    return (
        <Toolbar
            className={classes.navigation}
            disableGutters
        >
            <Structure.Center className={classes.wrapper}>
                <Link to={user.origin === 'individual' ? "/epargne" : "/transmission"}>
                    <img
                        className={classes.logo}
                        src={`${process.env.PUBLIC_URL}/logos/axa.svg`}
                        alt="logo"
                    />
                </Link>
                <div className="flexGrow"/>

                <MenuMobile
                    className={classes.spaceLeft}
                    name='navigation'
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    nav={[
                        {
                            label: 'MES CONTRATS',
                            url: `${config.AXA_CLIENT_SPACE_URL[process.env.REACT_APP_ENVIRONMENT]}`,
                            external: true
                        },
                        {
                            label: 'MES DOCUMENTS',
                            url: `${
                                config.AXA_CLIENT_SPACE_URL[process.env.REACT_APP_ENVIRONMENT]
                            }/documents/epargne-et-placements`,
                            external: true
                        },
                        {
                            label: 'MON PROFIL',
                            url: `${config.AXA_CLIENT_SPACE_URL[process.env.REACT_APP_ENVIRONMENT]}/profil`,
                            external: true
                        }/*,
                        {
                            label: 'DÉCONNEXION',
                            url: {`/logout/${application}`}
                        }*/
                    ]}
                    semibold
                />
            </Structure.Center>
        </Toolbar>
    );
}

export default Mobile;
