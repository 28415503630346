import React from 'react';
import {Buttons, Grid, Inputs} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 320,
        padding: theme.spacing(2)
    }
}));

function StepImprovementComment() {
    const classes = useStyles();

    return (
        <Grid
            item
            xs={12}
            className={classes.root}
        >
            <Inputs.Textarea
                name="improvement_comment"
                rows="6"
                style={{minHeight: 160, resize: 'none'}}
                label="Quels sont les points d'améliorations pour augmenter votre satisfaction personnelle ?"
            />
            <Buttons.Default
                label="Envoyer"
                color="blue"
                center
                next
                submit
            />
        </Grid>
    );
}

export default StepImprovementComment;
