export const s2e_profile = {
    "gender": "M",
    "first_name": "ERAWAN",
    "last_name": "EDRICH",
    "email": "s2e.recette@s2e-net.com",
    "mobile": "0600000000",
    "birth_date": "1988-07-07",
    "bank_accounts": [
        {
            "iban": "FR7630006000011234567890189",
            "bic": "AGRIFRPP",
            "type": "PRELEVEMENT",
            "warrant": {
                "rum": "MD002OES01427509",
                "ics": "FR82ZZZ480624"
            }
        },
        {
            "iban": "FR7610278040020002089530137",
            "bic": "CMCIFR2AXXX",
            "type": "VIREMENT",
            "warrant": null
        }
    ],
    "zip_postal_code": "88630",
    "street_line": "5 RUE DE CHERMISEY",
    "locality": "AVRANVILLE",
    "country": "FRANCE",
    "additional_address_1": null,
    "additional_address_2": null,
    "additional_address_3": null,
    "personal_phone": null,
    "companies": [
        {
            "id": "32322",
            "payor": false,
            "name": "KISIO SERVICES & CONSULTING",
            "main_company": true,
            "max_attachment_size": 10000000
        }
    ]
};

export const s2e_accounts = [
    {
        scope: 's2e',
        type: 'PEG',
        balance: 4842.498153,
        available_balance: 8507.317,
        company_name: 'ADIS',
        company_id: '803',
        title: 'PEEG AXA SHAREPLAN',
        policy_id: '0000229243',
        is_guided: false,
        investments: [
            {
                id: '61699',
                label: 'AXA ISR EUROPE ACTIONS',
                type: 'FONDS',
                amount: 20862.57,
                availabilities: [
                    {
                        montant: {
                            montant: 4156.397,
                            devise: 'EUR'
                        },
                        nb_parts: 59.3771,
                        disponibilite: {
                            condition: 'DISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2212.868,
                            devise: 'EUR'
                        },
                        nb_parts: 31.6124,
                        disponibilite: {
                            date: '2024-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2045.47,
                            devise: 'EUR'
                        },
                        nb_parts: 29.221,
                        disponibilite: {
                            date: '2025-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 92.582,
                            devise: 'EUR'
                        },
                        nb_parts: 1.3226,
                        disponibilite: {
                            date: '2026-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    }
                ],
                share_number: 12.2721,
                share_value: 1700.0,
                valuation_date: '2021-08-04T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_8_ANS_MIN',
                risk_level: 6,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: 'VCP',
                classification: 'Actions Internationales',
                invalid_guided_data: false,
                investment_underlying: {
                    title: 'AXA ISR EUROPE ACTIONS',
                    quotation_period: null,
                    validity: 6,
                    share_value: 1700.0,
                    valuation_date: '2021-08-04T00:00:00Z'
                }
            },
            {
                id: '991',
                label: 'AXA DIVERSIFIE SOLIDAIRE',
                type: 'FONDS',
                amount: 1223.7,
                availabilities: [
                    {
                        montant: {
                            montant: 4156.397,
                            devise: 'EUR'
                        },
                        nb_parts: 59.3771,
                        disponibilite: {
                            condition: 'DISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2212.868,
                            devise: 'EUR'
                        },
                        nb_parts: 31.6124,
                        disponibilite: {
                            date: '2024-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2045.47,
                            devise: 'EUR'
                        },
                        nb_parts: 29.221,
                        disponibilite: {
                            date: '2025-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 92.582,
                            devise: 'EUR'
                        },
                        nb_parts: 1.3226,
                        disponibilite: {
                            date: '2026-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    }
                ],
                share_number: 8.158,
                share_value: 150.0,
                valuation_date: '2021-09-20T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_4_ANS_MIN',
                risk_level: 4,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: 'VCP',
                classification: 'Non applicable',
                invalid_guided_data: false,
                investment_underlying: {
                    title: 'AXA DIVERSIFIE SOLIDAIRE',
                    quotation_period: 'OUVERTURE',
                    validity: 6,
                    share_value: 150.0,
                    valuation_date: '2021-09-20T00:00:00Z'
                }
            }
        ],
        external_management: false,
        label: 'PEG'
    },
    {
        scope: 's2e',
        type: 'PEG',
        balance: 64042.9372,
        available_balance: 1925.1492,
        company_name: 'ADIS',
        company_id: '803',
        title: 'PEEG AXA REFLEX',
        policy_id: '0000066096',
        is_guided: false,
        investments: [
            {
                id: '1005',
                label: 'AXA EURO 4M',
                type: 'FONDS',
                availabilities: [
                    {
                        montant: {
                            montant: 4156.397,
                            devise: 'EUR'
                        },
                        nb_parts: 59.3771,
                        disponibilite: {
                            condition: 'DISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2212.868,
                            devise: 'EUR'
                        },
                        nb_parts: 31.6124,
                        disponibilite: {
                            date: '2024-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2045.47,
                            devise: 'EUR'
                        },
                        nb_parts: 29.221,
                        disponibilite: {
                            date: '2025-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 92.582,
                            devise: 'EUR'
                        },
                        nb_parts: 1.3226,
                        disponibilite: {
                            date: '2026-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    }
                ],
                amount: 391.3992,
                share_number: 32.6166,
                share_value: 12.0,
                valuation_date: '2022-06-30T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_3_MOIS_MIN',
                risk_level: 1,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: 'VCP',
                classification: 'Obligations et autres TC euros',
                invalid_guided_data: false,
                investment_underlying: {
                    title: 'AXA EURO 4M',
                    quotation_period: null,
                    validity: 6,
                    share_value: 12.0,
                    valuation_date: '2022-06-30T00:00:00Z'
                }
            },
            {
                id: '169',
                label: 'AXA HORIZON ISR',
                type: 'FONDS',
                amount: 598.788,
                availabilities: [
                    {
                        montant: {
                            montant: 4156.397,
                            devise: 'EUR'
                        },
                        nb_parts: 59.3771,
                        disponibilite: {
                            condition: 'DISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2212.868,
                            devise: 'EUR'
                        },
                        nb_parts: 31.6124,
                        disponibilite: {
                            date: '2024-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2045.47,
                            devise: 'EUR'
                        },
                        nb_parts: 29.221,
                        disponibilite: {
                            date: '2025-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 92.582,
                            devise: 'EUR'
                        },
                        nb_parts: 1.3226,
                        disponibilite: {
                            date: '2026-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    }
                ],
                share_number: 9.9798,
                share_value: 60.0,
                valuation_date: '2021-09-20T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_3_ANS_MIN',
                risk_level: 3,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: 'VCP',
                classification: 'Obligations et autres TC euros',
                invalid_guided_data: false,
                investment_underlying: {
                    title: 'AXA HORIZON ISR',
                    quotation_period: null,
                    validity: 6,
                    share_value: 60.0,
                    valuation_date: '2021-09-20T00:00:00Z'
                }
            },
            {
                id: '167',
                label: 'AXA ES LONG TERME 2M',
                type: 'FONDS',
                amount: 934.962,
                availabilities: [
                    {
                        montant: {
                            montant: 4156.397,
                            devise: 'EUR'
                        },
                        nb_parts: 59.3771,
                        disponibilite: {
                            condition: 'DISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2212.868,
                            devise: 'EUR'
                        },
                        nb_parts: 31.6124,
                        disponibilite: {
                            date: '2024-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2045.47,
                            devise: 'EUR'
                        },
                        nb_parts: 29.221,
                        disponibilite: {
                            date: '2025-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 92.582,
                            devise: 'EUR'
                        },
                        nb_parts: 1.3226,
                        disponibilite: {
                            date: '2026-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    }
                ],
                share_number: 13.3566,
                share_value: 70.0,
                valuation_date: '2021-09-08T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_8_ANS_MIN',
                risk_level: 4,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: 'SDD',
                classification: 'Non applicable',
                invalid_guided_data: false,
                investment_underlying: {
                    title: 'AXA ES LONG TERME 2M',
                    quotation_period: null,
                    validity: 6,
                    share_value: 70.0,
                    valuation_date: '2021-09-08T00:00:00Z'
                }
            }
        ],
        external_management: false,
        label: 'PEG'
    },
    {
        scope: 's2e',
        type: 'PER C1 bis',
        balance: 150.0,
        available_balance: 10.0,
        company_name: 'ENTREPRISE DEMO',
        company_id: '31902',
        title: 'PERCO Piloté',
        policy_id: '0000229243',
        is_guided: true,
        investments: [],
        external_management: true,
        label: 'PERECO'
    },
    {
        scope: 's2e',
        type: 'PER C1',
        balance: 2000.0,
        available_balance: 2000.0,
        company_name: 'ENTREPRISE DEMO',
        company_id: '31902',
        title: 'PERCO Piloté',
        policy_id: '0000229243',
        is_guided: true,
        investments: [],
        external_management: false,
        label: 'PERECO'
    },
    {
        scope: 's2e',
        type: 'PERCO',
        balance: 8000.0,
        available_balance: 8000.0,
        company_name: 'ENTREPRISE DEMO',
        company_id: '31902',
        title: 'PERCO Piloté',
        policy_id: '0000229243',
        is_guided: true,
        investments: [],
        external_management: true,
        label: 'PERCO'
    },
    {
        scope: 's2e',
        type: 'PERCO',
        balance: 500.0,
        available_balance: 100.0,
        company_id: '31902',
        company_name: 'ENTREPRISE DEMO',
        title: 'PERCO Piloté',
        policy_id: '0000229223',
        is_guided: true,
        investments: [],
        external_management: true,
        label: 'PERCO'
    },
    {
        scope: 's2e',
        type: 'PERCO',
        balance: 1678.0,
        available_balance: 1678.0,
        company_id: '31902',
        company_name: 'ENTREPRISE DEMO',
        title: 'PERCO Piloté',
        policy_id: '0000229229',
        is_guided: true,
        investments: [],
        external_management: false,
        label: 'PERCO'
    },
    {
        scope: 's2e',
        type: 'PERCO',
        balance: 0.0,
        available_balance: 0.0,
        company_name: 'ENTREPRISE DEMO',
        company_id: '31902',
        title: 'PERCO Libre',
        policy_id: '0000229245',
        is_guided: false,
        investments: [],
        external_management: false,
        label: 'PERCO'
    },
    {
        scope: 's2e',
        type: 'PEE',
        balance: 562.4552,
        available_balance: 120.0,
        company_name: 'ENTREPRISE DEMO ARBITRAGE ES',
        company_id: '31903',
        title: 'PEE',
        policy_id: '0000229211',
        is_guided: false,
        external_management: false,
        label: 'PEE',
        investments: [
            {
                id: '1763',
                label: 'AXA WF GLOBAL STRATEGIC BONDS A(H) CAP EUR',
                type: 'FONDS',
                amount: 362.4552,
                availabilities: [
                    {
                        montant: {
                            montant: 4156.397,
                            devise: 'EUR'
                        },
                        nb_parts: 59.3771,
                        disponibilite: {
                            condition: 'DISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2212.868,
                            devise: 'EUR'
                        },
                        nb_parts: 31.6124,
                        disponibilite: {
                            date: '2024-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2045.47,
                            devise: 'EUR'
                        },
                        nb_parts: 29.221,
                        disponibilite: {
                            date: '2025-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 92.582,
                            devise: 'EUR'
                        },
                        nb_parts: 1.3226,
                        disponibilite: {
                            date: '2026-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    }
                ],
                share_number: 33.0856,
                share_value: 17.0,
                valuation_date: '2021-11-04T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_3_MOIS_MIN',
                risk_level: 1,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: 'VCP',
                classification: 'Monétaire',
                is_abundant: true,
                investment_underlying: {
                    title: 'AXA',
                    quotation_period: 'OUVERTURE',
                    validity: 2,
                    share_value: 9.0,
                    valuation_date: '2021-11-04T00:00:00Z'
                }
            },
            {
                id: '1789',
                label: 'AXA LONG TERM',
                type: 'FONDS',
                amount: 200,
                availabilities: [
                    {
                        montant: {
                            montant: 4156.397,
                            devise: 'EUR'
                        },
                        nb_parts: 59.3771,
                        disponibilite: {
                            condition: 'DISPONIBLE'
                        }
                    }
                ],
                share_number: 10,
                share_value: 20.0,
                valuation_date: '2021-11-04T00:00:00Z',
                valuation_frequency: 'TRIMESTRIELLE',
                horizon: 'HP_8_ANS_MIN',
                risk_level: 1,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Monétaire',
                is_abundant: true,
                investment_underlying: {}
            }
        ]
    },
    {
        scope: 's2e',
        type: 'PEE',
        balance: 820.4521,
        available_balance: 220.0,
        company_name: 'ENTREPRISE DEMO ARBITRAGE ES',
        title: 'PEE',
        policy_id: '0000229340',
        is_guided: false,
        external_management: false,
        investments: [
            {
                id: '1788',
                label: 'AXA WF GLOBAL STRATEGIC BONDS A(H) CAP EUR',
                type: 'FONDS',
                amount: 956.4882,
                availabilities: [
                    {
                        montant: {
                            montant: 4156.397,
                            devise: 'EUR'
                        },
                        nb_parts: 59.3771,
                        disponibilite: {
                            condition: 'DISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2212.868,
                            devise: 'EUR'
                        },
                        nb_parts: 31.6124,
                        disponibilite: {
                            date: '2024-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2045.47,
                            devise: 'EUR'
                        },
                        nb_parts: 29.221,
                        disponibilite: {
                            date: '2025-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 92.582,
                            devise: 'EUR'
                        },
                        nb_parts: 1.3226,
                        disponibilite: {
                            date: '2026-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    }
                ],
                share_number: 99.0878,
                share_value: 20.0,
                valuation_date: '2021-11-04T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_3_MOIS_MIN',
                risk_level: 1,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: 'VCP',
                classification: 'Monétaire',
                is_abundant: false,
                investment_underlying: {
                    title: 'AXA',
                    quotation_period: 'OUVERTURE',
                    validity: 2,
                    share_value: 9.0,
                    valuation_date: '2021-11-04T00:00:00Z'
                }
            },
            {
                id: '8956',
                label: 'AXA LONG TERM',
                type: 'FONDS',
                amount: 900,
                availabilities: [
                    {
                        montant: {
                            montant: 4156.397,
                            devise: 'EUR'
                        },
                        nb_parts: 59.3771,
                        disponibilite: {
                            condition: 'DISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2212.868,
                            devise: 'EUR'
                        },
                        nb_parts: 31.6124,
                        disponibilite: {
                            date: '2024-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2045.47,
                            devise: 'EUR'
                        },
                        nb_parts: 29.221,
                        disponibilite: {
                            date: '2025-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 92.582,
                            devise: 'EUR'
                        },
                        nb_parts: 1.3226,
                        disponibilite: {
                            date: '2026-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    }
                ],
                share_number: 55,
                share_value: 75.0,
                valuation_date: '2021-11-04T00:00:00Z',
                valuation_frequency: 'TRIMESTRIELLE',
                horizon: 'HP_8_ANS_MIN',
                risk_level: 2,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Monétaire',
                is_abundant: true,
                investment_underlying: {}
            }
        ]
    },
    {
        scope: 's2e',
        type: 'PERCO',
        balance: 400,
        available_balance: 0.0,
        company_name: 'ENTREPRISE DEMO',
        title: 'PERCO',
        policy_id: '0000229899',
        is_guided: false,
        company_id: '31902',
        external_management: true,
        label: 'PERCO',
        investments: [
            {
                id: '6365',
                label: 'CAPITAL MONETAIRE PART 2R',
                type: 'FONDS',
                amount: 400,
                availabilities: [
                    {
                        montant: {
                            montant: 4156.397,
                            devise: 'EUR'
                        },
                        nb_parts: 59.3771,
                        disponibilite: {
                            condition: 'DISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2212.868,
                            devise: 'EUR'
                        },
                        nb_parts: 31.6124,
                        disponibilite: {
                            date: '2024-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 2045.47,
                            devise: 'EUR'
                        },
                        nb_parts: 29.221,
                        disponibilite: {
                            date: '2025-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    },
                    {
                        montant: {
                            montant: 92.582,
                            devise: 'EUR'
                        },
                        nb_parts: 1.3226,
                        disponibilite: {
                            date: '2026-06-01T00:00:00Z',
                            condition: 'INDISPONIBLE'
                        }
                    }
                ],
                share_number: 33.0856,
                share_value: 17.0,
                valuation_date: '2021-11-04T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_3_MOIS_MIN',
                risk_level: 1,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: 'VCP',
                classification: 'Monétaire',
                is_abundant: true,
                investment_underlying: {
                    title: 'AXA',
                    quotation_period: 'OUVERTURE',
                    validity: 2,
                    share_value: 9.0,
                    valuation_date: '2021-11-04T00:00:00Z'
                }
            }
        ]
    }
];

export const s2e_arbitration_destination_account = [
    {
        scope: 's2e',
        type: 'PEG',
        balance: 0.0,
        company_name: 'ADIS',
        company_id: '803',
        title: 'PEE Libre',
        policy_id: '0000066096',
        is_guided: false,
        investments: [
            {
                id: '169',
                label: 'AXA HORIZON ISR',
                type: 'FONDS',
                amount: 0.0,
                availabilities: [],
                share_number: 0.0,
                share_value: 60.0,
                valuation_date: '2021-09-20T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_3_ANS_MIN',
                risk_level: 3,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Obligations et autres TC euros'
            },
            {
                id: '167',
                label: 'AXA ES LONG TERME 2M',
                type: 'FONDS',
                amount: 0.0,
                availabilities: [],
                share_number: 0.0,
                share_value: 70.0,
                valuation_date: '2021-09-08T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_8_ANS_MIN',
                risk_level: 4,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Non applicable'
            },
            {
                id: '61699',
                label: 'AXA ISR EUROPE ACTIONS',
                type: 'FONDS',
                amount: 0.0,
                availabilities: [],
                share_number: 0.0,
                share_value: 1700.0,
                valuation_date: '2021-08-04T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_8_ANS_MIN',
                risk_level: 6,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Actions Internationales'
            },
            {
                id: '991',
                label: 'AXA DIVERSIFIE SOLIDAIRE',
                type: 'FONDS',
                amount: 0.0,
                availabilities: [],
                share_number: 0.0,
                share_value: 150.0,
                valuation_date: '2021-09-20T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_4_ANS_MIN',
                risk_level: 4,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Non applicable'
            },
            {
                id: '1005',
                label: 'AXA EURO 4M',
                type: 'FONDS',
                amount: 0.0,
                availabilities: [],
                share_number: 0.0,
                share_value: 18.62,
                valuation_date: '2021-09-21T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_3_MOIS_MIN',
                risk_level: 1,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Obligations et autres TC euros'
            }
        ]
    },
    {
        scope: 's2e',
        type: 'PERCO',
        balance: 0.0,
        title: 'PERCO libre',
        policy_id: '0000446125',
        is_guided: false,
        retirement_due_date: true,
        investments: [
            {
                id: '1763',
                label: 'CAPITAL MONETAIRE PART 2R',
                type: 'FONDS',
                amount: 0.0,
                availabilities: [],
                share_number: 0.0,
                share_value: 17.0,
                valuation_date: null,
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_3_MOIS_MIN',
                risk_level: 1,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Monétaire',
                abundant_interessement: false,
                abundant_participation: false,
                invalid_guided_data: false,
                order: 0
            }
        ]
    },
    {
        scope: 's2e',
        type: 'PERCO',
        balance: 0.0,
        title: 'PERCO Piloté',
        policy_id: '0000229223',
        is_guided: true,
        retirement_due_date: true,
        investments: [
            {
                id: '1763',
                label: 'CAPITAL MONETAIRE PART 2R',
                type: 'FONDS',
                amount: 0.0,
                availabilities: [],
                share_number: 0.0,
                share_value: 17.0,
                valuation_date: null,
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_3_MOIS_MIN',
                risk_level: 1,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Monétaire',
                abundant_interessement: false,
                abundant_participation: false,
                invalid_guided_data: true,
                order: 0
            }
        ]
    }
];

export const s2e_transactions = [
    {
        amount: 0,
        date: '2022-06-22T00:00:00Z',
        id: '1-1KJTRS3',
        label: 'Arbitrage',
        status: 'En cours de traitement',
        type: 'TRANSFERT'
    },
    {
        amount: 2000,
        date: '2021-12-21T00:00:00Z',
        id: '1-1S2KZ0S',
        label: 'Participation',
        status: 'En cours de traitement',
        type: 'BO_PARTICIPATION',
        deadline_date: '2024-12-21T00:00:00Z'
    },
    {
        "id": "O-149960715",
        "amount": 63.3,
        "abondment": 0.0,
        "date": "2022-04-25T00:00:00Z",
        "status": "Traitée",
        "type": "BO_INTERESSEMENT",
        "label": "Intéressement",
        "deadline_date": null
    },
    {
        "id": "O-149959936",
        "amount": 479.13,
        "abondment": 0.0,
        "date": "2022-04-25T00:00:00Z",
        "status": "Traitée",
        "type": "BO_PARTICIPATION",
        "label": "Participation",
        "deadline_date": null
    },
    {
        amount: 0,
        date: '2020-06-26T00:00:00Z',
        label: 'Abondement',
        status: 'Traitée',
        type: 'COMPTABLE_ABONDEMENT'
    },
    {
        amount: 0,
        date: '2020-03-21T00:00:00Z',
        label: 'R\u00e9allocation',
        status: 'Traitée',
        type: 'COMPTABLE_REALLOCATION'
    },
    {
        amount: 0,
        date: '2019-05-29T00:00:00Z',
        label: 'Abondement',
        status: 'Traitée',
        type: 'COMPTABLE_ABONDEMENT'
    },
    {
        amount: 527.65,
        date: '2019-03-29T00:00:00Z',
        label: 'Int\u00e9ressement',
        status: 'Traitée',
        type: 'BO_INTERESSEMENT'
    },
    {
        amount: 0,
        date: '2020-06-26T00:00:00Z',
        label: 'Abondement',
        status: 'Traitée',
        type: 'COMPTABLE_ABONDEMENT'
    },
    {
        amount: 2609.52,
        date: '2020-04-29T00:00:00Z',
        label: 'Int\u00e9ressement',
        status: 'Traitée',
        type: 'BO_INTERESSEMENT'
    },
    {
        amount: 0,
        date: '2020-03-21T00:00:00Z',
        label: 'R\u00e9allocation',
        status: 'Traitée',
        type: 'COMPTABLE_REALLOCATION'
    },
    {
        amount: 0,
        date: '2019-05-29T00:00:00Z',
        label: 'Abondement',
        status: 'Traitée',
        type: 'COMPTABLE_ABONDEMENT'
    },
    {
        amount: 527.65,
        date: '2019-03-29T00:00:00Z',
        label: 'Int\u00e9ressement',
        status: 'Traitée',
        type: 'BO_INTERESSEMENT'
    }
];

export const s2e_transaction_details = {
    id: '1-1S2KZ0S',
    company_id: '31902',
    date: '2022-01-03T00:00:00Z',
    deadline_date: '2022-04-27T00:00:00Z',
    gross_amount: 2127.45,
    net_amount: 2000,
    social_tax: 127.45,
    payment_amount: 1000,
    instructions: [
        {
            policy_id: '0000229223',
            investment_id: '1763',
            amount: 250
        },
        {
            policy_id: '0000229219',
            investment_id: '2377',
            amount: 250
        },
        {
            policy_id: '0000229219',
            investment_id: '1763',
            amount: 500
        },
        {
            policy_id: '0000229223',
            investment_id: '1763',
            amount: 107.84,
            nature: 'ABONDEMENT'
        },
        {
            policy_id: '0000229219',
            investment_id: '2377',
            amount: 79.48,
            nature: 'ABONDEMENT'
        },
        {
            policy_id: '0000229219',
            investment_id: '1763',
            amount: 120.18,
            nature: 'ABONDEMENT'
        }
    ],
    allocable_accounts: [
        {
            scope: 's2e',
            type: 'PERCO',
            balance: 0.0,
            title: 'PERCO Piloté',
            policy_id: '0000229223',
            is_guided: true,
            retirement_due_date: true,
            investments: [
                {
                    id: '1763',
                    label: 'CAPITAL MONETAIRE PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 0.0,
                    share_value: 17.0,
                    valuation_date: null,
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_MOIS_MIN',
                    risk_level: 1,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'Monétaire',
                    abundant_interessement: false,
                    abundant_participation: false,
                    invalid_guided_data: false,
                    order: 0
                }
            ]
        },
        {
            scope: 's2e',
            type: 'PERCO',
            balance: 0.0,
            title: 'PERCO Libre',
            policy_id: '0000229219',
            is_guided: false,
            retirement_due_date: true,
            investments: [
                {
                    id: '2377',
                    label: 'AXA GENERATION EQUILIBRE PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 0.0,
                    share_value: 22.0,
                    valuation_date: null,
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_4_ANS_MIN',
                    risk_level: 4,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'Non applicable',
                    abundant_interessement: false,
                    abundant_participation: false,
                    invalid_guided_data: false,
                    order: 0
                },
                {
                    id: '2383',
                    label: 'AXA GENERATION VITALITE PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 0.0,
                    share_value: 22.0,
                    valuation_date: '2020-12-31T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_6_ANS_MIN',
                    risk_level: 5,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'Non applicable',
                    abundant_interessement: false,
                    abundant_participation: false,
                    invalid_guided_data: false,
                    order: 0
                },
                {
                    id: '2369',
                    label: 'AXA GENERATION EURO OBLIG PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 0.0,
                    share_value: 20.0,
                    valuation_date: '2021-10-20T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_ANS_MIN',
                    risk_level: 3,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'Obligations et autres TC euros',
                    abundant_interessement: false,
                    abundant_participation: false,
                    invalid_guided_data: false,
                    order: 0
                },
                {
                    id: '1763',
                    label: 'CAPITAL MONETAIRE PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 0.0,
                    share_value: 17.0,
                    valuation_date: '2021-11-04T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_MOIS_MIN',
                    risk_level: 1,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'Monétaire',
                    abundant_interessement: false,
                    abundant_participation: false,
                    invalid_guided_data: false,
                    order: 0
                },
                {
                    id: '2379',
                    label: 'AXA GEN TEMPERE SOLIDAIRE PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 0.0,
                    share_value: 20.0,
                    valuation_date: '2020-12-31T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_ANS_MIN',
                    risk_level: 3,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'Non applicable',
                    abundant_interessement: false,
                    abundant_participation: false,
                    invalid_guided_data: false,
                    order: 0
                }
            ]
        },
        {
            scope: 's2e',
            type: 'PEE',
            balance: 562.4552,
            title: 'PEE',
            policy_id: '0000229211',
            is_guided: false,
            retirement_due_date: false,
            investments: [
                {
                    id: '1763',
                    label: 'CAPITAL MONETAIRE PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 33.0856,
                    share_value: 17.0,
                    valuation_date: '2021-11-04T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_MOIS_MIN',
                    risk_level: 1,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'SDD',
                    classification: 'Monétaire',
                    abundant_interessement: true,
                    abundant_participation: true,
                    invalid_guided_data: false,
                    order: 0
                },
                {
                    id: '2375',
                    label: 'AXA GENERATION EUROPE ACT PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 0.0,
                    share_value: 27.0,
                    valuation_date: '2021-10-20T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_8_ANS_MIN',
                    risk_level: 6,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'Actions Internationales',
                    abundant_interessement: false,
                    abundant_participation: false,
                    invalid_guided_data: false,
                    order: 0
                },
                {
                    id: '2379',
                    label: 'AXA GEN TEMPERE SOLIDAIRE PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 0.0,
                    share_value: 20.0,
                    valuation_date: '2020-12-31T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_ANS_MIN',
                    risk_level: 3,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'Non applicable',
                    abundant_interessement: false,
                    abundant_participation: false,
                    invalid_guided_data: false,
                    order: 0
                },
                {
                    id: '2383',
                    label: 'AXA GENERATION VITALITE PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 0.0,
                    share_value: 22.0,
                    valuation_date: '2020-12-31T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_6_ANS_MIN',
                    risk_level: 5,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'Non applicable',
                    abundant_interessement: false,
                    abundant_participation: false,
                    invalid_guided_data: false,
                    order: 0
                },
                {
                    id: '2377',
                    label: 'AXA GENERATION EQUILIBRE PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 0.0,
                    share_value: 22.0,
                    valuation_date: '2020-12-31T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_4_ANS_MIN',
                    risk_level: 4,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'Non applicable',
                    abundant_interessement: false,
                    abundant_participation: false,
                    invalid_guided_data: false,
                    order: 0
                },
                {
                    id: '2369',
                    label: 'AXA GENERATION EURO OBLIG PART 2R',
                    type: 'FONDS',
                    amount: 0.0,
                    share_number: 0.0,
                    share_value: 20.0,
                    valuation_date: '2021-10-20T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_ANS_MIN',
                    risk_level: 3,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'Obligations et autres TC euros',
                    abundant_interessement: false,
                    abundant_participation: false,
                    invalid_guided_data: false,
                    order: 0
                }
            ]
        }
    ]
};

export const s2e_alerts = [
    {
        id_transaction: '1-1S23P2R',
        amount: 2480.0,
        date: '2022-06-29T00:00:00Z'
    },
    {
        id_transaction: '1-1S23P2E',
        amount: 1600.0,
        date: '2022-04-07T00:00:00Z'
    }
];

export const s2e_abondment_simulations = [
    {
        policy_id: '0000229211',
        investments: [{id: '1763', amount: 169.0}]
    },
    {
        policy_id: '0000501052',
        investments: [
            {id: '10001', amount: 199.0},
            {id: '10002', amount: 69.0}
        ]
    }
];

export const s2e_option_bulletins = [
    {
        id: '1-1S23P2R',
        type: 'PARTICIPATION',
        company_id: '31902',
        company_name: 'ENTREPRISE DEMO',
        amount: 2480.0,
        company_amount: 16000.0,
        is_abundantly: true,
        start_date: '2022-01-01T00:00:00Z',
        end_date: '2022-12-31T00:00:00Z',
        deadline_date: '2022-06-29T00:00:00Z',
        minimum_investment_amount: 500.0,
        default_affectations: [
            {
                policy_id: '0000229223',
                investments: [{id: '1763', amount: 800.0}]
            },
            {
                policy_id: '0000229211',
                investments: [{id: '1763', amount: 800.0}]
            }
        ],
        allocable_accounts: [
            {
                scope: 's2e',
                type: 'PERCO',
                company_id: '31902',
                balance: 0.0,
                title: 'PERCO Piloté',
                policy_id: '0000229223',
                is_guided: true,
                retirement_due_date: false,
                investments: [
                    {
                        id: '1763',
                        label: 'CAPITAL MONETAIRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 17.0,
                        valuation_date: null,
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_MOIS_MIN',
                        risk_level: 1,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Monétaire',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    }
                ]
            },
            {
                scope: 's2e',
                type: 'PERCO',
                company_id: '31902',
                balance: 0.0,
                title: 'PERCO Libre',
                policy_id: '0000229219',
                is_guided: false,
                retirement_due_date: true,
                investments: [
                    {
                        id: '2377',
                        label: 'AXA GENERATION EQUILIBRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 22.0,
                        valuation_date: null,
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_4_ANS_MIN',
                        risk_level: 4,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2383',
                        label: 'AXA GENERATION VITALITE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 22.0,
                        valuation_date: '2020-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_6_ANS_MIN',
                        risk_level: 5,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2369',
                        label: 'AXA GENERATION EURO OBLIG PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 20.0,
                        valuation_date: '2021-10-20T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_ANS_MIN',
                        risk_level: 3,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Obligations et autres TC euros',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '1763',
                        label: 'CAPITAL MONETAIRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 17.0,
                        valuation_date: '2021-11-04T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_MOIS_MIN',
                        risk_level: 1,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Monétaire',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2379',
                        label: 'AXA GEN TEMPERE SOLIDAIRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 20.0,
                        valuation_date: '2020-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_ANS_MIN',
                        risk_level: 3,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    }
                ]
            },
            {
                scope: 's2e',
                type: 'PEE',
                company_id: '31902',
                balance: 562.4552,
                title: 'PEE',
                policy_id: '0000229211',
                is_guided: false,
                retirement_due_date: true,
                investments: [
                    {
                        id: '1763',
                        label: 'CAPITAL MONETAIRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 33.0856,
                        share_value: 17.0,
                        valuation_date: '2021-11-04T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_MOIS_MIN',
                        risk_level: 1,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: 'SDD',
                        classification: 'Monétaire',
                        abundant_interessement: true,
                        abundant_participation: true,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2375',
                        label: 'AXA GENERATION EUROPE ACT PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 27.0,
                        valuation_date: '2021-10-20T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_8_ANS_MIN',
                        risk_level: 6,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Actions Internationales',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2379',
                        label: 'AXA GEN TEMPERE SOLIDAIRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 20.0,
                        valuation_date: '2020-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_ANS_MIN',
                        risk_level: 3,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2383',
                        label: 'AXA GENERATION VITALITE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 22.0,
                        valuation_date: '2020-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_6_ANS_MIN',
                        risk_level: 5,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2377',
                        label: 'AXA GENERATION EQUILIBRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 22.0,
                        valuation_date: '2020-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_4_ANS_MIN',
                        risk_level: 4,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2369',
                        label: 'AXA GENERATION EURO OBLIG PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 20.0,
                        valuation_date: '2021-10-20T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_ANS_MIN',
                        risk_level: 3,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Obligations et autres TC euros',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    }
                ]
            }
        ]
    },
    {
        id: '1-1S23P2E',
        type: 'INTERESSEMENT',
        company_id: '31902',
        company_name: 'ENTREPRISE DEMO 2',
        amount: 1600.0,
        company_amount: 16000.0,
        is_abundantly: true,
        start_date: '2022-01-01T00:00:00Z',
        end_date: '2022-12-31T00:00:00Z',
        deadline_date: '2022-04-07T00:00:00Z',
        minimum_investment_amount: 0,
        default_affectations: [
            {
                policy_id: '0000229223',
                investments: [{id: '1763', amount: 800.0}]
            },
            {
                policy_id: '0000229211',
                investments: [{id: '1763', amount: 800.0}]
            }
        ],
        allocable_accounts: [
            {
                scope: 's2e',
                company_id: '31902',
                type: 'PERCO',
                balance: 0,
                title: 'PERCO',
                policy_id: '0000501052',
                is_guided: false,
                investments: [
                    {
                        id: '10000',
                        label: 'FONDS EN EUROS',
                        type: 'FONDS',
                        amount: 0,
                        share_number: 0,
                        share_value: null,
                        valuation_date: null,
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_NC',
                        risk_level: 0,
                        management_company: 'FONDS COMPTA MANUELLE',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        invalid_guided_data: false,
                        investment_underlying: null,
                        abundant_interessement: false,
                        abundant_participation: false,
                        order: null
                    },
                    {
                        id: '10001',
                        label: 'GESTION PAR HORIZON PRUDENT',
                        type: 'FONDS',
                        amount: 0,
                        share_number: 0,
                        share_value: null,
                        valuation_date: null,
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_NC',
                        risk_level: 0,
                        management_company: 'FONDS COMPTA MANUELLE',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        invalid_guided_data: false,
                        investment_underlying: null,
                        abundant_interessement: true,
                        abundant_participation: true,
                        order: null
                    },
                    {
                        id: '10002',
                        label: 'GESTION PAR HORIZON EQUILIBRE',
                        type: 'FONDS',
                        amount: 0,
                        share_number: 0,
                        share_value: null,
                        valuation_date: null,
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_NC',
                        risk_level: 0,
                        management_company: 'FONDS COMPTA MANUELLE',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        invalid_guided_data: false,
                        investment_underlying: null,
                        abundant_interessement: false,
                        abundant_participation: false,
                        order: null
                    }
                ],
                order: 40,
                retirement_due_date: true
            },
            {
                scope: 'ais',
                type: 'PERU',
                company_id: '31902',
                balance: 0,
                title: 'PERU Gestion libre',
                policy_id: '0000444903',
                is_guided: false,
                retirement_due_date: true,
                investments: [
                    {
                        id: '674',
                        label: 'CAPITAL MONETAIRE PART 2',
                        type: 'GESTION_EXTERNE',
                        amount: 0,
                        share_number: 0,
                        share_value: 25,
                        valuation_date: '2021-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_MOIS_MIN',
                        risk_level: 1,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Monétaire',
                        invalid_guided_data: false,
                        order: 0,
                        abundant_interessement: false,
                        abundant_participation: false
                    },
                    {
                        id: '3196',
                        label: 'CCEP - ACTIONS INTERNATIONALES',
                        type: 'GESTION_EXTERNE',
                        amount: 0,
                        share_number: 0,
                        share_value: 50,
                        valuation_date: '2021-12-19T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_5_ANS_MIN',
                        risk_level: 6,
                        management_company: 'EQUITIS GESTION',
                        trigger_threshold: null,
                        classification: 'Actions Internationales',
                        invalid_guided_data: false,
                        order: 2,
                        abundant_interessement: false,
                        abundant_participation: false
                    },
                    {
                        id: '2371',
                        label: 'AXA GENERATION EURO OBLIG PART 2M',
                        type: 'GESTION_EXTERNE',
                        amount: 0,
                        share_number: 0,
                        share_value: 23,
                        valuation_date: '2021-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_ANS_MIN',
                        risk_level: 3,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Obligations et autres TC euros',
                        invalid_guided_data: false,
                        order: 0,
                        abundant_interessement: false,
                        abundant_participation: false
                    },
                    {
                        id: '10000',
                        label: 'FONDS EN EURO',
                        type: 'GESTION_EXTERNE',
                        amount: 0,
                        share_number: 0,
                        share_value: 0,
                        valuation_date: null,
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_NC',
                        risk_level: 0,
                        management_company: 'FONDS COMPTA MANUELLE',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        invalid_guided_data: false,
                        order: 0,
                        abundant_interessement: false,
                        abundant_participation: false
                    },
                    {
                        id: '1379',
                        label: 'AXA GEN TEMPERE SOLIDAIRE PART 2.',
                        type: 'GESTION_EXTERNE',
                        amount: 0,
                        share_number: 0,
                        share_value: 192,
                        valuation_date: '2021-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_ANS_MIN',
                        risk_level: 3,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        invalid_guided_data: false,
                        order: 0,
                        abundant_interessement: false,
                        abundant_participation: false
                    },
                    {
                        id: '167',
                        label: 'AXA ES LONG TERME 2M',
                        type: 'GESTION_EXTERNE',
                        amount: 0,
                        share_number: 0,
                        share_value: 75,
                        valuation_date: '2021-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_8_ANS_MIN',
                        risk_level: 4,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        invalid_guided_data: false,
                        order: 0,
                        abundant_interessement: false,
                        abundant_participation: false
                    }
                ],
                order: 10
            },
            {
                scope: 's2e',
                type: 'PERCO',
                company_id: '31902',
                balance: 0.0,
                title: 'PERCO Piloté',
                policy_id: '0000229223',
                is_guided: true,
                retirement_due_date: true,
                investments: [
                    {
                        id: '1763',
                        label: 'CAPITAL MONETAIRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 17.0,
                        valuation_date: null,
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_MOIS_MIN',
                        risk_level: 1,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Monétaire',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: true
                    }
                ],
                order: 11
            },
            {
                scope: 's2e',
                type: 'PERCO',
                company_id: '31902',
                balance: 0.0,
                title: 'PERCO Libre',
                policy_id: '0000229219',
                is_guided: false,
                retirement_due_date: true,
                investments: [
                    {
                        id: '2377',
                        label: 'AXA GENERATION EQUILIBRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 22.0,
                        valuation_date: null,
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_4_ANS_MIN',
                        risk_level: 4,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2383',
                        label: 'AXA GENERATION VITALITE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 22.0,
                        valuation_date: '2020-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_6_ANS_MIN',
                        risk_level: 5,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2369',
                        label: 'AXA GENERATION EURO OBLIG PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 20.0,
                        valuation_date: '2021-10-20T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_ANS_MIN',
                        risk_level: 3,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Obligations et autres TC euros',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '1763',
                        label: 'CAPITAL MONETAIRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 17.0,
                        valuation_date: '2021-11-04T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_MOIS_MIN',
                        risk_level: 1,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Monétaire',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2379',
                        label: 'AXA GEN TEMPERE SOLIDAIRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 20.0,
                        valuation_date: '2020-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_ANS_MIN',
                        risk_level: 3,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    }
                ],
                order: 12
            },
            {
                scope: 's2e',
                type: 'PEE',
                company_id: '31902',
                balance: 562.4552,
                title: 'PEE',
                policy_id: '0000229211',
                is_guided: false,
                retirement_due_date: true,
                investments: [
                    {
                        id: '1763',
                        label: 'CAPITAL MONETAIRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 33.0856,
                        share_value: 17.0,
                        valuation_date: '2021-11-04T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_MOIS_MIN',
                        risk_level: 1,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: 'SDD',
                        classification: 'Monétaire',
                        abundant_interessement: true,
                        abundant_participation: true,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2375',
                        label: 'AXA GENERATION EUROPE ACT PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 27.0,
                        valuation_date: '2021-10-20T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_8_ANS_MIN',
                        risk_level: 6,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Actions Internationales',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2379',
                        label: 'AXA GEN TEMPERE SOLIDAIRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 20.0,
                        valuation_date: '2020-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_ANS_MIN',
                        risk_level: 3,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2383',
                        label: 'AXA GENERATION VITALITE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 22.0,
                        valuation_date: '2020-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_6_ANS_MIN',
                        risk_level: 5,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2377',
                        label: 'AXA GENERATION EQUILIBRE PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 22.0,
                        valuation_date: '2020-12-31T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_4_ANS_MIN',
                        risk_level: 4,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Non applicable',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    },
                    {
                        id: '2369',
                        label: 'AXA GENERATION EURO OBLIG PART 2R',
                        type: 'FONDS',
                        amount: 0.0,
                        share_number: 0.0,
                        share_value: 20.0,
                        valuation_date: '2021-10-20T00:00:00Z',
                        valuation_frequency: 'QUOTIDIEN',
                        horizon: 'HP_3_ANS_MIN',
                        risk_level: 3,
                        management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                        trigger_threshold: null,
                        classification: 'Obligations et autres TC euros',
                        abundant_interessement: false,
                        abundant_participation: false,
                        invalid_guided_data: false,
                        order: 0
                    }
                ],
                order: 16
            }
        ]
    }
];

export const s2e_accounts_voluntary_payment = [
    {
        scope: 's2e',
        type: 'PEE',
        balance: 0.0,
        available_balance: 0.0,
        company_name: 'AXA',
        company_id: '31902',
        title: 'PEE',
        policy_id: '0000277694',
        is_guided: false,
        investments: [
            {
                id: '2378',
                label: 'AXA GEN TEMPERE SOLIDAIRE PART 2M',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 10.0,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_4_ANS_MIN',
                risk_level: 4,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Non applicable',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '674',
                label: 'CAPITAL MONETAIRE PART 2',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 23.4525,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_2_MOIS_MIN',
                risk_level: 1,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Monétaire',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '330',
                label: 'AXA GENERATION SOLIDAIRE PART 2',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 11.0,
                valuation_date: '2021-09-14T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_5_ANS_MIN',
                risk_level: 6,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Actions de pays zone Euro',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '136',
                label: 'CAPITAL FRANCE ACTIONS',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 119.4,
                valuation_date: '2021-07-28T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_5_ANS_MIN',
                risk_level: 6,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Actions Françaises',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '2371',
                label: 'AXA GENERATION EURO OBLIG PART 2M',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 21.72,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_3_ANS_MIN',
                risk_level: 3,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Obligations et autres TC euros',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '2376',
                label: 'AXA GENERATION EQUILIBRE PART 2M',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 25.02,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_4_ANS_MIN',
                risk_level: 4,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Non applicable',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '2380',
                label: 'AXA GENERATION VITALITE PART 2M',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 25.61,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_5_ANS_MIN',
                risk_level: 5,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Non applicable',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '2373',
                label: 'AXA GENERATION EUROPE ACT PART 2M',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 27.57,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_5_ANS_MIN',
                risk_level: 6,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Actions Internationales',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            }
        ],
        external_management: false,
        label: 'PEE'
    },
    {
        scope: 's2e',
        type: 'PERCO',
        balance: 0.0,
        available_balance: 0.0,
        company_name: 'AXA',
        company_id: '31902',
        title: 'PERCO Piloté',
        policy_id: '0000278173',
        is_guided: true,
        investments: [
            {
                id: '674',
                label: 'CAPITAL MONETAIRE PART 2',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 23.4525,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_2_MOIS_MIN',
                risk_level: 1,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Monétaire',
                invalid_guided_data: true,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            }
        ],
        external_management: false,
        label: 'PERCO'
    },
    {
        scope: 's2e',
        type: 'PERCO',
        balance: 0.0,
        available_balance: 0.0,
        company_name: 'AXA',
        company_id: '31902',
        title: 'PERCO LIBRE',
        policy_id: '0000278171',
        is_guided: false,
        investments: [
            {
                id: '330',
                label: 'AXA GENERATION SOLIDAIRE PART 2',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 11.0,
                valuation_date: '2021-09-14T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_5_ANS_MIN',
                risk_level: 6,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Actions de pays zone Euro',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '136',
                label: 'CAPITAL FRANCE ACTIONS',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 119.4,
                valuation_date: '2021-07-28T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_5_ANS_MIN',
                risk_level: 6,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Actions Françaises',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '2371',
                label: 'AXA GENERATION EURO OBLIG PART 2M',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 21.72,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_3_ANS_MIN',
                risk_level: 3,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Obligations et autres TC euros',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '2380',
                label: 'AXA GENERATION VITALITE PART 2M',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 25.61,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_5_ANS_MIN',
                risk_level: 5,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Non applicable',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '2376',
                label: 'AXA GENERATION EQUILIBRE PART 2M',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 25.02,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_4_ANS_MIN',
                risk_level: 4,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Non applicable',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '2373',
                label: 'AXA GENERATION EUROPE ACT PART 2M',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 27.57,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_5_ANS_MIN',
                risk_level: 6,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Actions Internationales',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: false
            },
            {
                id: '2378',
                label: 'AXA GEN TEMPERE SOLIDAIRE PART 2M',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 10.0,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_4_ANS_MIN',
                risk_level: 4,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Non applicable',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            },
            {
                id: '674',
                label: 'CAPITAL MONETAIRE PART 2',
                type: 'FONDS',
                amount: 0.0,
                share_number: 0.0,
                share_value: 23.4525,
                valuation_date: '2021-12-31T00:00:00Z',
                valuation_frequency: 'QUOTIDIEN',
                horizon: 'HP_2_MOIS_MIN',
                risk_level: 1,
                management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                trigger_threshold: null,
                classification: 'Monétaire',
                invalid_guided_data: false,
                investment_underlying: {
                    title: null,
                    quotation_period: null,
                    validity: null,
                    share_value: null,
                    valuation_date: null
                },
                is_abundant: true
            }
        ],
        external_management: false,
        label: 'PERCO'
    }
];

export const s2e_contribution_gauge = {
    pee: {
        ceiling: 3138,
        consumed: 150
    },
    perco: null
};

export const s2e_permissions = [
    {
        company_id: null,
        id: 'XZRZG',
        type: 'ABONDEMENT'
    },
    {
        company_id: null,
        id: 'ERYER',
        type: 'ABONDEMENT_SIMULATEUR_INTERESSEMENT'
    },
    {
        company_id: null,
        id: 'JZVZR',
        type: 'ABONDEMENT_SIMULATEUR_PARTICIPATION'
    }
];

export const s2e_valuations = [
    {
        amount: 18,
        date: '2022-01-24T00:00:00Z'
    },
    {
        amount: 18,
        date: '2022-01-17T00:00:00Z'
    },
    {
        amount: 18,
        date: '2022-01-15T00:00:00Z'
    },
    {
        amount: 18,
        date: '2022-01-05T00:00:00Z'
    },
    {
        amount: 18,
        date: '2022-01-01T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-12-03T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-12-02T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-12-01T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-11-30T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-11-29T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-11-25T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-11-24T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-11-23T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-11-22T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-11-04T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-11-03T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-11-02T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-10-29T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-10-28T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-10-27T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-10-26T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-10-25T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-10-22T00:00:00Z'
    },
    {
        amount: 17,
        date: '2021-10-21T00:00:00Z'
    },
    {
        amount: 16,
        date: '2021-10-20T00:00:00Z'
    },
    {
        amount: 16,
        date: '2021-10-19T00:00:00Z'
    },
    {
        amount: 16,
        date: '2021-10-18T00:00:00Z'
    },
    {
        amount: 16,
        date: '2021-10-15T00:00:00Z'
    },
    {
        amount: 16,
        date: '2021-10-14T00:00:00Z'
    },
    {
        amount: 16,
        date: '2021-10-13T00:00:00Z'
    },
    {
        amount: 16,
        date: '2021-10-12T00:00:00Z'
    },
    {
        amount: 16,
        date: '2021-10-11T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-31T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-30T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-29T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-28T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-24T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-23T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-22T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-21T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-18T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-17T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-16T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-15T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-14T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-11T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-10T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-09T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-08T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-07T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-04T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-03T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-02T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-12-01T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-30T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-27T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-26T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-25T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-24T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-23T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-20T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-19T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-18T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-17T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-16T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-13T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-12T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-10T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-09T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-06T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-05T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-04T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-03T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-11-02T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-30T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-29T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-28T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-27T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-26T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-23T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-22T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-21T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-20T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-19T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-16T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-15T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-14T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-13T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-12T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-09T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-08T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-07T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-06T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-05T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-02T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-10-01T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-30T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-29T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-28T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-25T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-24T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-23T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-22T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-21T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-18T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-17T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-16T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-15T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-14T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-11T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-10T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-09T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-08T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-07T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-04T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-03T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-02T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-09-01T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-08-31T00:00:00Z'
    },
    {
        amount: 16,
        date: '2020-08-28T00:00:00Z'
    },
    {
        amount: 15.0637,
        date: '2020-08-27T00:00:00Z'
    },
    {
        amount: 15.064,
        date: '2020-08-26T00:00:00Z'
    },
    {
        amount: 15.0639,
        date: '2020-08-25T00:00:00Z'
    },
    {
        amount: 15.0642,
        date: '2020-08-24T00:00:00Z'
    },
    {
        amount: 15.0643,
        date: '2020-08-21T00:00:00Z'
    },
    {
        amount: 15.0644,
        date: '2020-08-20T00:00:00Z'
    },
    {
        amount: 15.0644,
        date: '2020-08-19T00:00:00Z'
    },
    {
        amount: 15.0645,
        date: '2020-08-18T00:00:00Z'
    },
    {
        amount: 15.0646,
        date: '2020-08-17T00:00:00Z'
    },
    {
        amount: 15.065,
        date: '2020-08-14T00:00:00Z'
    },
    {
        amount: 15.0647,
        date: '2020-08-13T00:00:00Z'
    },
    {
        amount: 15.0648,
        date: '2020-08-12T00:00:00Z'
    },
    {
        amount: 15.0644,
        date: '2020-08-11T00:00:00Z'
    },
    {
        amount: 15.0648,
        date: '2020-08-10T00:00:00Z'
    },
    {
        amount: 15.065,
        date: '2020-08-07T00:00:00Z'
    },
    {
        amount: 15.0652,
        date: '2020-08-06T00:00:00Z'
    },
    {
        amount: 15.0652,
        date: '2020-08-05T00:00:00Z'
    },
    {
        amount: 15.0656,
        date: '2020-08-04T00:00:00Z'
    },
    {
        amount: 15.0655,
        date: '2020-08-03T00:00:00Z'
    },
    {
        amount: 15.0658,
        date: '2020-07-31T00:00:00Z'
    },
    {
        amount: 15.0656,
        date: '2020-07-30T00:00:00Z'
    },
    {
        amount: 15.0654,
        date: '2020-07-29T00:00:00Z'
    },
    {
        amount: 15.0651,
        date: '2020-07-28T00:00:00Z'
    },
    {
        amount: 15.0649,
        date: '2020-07-27T00:00:00Z'
    },
    {
        amount: 15.0654,
        date: '2020-07-24T00:00:00Z'
    },
    {
        amount: 15.0649,
        date: '2020-07-23T00:00:00Z'
    },
    {
        amount: 15.0651,
        date: '2020-07-22T00:00:00Z'
    },
    {
        amount: 15.065,
        date: '2020-07-21T00:00:00Z'
    },
    {
        amount: 15.0649,
        date: '2020-07-20T00:00:00Z'
    },
    {
        amount: 15.0652,
        date: '2020-07-17T00:00:00Z'
    },
    {
        amount: 15.0642,
        date: '2020-07-16T00:00:00Z'
    },
    {
        amount: 15.064,
        date: '2020-07-15T00:00:00Z'
    },
    {
        amount: 15.0644,
        date: '2020-07-13T00:00:00Z'
    },
    {
        amount: 15.0647,
        date: '2020-07-10T00:00:00Z'
    },
    {
        amount: 15.0646,
        date: '2020-07-09T00:00:00Z'
    },
    {
        amount: 15.0646,
        date: '2020-07-08T00:00:00Z'
    },
    {
        amount: 15.0645,
        date: '2020-07-07T00:00:00Z'
    },
    {
        amount: 15.0645,
        date: '2020-07-06T00:00:00Z'
    },
    {
        amount: 15.0647,
        date: '2020-07-03T00:00:00Z'
    },
    {
        amount: 15.0645,
        date: '2020-07-02T00:00:00Z'
    },
    {
        amount: 15.0646,
        date: '2020-07-01T00:00:00Z'
    },
    {
        amount: 15.0644,
        date: '2020-06-30T00:00:00Z'
    },
    {
        amount: 15.0644,
        date: '2020-06-29T00:00:00Z'
    },
    {
        amount: 15.0644,
        date: '2020-06-26T00:00:00Z'
    },
    {
        amount: 15.0638,
        date: '2020-06-25T00:00:00Z'
    },
    {
        amount: 15.064,
        date: '2020-06-24T00:00:00Z'
    },
    {
        amount: 15.064,
        date: '2020-06-23T00:00:00Z'
    },
    {
        amount: 15.0643,
        date: '2020-06-22T00:00:00Z'
    },
    {
        amount: 15.0641,
        date: '2020-06-19T00:00:00Z'
    },
    {
        amount: 15.0641,
        date: '2020-06-18T00:00:00Z'
    },
    {
        amount: 15.0639,
        date: '2020-06-17T00:00:00Z'
    },
    {
        amount: 15.0638,
        date: '2020-06-16T00:00:00Z'
    },
    {
        amount: 15.0638,
        date: '2020-06-15T00:00:00Z'
    },
    {
        amount: 15.064,
        date: '2020-06-12T00:00:00Z'
    },
    {
        amount: 15.0639,
        date: '2020-06-11T00:00:00Z'
    },
    {
        amount: 15.064,
        date: '2020-06-10T00:00:00Z'
    },
    {
        amount: 15.0642,
        date: '2020-06-09T00:00:00Z'
    },
    {
        amount: 15.064,
        date: '2020-06-08T00:00:00Z'
    },
    {
        amount: 15.064,
        date: '2020-06-05T00:00:00Z'
    },
    {
        amount: 15.0638,
        date: '2020-06-04T00:00:00Z'
    },
    {
        amount: 15.0628,
        date: '2020-06-03T00:00:00Z'
    },
    {
        amount: 15.0622,
        date: '2020-06-02T00:00:00Z'
    },
    {
        amount: 15.0616,
        date: '2020-05-29T00:00:00Z'
    },
    {
        amount: 15.0616,
        date: '2020-05-28T00:00:00Z'
    },
    {
        amount: 15.0613,
        date: '2020-05-27T00:00:00Z'
    },
    {
        amount: 15.0608,
        date: '2020-05-26T00:00:00Z'
    },
    {
        amount: 15.0607,
        date: '2020-05-25T00:00:00Z'
    },
    {
        amount: 15.0608,
        date: '2020-05-22T00:00:00Z'
    },
    {
        amount: 15.0601,
        date: '2020-05-20T00:00:00Z'
    },
    {
        amount: 15.0599,
        date: '2020-05-19T00:00:00Z'
    },
    {
        amount: 15.0594,
        date: '2020-05-18T00:00:00Z'
    },
    {
        amount: 15.0595,
        date: '2020-05-15T00:00:00Z'
    },
    {
        amount: 15.0595,
        date: '2020-05-14T00:00:00Z'
    },
    {
        amount: 15.0595,
        date: '2020-05-13T00:00:00Z'
    },
    {
        amount: 15.0595,
        date: '2020-05-12T00:00:00Z'
    },
    {
        amount: 15.0597,
        date: '2020-05-11T00:00:00Z'
    },
    {
        amount: 15.0595,
        date: '2020-05-07T00:00:00Z'
    },
    {
        amount: 15.0596,
        date: '2020-05-06T00:00:00Z'
    },
    {
        amount: 15.0595,
        date: '2020-05-05T00:00:00Z'
    },
    {
        amount: 15.0594,
        date: '2020-05-04T00:00:00Z'
    },
    {
        amount: 15.0595,
        date: '2020-04-30T00:00:00Z'
    },
    {
        amount: 15.0592,
        date: '2020-04-29T00:00:00Z'
    },
    {
        amount: 15.0589,
        date: '2020-04-28T00:00:00Z'
    },
    {
        amount: 15.0581,
        date: '2020-04-27T00:00:00Z'
    }
];

export const s2e_countries = [
    {label: 'AFGHANISTAN', value: 'AF', international_transfer: 'NON_ACTIF', account_type: 'BBAN'},
    {label: 'AFRIQUE DU SUD', value: 'ZA', international_transfer: 'NON_ACTIF', account_type: 'IBAN'},
    {label: 'ALBANIE', value: 'AL', international_transfer: 'NON_ACTIF', account_type: 'BBAN'},
    {label: 'ALGERIE', value: 'DZ', international_transfer: 'NON_ACTIF', account_type: 'BBAN'},
    {label: 'ALLEMAGNE', value: 'DE', international_transfer: 'ZONE_EURO', account_type: 'BBAN'},
    {label: 'ANDORRE', value: 'AD', international_transfer: 'ZONE_EURO', account_type: 'BBAN'},
    {label: 'ANGOLA', value: 'AO', international_transfer: 'NON_ACTIF', account_type: 'IBAN'},
    {label: 'ANGUILLA', value: 'AI', international_transfer: 'NON_ACTIF', account_type: 'BBAN'},
    {label: 'ANTARCTIQUE', value: 'AQ', international_transfer: 'NON_ACTIF', account_type: 'IBAN'},
    {label: 'ANTIGUA ET BARBUDA', value: 'AG', international_transfer: 'NON_ACTIF', account_type: 'BBAN'},
    {label: 'ANTILLES NEERLANDAISES', value: 'AN', international_transfer: 'HORS_ZONE', account_type: 'RIB'},
    {label: 'FRANCE', value: 'FR', international_transfer: 'ZONE_EURO', account_type: 'IBAN'}
];

export const s2e_bank_account_context = {
    tsa_address: {
        recipient: 'M. JEAN DUPONT',
        precision: 'precision',
        zip_postal_code: '33700',
        street_line: '3, rue des Oiseaux',
        locality: 'Mérignac',
        country: 'France',
        additional_address_1: 'Bat. B',
        additional_address_2: 'Additional 2',
        additional_address_3: 'Additional 3'
    },
    transfer: {
        modification: true,
        state: 'VIDE',
        debit_authorization: false,
        input_mode: 'DATA_AND_UPLOAD'
    },
    debit: {
        modification: true,
        state: 'VALIDE',
        input_mode: 'DATA_AND_UPLOAD'
    },
    signature: {
        max_attachment_size: 2000000,
        authorized_countries: ['FR', 'DE'],
        authorized_signature: true,
        valid_mobile_phone: false,
        ladrad_authorization: true
    }
};

export const s2e_voluntary_payment_context_31902 = {
    max_amount: 10000,
    blockings: [],
    day_limit: 15,
    day_value_liquidity: 28,
    direct_debit_exceptional_payment: true,
    debit_card_exceptional_payment: true,
    scheduled_payment: true,
    scheduled_payment_creation: true,
    frequencies: ['MENSUELLE', 'TRIMESTRIELLE', 'ANNUELLE']
};

export const s2e_voluntary_payment_lab_context_31902 = {
    mode: 'UPLOAD',
    file_size: 10000000,
    total_file_size: 20000000,
    tsa_address: {
        recipient: 'M. JEAN DUPONT',
        precision: 'precision',
        zip_postal_code: '33700',
        street_line: '3, rue des Oiseaux',
        locality: 'Mérignac',
        country: 'France',
        additional_address_1: 'Bat. B',
        additional_address_2: 'Additional 2',
        additional_address_3: 'Additional 3'
    },
    company_id: '31902'
};

export const s2e_voluntary_payment_context_31903 = {
    max_amount: 20000,
    blockings: ['DONNEES_PERSO'],
    day_limit: 15,
    day_value_liquidity: 28,
    direct_debit_exceptional_payment: false,
    debit_card_exceptional_payment: true,
    scheduled_payment: true,
    scheduled_payment_creation: true,
    frequencies: ['MENSUELLE', 'TRIMESTRIELLE', 'SEMESTRIELLE', 'ANNUELLE']
};

export const s2e_voluntary_payment_lab_context_31903 = {
    mode: 'UPLOAD',
    file_size: 10000000,
    total_file_size: 20000000,
    tsa_address: {
        recipient: 'M. JEAN DUPONT',
        precision: 'precision',
        zip_postal_code: '33700',
        street_line: '3, rue des Oiseaux',
        locality: 'Mérignac',
        country: 'France',
        additional_address_1: 'Bat. B',
        additional_address_2: 'Additional 2',
        additional_address_3: 'Additional 3'
    },
    company_id: '31903'
};

export const s2e_voluntary_payment_permission = {
    punctual: true,
    periodic: true
};

export const s2e_identity_context = {
    input_mode: 'CORRESPONDANT',
    tsa_address: {
        recipient: 'M. JEAN DUPONT',
        precision: 'precision',
        zip_postal_code: '33700',
        street_line: '3, rue des Oiseaux',
        locality: 'Mérignac',
        country: 'France',
        additional_address_1: 'Bat. B',
        additional_address_2: 'Additional 2',
        additional_address_3: 'Additional 3'
    }
};

export const s2e_address_context = {
    input_mode: 'TSA_OR_UPLOAD',
    tsa_address: {
        recipient: 'M. JEAN DUPONT',
        precision: 'precision',
        zip_postal_code: '33700',
        street_line: '3, rue des Oiseaux',
        locality: 'Mérignac',
        country: 'France',
        additional_address_1: 'Bat. B',
        additional_address_2: 'Additional 2',
        additional_address_3: 'Additional 3'
    },
    validation_state: 'VIDE'
};

export const s2e_refunds_permissions = {
    s2e_refunds_permissions: [
        {
            company_id: '31902',
            type: 'REMBOURSEMENT_DISPONIBLE',
            id: '31902_REMBOURSEMENT_DISPONIBLE'
        },
        {
            company_id: '31902',
            type: 'REMBOURSEMENT_A_ECHEANCE',
            id: '31902_REMBOURSEMENT_A_ECHEANCE'
        },
        {
            company_id: '31902',
            type: 'REMBOURSEMENT_INDISPONIBLE',
            id: '31902_REMBOURSEMENT_INDISPONIBLE'
        }
    ]
};

export const s2e_refunds_context_now = {
    s2e_refund_now: {
        id_entreprise: '31902',
        blocages: [],
        blocage_avoirs: false,
        avoirs_sans_fiscalite: false,
        avoirs_sans_rachat: false,
        autorisation: true,
        _links: {
            entreprise: {
                href: '/entreprises/31902',
                method: 'GET'
            }
        },
        type: 'REMBOURSEMENT_DISPONIBLE'
    }
};

export const s2e_refunds_context_withdelay = {
    s2e_refund_withDelay: {
        id_entreprise: '31902',
        blocages: [],
        blocage_avoirs: false,
        avoirs_sans_fiscalite: false,
        avoirs_sans_rachat: false,
        autorisation: true,
        _links: {
            entreprise: {
                href: '/entreprises/31902',
                method: 'GET'
            }
        },
        type: 'REMBOURSEMENT_A_ECHEANCE'
    }
};

export const s2e_refunds_context_withConditions = {
    s2e_refund_withConditions: {
        id_entreprise: '31902',
        blocages: [],
        blocage_avoirs: false,
        avoirs_sans_fiscalite: false,
        avoirs_sans_rachat: false,
        autorisation: true,
        mode_saisie: 'TSA_OR_UPLOAD',
        taille_max_pj: 10000000,
        taille_max_pj_total: 25000000,
        _links: {
            entreprise: {
                href: '/entreprises/31902',
                method: 'GET'
            }
        },
        type: 'REMBOURSEMENT_INDISPONIBLE'
    }
};

export const s2e_refund_now = {
    s2e_refund_now: [
        {
            scope: 's2e',
            type: 'PEG',
            balance: 1925.1492,
            available_balance: 0.0,
            company_name: 'ADIS',
            company_id: '31902',
            title: 'PEEG AXA REFLEX',
            policy_id: '0000066096',
            is_guided: false,
            investments: [
                {
                    id: '1005',
                    label: 'AXA EURO 4M',
                    type: 'FONDS',
                    amount: 391.3992,
                    share_number: 32.6166,
                    share_value: 12.0,
                    valuation_date: '2022-06-30T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_MOIS_MIN',
                    risk_level: 1,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'VCP',
                    classification: 'Obligations et autres TC euros',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA EURO 4M',
                        quotation_period: null,
                        validity: 6,
                        share_value: 12.0,
                        valuation_date: '2022-06-30T00:00:00Z'
                    }
                },
                {
                    id: '169',
                    label: 'AXA HORIZON ISR',
                    type: 'FONDS',
                    amount: 598.788,
                    share_number: 9.9798,
                    share_value: 60.0,
                    valuation_date: '2021-09-20T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_ANS_MIN',
                    risk_level: 3,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'SDD',
                    classification: 'Obligations et autres TC euros',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA HORIZON ISR',
                        quotation_period: null,
                        validity: 6,
                        share_value: 60.0,
                        valuation_date: '2021-09-20T00:00:00Z'
                    }
                },
                {
                    id: '167',
                    label: 'AXA ES LONG TERME 2M',
                    type: 'FONDS',
                    amount: 934.962,
                    share_number: 13.3566,
                    share_value: 70.0,
                    valuation_date: '2021-09-08T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_8_ANS_MIN',
                    risk_level: 4,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'SDD',
                    classification: 'Non applicable',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA ES LONG TERME 2M',
                        quotation_period: null,
                        validity: 6,
                        share_value: 70.0,
                        valuation_date: '2021-09-08T00:00:00Z'
                    }
                }
            ],
            external_management: false,
            label: 'PEG'
        },
        {
            scope: 's2e',
            type: 'PERCO',
            balance: 4725.1492,
            available_balance: 0.0,
            company_name: 'ADIS',
            company_id: '31902',
            title: 'PERCO AXA TEST',
            policy_id: '0000066091',
            is_guided: false,
            investments: [
                {
                    id: '1001',
                    label: 'AXA EURO 4M',
                    type: 'FONDS',
                    amount: 3491.3992,
                    share_number: 32.6166,
                    share_value: 12.0,
                    valuation_date: '2022-06-30T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_MOIS_MIN',
                    risk_level: 1,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'SDD',
                    classification: 'Obligations et autres TC euros',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA EURO 4M',
                        quotation_period: null,
                        validity: 6,
                        share_value: 12.0,
                        valuation_date: '2022-06-30T00:00:00Z'
                    }
                },
                {
                    id: '163',
                    label: 'AXA HORIZON ISR',
                    type: 'FONDS',
                    amount: 298.788,
                    share_number: 9.9798,
                    share_value: 60.0,
                    valuation_date: '2021-09-20T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_ANS_MIN',
                    risk_level: 3,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'SDD',
                    classification: 'Obligations et autres TC euros',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA HORIZON ISR',
                        quotation_period: null,
                        validity: 6,
                        share_value: 60.0,
                        valuation_date: '2021-09-20T00:00:00Z'
                    }
                },
                {
                    id: '167',
                    label: 'AXA ES LONG TERME 2M',
                    type: 'FONDS',
                    amount: 934.962,
                    share_number: 13.3566,
                    share_value: 70.0,
                    valuation_date: '2021-09-08T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_8_ANS_MIN',
                    risk_level: 4,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'SDD',
                    classification: 'Non applicable',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA ES LONG TERME 2M',
                        quotation_period: null,
                        validity: 6,
                        share_value: 70.0,
                        valuation_date: '2021-09-08T00:00:00Z'
                    }
                }
            ],
            external_management: false,
            label: 'PEG'
        }
    ]
};

export const s2e_refund_withConditions_reason = {
    s2e_refund_withConditions: [
        {
            scope: 's2e',
            type: 'PEG',
            balance: 4842.498153,
            available_balance: 0.0,
            company_name: 'ADIS',
            company_id: '31902',
            title: 'PEEG AXA SHAREPLAN',
            policy_id: '0000066098',
            is_guided: false,
            investments: [
                {
                    id: '3150',
                    label: 'AXA PLAN 2019 FRANCE',
                    type: 'FONDS',
                    amount: 1439.999294,
                    share_number: 66.2678,
                    share_value: 21.73,
                    valuation_date: '2021-07-26T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_5_ANS_MIN',
                    risk_level: 1,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'A formule',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: null,
                        quotation_period: null,
                        validity: null,
                        share_value: null,
                        valuation_date: null
                    }
                },
                {
                    id: '1734',
                    label: 'AXA ACTIONNARIAT DIRECT',
                    type: 'FONDS',
                    amount: 3402.498859,
                    share_number: 145.4681,
                    share_value: 23.39,
                    valuation_date: '2021-12-30T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_8_ANS_MIN',
                    risk_level: 7,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'VCP',
                    classification: 'Titres cotés entreprise',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA',
                        quotation_period: 'OUVERTURE',
                        validity: 6,
                        share_value: 23.5,
                        valuation_date: '2021-04-09T00:00:00Z'
                    }
                }
            ],
            external_management: false,
            label: 'PEG'
        }
    ]
};

export const s2e_refund_withConditions = {
    s2e_refund_withConditions: [
        {
            scope: 's2e',
            type: 'PEG',
            balance: 62117.788,
            available_balance: 0.0,
            company_name: 'ADIS',
            company_id: '31902',
            title: 'PEEG AXA REFLEX',
            policy_id: '0000066096',
            is_guided: false,
            investments: [
                {
                    id: '61699',
                    label: 'AXA ISR EUROPE ACTIONS',
                    type: 'FONDS',
                    amount: 55979.3,
                    share_number: 32.929,
                    share_value: 1700.0,
                    valuation_date: '2021-08-04T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_8_ANS_MIN',
                    risk_level: 6,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'SDD',
                    classification: 'Actions Internationales',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA ISR EUROPE ACTIONS',
                        quotation_period: null,
                        validity: 6,
                        share_value: 1700.0,
                        valuation_date: '2021-08-04T00:00:00Z'
                    }
                },
                {
                    id: '991',
                    label: 'AXA DIVERSIFIE SOLIDAIRE',
                    type: 'FONDS',
                    amount: 1223.7,
                    share_number: 8.158,
                    share_value: 150.0,
                    valuation_date: '2021-09-20T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_4_ANS_MIN',
                    risk_level: 4,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'SDD',
                    classification: 'Non applicable',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA DIVERSIFIE SOLIDAIRE',
                        quotation_period: null,
                        validity: 6,
                        share_value: 150.0,
                        valuation_date: '2021-09-20T00:00:00Z'
                    }
                },
                {
                    id: '169',
                    label: 'AXA HORIZON ISR',
                    type: 'FONDS',
                    amount: 1270.56,
                    share_number: 21.176,
                    share_value: 60.0,
                    valuation_date: '2021-09-20T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_3_ANS_MIN',
                    risk_level: 3,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'SDD',
                    classification: 'Obligations et autres TC euros',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA HORIZON ISR',
                        quotation_period: null,
                        validity: 6,
                        share_value: 60.0,
                        valuation_date: '2021-09-20T00:00:00Z'
                    }
                },
                {
                    id: '167',
                    label: 'AXA ES LONG TERME 2M',
                    type: 'FONDS',
                    amount: 3644.228,
                    share_number: 52.0604,
                    share_value: 70.0,
                    valuation_date: '2021-09-08T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_8_ANS_MIN',
                    risk_level: 4,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'SDD',
                    classification: 'Non applicable',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA ES LONG TERME 2M',
                        quotation_period: null,
                        validity: 6,
                        share_value: 70.0,
                        valuation_date: '2021-09-08T00:00:00Z'
                    }
                }
            ],
            external_management: false,
            label: 'PEG'
        },
        {
            scope: 's2e',
            type: 'PEG',
            balance: 4842.498153,
            available_balance: 0.0,
            company_name: 'ADIS',
            company_id: '31902',
            title: 'PEEG AXA SHAREPLAN',
            policy_id: '0000066098',
            is_guided: false,
            investments: [
                {
                    id: '3150',
                    label: 'AXA PLAN 2019 FRANCE',
                    type: 'FONDS',
                    amount: 1439.999294,
                    share_number: 66.2678,
                    share_value: 21.73,
                    valuation_date: '2021-07-26T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_5_ANS_MIN',
                    risk_level: 1,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: null,
                    classification: 'A formule',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: null,
                        quotation_period: null,
                        validity: null,
                        share_value: null,
                        valuation_date: null
                    }
                },
                {
                    id: '1734',
                    label: 'AXA ACTIONNARIAT DIRECT',
                    type: 'FONDS',
                    amount: 3402.498859,
                    share_number: 145.4681,
                    share_value: 23.39,
                    valuation_date: '2021-12-30T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_8_ANS_MIN',
                    risk_level: 7,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'VCP',
                    classification: 'Titres cotés entreprise',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA',
                        quotation_period: 'OUVERTURE',
                        validity: 6,
                        share_value: 23.5,
                        valuation_date: '2021-04-09T00:00:00Z'
                    }
                }
            ],
            external_management: false,
            label: 'PEG'
        }
    ]
};

export const s2e_refund_withDelay = {
    s2e_refund_withDelay: [
        {
            scope: 's2e',
            type: 'PEG',
            balance: 22086.27,
            available_balance: 0.0,
            company_name: 'ADIS',
            company_id: '31902',
            title: 'PEEG AXA REFLEX',
            policy_id: '0000066096',
            is_guided: false,
            investments: [
                {
                    id: '61699',
                    label: 'AXA ISR EUROPE ACTIONS',
                    type: 'FONDS',
                    amount: 20862.57,
                    share_number: 12.2721,
                    share_value: 1700.0,
                    valuation_date: '2021-08-04T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_8_ANS_MIN',
                    risk_level: 6,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'SDD',
                    classification: 'Actions Internationales',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA ISR EUROPE ACTIONS',
                        quotation_period: null,
                        validity: 6,
                        share_value: 1700.0,
                        valuation_date: '2021-08-04T00:00:00Z'
                    }
                },
                {
                    id: '991',
                    label: 'AXA DIVERSIFIE SOLIDAIRE',
                    type: 'FONDS',
                    amount: 1223.7,
                    share_number: 8.158,
                    share_value: 150.0,
                    valuation_date: '2021-09-20T00:00:00Z',
                    valuation_frequency: 'QUOTIDIEN',
                    horizon: 'HP_4_ANS_MIN',
                    risk_level: 4,
                    management_company: 'AXA INVESTISSEMENT MANAGERS PARIS',
                    trigger_threshold: 'VCP',
                    classification: 'Non applicable',
                    invalid_guided_data: false,
                    investment_underlying: {
                        title: 'AXA DIVERSIFIE SOLIDAIRE',
                        quotation_period: 'OUVERTURE',
                        validity: 6,
                        share_value: 150.0,
                        valuation_date: '2021-09-20T00:00:00Z'
                    }
                }
            ],
            external_management: false,
            label: 'PEG'
        }
    ]
};

export const s2e_releases = {
    s2e_releases: [
        {
            id: '1-GCLQ',
            code_motif: 'D',
            code_sous_motif: 'D-02',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCI1',
            code_motif: 'CE',
            code_sous_motif: 'CE-06',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-9HHZHM',
            code_motif: 'M',
            code_sous_motif: 'M-04',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-9HGW3Z',
            code_motif: 'M',
            code_sous_motif: 'M-05',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCEX',
            code_motif: 'HA - PEE',
            code_sous_motif: 'HA-PEE-01',
            actif: true,
            declarer_montant_c1: true,
            declarer_montant: true,
            debut_intervalle_dates: 'DATE_DU_JOUR',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCGT',
            code_motif: 'CE',
            code_sous_motif: 'CE-07',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCFF',
            code_motif: 'HA',
            code_sous_motif: 'HA-05',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: true,
            declarer_montant: true,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-GCIB',
            code_motif: 'CE',
            code_sous_motif: 'CE-11',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCMH',
            code_motif: 'IV',
            code_sous_motif: 'IV-01',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-1NASOBX',
            code_motif: 'AC',
            code_sous_motif: 'AC-01',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-GCGH',
            code_motif: 'HA',
            code_sous_motif: 'HA-02',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: true,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-6SBVU1',
            code_motif: 'HA',
            code_sous_motif: 'HA-03',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-GCKP',
            code_motif: 'HA',
            code_sous_motif: 'HA-04',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-9HGW19',
            code_motif: 'M',
            code_sous_motif: 'M-06',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-9HIMTV',
            code_motif: 'CE',
            code_sous_motif: 'CE-09',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCK7',
            code_motif: 'CC',
            code_sous_motif: 'CC-05',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-GCEN',
            code_motif: 'TE',
            code_sous_motif: 'TE-01',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-1NASOC2',
            code_motif: 'CA',
            code_sous_motif: 'CA-01',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-GCE3',
            code_motif: 'M',
            code_sous_motif: 'M-03',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-1NYVPMM',
            code_motif: 'CA',
            code_sous_motif: 'CA-02',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-6SBVRT',
            code_motif: 'HA',
            code_sous_motif: 'HA-01',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: true,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-9HG72D',
            code_motif: 'CE',
            code_sous_motif: 'CE-05',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCEB',
            code_motif: 'M',
            code_sous_motif: 'M-01',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCKB',
            code_motif: 'CC',
            code_sous_motif: 'CC-02',
            actif: true,
            declarer_montant_c1: true,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-1PUYRYC',
            code_motif: 'VC',
            code_sous_motif: 'VC-01',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-GCM5',
            code_motif: 'IV',
            code_sous_motif: 'IV-02',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCEG',
            code_motif: 'TE',
            code_sous_motif: 'TE-02',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-6SBVZ5',
            code_motif: 'HA',
            code_sous_motif: 'HA-06',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: true,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-1XGUNWS',
            code_motif: 'DE2',
            code_sous_motif: 'DE2-01',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-GCJR',
            code_motif: 'CC',
            code_sous_motif: 'CC-04',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-GCH5',
            code_motif: 'CE',
            code_sous_motif: 'CE-13',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-9HGVZ3',
            code_motif: 'CE',
            code_sous_motif: 'CE-03',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCHD',
            code_motif: 'CE',
            code_sous_motif: 'CE-08',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCJ0',
            code_motif: 'CE',
            code_sous_motif: 'CE-01',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCDN',
            code_motif: 'M',
            code_sous_motif: 'M-02',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-9HGVZ8',
            code_motif: 'CE',
            code_sous_motif: 'CE-04',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCIR',
            code_motif: 'CE',
            code_sous_motif: 'CE-10',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCII',
            code_motif: 'CE',
            code_sous_motif: 'CE-12',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-2MC25Z',
            code_motif: 'CE',
            code_sous_motif: 'CE-02',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCR5',
            code_motif: 'DC',
            code_sous_motif: 'DC-01',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-GCL9',
            code_motif: 'D',
            code_sous_motif: 'D-01',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        },
        {
            id: '1-GCKK',
            code_motif: 'CC',
            code_sous_motif: 'CC-03',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-GCJ7',
            code_motif: 'CC',
            code_sous_motif: 'CC-01',
            actif: true,
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'ANY',
            fin_intervalle_dates: 'DATE_DU_JOUR_EXCLUE'
        },
        {
            id: '1-GCHQ',
            code_motif: 'CE',
            code_sous_motif: 'CE-14',
            actif: true,
            delai: '6 mois',
            declarer_montant_c1: false,
            declarer_montant: false,
            debut_intervalle_dates: 'DELAI',
            fin_intervalle_dates: 'DATE_DU_JOUR'
        }
    ]
};

export const s2e_documents = [
    {
        810: [
            {
                id: '20210329160904210329_60825694IF2020',
                code_type: 'EDI_95',
                date_creation: '2021-03-29T00:00:00Z',
                code_langue: 'FRA',
                autorise_envoi_postal: false,
                id_entreprise: '810',
                _links: {
                    download: {
                        href: '/ereleves/20210329160904210329_60825694IF2020?id_entreprise=810',
                        method: 'GET'
                    },
                    entreprise: {
                        href: '/entreprises/810',
                        method: 'GET'
                    }
                }
            }
        ]
    }
];
