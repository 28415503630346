const formatIcs = (string) => {
    if (!string)
        return '';

    if (string.length >= 11)
        return `${string.slice(0, 5)} ${string.slice(5, 8)} ${string.slice(8, string.length)}`

    return string;
};

export default formatIcs;