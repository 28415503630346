import React, {useEffect} from 'react';
import {Alert, Buttons, Info, Inputs, Typography} from 'components';
import {Patrimony} from './components';
import {makeStyles} from '@material-ui/styles';
import {useForm, useStepper, useUser} from 'hooks';
import {amount, events, modal} from 'utils';
import {sendTag} from 'store/Tags';

const useStyles = makeStyles((theme) => ({
    root: {},
    columnItems: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:not(:last-child)': {
            marginRight: 10
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            '& > *': {
                width: '100%'
            },
            '.flexGrow': {
                display: 'none !important'
            }
        }
    },
    rowItems: {
        display: 'flex',
        flexDirection: 'column'
    },
    info: {
        border: `1px solid ${theme.palette.blue}`,
        padding: theme.spacing(1)
    },
    button: {
        cursor: 'pointer',
        border: 'none',
        padding: '2px'
    },
    icon: {
        verticalAlign: 'middle'
    },
    percentInput: {
        maxWidth: 80,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        }
    },
    marginBottom: {
        marginBottom: 20
    },
    flexWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:not(:last-child)': {
            marginRight: 10
        }
    }
}));

export const sanitizePatrimony = (values) => {
    let sanitizedValues = values;
    if ('home_value' in values) {
        sanitizedValues = {
            ...sanitizedValues,
            ...{
                home_value: amount.parse(values.home_value)
            }
        };
    }
    if ('passif' in values) {
        sanitizedValues = {
            ...sanitizedValues,
            ...{
                passif: amount.parse(values.passif)
            }
        };
    }
    return sanitizedValues;
};

function StepPatrimony({isStepper}) {
    const classes = useStyles();
    const {user, setUser} = useUser();
    const {formState, setFormState} = useForm();
    const {stepper} = useStepper();

    useEffect(() => {
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                home_percentage: isStepper && stepper.payload?.heirs?.family_situation === 'spouse' ? 50 : 100
            }
        });
        // eslint-disable-next-line
    }, [stepper.payload?.patrimony?.owner_home]);

    return (
        <div>
            <Typography
                variant="h3"
                semibold
                color="blue"
            >
                Je renseigne mon patrimoine {isStepper ? '- 2/3' : ''}
            </Typography>
            <div className={classes.flexWrapper}>
                <Typography>J'ai réalisé au moins une donation il y a moins de 15 ans ?</Typography>
                <div className="flexGrow" />
                <Inputs.Switch name="has_donate" />
            </div>
            {formState.values.has_donate === true && (
                <Alert
                    message={
                        <>
                            Les donations ne sont pas prises en compte dans le cadre de cette simulation. <br />
                            La donation permet d’organiser et d’optimiser, de son vivant, la transmission de son
                            patrimoine. Pour en connaitre les modalités et explorer toutes les options à votre
                            disposition, parlez-en avec votre Conseiller.
                            <Buttons.Default
                                center
                                label="Je contacte mon conseiller"
                                color="blueAlpha"
                                next
                                onClick={() => {
                                    setUser({...user, actions: user.actions ? user.actions + 1 : 1});
                                    sendTag(user, 'ct_transmission360::clic::conseiller');
                                    modal.close('modifier_patrimoine');
                                    modal.open('advisor_transmission');
                                }}
                            />
                        </>
                    }
                    severity="info"
                />
            )}
            <div className={classes.flexWrapper}>
                <Typography>Je suis propriétaire de ma résidence principale ?</Typography>
                <div className="flexGrow" />
                <Inputs.Switch name="owner_home" />
            </div>
            {formState.values.owner_home === true && (
                <>
                    <div className={classes.rowItems}>
                        <div className={classes.columnItems}>
                            <Typography>Quelle est la valeur de ma résidence principale ?</Typography>
                            <div className="flexGrow" />
                            <Inputs.Number
                                name="home_value"
                                format="money"
                                adornment="€"
                                right
                            />
                        </div>
                    </div>
                    <div className={classes.rowItems}>
                        <div className={classes.columnItems}>
                            <div className={classes.flexWrapper}>
                                <Typography>Je détiens ma résidence principale à hauteur de:</Typography>
                                <Info
                                    hash="homeValue"
                                    title="Informations"
                                    color="blue"
                                    tooltip
                                >
                                    La part que vous détenez a pu être stipulée dans l’acte notarié d’achat de votre
                                    résidence principale ou peut dépendre de votre régime matrimonial.
                                </Info>
                            </div>
                            <div className="flexGrow" />
                            <Inputs.Number
                                className={classes.percentInput}
                                name="home_percentage"
                                format="percentage"
                                adornment="%"
                                right
                            />
                        </div>
                    </div>
                    <div className={classes.flexWrapper}>
                        <Typography>Mon conjoint/mes enfants vivent-ils toujours dans cette résidence ?</Typography>
                        <Info
                            hash="familyAtHome"
                            title="Informations"
                            color="blue"
                            tooltip
                        >
                            Pour les enfants, uniquement si mineurs ou majeurs protégés, du défunt, de son conjoint ou
                            de son partenaire. Dans ce cas, le bien bénéficie d'un abattement de 20% de sa valeur.
                        </Info>
                        <div className="flexGrow" />
                        <Inputs.Switch name="family_at_home" />
                    </div>
                </>
            )}
            <div className={classes.rowItems}>
                <div className={classes.flexWrapper}>
                    <Typography>
                        Ai-je d’autres biens hors assurance-vie (immobilier, liquidités, épargne bancaire, valeurs
                        mobilières…) ?
                    </Typography>
                    <Info
                        hash="hasOtherPatrimony"
                        title="Informations"
                        color="blue"
                        tooltip
                    >
                        Hors assurance-vie, les biens ayant une fiscalité successorale spécifique ne sont pas pris en
                        charge par le simulateur : forets, œuvres d’art, sociétés, Pacte Dutreil…
                    </Info>
                    <div className="flexGrow" />
                    <Inputs.Switch name="has_other_patrimony" />
                </div>
                {formState.values.has_other_patrimony === true &&
                    formState.values.other_goods?.length > 0 &&
                    formState.values.other_goods.map((patrimony, index) => (
                        <Patrimony
                            key={`patrimonyRow-${index}`}
                            index={index}
                            setFormStateParent={setFormState}
                            formStateParent={formState}
                        />
                    ))}
                {formState.values.has_other_patrimony === true && (
                    <Buttons.Default
                        className={classes.marginBottom}
                        iconName="axa-icons-more"
                        label="Ajouter un bien"
                        color="blue"
                        center
                        onClick={() => {
                            let otherGoods = formState.values.other_goods || [];
                            otherGoods.push({
                                type: 'immovable',
                                amount: '0',
                                rate: '100'
                            });
                            setFormState({
                                ...formState,
                                values: {
                                    ...formState.values,
                                    other_goods: otherGoods
                                }
                            });
                            events.resize();
                        }}
                    />
                )}
            </div>
            <div className={classes.rowItems}>
                <div className="flex">
                    <div className={classes.columnItems}>
                        <div className={classes.flexWrapper}>
                            <Typography>Quel est le montant de mon passif ?</Typography>
                            <Info
                                hash="passif"
                                title="Informations"
                                color="blue"
                                tooltip
                            >
                                Le passif successoral est composé de toutes les dettes du défunt, arrêtées au jour de
                                son décès, et qui sont à sa charge personnelle. En complément du montant saisi le
                                simulateur inclut 1500€ de frais funéraires.
                            </Info>
                        </div>
                    </div>
                </div>
                <Inputs.Number
                    name="passif"
                    format="money"
                    adornment="€"
                    right
                />
            </div>
        </div>
    );
}

export default StepPatrimony;
