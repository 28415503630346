import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from 'components';
import {modal} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        background: theme.palette.white,
        width: 180,
        top: 250,
        [theme.breakpoints.down('xs')]: {
            top: '305px !important'
        },
        right: -80,
        padding: 8,
        border: `solid 1px ${theme.palette.blue}`,
        transform: 'rotate(-90deg)',
        cursor: 'pointer'
    }
}));

function ToggleLexicon(props) {
    const {hash} = props;
    const classes = useStyles();

    return (
        <button
            className={classes.root}
            onClick={() => (modal.isOpen(hash) ? modal.close(hash) : modal.open(hash))}
        >
            <Typography
                size={13}
                color="blue"
                caps
                gutterBottom={false}
            >
                Lexique
            </Typography>
        </button>
    );
}

ToggleLexicon.propTypes = {
    hash: PropTypes.string.isRequired
};

export default ToggleLexicon;
