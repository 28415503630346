const config = {
    RV_R: 'Rente viagère réversible',
    RV_NR: 'Rente viagère non réversible',
    RV_RAG: 'Rente viagère réversible avec annuités garanties',
    RV_NRAG: 'Rente viagère non réversible avec annuités garanties',
    RV_CPP: 'Rente viagère croissant par paliers aux 70ème et 75ème anniversaires'
};

const translateAnnuity = (annuity_type, capitalize = true) => {
    if (Object.keys(config).includes(annuity_type))
        return capitalize ? config[annuity_type] : config[annuity_type].toLowerCase();
    return annuity_type;
};

export default translateAnnuity;
