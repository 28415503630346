import React from 'react';
import {Grid, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    disclaimer: {
        padding: theme.spacing(2, 1),
        margin: theme.spacing(2, 0),
        background: theme.palette.pale
    },
    header: {
        background: theme.palette.blue,
        padding: theme.spacing(1)
    },
    content: {
        minHeight: 120,
        padding: theme.spacing(1, 1, 1, 0),
        border: `solid 1px ${theme.palette.blue}`
    },
    list: {
        color: theme.palette.blue,
        marginLeft: theme.spacing(3),
        listStyle: 'square',
        '& li': {
            marginBottom: theme.spacing(1)
        }
    }
}));

function SampleDisclaimer() {
    const classes = useStyles();

    return (
        <div className={classes.disclaimer}>
            <Typography semibold>Important - Fiscalité et prélèvements</Typography>
            <Typography>
                La fiscalité des sommes débloquées au motif d'acquisition de la résidence principale est la
                suivante&nbsp;:
            </Typography>

            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    xs={12}
                    sm={4}
                >
                    <div className={classes.header}>
                        <Typography
                            color="white"
                            gutterBottom
                            center
                        >
                            Épargne salariale et épargne temps
                        </Typography>
                    </div>
                    <div className={classes.content}>
                        <ul className={classes.list}>
                            <li>Sommes investies exonérées de l'impôt sur le revenu et des prélèvements sociaux</li>
                            <li>Plus values soumises uniquement aux prélèvements sociaux: 17,2%</li>
                        </ul>
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={4}
                >
                    <div className={classes.header}>
                        <Typography
                            color="white"
                            gutterBottom
                            center
                        >
                            Versements volontaires déductibles
                        </Typography>
                    </div>

                    <div className={classes.content}>
                        <ul className={classes.list}>
                            <li>Sommes investies soumises à l'impôt sur le revenu. Pas de prélèvements sociaux</li>
                            <li>Plus values soumises à la Flat Tax de 30%</li>
                        </ul>
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={4}
                >
                    <div className={classes.header}>
                        <Typography
                            color="white"
                            gutterBottom
                            center
                        >
                            Versements volontaires non déductibles
                        </Typography>
                    </div>

                    <div className={classes.content}>
                        <ul className={classes.list}>
                            <li>Sommes investies exonérées d'impôt sur le revenu et des prélèvements sociaux</li>
                            <li>Plus values soumises à la Flat Tax de 30%</li>
                        </ul>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default SampleDisclaimer;
