import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';
import * as ReactDOMServer from 'react-dom/server';

import Chart from 'react-apexcharts';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    mobile: {
        flexDirection: 'column'
    },
    wrapper: {
        maxWidth: 250
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    dot: {
        width: 17,
        height: 17,
        marginTop: 2,
        borderRadius: '50%'
    },
    hover: {
        transform: 'scale(1.25)'
    },
    tooltip: {
        fontSize: 16,
        backgroundColor: '#F1F5FB',
        color: '#5F5F5F',
        textAlign: 'center',
        border: 'solid 1px #00008F',
        borderRadius: '4px',
        padding: 16,
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px -2px rgba(51, 51, 51, 0.20)',
        '&>svg': {
            zIndex: 10000,
            position: 'absolute',
            left: 'calc(50% - 5px)',
            bottom: -9
        }
    }
}));

const Tooltip = ({content, series, seriesIndex, dataPointIndex, w}) => {
    const classes = useStyles();
    return (
        <div className={classes.tooltip}>
            {content && content(series, seriesIndex, dataPointIndex, w)}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
            >
                <g clip-path="url(#clip0_3638_6800)">
                    <path
                        d="M6.59872 7.69826L7.00002 8.23819L7.40132 7.69827L12.9014 0.298265L13.4947 -0.5H12.5001H1.50012H0.505532L1.09882 0.298258L6.59872 7.69826Z"
                        fill="#F1F5FB"
                        stroke="#00008F"
                    />
                    <path
                        d="M12.5 0L7.00008 7.4L1.5 0H12.5Z"
                        fill="#F1F5FB"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_3638_6800">
                        <rect
                            width="14"
                            height="10"
                            fill="white"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

function Donut({
    data,
    className,
    withTotal,
    symbol,
    tooltipEnabled = true,
    tooltipContent,
    width = 280,
    height = 280,
    ...rest
}) {
    const classes = useStyles();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [className]: className,
                [classes.mobile]: isMobile
            })}
        >
            <Chart
                key={`doughnut-chart-1`}
                className={classes.root}
                options={{
                    labels: data.map((serie) => serie.label),
                    colors: data.map((serie) => serie.color),
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '80%'
                            }
                        }
                    },
                    stroke: {
                        show: true,
                        width: 1
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        enabled: tooltipEnabled,
                        custom: function ({series, seriesIndex, dataPointIndex, w}) {
                            return ReactDOMServer.renderToString(
                                <Tooltip
                                    series={series}
                                    seriesIndex={seriesIndex}
                                    dataPointIndex={dataPointIndex}
                                    w={w}
                                    content={tooltipContent}
                                />
                            );
                        }
                    }
                }}
                series={data.map((serie) => serie.value)}
                type="donut"
                width={width}
                height={height}
            />
        </div>
    );
}

Donut.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
            color: PropTypes.string,
            highlight_color: PropTypes.string
        })
    ),
    className: PropTypes.string,
    withTotal: PropTypes.bool,
    tooltipContent: PropTypes.func
};

export default Donut;
