import React from 'react';
import {Buttons, Grid, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 320,
        padding: theme.spacing(2)
    },
    img: {
        margin: 'auto'
    }
}));

function StepIntro({setStep}) {
    const classes = useStyles();

    return (
        <Grid
            item
            xs={12}
            className={classes.root}
        >
            <Typography>Le questionnaire vous prendra seulement 1 minute</Typography>
            <img
                className={classes.img}
                src={`${process.env.PUBLIC_URL}/images/bg-opinion.png`}
                alt=""
            />
            <Buttons.Default
                label="C'est parti !"
                color="blue"
                center
                next
                onClick={() => setStep(1)}
            />
        </Grid>
    );
}

export default StepIntro;
