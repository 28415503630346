import React from 'react';
import PropTypes from 'prop-types';

import {Icon, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 1),
        background: theme.palette.blue,
        '& i:before': {
            verticalAlign: 'middle'
        }
    },
    icon: {
        marginRight: theme.spacing(1.5)
    }
}));

const FolioTitle = ({title, iconName}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography
                variant="h2"
                color="white"
                semibold
                center
                gutterBottom={false}
            >
                {iconName && <Icon
                    className={classes.icon}
                    name={iconName}
                    color="white"
                    size={32}
                />}
                {title}
            </Typography>
        </div>
    );
};

FolioTitle.propTypes = {
    title: PropTypes.string.isRequired,
    iconName: PropTypes.string
};

export default FolioTitle;
