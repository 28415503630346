import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useUser} from 'hooks';

export const TaxSimulatorContext = React.createContext({
    taxSimulator: {},
    setTaxSimulator: () => {}
});

export const TaxSimulatorProvider = (props) => {
    const {variant} = props;

    const {user} = useUser();

    const setTaxSimulator = (taxSimulator) => {
        setState({...state, taxSimulator: taxSimulator});
    };

    useEffect(() => {
        setState({...state, variant: variant});

        // eslint-disable-next-line
    }, [variant]);

    const initState = {
        taxSimulator: {
            inputs: {
                gender: user.ais_profile?.gender,
                client_age: user.ais_profile?.birth_date && moment().diff(user.ais_profile?.birth_date, 'years', false)
            },
            response: {}
        },
        setTaxSimulator: setTaxSimulator,
        variant: variant
    };

    const [state, setState] = useState(initState);

    return <TaxSimulatorContext.Provider value={state}>{props.children}</TaxSimulatorContext.Provider>;
};
