const round = (value) => {
    if (typeof value === 'string') value = parseFloat(value);

    const result = Math.round(value * 100) / 100;

    if (isNaN(result)) return 0;

    return result;
};

export default round;
