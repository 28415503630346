import React, {useState} from 'react';

export const ErrorContext = React.createContext({
    error: null,
    setError: () => {}
});

export const ErrorProvider = (props) => {
    const setError = (error) => {
        setState({...state, error: error});
    };

    const initState = {
        error: null,
        setError: setError
    };

    const [state, setState] = useState(initState);

    return <ErrorContext.Provider value={state}>{props.children}</ErrorContext.Provider>;
};
