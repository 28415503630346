import {HOME_URLS} from './urlMapping';

const getHomeUrl = (user) => {
    if (user.logged) {
        return HOME_URLS.CONNECTED;
    } else {
        return HOME_URLS.NOT_CONNECTED;
    }
};

export default getHomeUrl;
