import format from './format';
import {Trans} from 'react-i18next';
import * as ReactDOMServer from "react-dom/server";
import React from "react";

const CURRENCIES = {
    en: {dec_point: '.', thousands_sep: ','},
    fr: {dec_point: ',', thousands_sep: ' '}
};

function formatCurrency(number, i18n = 'fr', currency = 'currency.euro') {
    const formattedCurrency = format(number, 2, '', CURRENCIES[i18n].dec_point, CURRENCIES[i18n].thousands_sep);
    return ReactDOMServer.renderToString(<Trans i18nKey={currency} values={{number: formattedCurrency}}/>);
}

export default formatCurrency;
