import React, {useState} from 'react';
import {Buttons, Modal, Structure, Tabs, Typography} from 'components';
import {CareerTable} from './components';
import {modal} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#eee'
    },
    scroll: {
        overflow: 'hidden auto',
        maxHeight: 600
    },
    content: {
        padding: theme.spacing(2, 0)
    },
    button: {
        marginLeft: 'auto'
    }
}));

function CareerModal() {
    const classes = useStyles();

    const [tab, setTab] = useState(0);

    return (
        <Modal
            hash="career"
            className={classes.root}
            title="Mon relevé de carrière"
        >
            <div className={classes.scroll}>
                <Structure.Center className={classes.content}>
                    <Typography
                        variant="h1"
                        color="blue"
                        center
                        semibold
                    >
                        Mon relevé de carrière
                    </Typography>
                    <Typography
                        variant="h5"
                        center
                    >
                        Si vous constatez des données manquantes, veuillez vous rapprocher de votre caisse de retraite.
                    </Typography>

                    <Tabs
                        labels={['Régimes de base', 'Régimes complémentaires']}
                        tab={tab}
                        setTab={setTab}
                    />

                    <CareerTable careerType={tab === 0 ? 'base' : 'complementary'} />

                    <Buttons.Default
                        label="Retour au tableau de bord"
                        className={classes.button}
                        color="blueAlpha"
                        next
                        onClick={() => modal.close('career')}
                    />
                </Structure.Center>
            </div>
        </Modal>
    );
}

export default CareerModal;
