import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    gauge: {
        position: 'relative',
        background: '#d3e2f3',
        width: '100%',
        height: 10,
        borderRadius: 10,
        margin: '2px 0'
    },
    fill: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        maxWidth: 0, // is animated
        height: '100%',
        background: theme.palette.blue,
        borderRadius: 'inherit',
        transition: 'max-width 1.2s ease-in'
    },
    subtitle: {
        display: 'flex'
    }
}));

function Progress({min, max, formatLabel}) {
    const classes = useStyles();

    const [width, setWidth] = useState(0);
    useEffect(() => {
        max ? setWidth(`${(100 * min) / max}%`) : setWidth(0);
    }, [min, max]);

    return (
        <>
            <Typography semibold>Objectif</Typography>
            <div className={classes.gauge}>
                <div
                    className={classes.fill}
                    style={{maxWidth: width}}
                />
            </div>
            <div className={classes.subtitle}>
                <Typography>
                    {min !== undefined && formatLabel instanceof Function ? formatLabel(min, 0) : min}
                </Typography>
                <div className="flexGrow" />
                <Typography>
                    {max !== undefined && formatLabel instanceof Function ? formatLabel(max, 0) : max}
                </Typography>
            </div>
        </>
    );
}

Progress.propTypes = {
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    formatLabel: PropTypes.func
};

export default Progress;
