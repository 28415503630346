import React from 'react';
import {Alert, Icon, Inputs, Typography} from 'components';
import {makeStyles} from '@material-ui/styles';
import {useForm} from 'hooks';
import {events} from 'utils';

const useStyles = makeStyles((theme) => ({
    root: {},
    columnItems: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *': {
            marginRight: 20
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            '& > *': {
                width: '100%'
            },
            '& .flexGrow': {
                display: 'none !important'
            }
        }
    },
    rowItems: {
        display: 'flex',
        flexDirection: 'column'
    },
    lifeInsurance: {
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
        borderTop: `1px solid ${theme.palette.gray}`,
        marginBottom: 5,
        '&:last-child': {
            borderBottom: `1px solid ${theme.palette.gray}`
        }
    },
    button: {
        width: 110,
        cursor: 'pointer',
        border: 'none',
        padding: '2px'
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    },
    icon: {
        verticalAlign: 'middle'
    },
    hide: {
        width: '0 !important',
        height: '0 !important',
        padding: '0 !important',
        margin: '0 !important',
        overflow: 'hidden  !important'
    }
}));

const RemoveLifeInsurance = ({...rest}) => {
    const classes = useStyles();

    return (
        <div className={classes.center}>
            <button
                className={classes.button}
                {...rest}
            >
                <Typography
                    color="redLight"
                    gutterBottom={false}
                >
                    <Icon
                        name="axa-icons-remove-circle"
                        className={classes.icon}
                    />{' '}
                    Supprimer
                </Typography>
            </button>
        </div>
    );
};

function LifeInsuranceTile({index, lifeInsurances, setLifeInsurances}) {
    const classes = useStyles();
    const {formState, setFormState} = useForm();

    return (
        <div className={classes.lifeInsurance}>
            <div className={classes.columnItems}>
                <Typography>Contrat d’assurance-vie</Typography>
                <div className="flexGrow" />
                <Inputs.Text
                    name={`${index}-name`}
                    placeholder="Nom / Organisme du contrat"
                    right
                    disableCleave
                    disabled={formState.values?.[`${index}-is_axa`]}
                />
            </div>
            <div className={classes.columnItems}>
                <Typography>Montant</Typography>
                <div className="flexGrow" />
                <Inputs.Number
                    name={`${index}-amount`}
                    format="money"
                    adornment="€"
                    right
                    disabled={formState.values?.[`${index}-is_axa`]}
                />
            </div>

            <>
                <div className={classes.columnItems}>
                    <Typography>Quels bénéficiaires ai-je choisis ?</Typography>
                    <div className="flexGrow" />
                    <div className={classes.hide}>
                        <Inputs.Switch name={`${index}-is_axa`} />
                    </div>
                    <Inputs.Select
                        name={`${index}-beneficiary`}
                        options={[
                            {
                                label: 'Le conjoint survivant, à défaut les enfants, à défaut les héritiers',
                                value: 'spouse'
                            },
                            {
                                label: 'Les enfants, à défaut les héritiers',
                                value: 'children'
                            },
                            {
                                label: 'Les héritiers à parts égales',
                                value: 'heir'
                            },
                            {
                                label: 'Bénéficiaire désigné',
                                value: 'beneficiary'
                            }
                        ]}
                    />
                </div>

                <Alert
                    message={
                        <>
                            La clause bénéficiaire simulée ici peut différer de celle mise en place dans votre contrat.
                            Contactez votre Conseiller pour vous assurer qu'elle correspond bien à vos souhaits.
                        </>
                    }
                    severity="info"
                />
                {!formState.values?.[`${index}-is_axa`] && (
                    <RemoveLifeInsurance
                        onClick={(e) => {
                            e.preventDefault();
                            setLifeInsurances(
                                Object.fromEntries(Object.entries(lifeInsurances).filter(([key]) => key !== index))
                            );

                            let newValues = formState.values;
                            delete newValues[`${index}-is_axa`];
                            delete newValues[`${index}-beneficiary`];
                            delete newValues[`${index}-name`];
                            delete newValues[`${index}-amount`];
                            setFormState({
                                ...formState,
                                values: newValues
                            });
                            events.resize();
                        }}
                    />
                )}
            </>
        </div>
    );
}

export default LifeInsuranceTile;
