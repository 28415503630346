import React, {useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Form, Structure} from 'components';
import {StepperActions, StepperNavigation} from './components';
import {useForm, useStepper} from 'hooks';
import {StepperProvider} from 'store/Stepper';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    actions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    }
}));

const Component = ({
    children,
    index,
    step,
    values,
    setStepFormState,
    setStepActionComponent,
    setStepSubmit,
    isValid,
    context
}) => {
    const {formState} = useForm();
    const {stepper} = useStepper();

    useEffect(() => {
        if (step === index) {
            setStepActionComponent(children.props.stepAction);

            if (children.props.submit)
                setStepSubmit(
                    () => (stepper, setStepper, setResponse) =>
                        children.props.submit(stepper, setStepper, setResponse, context)
                );
            else setStepSubmit(() => () => new Promise((resolve) => resolve()));
        }

        // eslint-disable-next-line
    }, [step, context]);

    useEffect(() => {
        if (step === index) {
            setStepFormState({
                values: formState.values || values,
                isValid: isValid instanceof Function ? isValid(stepper, formState.values, context) : formState.isValid
            });
        }

        // eslint-disable-next-line
    }, [step, formState.values, formState.isValid, context]);

    return <>{children}</>;
};

const HiddenStep = () => {
    return <div />;
};

function StepperContent({children, context, className, firstAction, lastAction, variant, onChange, debug, ...rest}) {
    const classes = useStyles();
    const {nextLabel, previousLabel} = rest;
    const {stepper, setStepper} = useStepper();

    const [step, setStep] = useState(0);
    const [stepParent, setStepParent] = useState(0);
    const hasShowIf = rest.hasShowIf || false;

    const subSteps = useMemo(
        () =>
            React.Children.map(children, (child, index) => {
                if (child === null) return null;

                if (child.props.children instanceof Array) {
                    if (child.props.showIf instanceof Function && !child.props.showIf(stepper)) {
                        return React.cloneElement(<HiddenStep />, {
                            ...child.props
                        });
                    }

                    return child.props.children.map((inner) => {
                        if (inner.props.showIf instanceof Function && !inner.props.showIf(stepper)) {
                            return React.cloneElement(<HiddenStep />, {
                                ...inner.props
                            });
                        }

                        return React.cloneElement(inner, {
                            stepParent: index,
                            parentNode: child.props.parentNode,
                            ...inner.props
                        });
                    });
                }

                if (child.props.showIf instanceof Function && !child.props.showIf(stepper))
                    return React.cloneElement(<HiddenStep />, {
                        ...child.props
                    });

                return React.cloneElement(child, {
                    stepParent: index,
                    ...child.props
                });
            }),

        // eslint-disable-next-line
        [children, step]
    );

    const navigation = useMemo(
        () =>
            React.Children.map(children, (child) => {
                if (child === null) return null;

                return child.props.name;
            }),
        [children]
    );

    const subStepsModals = useMemo(() => subSteps.map((element) => element.props.modals), [subSteps]);

    const indexes = subSteps.map((child, index) => {
        if (child.props.showIf instanceof Function && !child.props.showIf(stepper)) return null;

        return index;
    });

    useEffect(() => {
        setStepParent(subSteps[step].props.stepParent);

        setStepper({
            ...stepper,
            step
        });

        // eslint-disable-next-line
    }, [step]);

    const [stepFormState, setStepFormState] = useState({
        values: {},
        isValid: true
    });

    useEffect(() => {
        const child = subSteps[step];
        const nestedKey =
            typeof child.props.nestedKey === 'function' ? child.props.nestedKey(stepper) : child.props.nestedKey;

        setStepper({
            ...stepper,
            payload: nestedKey
                ? {
                      ...stepper.payload,
                      [nestedKey]: stepFormState.values
                  }
                : {
                      ...stepper.payload,
                      ...stepFormState.values
                  }
        });

        // eslint-disable-next-line
    }, [stepFormState.values]);

    const [stepActionComponent, setStepActionComponent] = useState(null);
    const [stepSubmit, setStepSubmit] = useState(() => () => new Promise((resolve) => resolve()));

    return (
        <div className={clsx(classes.root, className)}>
            <StepperNavigation
                navigation={navigation}
                step={step}
                stepParent={stepParent}
                currentSubStepsCounts={[...Array(subSteps[subSteps.length - 1].props.stepParent + 1 || 0).keys()].map(
                    (stepParent) => {
                        let subStepsLength = subSteps.filter(
                            (subStep) => subStep.props.stepParent === stepParent
                        ).length;
                        return subStepsLength > 0 ? subStepsLength : 1;
                    }
                )}
                hasShowIf={hasShowIf}
            />

            {subSteps[step].props.parentNode}

            <Structure.Carousel
                adaptiveHeight
                current={step}
                onChange={(step) => {
                    setStep(step);
                    onChange instanceof Function && onChange(step);
                }}
                smooth
                {...rest}
            >
                {subSteps.map((subStep, index) => (
                    <Form
                        key={`step-${index}`}
                        name={`step-${index}`}
                        values={subStep.props.values}
                        schema={
                            subStep.props.schema instanceof Function
                                ? subStep.props.schema(stepper)
                                : subStep.props.schema
                        }
                        variant={subStep.props.variant || variant}
                    >
                        <Component
                            index={index}
                            step={step}
                            setStepFormState={setStepFormState}
                            setStepActionComponent={setStepActionComponent}
                            setStepSubmit={setStepSubmit}
                            isValid={subStep.props.isValid || false}
                            context={context}
                        >
                            {subStep}
                        </Component>
                    </Form>
                ))}
            </Structure.Carousel>

            <StepperActions
                step={step}
                setStep={setStep}
                firstAction={firstAction}
                lastAction={lastAction}
                stepActionComponent={stepActionComponent}
                stepSubmit={stepSubmit}
                isValid={stepFormState.isValid}
                indexes={indexes}
                subStepsModals={subStepsModals}
                previousLabel={subSteps[step].props.previousLabel || previousLabel || 'Précédent'}
                nextLabel={subSteps[step].props.nextLabel || nextLabel || 'Suivant'}
                hiddenPrevious={subSteps[step].props.hiddenPrevious}
            />

            {debug && (
                <>
                    <pre>{JSON.stringify(stepper, null, 4)}</pre>

                    {context && <pre>{JSON.stringify(context, null, 4)}</pre>}
                </>
            )}
        </div>
    );
}

function Stepper({children, provider: Provider, consumer: Consumer, ...rest}) {
    return (
        <StepperProvider>
            {Provider ? (
                <Provider>
                    <Consumer>
                        {(context) => (
                            <StepperContent
                                context={context}
                                {...rest}
                            >
                                {children}
                            </StepperContent>
                        )}
                    </Consumer>
                </Provider>
            ) : (
                <StepperContent {...rest}>{children}</StepperContent>
            )}
        </StepperProvider>
    );
}

Stepper.propTypes = {
    children: PropTypes.node.isRequired,
    firstAction: PropTypes.node,
    lastAction: PropTypes.node,
    stepAction: PropTypes.node,
    provider: PropTypes.func,
    consumer: PropTypes.object,
    debug: PropTypes.bool,
    variant: PropTypes.oneOf(['white', 'blue', 'transparent']),
    onChange: PropTypes.func
};

export default Stepper;
