import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useB2V, useUser} from 'hooks';

import {AppBar, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: theme.palette.white,
        zIndex: 1000
    }
}));

function Header({desktop: Desktop, mobile: Mobile, subHeader, breadcrumb, setHeaderHeight, dynamicTop}) {
    const {B2V} = useB2V();

    const classes = useStyles();
    const theme = useTheme();
    const {user} = useUser();

    const ref = useRef();

    const isDesktop = useMediaQuery(
        user.origin === 'collective' ? theme.breakpoints.up('lg') : theme.breakpoints.up('sm')
    );

    useEffect(() => {
        let cancelled = false;
        const getHeight = () => {
            const {current} = ref;
            if (!current || !current.clientHeight) {
                if (!cancelled) {
                    requestAnimationFrame(getHeight);
                }
            } else {
                setHeaderHeight(current.clientHeight);
                setTimeout(() => setHeaderHeight(current.clientHeight), 0);
            }
        };
        getHeight();
        return () => {
            cancelled = true;
        };

        // eslint-disable-next-line
    }, [isDesktop, breadcrumb, B2V, window.location.href, user.s2e_alerts]);

    return (
        <AppBar
            className={classes.root}
            elevation={3}
            ref={ref}
            id="header"
            style={{top: dynamicTop}}
        >
            {isDesktop ? <Desktop /> : <Mobile />}
            {breadcrumb}
            {subHeader}
        </AppBar>
    );
}

Header.propTypes = {
    desktop: PropTypes.func.isRequired, // component
    mobile: PropTypes.func.isRequired, // component
    subHeader: PropTypes.object, // component
    breadcrumb: PropTypes.object, // component
    setHeaderHeight: PropTypes.func.isRequired
};

export default Header;
