import React from 'react';
import {Buttons, Grid, Inputs} from 'components';
import {useForm} from 'hooks';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 320,
        padding: theme.spacing(2)
    }
}));

function StepStatisfaction({setStep}) {
    const classes = useStyles();

    const {formState} = useForm();

    return (
        <Grid
            item
            xs={12}
            className={classes.root}
        >
            <Inputs.Radio
                name="satisfaction"
                label="Êtes-vous satisfait(e) des services d'easyprojets.com ?"
                options={[
                    {
                        label: 'Très satisfait(e)',
                        value: 'Très satisfait(e)'
                    },
                    {
                        label: 'Satisfait(e)',
                        value: 'Satisfait(e)'
                    },
                    {
                        label: 'Ni satisfait(e) ni insatisfait(e)',
                        value: 'Ni satisfait(e) ni insatisfait(e)'
                    },
                    {
                        label: 'Insatisfait(e)',
                        value: 'Insatisfait(e)'
                    },
                    {
                        label: 'Très insatisfait(e)',
                        value: 'Très insatisfait(e)'
                    }
                ]}
            />
            <Buttons.Default
                label="Suivant"
                center
                color="blue"
                next
                disabled={!formState.values.satisfaction}
                onClick={() => {
                    setStep(2);
                }}
            />
        </Grid>
    );
}

export default StepStatisfaction;
