const regroup = (accounts, key) =>
    Object.values(
        accounts.reduce(
            (r, o) => (
                r[o[key]]
                    ? ((r[o[key]].balance += o.balance),
                      (r[o[key]].balance_co += o.balance_co),
                      (r[o[key]].balance_vv += o.balance_vv),
                      (r[o[key]].balance_vvd += o.balance_vvd),
                      (r[o[key]].balance_vvnd += o.balance_vvnd),
                      (r[o[key]].balance_es += o.balance_es)) // eslint-disable-next-line
                    : (r[o[key]] = {...o}),
                r
            ),
            {}
        )
    );

export default regroup;
