import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {api} from 'api';
import {Buttons, Modal, Typography, Warn} from 'components';
import {useUser} from 'hooks';
import {modal} from 'utils';
import config from 'config';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 600
    }
}));

function AutoLogoutModal({open}) {
    const classes = useStyles();

    const {user, setUser} = useUser();

    useEffect(() => {
        open && modal.open('auto_logout');
    }, [open]);

    useEffect(() => {
        if (
            modal.isOpen('auto_logout') &&
            (window.location.pathname === '/error-page' || window.location.pathname === '/presentation')
        ) {
            setUser({
                origin: user.origin
            });
            api.get(`/login/oauth/authorization/${user.origin || 'collective'}`)
                .then((response) => (window.location.href = response.data))
                .catch(() =>
                    ["individual", "individual_transmission"].includes(user.origin)
                        ? (window.location.href = config.AXA_CLIENT_SPACE_URL[process.env.REACT_APP_ENVIRONMENT])
                        : (window.location.href = '/')
                );
        }

        // eslint-disable-next-line
    }, [user.origin, modal.isOpen('auto_logout')]);

    if (
        modal.isOpen('auto_logout') &&
        (window.location.pathname === '/error-page' || window.location.pathname === '/presentation')
    )
        return null;

    return (
        <Modal
            hash="auto_logout"
            className={classes.root}
            title="Déconnexion"
            icon={
                <Warn
                    message=""
                    color="white"
                />
            }
            disableBackdropClick
            disableEscapeKeyDown
        >
            <Typography variant="h5">Votre session a expiré</Typography>

            <Buttons.Default
                label="Reconnexion"
                color="blueAlpha"
                center
                next
                onClick={() => {
                    setUser({
                        origin: user.origin
                    });
                    api.get(`/login/oauth/authorization/${user.origin || 'collective'}`)
                        .then((response) => (window.location.href = response.data))
                        .catch(() =>
                             ["individual", "individual_transmission"].includes(user.origin)
                                ? (window.location.href =
                                      config.AXA_CLIENT_SPACE_URL[process.env.REACT_APP_ENVIRONMENT])
                                : (window.location.href = '/')
                        );
                }}
            />
        </Modal>
    );
}

AutoLogoutModal.propTypes = {
    open: PropTypes.bool
};

export default AutoLogoutModal;
