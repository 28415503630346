import React, {useState} from 'react';
import {json} from '../utils';

export const SettingsContext = React.createContext({
    settings: {
        obfuscation: false
    },
    saveSettings: () => {}
});

export const SettingsProvider = (props) => {
    const saveSettings = (settings) => {
        setState({...state, settings: settings});
        localStorage.setItem('EP_settings', JSON.stringify(settings));
    };

    const initState = {
        settings: json.parse(localStorage.getItem('EP_settings')) || {obfuscation: false},
        saveSettings: saveSettings
    };

    const [state, setState] = useState(initState);

    return <SettingsContext.Provider value={state}>{props.children}</SettingsContext.Provider>;
};

export const SettingsConsumer = SettingsContext.Consumer;
