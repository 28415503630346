import React, {useEffect} from 'react';
import createPersistedState from 'use-persisted-state';

import {api} from 'api';
import {Form, Inputs, Modal, Warn} from 'components';
import {useUser} from 'hooks';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 500
    },
    form: {
        padding: theme.spacing(0, 1)
    }
}));

const INDIVIDUAL_SCOPE = 'https://connect.axa.fr/individualOrProfessional.mainCustomerId';

const useMirrorConsent = createPersistedState('EP_mirror_consent');

function ConsentMirrorModal() {
    const classes = useStyles();

    const {user} = useUser();

    const [consent, setConsent] = useMirrorConsent(false);
    useEffect(() => {
        user.id &&
            api
                .get('/consents/mirror', {params: {user_id: user.id}})
                .then((response) => setConsent(response.data.mirror_consent));

        // eslint-disable-next-line
    }, [user.id]);

    const isPersona = !!user.persona;

    // FIXME : Enable individual mirror feature in production
    if (process.env.REACT_APP_ENVIRONMENT === 'production') return null;

    return (
        <Modal
            hash="consent_mirror"
            className={classes.root}
            title="Confidentialité de mes données"
            keepMounted
        >
            {user.oauth_profile && user.oauth_profile[INDIVIDUAL_SCOPE] ? (
                <Form
                    className={classes.form}
                    dynamic
                    submit={(formState) => {
                        if (
                            user.id &&
                            typeof formState.values.mirror_consent === 'boolean' &&
                            formState.values.mirror_consent === !consent
                        ) {
                            api.post('/consents/mirror', {
                                user_id: user.id,
                                mirror_consent: formState.values.mirror_consent,
                                main_customer_id: user.oauth_profile[INDIVIDUAL_SCOPE],
                                email: user.email
                            }).then(() => setConsent(formState.values.mirror_consent));
                        } else return new Promise((resolve) => resolve());
                    }}
                    values={{
                        mirror_consent: consent
                    }}
                >
                    <Inputs.Switch
                        name="mirror_consent"
                        label="Dans un objectif d'assistance à l'utilisation ou de conseil à la préparation de mes projets,
                j'accepte de partager mes éléments de simulation avec mes interlocuteurs AXA"
                    />
                </Form>
            ) : (
                <Warn
                    message={
                        isPersona
                            ? 'OAuth profile missing. Please connect using real dataset.'
                            : 'Vous ne pouvez pas partager vos éléments de simulation avec vos interlocuteurs AXA.'
                    }
                    color="redLight"
                />
            )}
        </Modal>
    );
}

export default ConsentMirrorModal;
