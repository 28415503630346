const config = {
    PER: 'PER Obligatoire',
    A83: 'Article 83',
    A82: 'Article 82',
    RETIREMENT_SAVINGS: 'Épargne retraite individuelle',
    B2V: 'Fonds de pension professionnel BCAC'
};

const translateAccountType = (account_type) => {
    if (Object.keys(config).includes(account_type)) return config[account_type];
    return account_type;
};

export default translateAccountType;
