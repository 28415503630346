import React from 'react';
import {Buttons, Typography} from 'components';
import {modal} from 'utils';

const SubStepStart = (props) => {
    const {setStep} = props;

    return (
        <>
            <Typography
                variant="h5"
                color="blue"
            >
                Avant de commencer, munissez-vous de votre dernier avis d'impôt
            </Typography>
            <Buttons.Default
                label="C'est fait"
                color="green"
                next
                center
                onClick={() => setStep(1)}
            />
            <Buttons.Chevron
                label="Annuler"
                color="blue"
                center
                onClick={() => modal.close('refine')}
            />
        </>
    );
};

export default SubStepStart;
