import {TRANSFER_URLS} from './urlMapping';
const getTransferUrl = (scopes = []) => {
    const filteredScopes = scopes.filter((scope) => scope === 'ais' || scope === 's2e');

    switch (filteredScopes.length) {
        case 1:
            return TRANSFER_URLS.ONE_SCOPE[filteredScopes[0]] || null;
        case 2:
            return TRANSFER_URLS.TWO_SCOPES;
        default:
            return null;
    }
};

export default getTransferUrl;
