import React, {cloneElement} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Icon, Loaders} from 'components/index';
import {useForm} from 'hooks';
import {browser} from 'utils';

import {makeStyles} from '@material-ui/styles';
import {Trans, useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'relative',
        display: 'block',
        minWidth: '14rem',
        width: '100%',
        maxWidth: '16rem',
        margin: theme.spacing(1, 0),
        padding: 12,
        border: '1px solid transparent',
        color: theme.palette.white,
        userSelect: 'none',
        cursor: 'pointer',
        transition:
            'color .5s cubic-bezier(0, 0.99, 0.865, 1.005), background-color .6s cubic-bezier(0, 0.99, 0.865, 1.005)',
        textAlign: 'left'
    },
    orange: {
        backgroundColor: theme.palette.orangeLight,
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.orange
        }
    },
    orangeDark: {
        backgroundColor: theme.palette.orangeDark,
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.orange
        }
    },
    orangeRed: {
        height: '45',
        backgroundColor: theme.palette.orangeRed,
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.crimson
        }
    },
    orangeAlpha: {
        color: theme.palette.orangeLight,
        border: `1px solid ${theme.palette.orangeLight}`,
        backgroundColor: theme.palette.transparent,
        '&:hover, &:focus-visible': {
            border: `1px solid ${theme.palette.orange}`,
            color: theme.palette.orange
        }
    },
    green: {
        backgroundColor: theme.palette.green,
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.greenDark
        }
    },
    turquoise: {
        backgroundColor: theme.palette.turquoise,
        '&:hover, &:focus-visible': {
            opacity: 0.9
        }
    },
    blue: {
        backgroundColor: theme.palette.blue,
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.blueDark
        }
    },
    blueLight: {
        backgroundColor: theme.palette.blueLight,
        '&:hover, &:focus-visible': {
            color: theme.palette.blueLight,
            backgroundColor: theme.palette.white,
            border: `1px solid ${theme.palette.blueLight}`
        }
    },
    gray: {
        color: theme.palette.grayDark,
        border: `1px solid ${theme.palette.grayDark}`,
        backgroundColor: theme.palette.transparent,
        '&:hover, &:focus-visible': {
            opacity: 0.9
        }
    },
    red: {
        backgroundColor: theme.palette.redLight,
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.red
        }
    },
    errorRed: {
        color: theme.palette.errorRed,
        border: `1px solid ${theme.palette.errorRed}`,
        backgroundColor: theme.palette.transparent,
        '&:hover, &:focus-visible': {
            border: `1px solid ${theme.palette.red}`,
            color: theme.palette.red
        }
    },
    blueAlpha: {
        color: theme.palette.blue,
        border: `1px solid ${theme.palette.blue}`,
        backgroundColor: theme.palette.transparent,
        '&:hover, &:focus-visible': {
            color: theme.palette.white,
            backgroundColor: theme.palette.blue
        }
    },
    black: {
        padding: 8,
        color: theme.palette.black,
        border: `2px solid ${theme.palette.black}`,
        backgroundColor: theme.palette.transparent,
        '&:hover, &:focus-visible': {
            color: theme.palette.white,
            backgroundColor: theme.palette.black
        }
    },
    cyan: {
        backgroundColor: theme.palette.kerialis.light,
        border: `1px solid ${theme.palette.kerialis.light}`,
        '&:hover, &:focus-visible': {
            color: theme.palette.white,
            backgroundColor: theme.palette.kerialis.main,
            border: `1px solid ${theme.palette.kerialis.main}`
        }
    },
    white: {
        backgroundColor: theme.palette.transparent,
        border: `1px solid ${theme.palette.white}`,
        '&:hover, &:focus-visible': {
            color: theme.palette.grayLight,
            border: `1px solid ${theme.palette.grayLight}`
        }
    },
    transparent_hover_blue: {
        height: '50px',
        backgroundColor: theme.palette.transparent,
        border: `1px solid ${theme.palette.white}`,
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.white,
            color: theme.palette.blue,
            border: `1px solid ${theme.palette.white}`
        }
    },
    transparent_border_blue: {
        minWidth: '141px',
        maxWidth: '141px',
        borderRadius: '4px',
        color: theme.palette.blue,
        backgroundColor: theme.palette.transparent,
        border: `1px solid ${theme.palette.blue}`,
        textAlign: 'center',
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.blueDark,
            color: 'white'
        }
    },
    white_border_blue: {
        backgroundColor: theme.palette.white,
        border: `1px solid ${theme.palette.blue}`,
        color: theme.palette.blue,
        '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.blueDark,
            color: theme.palette.white,
            border: `1px solid ${theme.palette.blueDark}`
        }
    },
    disabled: {
        backgroundColor: `${theme.palette.gray} !important`,
        color: `${theme.palette.white} !important`,
        border: 'none !important'
    },
    wrapper: {
        width: '100%',
        display: 'inherit'
    },
    right: {
        textAlign: 'right'
    },
    centerText: {
        textAlign: 'center'
    },
    center: {
        margin: `${theme.spacing(1)}px auto`
    },
    icon: {
        '&::before': {
            position: 'absolute',
            right: 14,
            fontSize: 16,
            top: '50%',
            transform: 'translateY(-50%)'
        }
    },
    muiIcon: {
        padding: 0,
        margin: 0,
        position: 'absolute',
        right: 12,
        top: 'calc(50%)',
        transform: 'translateY(-50%)'
    },
    alignLeft: {
        '&::before': {
            left: 16,
            right: 'unset'
        }
    },
    label: {
        letterSpacing: '0.22px',
        fontWeight: '600',
        whiteSpace: 'nowrap'
    },
    fullWidth: {
        maxWidth: browser.isIE11() ? 'none' : 'initial'
    },
    loader: {
        justifyContent: 'flex-end',
        '& > div': {
            padding: 8,
            color: 'white !important'
        }
    },
    loading: {
        cursor: 'initial !important',
        pointerEvents: 'none !important'
    },
    small: {
        padding: '8px !important',
        borderWidth: '2px !important'
    },
    lang: {
        color: theme.palette.blue,
        maxWidth: '17rem'
    },
    blueLabel: {
        color: theme.palette.blue,
        maxWidth: '17rem',
        '&:hover, &:focus-visible': {
            color: theme.palette.blueDark
        }
    }
}));

function Default(props) {
    const {
        label,
        className,
        classLabel,
        color,
        icon,
        iconName,
        disabled,
        center,
        previous,
        next,
        right,
        centerText,
        submit,
        fullWidth,
        loading,
        small,
        role,
        title,
        ariaLabel,
        ...rest
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    const {formState} = useForm();

    return (
        <button
            className={clsx({
                [classes.button]: true,
                [classes[color]]: true,
                [classes.disabled]: submit ? !formState.isValid : disabled,
                [classes.center]: center,
                [classes.fullWidth]: fullWidth,
                [classes.loading]: loading,
                [classes.small]: small,
                [className]: className,
                previous: previous,
                next: next
            })}
            disabled={submit ? !formState.isValid : disabled}
            tabIndex="0"
            type={submit ? 'submit' : 'button'}
            role={role ? role : 'button'}
            title={t(title ? title : label)}
            aria-label={t(ariaLabel ? ariaLabel : label)}
            {...rest}
        >
            <span
                className={clsx({
                    [classes.wrapper]: true,
                    [classes.right]: right || previous,
                    [classes.centerText]: centerText
                })}
            >
                <span className={clsx(classes.label, classLabel && classLabel)}>
                    <Trans i18nKey={label} />
                </span>
                {loading ? (
                    <Loaders.Circular
                        className={classes.loader}
                        open
                        transparent
                    />
                ) : iconName || next || previous ? (
                    <Icon
                        className={clsx({
                            [classes.icon]: true,
                            [classes.alignLeft]: right || previous
                        })}
                        name={
                            iconName
                                ? iconName
                                : next
                                ? 'axa-icons-arrow-forward'
                                : previous
                                ? 'axa-icons-arrow-back'
                                : ''
                        }
                    />
                ) : (
                    icon && cloneElement(icon, {className: classes.muiIcon})
                )}
            </span>
        </button>
    );
}

Default.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    classLabel: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.node,
    iconName: PropTypes.string,
    disabled: PropTypes.bool,
    center: PropTypes.bool,
    right: PropTypes.bool,
    centerText: PropTypes.bool,
    previous: PropTypes.bool,
    next: PropTypes.bool,
    submit: PropTypes.bool,
    fullWidth: PropTypes.bool,
    loading: PropTypes.bool,
    small: PropTypes.bool
};

export default Default;
