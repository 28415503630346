import mock from 'api/mock';

mock.onGet('/login/oauth/authorization/collective').reply(() => [200, '/callback/easyprojets']);
mock.onGet('/login/oauth/authorization/individual').reply(() => [200, '/callback/maretraite360']);
mock.onGet('/login/oauth/authorization/individual_transmission').reply(() => [200, '/callback/matransmission360']);

mock.onGet('/login/change-password/collective').reply(() => [
    200,
    'https://connect-recprj.ma-protectionsociale.fr/?ReturnUrl=/connect/authorize/callback?client_id=3bdd694e-8352-4735-9211-1ec507b3bda6'
]);

mock.onGet('/login/oauth/profile/collective').reply(() => {
    const oauth_profile = {
        vot: 'Kl.Cc',
        vtm: 'https://connect.ma-protectionsociale.fr/vot-trust-framework.html',
        auth_time: 1621333413,
        previous_auth_time: 1621281361,
        sub: 'c9c43d48-f9d8-4e50-98cd-324e3eb58af7',
        'axa-fr-salary-saving-internet-login': '00000000',
        'ais-fr-retirement-saving-customer-login': '00000000',
        'axa-fr-salary-saving-customer-id': 'a799df79-8275-41a5-aec9-a16160fc5ca5',
        'ais-fr-retirement-saving-customer-id': '000000000',
        'https://connect.axa.fr/individualOrProfessional.mainCustomerId': '0000',
        'https://connect.axa.fr/individualOrProfessional.customerId': '0000',
        preferred_username: 'collective@axa.fr',
        name: 'collective@axa.fr',
        given_name: 'Acon',
        family_name: 'MOCK'
    };

    return [200, {oauth_profile}];
});

mock.onGet('/login/oauth/profile/individual').reply(() => {
    const oauth_profile = {
        vot: 'Kl.Cc',
        vtm: 'https://connect.axa.fr/vot-trust-framework.html',
        auth_time: 1621413423,
        previous_auth_time: 1621339432,
        sub: '929000aa-8041-4e80-a840-0d5eccc20a1b',
        'https://connect.axa.fr/individualOrProfessional.mainCustomerId': '00000000',
        preferred_username: '1789452',
        name: '1789452',
        gender: '1',
        given_name: 'INDIVIDUAL',
        family_name: 'MOCK'
    };

    return [200, {oauth_profile}];
});
mock.onGet('/login/oauth/profile/individual_transmission').reply(() => {
    const oauth_profile = {
        vot: 'Kl.Cc',
        vtm: 'https://connect.axa.fr/vot-trust-framework.html',
        auth_time: 1621413423,
        previous_auth_time: 1621339432,
        sub: '929000aa-8041-4e80-a840-0d5eccc20a1b',
        'https://connect.axa.fr/individualOrProfessional.mainCustomerId': '00000000',
        preferred_username: '1789452',
        name: '1789452',
        gender: '1',
        given_name: 'INDIVIDUAL_transmission',
        family_name: 'MOCK'
    };

    return [200, {oauth_profile}];
});

mock.onGet('/login/api-client/token/collective').reply(() => [200, 'OK']);
mock.onGet('/login/api-client/token/individual').reply(() => [200, 'OK']);
mock.onGet('/login/api-client/token/individual_transmission').reply(() => [200, 'OK']);

mock.onGet('/login/oauth/otp/collective').reply((config) => [200, `/authcallback?state=${config.params.state}`]);
mock.onGet('/login/oauth/otp/callback/collective').reply(() => [200, {has_validate_acon_otp: true}]);
