const config = {
    CO: 'Cotisations obligatoires',
    VV: 'Versements volontaires',
    VVD: 'Versements volontaires déductibles',
    VVND: 'Versements volontaires non déductibles',
    ES: 'Épargne salariale'
};

const translateCompartment = (compartment) => {
    if (Object.keys(config).includes(compartment)) return config[compartment];
    return compartment;
};

export default translateCompartment;
