import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Grid, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    tab: {
        cursor: 'pointer',
        borderBottom: `solid 1px ${theme.palette.blue}`
    },
    selected: {
        borderBottom: `solid 3px ${theme.palette.blue}`
    }
}));

function Tab({tab, tabSelected, setTabSelected, setItemSelected}) {
    const classes = useStyles();

    return (
        <Grid
            className={clsx({
                [classes.tab]: true,
                [classes.selected]: tab.name === tabSelected.name
            })}
            item
            xs={6}
            onClick={() => {
                setTabSelected(tab);
                setItemSelected(tab.items[0]);
            }}
        >
            <Typography
                color="blue"
                semibold
                center
                gutterBottom={false}
            >
                {tab.label.toUpperCase()}
            </Typography>
        </Grid>
    );
}

Tab.propTypes = {
    tab: PropTypes.object.isRequired,
    tabSelected: PropTypes.object.isRequired,
    setTabSelected: PropTypes.func.isRequired,
    setItemSelected: PropTypes.func.isRequired
};

export default Tab;
