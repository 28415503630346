import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import config from 'config';
import {Box} from '@material-ui/core';

import {makeStyles} from '@material-ui/styles';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDownOutlined';
import {useTranslation} from 'react-i18next';
import {Typography} from 'components';
import {useUser} from 'hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        zIndex: 1002,
        right: 20,
        bottom: 65,
        padding: 8,
        [theme.breakpoints.down('sm')]: {
            right: 0
        }
    },
    hidden: {
        display: 'none'
    },
    iconWrapper: {
        width: 56,
        height: 56,
        border: `solid 1px ${theme.palette.blue}`,
        borderRadius: '50%',
        padding: 11,
        boxShadow: theme.shadows[3],
        background: theme.palette.blue,
        '&:hover': {
            background: theme.palette.blueDark
        }
    },
    icon: {
        color: 'white',
        fontSize: 30
    },
    button: {
        border: 'none',
        cursor: 'pointer',
        '&:focus $iconWrapper': {
            background: theme.palette.blueDark
        }
    },
    box: {
        background: theme.palette.white,
        borderRadius: '100px',
        boxShadow: '0px 6px 24px -2px #00008F33',
        marginRight: '10px',
        overflow: 'hidden',
        width: '0px',
        height: '88px',
        whiteSpace: 'nowrap',
        transition: 'width 0.6s ease-out',
        '@media (max-width: 600px)': {
            height: '108px',
            whiteSpace: 'normal'
        },
        '&>div': {
            transitionDelay: '0s',
            transition: 'opacity 0.2s ease-out',
            opacity: 0
        }
    },
    expanded: {
        width: '100%',
        '&>div': {
            transitionDelay: '0.7s',
            transition: 'opacity 0.5s ease-out',
            opacity: 1
        }
    },
    textBox: {
        margin: theme.spacing('16px', '32px')
    }
}));

function ToggleOpinion({open, setOpen, visible, setVisible}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {user, setUser} = useUser();

    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setVisible(config.OPINION_PATHS.includes(window.location.pathname));

        // eslint-disable-next-line
    }, [window.location.href]);

    useEffect(() => {
        if (window.location.pathname === '/epargne' && !user?.seeOpinionMessage) {
            setTimeout(() => setExpanded(true), 500);
            setUser({...user, ...{seeOpinionMessage: true}});
        }
        const collapseTimeout = setTimeout(() => setExpanded(false), 5000);

        return () => {
            clearTimeout(collapseTimeout);
        };

        // eslint-disable-next-line
    }, [window.location.pathname]);

    return (
        <Box
            className={clsx({
                [classes.root]: true,
                [classes.hidden]: !visible || open
            })}
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
        >
            <Box className={clsx(classes.box, expanded && classes.expanded)}>
                <Box className={classes.textBox}>
                    <Typography
                        size={18}
                        semibold
                        color="blue"
                        localization={'home.yourOpinion'}
                    ></Typography>
                    <Typography localization={'home.shareOpinion'}></Typography>
                </Box>
            </Box>
            <button
                className={classes.button}
                onClick={() => {
                    setOpen(true);
                }}
                tabIndex="0"
                title={t('home.opinion')}
                aria-label={t('home.opinion')}
            >
                <div className={classes.iconWrapper}>
                    <ThumbsUpDownIcon className={classes.icon} />
                    <Typography
                        variant="srOnly"
                        localization={'home.opinion'}
                    />
                </div>
            </button>
        </Box>
    );
}

ToggleOpinion.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default ToggleOpinion;
