import React from 'react';
import {Alert, Grid, Inputs, Typography} from 'components';
import {useForm} from 'hooks';
import clsx from 'clsx';
import {amount} from 'utils';
import {makeStyles} from '@material-ui/styles';
import {Box, useMediaQuery, useTheme} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        borderRadius: 10,
        boxShadow: '0px 0px 8px #00000033',
        margin: theme.spacing(1, 0, 2)
    },
    item: {
        padding: '10px 20px 10px 20px',
        background: theme.palette.whiteGauge,
        '&:last-child': {
            borderRadius: 6
        },
        '&:nth-child(2)': {
            borderLeft: `solid 1px ${theme.palette.gray}`,
            borderTop: `none`
        }
    },
    itemMobile: {
        borderLeft: 'none !important',
        borderTop: `solid 1px ${theme.palette.gray} !important`
    },
    alert: {
        width: '100%'
    }
}));

const MultiDevicesGauge = ({contribution_gauge}) => {
    const classes = useStyles();
    const theme = useTheme();

    const {formState} = useForm();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const ceiling = contribution_gauge[formState.values.account_type]?.ceiling;

    const consumed = contribution_gauge[formState.values.account_type]?.consumed;
    const available = Math.max(0, ceiling - consumed);

    return (
        <div className={classes.root}>
            <Inputs.Radio
                name="account_type"
                hiddenLabel
                row
                labelColor="blue"
                labelBold
                options={Object.keys(contribution_gauge)
                    .filter((account_type) => contribution_gauge[account_type])
                    .map((account_type) => ({
                        label: account_type.toUpperCase(),
                        value: account_type.toLowerCase()
                    }))}
            />
            {typeof ceiling === 'number' && typeof consumed === 'number' ? (
                <>
                    <Grid
                        className={classes.container}
                        container
                    >
                        <Grid
                            className={classes.item}
                            item
                            sm={4}
                            xs={12}
                        >
                            <Box pb={1.5}>
                                <Typography
                                    color="scorpion"
                                    semibold
                                    gutterBottom={false}
                                >
                                    Plafond d'abondement
                                </Typography>
                            </Box>
                            <Typography
                                variant="h4"
                                semibold
                                color="dark"
                            >
                                {amount.format(ceiling)}
                            </Typography>
                        </Grid>
                        <Grid
                            className={clsx(classes.item, isMobile && classes.itemMobile)}
                            item
                            sm={4}
                            xs={12}
                        >
                            <Box pb={1.5}>
                                <Typography
                                    color="scorpion"
                                    semibold
                                    gutterBottom={false}
                                >
                                    Abondement consommé
                                </Typography>
                            </Box>
                            <Typography
                                variant="h4"
                                semibold
                                color="dark"
                            >
                                {amount.format(consumed)}
                            </Typography>
                        </Grid>
                        <Grid
                            className={classes.item}
                            item
                            sm={4}
                            xs={12}
                            style={{
                                background: available > 0 ? theme.palette.blue : '#333333'
                            }}
                        >
                            <Box pb={1.5}>
                                <Typography
                                    color="white"
                                    semibold
                                    variant="h7"
                                    gutterBottom={false}
                                >
                                    Abondement disponible
                                </Typography>
                            </Box>
                            <Typography
                                color="white"
                                bold
                                variant="h3max"
                                size={22}
                            >
                                {amount.format(available)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Alert
                        className={classes.alert}
                        message="Montants bruts estimés sous réserve des opérations en cours."
                        severity="info"
                    />
                </>
            ) : (
                <Alert
                    className={classes.alert}
                    message="Le simulateur est temporairement indisponible."
                    severity="warning"
                />
            )}
        </div>
    );
};

export default MultiDevicesGauge;
