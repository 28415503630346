import React from 'react';
import {useParams} from 'react-router-dom';
import {Grid, Typography} from 'components';
import {AccountMrm, SampleDisclaimer} from './components';
import {useUser} from 'hooks';
import {accounts} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1, 0)
    },
    disclaimer: {
        margin: theme.spacing(2, 0),
        background: theme.palette.pale
    }
}));

function StepAccountsReminder() {
    const classes = useStyles();

    const {user} = useUser();

    const {withdrawCase} = useParams();

    return (
        <>
            <Typography
                variant="h3"
                color="blue"
                semibold
                center
            >
                Rappel de vos dispositifs retraite
            </Typography>
            <Grid
                className={classes.container}
                container
                justifyContent="center"
                spacing={2}
            >
                {accounts
                    .filter(user.accounts, 'ais', ['PER', 'PERO', 'PERECO', 'PERU', 'A83', 'A82'])
                    .map((account, index) => (
                        <AccountMrm
                            index={index}
                            account={account}
                            disabled={withdrawCase ? account.type === 'A83' && withdrawCase !== 'retirement' : false}
                            key={`accountMrm-${account.policy_id}`}
                        />
                    ))}
            </Grid>

            {withdrawCase && withdrawCase !== 'retirement' && <SampleDisclaimer />}
        </>
    );
}

export default StepAccountsReminder;
