const parse = (string) => {
    if (!string) return 0;

    if (typeof string === 'number') return Math.round(string * 100) / 100;

    if (string.includes(',')) {
        let decimals = string.split(',')[1];

        let amount = parseFloat(string.replaceAll(' ', '').replace(',', '')) / 10 ** decimals.length;
        return Math.round(amount * 100) / 100;
    }

    let amount = parseFloat(string.replaceAll(' ', ''));
    return Math.round(amount * 100) / 100;
};

export default parse;
