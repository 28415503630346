export const age_full_rate = '67 ans et 3 mois';

export const date = '2021-05-25T15:30:18.070420';

export const careers = {
    base: [
        {
            start_date: '01/2017',
            end_date: '12/2017',
            regime: 'AGIRC-ARRCO',
            points: 180,
            quarters: 4,
            employer: 'Woodmee',
            regime_label: 'Régime de retraite complémentaire des Salariés et des cadres'
        }
    ],
    complementary: [
        {
            start_date: '10/2010',
            end_date: '12/2010',
            regime: 'AGIRC-ARRCO',
            points: 29.5247887,
            quarters: 4,
            employer: 'Eurogiciel ingenierie',
            regime_label: 'Régime de retraite complémentaire des Salariés et des cadres'
        }
    ]
};

export const family_situation = {
    options: [
        {label: 'Célibataire', value: 'CE'},
        {
            label: 'Marié(e)',
            value: 'MA'
        },
        {
            label: 'Pacsé(e)',
            value: 'PAC'
        },
        {
            label: 'Veuf(ve)',
            value: 'VEF'
        },
        {
            label: 'En concubinage',
            value: 'COC'
        },
        {label: 'Divorcé(e)', value: 'DI'}
    ]
};

export const professional_situation = [
    {
        subcategory: 'Activité',
        options: [{label: 'En activité', value: 'ACT'}]
    },
    {
        subcategory: 'Activité partielle',
        options: [
            {
                label: 'Activité et Maladie',
                value: 'ACT_MAL'
            },
            {
                label: 'Activité et chômage indemnisé',
                value: 'ACT_CHO_IND'
            },
            {
                label: 'Activité et Invalidité',
                value: 'ACT_INV'
            },
            {
                label: 'Activité et Disponibilité pour tierce personne',
                value: 'ACT_DIS'
            }
        ]
    },
    {
        subcategory: 'Inactivité',
        options: [
            {
                label: 'Maladie',
                value: 'INA_MAL'
            },
            {
                label: 'Chômage indemnisé',
                value: 'INA_CHO_IND'
            },
            {
                label: 'Chômage non indemnisé',
                value: 'INA_CHO_NON_IND'
            },
            {
                label: 'Invalidité',
                value: 'INA_INV'
            },
            {
                label: 'Disponibilité pour tierce personne',
                value: 'INA_DIS'
            },
            {
                label: 'Congé parental',
                value: 'INA_CPA'
            },
            {
                label: 'Congés sans soldes',
                value: 'INA_CSS'
            },
            {
                label: "Congés pour s'occuper d'un proche (fin de vie, aidant, handicap,...)",
                value: 'INA_COP'
            },
            {
                label: "Congés de fin d'activité",
                value: 'INA_CFA'
            },
            {
                label: 'Disponibilité',
                value: 'INA_DISPO_GEN'
            }
        ]
    }
];

export const professional_categories = [
    {
        subcategory: 'Salarié(e)',
        options: [
            {
                value: 'NS',
                label: 'Salarié(e) du privé',
                disabled: false
            },
            {
                value: 'NG',
                label: "Salarié(e) de l'agriculture",
                disabled: false
            },
            {
                value: 'AGENT',
                label: 'Contractuel(le) de droit public',
                disabled: false
            },
            {
                value: 'C_NOT',
                label: 'Clerc ou Employé(e) de notaire',
                disabled: false
            }
        ]
    },
    {
        subcategory: 'Artisan / Commerçant / Indépendant',
        options: [
            {
                value: 'ACI',
                label: "Chef d'entreprise (artisan, commerçant)",
                disabled: false
            },
            {
                value: 'ACI_CONJ',
                label: 'Conjoint collaborateur (artisan, commerçant)',
                disabled: false
            }
        ]
    },
    {
        subcategory: 'Micro entrepreneur (profession libérale non réglementée)',
        options: [
            {
                value: 'MIC_VHR',
                label: 'Activité Vente, Hotellerie ou Restauration',
                disabled: false
            },
            {
                value: 'MIC_BIC',
                label: 'Prestations de services BIC',
                disabled: false
            },
            {
                value: 'MIC_BNC',
                label: 'Prestations de services BNC',
                disabled: false
            }
        ]
    },
    {
        subcategory: 'Non salarié(e) agricole',
        options: [
            {
                value: 'NSA',
                label: "Chef d'exploitation ou d'entreprise",
                disabled: false
            },
            {
                value: 'NSA_COLLAB',
                label: "Collaborateur d'exploitation ou d'entreprise agricole",
                disabled: false
            },
            {
                value: 'NSA_AIDE',
                label: 'Aide familial agricole',
                disabled: false
            }
        ]
    },
    {
        subcategory: "Agent de la fonction publique d'État",
        options: [
            {
                value: 'FSA',
                label: 'Magistrat ou Fonctionnaire civil (catégorie sédentaire)',
                disabled: false
            },
            {
                value: 'FSB',
                label: 'Fonctionnaire civil (catégorie active)',
                disabled: false
            },
            {
                value: 'DAPO',
                label: 'Agent de la Police Nationale',
                disabled: false
            },
            {
                value: 'DAPE',
                label: 'Surveillant pénitentiaire',
                disabled: false
            }
        ]
    },
    {
        subcategory: 'Agent de la fonction publique territoriale',
        options: [
            {
                value: 'FCA',
                label: 'Fonctionnaire civil (catégorie sédentaire)',
                disabled: false
            },
            {
                value: 'FCB',
                label: 'Fonctionnaire civil (catégorie active)',
                disabled: false
            },
            {
                value: 'SPP',
                label: 'Sapeur-Pompier Professionnel',
                disabled: false
            },
            {
                value: 'APM',
                label: 'Agent de la Police Municipale',
                disabled: false
            },
            {
                value: 'CDSPT',
                label: 'Cadre territorial de santé paramédical',
                disabled: false
            },
            {
                value: 'PUET',
                label: 'Puéricultrice territoriale',
                disabled: false
            },
            {
                value: 'INFT',
                label: 'Infirmier',
                disabled: false
            }
        ]
    },
    {
        subcategory: 'Agent de la fonction publique hospitalière',
        options: [
            {
                value: 'FHA',
                label: 'Autre activité de catégorie sédentaire',
                disabled: false
            },
            {
                value: 'FHB',
                label: 'Autre activité de catégorie active',
                disabled: false
            },
            {
                value: 'AS',
                label: 'Aide-Soignant',
                disabled: false
            },
            {value: 'INFH', label: 'Infirmier', disabled: false},
            {
                value: 'CDSPH',
                label: 'Cadre hospitalier de santé paramédical',
                disabled: false
            },
            {
                value: 'PUEH',
                label: 'Puéricultrice hospitalière',
                disabled: false
            },
            {
                value: 'PDRH',
                label: 'Personnel de la rééducation',
                disabled: false
            },
            {
                value: 'MEMH',
                label: 'Manipulateur d’électroradiologie médicale',
                disabled: false
            }
        ]
    },
    {
        subcategory: 'Profession libérale',
        options: [
            {
                value: 'ASS',
                label: 'Agent général d’assurances(CAVAMAC)',
                disabled: false
            },
            {
                value: 'ASS_CAJ',
                label: 'Conjoint collaborateur (CAVAMAC)',
                disabled: false
            },
            {
                value: 'ASS_MAJ',
                label: 'Agent gérant majoritaire (CAVAMAC)',
                disabled: false
            },
            {
                value: 'PLCI',
                label: 'Profession libérale (CIPAV)',
                disabled: false
            },
            {
                value: 'AUX',
                label: 'Auxiliaire médical conventionné (CARPIMKO)',
                disabled: false
            },
            {
                value: 'AUX_NC',
                label: 'Auxiliaire médical non conventionné (CARPIMKO)',
                disabled: false
            },
            {
                value: 'CDC',
                label: 'Chirurgien-dentiste Conventionné (CARCDSF)',
                disabled: false
            },
            {
                value: 'CD',
                label: 'Chirurgien-dentiste (CARCDSF)',
                disabled: false
            },
            {
                value: 'NOT',
                label: 'Notaire libéral (CPRN)',
                disabled: false
            },
            {
                value: 'SFC',
                label: 'Sage-femme',
                disabled: false
            },
            {value: 'SF', label: 'Sage-femme (CARCDSF)', disabled: false}
        ]
    },
    {
        subcategory: 'Pharmacien et Biologiste',
        options: [
            {value: 'PHARM', label: 'Pharmacien libéral', disabled: false},
            {
                value: 'BIOL_CONV',
                label: 'Biologiste médical conventionné',
                disabled: false
            },
            {
                value: 'BIOL_NCONV',
                label: 'Biologiste médical non conventionné',
                disabled: false
            },
            {
                value: 'COTI',
                label: 'Cotisant volontaire',
                disabled: false
            }
        ]
    },
    {
        subcategory: 'Médecin',
        options: [
            {
                value: 'MCS1',
                label: 'Médecin conventionné secteur 1',
                disabled: false
            },
            {
                value: 'MCS2',
                label: 'Médecin conventionné secteur 2',
                disabled: false
            },
            {
                value: 'MNC',
                label: 'Médecin non conventionné',
                disabled: false
            },
            {
                value: 'MAV',
                label: 'Adhérent volontaire',
                disabled: false
            },
            {
                value: 'MCCM',
                label: 'Conjoint collaborateur de médecin',
                disabled: false
            }
        ]
    },
    {subcategory: 'Assuré du régime des cultes', options: []},
    {
        subcategory: 'Autres',
        options: []
    },
    {subcategory: 'Régimes spéciaux', options: []},
    {
        subcategory: 'Régime spécial SNCF',
        options: [
            {value: 'FTA', label: 'Agent sédentaire SNCF', disabled: false},
            {
                value: 'FTB',
                label: 'Agent de conduite SNCF ',
                disabled: false
            }
        ]
    },
    {subcategory: "Agent d'assurance", options: []},
    {
        subcategory: 'Opéra de Paris',
        options: [
            {value: 'AC', label: 'Artiste des chœurs', disabled: false},
            {
                value: 'AB',
                label: 'Artiste du ballet',
                disabled: false
            },
            {value: 'AO', label: 'Artiste de l’orchestre', disabled: false},
            {
                value: 'AL',
                label: 'Artiste lyrique',
                disabled: false
            },
            {
                value: 'CCPA',
                label: 'Chefs de chant et pianiste accompagnateur',
                disabled: false
            },
            {
                value: 'TADA',
                label: 'Technicien avec départ anticipé',
                disabled: false
            },
            {
                value: 'TSDA',
                label: 'Technicien sans départ anticipé',
                disabled: false
            },
            {
                value: 'CAA',
                label: 'Cadre, administratif et autre',
                disabled: false
            }
        ]
    }
];

export const result = [
    {
        age: '62 ans ',
        quarters: 135,
        yearly_amount_base: 51770,
        monthly: 3650,
        type: 'B'
    },
    {
        age: '63 ans ',
        quarters: 139,
        yearly_amount_base: 54960,
        monthly: 3910,
        type: 'B'
    },
    {
        age: '64 ans ',
        quarters: 143,
        yearly_amount_base: 58260,
        monthly: 3190,
        type: 'B'
    },
    {
        age: '65 ans ',
        quarters: 147,
        yearly_amount_base: 61340,
        monthly: 4450,
        type: 'B'
    },
    {
        age: '66 ans ',
        quarters: 151,
        yearly_amount_base: 64530,
        monthly: 3710,
        type: 'B'
    },
    {
        age: '67 ans ',
        quarters: 155,
        yearly_amount_base: 67820,
        monthly: 4980,
        type: 'B'
    },
    {
        age: '68 ans ',
        quarters: 172,
        yearly_amount_base: 70580,
        monthly: 4210,
        type: 'B'
    }
];
