const translateLifeProject = (type, raw = false) => {
    if (raw)
        return {
            retirement: 'projet retraite',
            owner: 'projet immobilier',
            children: 'projet enfants',
            travel: 'projet voyage',
            company: 'projet entreprise',
            housing: 'projet aménagement',
            other: 'autre projet'
        }[type];
    else
        return {
            retirement: 'Préparer ma retraite',
            owner: 'Devenir propriétaire',
            children: 'Préparer le futur de mes enfants',
            travel: 'Préparer un voyage',
            company: 'Créer une entreprise',
            housing: 'Agrandir ma maison',
            other: 'Autre projet'
        }[type];
};

export default translateLifeProject;
