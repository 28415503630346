import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Structure} from 'components';
import {StepAge, StepChildren, StepGender, StepMaritalStatus, StepMonthlyIncome, StepTaxResult} from './components';
import {useUser} from 'hooks';
import {TaxSimulatorProvider} from 'store/TaxSimulator';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(6, 1),
        minHeight: 350
    }
}));

function TaxSimulator({variant}) {
    const {user} = useUser();

    const classes = useStyles();

    return (
        <div className={clsx(variant !== 'contained' && classes.root)}>
            <TaxSimulatorProvider variant={variant}>
                {variant !== 'contained' && (
                    <Structure.Carousel
                        adaptiveHeight
                        initialSlide={user.ais_profile?.gender && user.ais_profile.birth_date ? 2 : 0}
                        smooth
                        speed={500}
                    >
                        <StepGender />
                        <StepAge />
                        <StepMaritalStatus />
                        <StepChildren />
                        <StepMonthlyIncome />
                        <StepTaxResult />
                    </Structure.Carousel>
                )}
                {variant === 'contained' && <StepTaxResult />}
            </TaxSimulatorProvider>
        </div>
    );
}

TaxSimulator.propTypes = {
    variant: PropTypes.oneOf(['public', 'standalone', 'contained'])
};

export default TaxSimulator;
