import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Info, Inputs, Typography, Warn} from 'components';
import {amount} from 'utils';
import {useForm} from 'hooks';

import {useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 0, 3)
    },
    table: {
        width: '100%',
        margin: '4px 0',
        tableLayout: 'fixed',
        borderCollapse: 'collapse',
        borderSpacing: '0',

        '& th:not(:first-child), tbody td': {
            padding: theme.spacing(0, 1),
            background: theme.palette.purpleLight,
            border: 'solid 3px white',
            height: 45
        },
        '& tfoot td': {
            padding: theme.spacing(0, 1),
            background: theme.palette.purple,
            height: 45,
            '&:nth-last-child(2), &:last-child': {
                padding: '0 25px'
            }
        }
    },
    bgPurple: {
        background: `${theme.palette.purpleDark} !important`
    }
}));

const DistributionRow = ({index, label, account, compartment, redemption}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const total = account[compartment ? `balance_${compartment}` : 'balance'];

    const InputTemplate = ({column}) => (
        <Inputs.Number
            name={compartment ? `${column}_${account.policy_id}_${compartment}` : `${column}_${account.policy_id}`}
            format="money-2"
            adornment="€"
            right
            disableSpacing
            size={18}
            disabled={compartment === 'co' || account.type === 'A83' || total === 0}
            submitOnBlur
        />
    );

    return (
        <>
            <tr>
                <td colSpan={isDesktop ? 2 : 1}>
                    <Typography
                        variant="h5"
                        semibold={!isDesktop}
                        gutterBottom={false}
                    >
                        {label}
                    </Typography>
                </td>
                <td>
                    <Typography
                        variant="h5"
                        right={isDesktop}
                        center={!isDesktop}
                        semibold={!isDesktop}
                        gutterBottom={false}
                    >
                        {amount.format(total)}
                    </Typography>
                </td>
                {isDesktop && (
                    <td>
                        <InputTemplate column="capital" />
                    </td>
                )}
                {isDesktop && !redemption && (
                    <td>
                        <InputTemplate column="annuity" />
                    </td>
                )}
            </tr>
            {!isDesktop && (
                <>
                    <tr>
                        <td className={classes.bgPurple}>
                            <Typography
                                variant="h5"
                                color="white"
                                gutterBottom={false}
                            >
                                {redemption ? 'Déblocage' : 'Capital'}
                            </Typography>
                        </td>
                        <td>
                            <InputTemplate column="capital" />
                        </td>
                    </tr>
                    {!redemption && (
                        <tr>
                            <td className={classes.bgPurple}>
                                <Typography
                                    variant="h5"
                                    color="white"
                                    gutterBottom={false}
                                >
                                    Rente&nbsp;
                                    <Info
                                        hash={compartment ? `annuity_${compartment}_${index}` : `annuity_${index}`}
                                        color="white"
                                        tooltip
                                    >
                                        Capital à convertir en rente
                                    </Info>
                                </Typography>
                            </td>
                            <td>
                                <InputTemplate column="annuity" />
                            </td>
                        </tr>
                    )}
                </>
            )}
        </>
    );
};

function DistributionTable({index, account, redemption}) {
    const classes = useStyles();
    const theme = useTheme();

    const {formState} = useForm();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const amountRest = useMemo(
        () =>
            account.balance -
            Object.entries(formState.values)
                .filter(([name]) => name.includes(account.policy_id))
                .map(([, balance]) => amount.parse(balance))
                .reduce((acc, val) => acc + val, 0),
        [account, formState.values]
    );

    const getTotalAmount = (column) =>
        amount.format(
            Object.entries(formState.values)
                .filter(([name]) => name.startsWith(`${column}_${account.policy_id}`))
                .map(([, balance]) => amount.parse(balance))
                .reduce((acc, val) => acc + val, 0)
        );

    return (
        <div className={classes.root}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th colSpan={isDesktop ? 2 : 1} />
                        <th>
                            <Typography
                                variant="h5"
                                semibold
                                gutterBottom={false}
                            >
                                {account.title}
                            </Typography>
                        </th>
                        {isDesktop && (
                            <th className={classes.bgPurple}>
                                <Typography
                                    variant="h5"
                                    color="white"
                                    gutterBottom={false}
                                >
                                    {redemption ? 'Déblocage' : 'Capital'}
                                </Typography>
                            </th>
                        )}
                        {isDesktop && !redemption && (
                            <th className={classes.bgPurple}>
                                <Typography
                                    variant="h5"
                                    color="white"
                                    gutterBottom={false}
                                >
                                    Rente&nbsp;
                                    <Info
                                        hash={`capital_${index}`}
                                        color="white"
                                        tooltip
                                    >
                                        Capital à convertir en rente
                                    </Info>
                                </Typography>
                            </th>
                        )}
                    </tr>
                </thead>

                <tbody>
                    {account.type === 'A83' && (
                        <DistributionRow
                            index={index}
                            label="Épargne disponible"
                            account={account}
                            redemption={redemption}
                        />
                    )}
                    {(account.type === 'PER' ||
                        account.type === 'PERO' ||
                        account.type === 'PERECO' ||
                        account.type === 'PERU') && (
                        <>
                            <DistributionRow
                                index={index}
                                label="Versements obligatoires"
                                account={account}
                                compartment="co"
                                redemption={redemption}
                            />
                            <DistributionRow
                                index={index}
                                label="Versements volontaires"
                                account={account}
                                compartment="vv"
                                redemption={redemption}
                            />
                            <DistributionRow
                                index={index}
                                label="CET, Participation et Intéressement"
                                account={account}
                                compartment="es"
                                redemption={redemption}
                            />
                        </>
                    )}
                </tbody>

                <tfoot>
                    <tr>
                        <td colSpan={isDesktop ? 2 : 1}>
                            <Typography
                                variant="h5"
                                semibold
                                gutterBottom={false}
                            >
                                Total
                            </Typography>
                        </td>
                        <td>
                            <Typography
                                variant="h5"
                                right
                                semibold
                                gutterBottom={false}
                            >
                                {amount.format(account.balance)}
                            </Typography>
                        </td>
                        {isDesktop && (
                            <td>
                                <Typography
                                    variant="h5"
                                    right
                                    semibold
                                    gutterBottom={false}
                                >
                                    {getTotalAmount('capital')}
                                </Typography>
                            </td>
                        )}
                        {isDesktop && !redemption && (
                            <td>
                                <Typography
                                    variant="h5"
                                    right
                                    semibold
                                    gutterBottom={false}
                                >
                                    {getTotalAmount('annuity')}
                                </Typography>
                            </td>
                        )}
                    </tr>
                </tfoot>
            </table>

            {amountRest >= 0.01 && (
                <Typography
                    size={15}
                    right
                >
                    Vous avez un solde restant de&nbsp;
                    <Typography
                        component="span"
                        semibold
                    >
                        {amount.format(amountRest)}
                    </Typography>
                </Typography>
            )}
            <Warn
                if={amountRest <= -0.01}
                message="Vous avez dépassé le maximum"
                right
                color="orange"
            />
        </div>
    );
}

DistributionTable.propTypes = {
    account: PropTypes.object.isRequired,
    redemption: PropTypes.bool
};

export default DistributionTable;
