import React from 'react';
import {Buttons, Form, Inputs, Typography} from 'components';
import {useTaxSimulator} from 'hooks';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    select: {
        maxWidth: 100
    },
    label: {
        fontSize: 25,
        minHeight: 70
    }
}));

const options = [
    {
        label: '0',
        value: 0
    },
    {
        label: '1',
        value: 1
    },
    {
        label: '2',
        value: 2
    },
    {
        label: '3',
        value: 3
    },
    {
        label: '4',
        value: 4
    },
    {
        label: '5+',
        value: 5
    }
];

function StepChildren({setStep}) {
    const classes = useStyles();

    const {taxSimulator, setTaxSimulator, variant} = useTaxSimulator();

    return (
        <Form
            dynamic={variant !== 'contained'}
            submit={(formState) => {
                setTaxSimulator({
                    ...taxSimulator,
                    inputs: {
                        ...taxSimulator.inputs,
                        ...formState.values
                    }
                });
                setStep(4);
                return new Promise((resolve) => resolve());
            }}
        >
            <Typography
                variant={variant === 'public' ? 'h1' : variant === 'standalone' ? 'h2' : 'h4'}
                color="blue"
                center
            >
                Combien avez-vous d'enfants à charge&nbsp;?
            </Typography>
            {variant === 'contained' ? (
                <>
                    <Inputs.Select
                        name="children"
                        className={classes.select}
                        options={options}
                        center
                    />
                    <Buttons.Chevron
                        label="Suivant"
                        color="blue"
                        center
                        submit
                    />
                </>
            ) : (
                <Inputs.RadioButton
                    name="children"
                    classNameLabel={classes.label}
                    spaced
                    options={options}
                />
            )}
        </Form>
    );
}

export default StepChildren;
