export const bi_accounts = [
    {
        balance: 1105.49,
        bi_state: null,
        company_name: 'Société générale',
        id: 1281,
        policy_id: 'EX6713120945700300290000481',
        scope: 'bi',
        title: 'Assurance Vie',
        type: 'LIFE'
    },
    {
        balance: 4983.07,
        bi_state: null,
        company_name: 'Crédit agricole',
        id: 1282,
        policy_id: 'EX6713120945700300290000869',
        scope: 'bi',
        title: 'Compte PERCO',
        type: 'PERCO'
    }
];

export const bi_investments = [
    {
        amount: 174918.15,
        label: 'Agressor PEA',
        share_number: 9.577,
        share_value: 18264.399
    },
    {
        amount: 52475.44,
        label: 'EDGEWOOD L SEL US SEL GW',
        share_number: 3.122,
        share_value: 16808.277
    },
    {
        amount: 10386.68,
        label: 'PEUGEOT',
        share_number: 8.286,
        share_value: 1253.522
    },
    {
        amount: 6313.2,
        label: 'Fonds en euros (Eurossima)',
        share_number: 0.0,
        share_value: 0.0
    },
    {
        amount: 3083.94,
        label: 'EDR India',
        share_number: 6.644,
        share_value: 464.169
    },
    {
        amount: 2705.66,
        label: 'Pioneer Funds Euroland E',
        share_number: 0.848,
        share_value: 3190.637
    }
];

export const bi_transactions = [
    {
        amount: 345.2,
        date: '2021-06-28',
        id: 26824,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 277.68,
        date: '2021-06-24',
        id: 26825,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 216.09,
        date: '2021-06-20',
        id: 26826,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 117.48,
        date: '2021-06-16',
        id: 26827,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 91.3,
        date: '2021-06-12',
        id: 26828,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 225.12,
        date: '2021-06-08',
        id: 26829,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 78.66,
        date: '2021-06-04',
        id: 26830,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 1.06,
        date: '2021-05-28',
        id: 26831,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 81.6,
        date: '2021-05-24',
        id: 26832,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 118.42,
        date: '2021-05-20',
        id: 26833,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 99.6,
        date: '2021-05-16',
        id: 26834,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 101.86,
        date: '2021-05-12',
        id: 26835,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 218.8,
        date: '2021-05-08',
        id: 26836,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 94.8,
        date: '2021-05-04',
        id: 26837,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 257.2,
        date: '2021-04-28',
        id: 26838,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 184.8,
        date: '2021-04-24',
        id: 26839,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 83.76,
        date: '2021-04-20',
        id: 26840,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 16.08,
        date: '2021-04-16',
        id: 26841,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 239.76,
        date: '2021-04-12',
        id: 26842,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 61.05,
        date: '2021-04-08',
        id: 26843,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 78.16,
        date: '2021-04-04',
        id: 26844,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 81.86,
        date: '2021-03-28',
        id: 26845,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 100.08,
        date: '2021-03-24',
        id: 26846,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 57.04,
        date: '2021-03-20',
        id: 26847,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 242.01,
        date: '2021-03-16',
        id: 26848,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 378.96,
        date: '2021-03-12',
        id: 26849,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 81.68,
        date: '2021-03-08',
        id: 26850,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    },
    {
        amount: 196.66,
        date: '2021-03-04',
        id: 26851,
        label: 'Arbitrage',
        status: 'Trait\u00e9',
        type: null
    }
];
