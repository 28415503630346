import React from 'react';
import {Inputs, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: 200,
        margin: 'auto'
    }
}));

function StepRetirementAge() {
    const classes = useStyles();

    return (
        <>
            <Typography
                variant="h3"
                color="blue"
                semibold
                center
            >
                À quel âge souhaitez-vous partir à la retraite&nbsp;?
            </Typography>
            <div className={classes.wrapper}>
                <Inputs.Number
                    name="retirement_age"
                    adornment="ans"
                    right
                />
            </div>
        </>
    );
}

export default StepRetirementAge;
