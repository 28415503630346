import palette from './palette';

export default {
    MuiDataGrid: {
        root: {
            border: `1px solid ${palette.mercury} !important`,
            '& [class$=toolbarContainer]': {
                borderLeft: `6px solid ${palette.blue}`,
                borderBottom: `1px solid ${palette.mercury} !important`,
                minHeight: 70,
                padding: 0,
                paddingLeft: 20
            },
            '& [class$=columnsContainer]': {
                justifyContent: 'center',
                background: palette.purpleLight,
                color: palette.blue
            },
            '& [class$=columnHeaderTitleContainer]': {
                padding: 0
            },
            '& [class$=cell]': {
                borderColor: palette.mercury
            },
            '& [class$=iconSeparator]': {
                display: 'none'
            },
            '& [class$=columnHeader]': {
                outline: 'none'
            }
        }
    },
    MuiTab: {
        root: {
            '@media (max-width: 600px)': {
                flex: 1
            }
        }
    },
    MuiAccordion: {
        root: {
            border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0
            },
            '&:before': {
                display: 'none'
            },
            '&$expanded': {
                margin: 'auto'
            }
        },
        expanded: {}
    },
    MuiAccordionSummary: {
        root: {
            backgroundColor: palette.blue,
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
            marginBottom: 0,
            minHeight: 50,
            '&$expanded': {
                minHeight: 50
            }
        },
        content: {
            '&$expanded': {
                margin: '12px 0'
            },
            '& h6': {
                color: palette.white
            }
        },
        expanded: {}
    },
    MuiAccordionDetails: {
        root: {
            padding: 0
        }
    },
    MuiListItemIcon: {
        root: {
            minWidth: 0
        }
    },
    MuiBadge: {
        colorPrimary: {
            color: palette.green,
            backgroundColor: palette.green
        }
    },
    MuiPaper: {
        elevation3: {
            boxShadow: '0px 4px 5px 0px #00008F33'
        }
    }
};
