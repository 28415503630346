import React, {useState} from 'react';

export const StepperContext = React.createContext({
    stepper: {
        payload: {},
        response: {},
        step: 0,
        stepParent: 0
    },
    setStepper: () => {},
    setResponse: () => {}
});

export const StepperProvider = (props) => {
    const setStepper = (stepper) => {
        setState((state) => ({...state, stepper: stepper}));
    };

    const setResponse = (response) => {
        setState((state) => ({
            ...state,
            stepper: {
                ...state.stepper,
                response: {
                    ...state.stepper.response,
                    ...response
                }
            }
        }));
    };

    const initState = {
        stepper: {
            payload: {},
            response: {},
            step: 0,
            stepParent: 0
        },
        setStepper: setStepper,
        setResponse: setResponse
    };

    const [state, setState] = useState(initState);

    return <StepperContext.Provider value={state}>{props.children}</StepperContext.Provider>;
};
