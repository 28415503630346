import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Icon, Typography} from 'components';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    right: {
        justifyContent: 'flex-end'
    }
}));

function Warn(props) {
    const {message, color, right} = props;
    const classes = useStyles();

    if (typeof props.if === 'boolean' && !props.if) return null;

    return (
        <div className={clsx(classes.root, right && classes.right)}>
            <Icon
                name="axa-icons-danger"
                color={color}
                size={20}
            />
            <Typography
                component="span"
                color={color}
                gutterBottom={false}
            >
                &nbsp;{message}
            </Typography>
        </div>
    );
}

Warn.propTypes = {
    message: PropTypes.string.isRequired,
    color: PropTypes.string,
    if: PropTypes.bool,
    right: PropTypes.bool
};

export default Warn;
