import React, {useCallback, useState} from 'react';
import {Link} from 'react-router-dom';
import {api} from 'api';
import {Structure} from 'components';
import {MenuMobile} from 'layouts/_components';
import {useLoading} from 'hooks';

import {Box, Toolbar} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {sendTag} from 'store/Tags';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

const useStyles = makeStyles((theme) => ({
    navigation: {
        height: 58,
        minHeight: 'initial'
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        width: 42,
        height: 42
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        paddingRight: theme.spacing(1),
        borderRight: `solid 1px ${theme.palette.silver}`
    },
    icon: {
        display: 'flex',
        color: theme.palette.blue
    },
    caption: {
        fontSize: 10,
        whiteSpace: 'noWrap'
    },
    borderRight: {
        borderRight: `solid 1px ${theme.palette.silver}`
    }
}));

function Mobile() {
    const classes = useStyles();

    const {setLoading} = useLoading();
    const [activeMenu, setActiveMenu] = useState();

    const handleLogin = useCallback(() => {
        setLoading(true);
        localStorage.clear();
        sendTag({}, `Label::header::connecter::espace_client`, {nom_etape: null});

        api.get('/login/oauth/authorization/collective')
            .then((response) => {
                window.location.href = response.data;
            })
            .finally(() => setLoading(false));
    }, [setLoading]);

    return (
        <Toolbar
            className={classes.navigation}
            disableGutters
        >
            <Structure.Center className={classes.wrapper}>
                <Link to="/">
                    <img
                        className={classes.logo}
                        src={`${process.env.PUBLIC_URL}/logos/axa.svg`}
                        alt="logo"
                    />
                </Link>

                <Box flexGrow={1} />

                <div
                    className={classes.iconContainer}
                    onClick={handleLogin}
                >
                    <AccountCircleOutlinedIcon
                        fontSize={'large'}
                        className={classes.icon}
                    />
                </div>

                <MenuMobile
                    className={classes.spaceLeft}
                    name="navigation"
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    nav={[
                        {
                            label: 'Mon épargne entreprise en bref',
                            url: '/'
                        },
                        {
                            label: 'Showroom épargne retraite',
                            url: '/'
                        },
                        {
                            label: 'En savoir plus en vidéos',
                            url: '/'
                        },
                        {
                            label: 'À la une cette semaine',
                            url: '/'
                        }
                    ]}
                />
            </Structure.Center>
        </Toolbar>
    );
}

export default Mobile;
