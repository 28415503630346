import React, {useState} from 'react';

export const RoboAdvisorContext = React.createContext({
    entities: [],
    setEntities: () => {}
});

export const RoboAdvisorProvider = (props) => {
    const setEntities = (entities) => {
        setState({...state, entities});
        localStorage.setItem('EP_robo_entities', JSON.stringify(entities));
    };

    const initState = {
        entities: localStorage.getItem('EP_robo_entities') || [],
        setEntities: setEntities
    };

    const [state, setState] = useState(initState);

    return <RoboAdvisorContext.Provider value={state}>{props.children}</RoboAdvisorContext.Provider>;
};
