const parseQueryArgs = (url) => {
    if (!url) url = window.location.search;
    let query = url.substr(1);
    let result = {};
    query.split('&').forEach(function (part) {
        let item = part.split('=');
        result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
};

export default parseQueryArgs;
