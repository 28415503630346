function requestFullScreen(element) {
    if (!element) return;

    if (element.requestFullscreen) element.requestFullscreen();

    if (element.webkitRequestFullScreen) element.webkitRequestFullScreen();

    if (element.mozRequestFullScreen) element.mozRequestFullScreen();
}

export default requestFullScreen;
