const regroupAccountsByLabel = (accounts) =>
    Object.values(
        accounts.reduce(
            (acc, cur) => (
                // eslint-disable-next-line
                (acc[cur.label] = acc[cur.label] || {...cur, balance: 0}), (acc[cur.label].balance += cur.balance), acc
            ),
            {}
        )
    );

export default regroupAccountsByLabel;
