import React from 'react';
import {Buttons, Structure} from 'components';
import {fastapi} from 'api';
import {StepHeirs, StepLifeInsurance, StepPatrimony} from './components';
import {SanitizeLifeInsurances} from 'macros/TransmissionStepper/components/3-StepProfile/components/3-StepLifeInsurance/StepLifeInsurance';
import {makeStyles} from '@material-ui/styles';
import {useTransmission, useUser} from 'hooks';
import {sendTag} from 'store/Tags';
import {getTransmissionDatalayer} from '../../../../blocks/TransmissionResults/utils';
import {sanitizePatrimony} from './components/2-StepPatrimony/StepPatrimony';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(2),
        backgroundImage: ``,
        backgroundPosition: 'center right',
        backgroundSize: 'cover',
        backgroundColor: 'transparent'
    },
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 100
    },
    content: {
        maxWidth: 600,
        padding: 20,
        marginTop: 30,
        background: theme.palette.white
    },
    intro: {
        transition: 'background-color 0.2s ease-in'
    },
    flex: {
        display: 'flex'
    },
    padding: {
        padding: theme.spacing(0, 1)
    }
}));

function StepProfile({step, setStep, initialStep}) {
    const classes = useStyles();
    const {user, setUser} = useUser();
    const {setTransmission} = useTransmission();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Structure.Stepper
                    firstAction={
                        <Buttons.Default
                            color="blueAlpha"
                            label="Précédent"
                            onClick={() => setStep(step - 1)}
                            previous
                        />
                    }
                    lastAction={
                        <Buttons.Default
                            color="orange"
                            label="Valider"
                            next
                        />
                    }
                >
                    <StepHeirs
                        nestedKey="heirs"
                        schema={{
                            family_situation: (value, attributes) =>
                                attributes?.has_testament === true
                                    ? null
                                    : {
                                          presence: {
                                              allowEmpty: false,
                                              message: 'Veuillez sélectionner une option de rente'
                                          }
                                      },
                            children: (value, attributes) =>
                                attributes?.has_testament === true
                                    ? null
                                    : {
                                          presence: {
                                              allowEmpty: false,
                                              message: "Veuillez renseigner le nombre d'enfant dans le foyer"
                                          }
                                      },
                            siblings: (value, attributes) =>
                                attributes?.has_testament === true ||
                                parseInt(attributes?.children) > 0 ||
                                attributes.family_situation === 'spouse'
                                    ? null
                                    : {
                                          presence: {
                                              allowEmpty: false,
                                              message: 'Veuillez renseigner le nombre de frère et soeur'
                                          }
                                      },
                            spouse_age: (value, attributes) =>
                                attributes?.has_testament === true ||
                                (parseInt(attributes?.children) !== 0 &&
                                (attributes.has_same_parents === true || attributes.has_spouse_donation === true)
                                    ? {
                                          presence: {
                                              allowEmpty: false,
                                              message: "Veuillez renseigner l'age de votre conjoint"
                                          },
                                          numericality: {
                                              greaterThanOrEqualTo: 18,
                                              message: 'Veuillez saisir un âge supérieur ou égal à 18ans '
                                          }
                                      }
                                    : null)
                        }}
                        values={{
                            has_testament: false,
                            parents: '2',
                            family_situation: ''
                        }}
                        submit={(stepper) =>
                            fastapi
                                .post('/simulators/transmission/simulation', {
                                    user_id: user.id,
                                    heirs: {},
                                    patrimony: {},
                                    life_insurances: {}
                                })
                                .then((response) => {
                                    if (
                                        stepper.payload?.heirs?.has_testament === true ||
                                        (stepper.payload?.heirs?.children === '0' &&
                                            stepper.payload?.heirs?.family_situation !== 'spouse' &&
                                            stepper.payload?.heirs?.siblings === '0')
                                    ) {
                                        setTransmission({
                                            heirs: {},
                                            patrimonies_amount: {},
                                            life_insurances_amount: {},
                                            heirs_result: {}
                                        });
                                    }
                                    setUser({...user, actions: user.actions ? user.actions + 1 : 1});
                                    let datalayer = getTransmissionDatalayer(stepper.payload);
                                    sendTag(user, 'mon_patrimoine', datalayer);
                                })
                        }
                        isStepper={true}
                    />
                    <StepPatrimony
                        nestedKey="patrimony"
                        schema={{
                            home_percentage: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    lessThanOrEqualTo: 100,
                                    message: 'Veuillez saisir un pourcentage entre 0% et 100%'
                                }
                            }
                        }}
                        values={{
                            has_donate: false,
                            owner_home: false,
                            home_value: 0,
                            home_percentage: 100
                        }}
                        showIf={(stepper) =>
                            stepper.payload?.heirs?.has_testament !== true &&
                            !(
                                stepper.payload?.heirs?.children === '0' &&
                                stepper.payload?.heirs?.family_situation !== 'spouse' &&
                                stepper.payload?.heirs?.siblings === '0'
                            )
                        }
                        submit={(stepper) =>
                            fastapi
                                .post('/services/mail-handler/load-transmission', {
                                    user_id: user.id,
                                    advisor_email: user?.axa_advisor?.email
                                })
                                .then(() => {
                                    let datalayer = getTransmissionDatalayer(stepper.payload);
                                    sendTag(user, 'mon_assurance_vie', datalayer);
                                })
                        }
                        isStepper={true}
                    />
                    <StepLifeInsurance
                        nestedKey="life_insurances"
                        showIf={(stepper) =>
                            stepper.payload?.heirs?.has_testament !== true &&
                            !(
                                stepper.payload?.heirs?.children === '0' &&
                                stepper.payload?.heirs?.family_situation !== 'spouse' &&
                                stepper.payload?.heirs?.siblings === '0'
                            )
                        }
                        values={{
                            has_life_insurance:
                                user.accounts.filter((account) => account.type === 'LIFE_INSURANCE').length > 0,
                            ...Object.assign(
                                {},
                                ...user.accounts
                                    .filter((account) => account.type === 'LIFE_INSURANCE')
                                    .map((axaLifeInsurance, index) => ({
                                        [`${index}-lifeInsurance-name`]: `${axaLifeInsurance.title}/${axaLifeInsurance.company_name}`,
                                        [`${index}-lifeInsurance-amount`]: axaLifeInsurance?.balance,
                                        [`${index}-lifeInsurance-beneficiary`]: 'spouse',
                                        [`${index}-lifeInsurance-is_axa`]: true
                                    }))
                            )
                        }}
                        submit={(stepper) =>
                            fastapi
                                .post('/simulators/transmission/simulation', {
                                    user_id: user.id,
                                    heirs: stepper.payload.heirs,
                                    patrimony: sanitizePatrimony(stepper.payload.patrimony),
                                    life_insurances: SanitizeLifeInsurances(stepper.payload.life_insurances)
                                })
                                .then((response) => {
                                    setTransmission({
                                        ...response.data,
                                        heirs: stepper.payload?.heirs,
                                        life_insurances: SanitizeLifeInsurances(stepper.payload.life_insurances),
                                        patrimony: sanitizePatrimony(stepper.payload.patrimony)
                                    });
                                    let datalayer = getTransmissionDatalayer(stepper.payload);
                                    sendTag(user, 'ma_situation', datalayer);
                                })
                        }
                        isStepper={true}
                        initialLifeInsurances={Object.assign(
                            {},
                            ...user.accounts
                                .filter((account) => account.type === 'LIFE_INSURANCE')
                                .map((axaLifeInsurance, index) => ({
                                    [`${index}-lifeInsurance`]: {
                                        name: `${axaLifeInsurance.title}/${axaLifeInsurance.company_name}`,
                                        amount: axaLifeInsurance?.balance,
                                        beneficiary: 'spouse',
                                        is_axa: true
                                    }
                                }))
                        )}
                    />
                </Structure.Stepper>
            </div>
        </div>
    );
}

export default StepProfile;
