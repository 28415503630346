import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useForm} from 'hooks';

import {makeStyles} from '@material-ui/styles';
import {Typography} from 'components';

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    center: {
        justifyContent: 'center'
    },
    spaced: {
        justifyContent: 'space-evenly'
    },
    input: {
        position: 'absolute',
        overflow: 'hidden',
        width: 0.1,
        height: 0.1,
        opacity: 0,
        zIndex: -1,
        '&:checked + label': {
            background: theme.palette.blue,
            color: theme.palette.white
        },
        '&:focus + label': {
            border: `dashed 1px ${theme.palette.blue}`
        },
        '&:focus&:checked + label': {
            background: theme.palette.blue,
            color: theme.palette.white,
            border: `dashed 1px ${theme.palette.blue}`
        }
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        background: theme.palette.white,
        color: theme.palette.blue,
        border: `solid 1px ${theme.palette.blue}`,
        borderRadius: 12,
        cursor: 'pointer',
        margin: 5,
        minWidth: 70
    },
    variant: {
        fontSize: 16,
        borderRadius: 4,
        borderColor: `${theme.palette.scorpion} !important`,
        color: `${theme.palette.scorpion} !important`,
        '&:focus&:checked + label': {
            background: `${theme.palette.white} !important`,
            fontWeight: 'bold !important',
            border: `solid 2px ${theme.palette.blue} !important`
        },
        '&:checked + label': {
            background: theme.palette.white,
            fontWeight: 'bold !important',
            border: `solid 2px ${theme.palette.blue} !important`
        },
        '&:focus + label': {
            border: `solid 2px ${theme.palette.blue}`
        },
        '&:hover + label': {
            border: `solid 2px ${theme.palette.blue} !important`,
            fontWeight: 'bold !important'
        }
    }
}));

function RadioButton(props) {
    const {className, classNameLabel, options, name, label, labelColor, center, spaced, variant, ...rest} = props;
    const {formState, setFormState} = useForm();

    const classes = useStyles();

    const handleChange = (event) => {
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [name]: true
            }
        });
    };

    return (
        <div className={clsx(classes.root, className)}>
            <Typography
                className="full-width"
                color={labelColor}
                gutterBottom={false}
                variant="caption"
            >
                {label}
            </Typography>
            <div
                className={clsx({
                    [classes.wrapper]: true,
                    [classes.center]: center,
                    [classes.spaced]: spaced
                })}
            >
                {options.map((option) => (
                    <React.Fragment key={`radio-${option.value}`}>
                        <input
                            className={clsx(classes.input, variant && classes.variant)}
                            id={`radio-${option.value}`}
                            type="radio"
                            name={name}
                            onClick={handleChange}
                            value={option.value}
                            defaultChecked={formState.values[name] === option.value}
                            {...rest}
                        />
                        <label
                            className={clsx(classes.label, classNameLabel, variant && classes.variant)}
                            htmlFor={`radio-${option.value}`}
                        >
                            {option.icon}
                            {option.label}
                        </label>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

RadioButton.propTypes = {
    className: PropTypes.string,
    classNameLabel: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    labelColor: PropTypes.string,
    center: PropTypes.bool,
    spaced: PropTypes.bool,
    variant: PropTypes.oneOf(['scorpion'])
};

export default RadioButton;
