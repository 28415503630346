import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {fastapi} from 'api';
import {Buttons, Form, Grid, Inputs, Typography} from 'components';
import {sendTag} from 'store/Tags';
import {json} from 'utils';

import {useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/styles';
import {useUser} from "hooks";

function SubStepForgotPasswordTwo({setStep, secretQuestion, sessionId}) {
    const theme = useTheme();
    const {user} = useUser();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <>
            <Form
                schema={{
                    secret_answer: {
                        presence: {
                            allowEmpty: false,
                            message: 'Veuillez renseigner votre réponse secrète'
                        }
                    },
                    birth_date: {
                        presence: {
                            allowEmpty: false,
                            message: 'Veuillez renseigner votre date de naissance'
                        }
                    }
                }}
                submit={(formState, setFormState) =>
                    fastapi
                        .post('/simulators/marel/forgot-password/second', {
                            ...formState.values,
                            session_id: sessionId,
                            identifier: json.parse(sessionStorage.getItem('EP_M@REL_password')).identifier
                        })
                        .then(() => {
                            setStep(2);
                            sendTag(user, 'cnav::forgotpassword');
                        }) // SubStepForgotPasswordResult
                        .catch(() =>
                            setFormState({
                                ...formState,
                                errors: {
                                    ...formState.errors,
                                    birth_date: [
                                        'La réponse à la question secrète ou la date de naissance est invalide.'
                                    ]
                                }
                            })
                        )
                }
                values={{
                    secret_answer: '',
                    birth_date: ''
                }}
                variant="transparent"
            >
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        sm={6}
                        xs={12}
                    >
                        <Inputs.Text
                            name="secret_answer"
                            label={`${secretQuestion} *`}
                            disableCleave
                        />
                        <Inputs.Datepicker
                            name="birth_date"
                            label="Saisissez votre date de naissance *"
                        />
                    </Grid>
                </Grid>
                <Typography>
                    Les champs avec un astérisque sont
                    <Typography
                        semibold
                        component="span"
                    >
                        {' '}
                        obligatoires.
                    </Typography>
                </Typography>
                <Buttons.Default
                    className={clsx(isDesktop && 'toLeft')}
                    label="Étape précédente"
                    color="blueAlpha"
                    previous
                    center
                    onClick={() => setStep(0)} // SubStepForgotPasswordOne
                />
                <Buttons.Default
                    className={clsx(isDesktop && 'toRight')}
                    label="Étape suivante"
                    color="green"
                    next
                    center
                    submit
                />
            </Form>
        </>
    );
}

SubStepForgotPasswordTwo.propTypes = {
    secretQuestion: PropTypes.string.isRequired
};

export default SubStepForgotPasswordTwo;
