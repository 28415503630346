import React from 'react';
import clsx from 'clsx';
import {Form, Info, Inputs, Typography} from 'components';
import {useTaxSimulator} from 'hooks';

import {amount} from 'utils';

import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    disabled: {
        background: '#c2c2c2'
    },
    border: {
        height: '100%',
        minHeight: 150,
        border: `solid 2px ${theme.palette.grayLight}`,
        padding: theme.spacing(2, 1)
    },
    amount: {
        padding: theme.spacing(2, 0, 1)
    },
    form: {
        display: 'inline-block',
        width: 90
    },
    select: {
        height: 28,
        fontSize: 16
    },
    button: {
        margin: theme.spacing(1)
    },
    text: {
        lineHeight: 1
    }
}));

function TabAnnuity() {
    const classes = useStyles();

    const {taxSimulator, setTaxSimulator, variant} = useTaxSimulator();

    const enabled = variant === 'contained' ? !!taxSimulator.response.retirement_annuity : true;

    return (
        <div
            className={clsx({
                [classes.border]: variant === 'contained',
                [classes.disabled]: !enabled
            })}
        >
            <Typography
                variant="h4"
                color={enabled ? 'blue' : 'black'}
                center
            >
                Vous bénéficierez de&nbsp;
                {enabled && (
                    <Info
                        hash="disclaimer_tax_3"
                        title="Information"
                        color="blue"
                    >
                        Contrat de retraite supplémentaire Article 83 et PER. Cette estimation est calculé sur la base
                        du versement indiquée jusqu'à la retraite. Elle tient compte d'une hypothèse de taux de
                        rendement de 2%. Elle ne prend pas en compte vos cotisations obligatoires. La sortie en capital
                        n'est possible que pour les contrats PER.
                    </Info>
                )}
            </Typography>
            <Typography
                className={classes.amount}
                variant="h2"
                color={enabled ? 'blue' : 'black'}
                semibold
                center
            >
                {amount.format(taxSimulator.response.retirement_annuity, 0, '€ / an')}
            </Typography>
            <Typography
                variant="h5"
                color={enabled ? 'blue' : 'black'}
                center
            >
                de retraite en plus
                <br />à partir de&nbsp;
                {enabled ? (
                    <Form
                        className={classes.form}
                        dynamic
                        values={{
                            retirement_age: '65'
                        }}
                        schema={{
                            retirement_age: {}
                        }}
                        submit={(formState) => {
                            setTaxSimulator({
                                ...taxSimulator,
                                inputs: {
                                    ...taxSimulator.inputs,
                                    ...formState.values
                                }
                            });
                        }}
                    >
                        <Inputs.Select
                            className={classes.select}
                            name="retirement_age"
                            options={[60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70].map((retirement_age) => ({
                                label: `${retirement_age} ans`,
                                value: retirement_age
                            }))}
                            disableSpacing
                        />
                    </Form>
                ) : (
                    ' 65 ans'
                )}
            </Typography>
            {enabled && (
                <Typography
                    className={classes.text}
                    center
                >
                    Ou d'un capital de&nbsp;
                    <Typography
                        component="span"
                        semibold
                    >
                        {amount.format(taxSimulator.response.retirement_capital, 0)}
                    </Typography>
                    &nbsp; si le contrat de votre entreprise est un PER
                </Typography>
            )}
        </div>
    );
}

export default TabAnnuity;
