const config = {
    retirement: 'Retraite',
    owner: 'Immobilier',
    family: 'Familiaux',
    business: 'Professionnels',
    hardblows: 'Coups durs',
    other: 'Autres'
};

const translateWithdrawCase = (withdrawCase, capitalize = true) => {
    if (Object.keys(config).includes(withdrawCase))
        return capitalize ? config[withdrawCase] : config[withdrawCase].toLowerCase();
    return withdrawCase;
};

export default translateWithdrawCase;
