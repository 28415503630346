export const mrm_simulations = [
    {
        policy_id: '2726002000300/00507',
        response: {
            tauxRendementRetenu: 2,
            tauxRevalorisationGlobal: 2.0,
            codeProduit: '',
            capital: 14694.297,
            resultatPourChaqueTaux: {
                resultatTauxTechnique: [
                    {
                        optionReversion: {
                            rentesParAge: {
                                libelleOptionReversion: 'Rente sans annuit\u00e9 garantie',
                                renteParAge: [
                                    {
                                        age: 60,
                                        rente: 450.2192179030931
                                    }
                                ]
                            },
                            valeurTaux: 0,
                            rentesAnnuitesGaranties: {
                                renteAnnuitesGaranties: [
                                    {
                                        rente: 447.9793212966101,
                                        annuitesGaranties: 'Rente avec annuités garanties 10 ans'
                                    },
                                    {
                                        rente: 445.76160188425064,
                                        annuitesGaranties: 'Rente avec annuités garanties 15 ans'
                                    }
                                ]
                            },
                            indiceTauxTechnique: 0
                        },
                        horsOption: {
                            libelleHorsOption: 'Rente viag\u00e8re simple',
                            rentesParPalier: {
                                renteParPalier: [
                                    {
                                        palier: 'Avant 70 ans',
                                        rente: '328.6299917563884217'
                                    },
                                    {
                                        palier: '70 - 75 ans',
                                        rente: '+20%'
                                    },
                                    {
                                        palier: 'Apr\u00e8s 75 ans',
                                        rente: '+20%'
                                    }
                                ]
                            },
                            valeurTaux: 0,
                            rentesAnnuitesGaranties: {
                                renteAnnuitesGaranties: [
                                    {
                                        annuitesGaranties: 'Rente avec annuit\u00e9s garanties 5 ans',
                                        rente: 420.61879914658283
                                    },
                                    {
                                        annuitesGaranties: 'Rente avec annuit\u00e9s garanties 10 ans',
                                        rente: 419.2633507213213
                                    },
                                    {
                                        annuitesGaranties: 'Rente avec annuit\u00e9s garanties 15 ans',
                                        rente: 416.643793920443
                                    }
                                ]
                            },
                            rentesParAge: {
                                libelleOptionReversion: null,
                                renteParAge: [
                                    {
                                        rente: 421.04563187245054,
                                        age: 60
                                    },
                                    {
                                        rente: 429.46654450989956,
                                        age: 61
                                    },
                                    {
                                        rente: 438.05587540009753,
                                        age: 62
                                    },
                                    {
                                        rente: 446.81699290809956,
                                        age: 63
                                    },
                                    {
                                        rente: 455.7533327662615,
                                        age: 64
                                    },
                                    {
                                        rente: 464.86839942158673,
                                        age: 65
                                    },
                                    {
                                        rente: 474.1657674100185,
                                        age: 66
                                    },
                                    {
                                        rente: 483.64908275821887,
                                        age: 67
                                    },
                                    {
                                        rente: 493.32206441338326,
                                        age: 68
                                    },
                                    {
                                        rente: 503.1885057016509,
                                        age: 69
                                    },
                                    {
                                        rente: 513.252275815684,
                                        age: 70
                                    },
                                    {
                                        rente: 523.5173213319977,
                                        age: 71
                                    },
                                    {
                                        rente: 533.9876677586376,
                                        age: 72
                                    },
                                    {
                                        rente: 544.6674211138104,
                                        age: 73
                                    },
                                    {
                                        rente: 555.5607695360866,
                                        age: 74
                                    },
                                    {
                                        rente: 566.6719849268084,
                                        age: 75
                                    },
                                    {
                                        rente: 578.0054246253445,
                                        age: 76
                                    },
                                    {
                                        rente: 589.5655331178515,
                                        age: 77
                                    },
                                    {
                                        rente: 601.3568437802085,
                                        age: 78
                                    },
                                    {
                                        rente: 613.3839806558126,
                                        age: 79
                                    },
                                    {
                                        rente: 625.6516602689289,
                                        age: 80
                                    },
                                    {
                                        rente: 638.1646934743075,
                                        age: 81
                                    },
                                    {
                                        rente: 650.9279873437937,
                                        age: 82
                                    },
                                    {
                                        rente: 663.9465470906696,
                                        age: 83
                                    },
                                    {
                                        rente: 677.225478032483,
                                        age: 84
                                    },
                                    {
                                        rente: 690.7699875931327,
                                        age: 85
                                    },
                                    {
                                        rente: 704.5853873449953,
                                        age: 86
                                    },
                                    {
                                        rente: 718.6770950918952,
                                        age: 87
                                    },
                                    {
                                        rente: 733.0506369937332,
                                        age: 88
                                    },
                                    {
                                        rente: 747.7116497336078,
                                        age: 89
                                    },
                                    {
                                        rente: 762.66588272828,
                                        age: 90
                                    },
                                    {
                                        rente: 777.9192003828456,
                                        age: 91
                                    },
                                    {
                                        rente: 793.4775843905024,
                                        age: 92
                                    },
                                    {
                                        rente: 809.3471360783126,
                                        age: 93
                                    },
                                    {
                                        rente: 825.5340787998788,
                                        age: 94
                                    },
                                    {
                                        rente: 842.0447603758765,
                                        age: 95
                                    }
                                ]
                            },
                            indiceTauxTechnique: 0
                        }
                    }
                ]
            },
            montantAconvertirEnRenteProjetee: 14694.297,
            montantElligibleSortieCapitalProjetee: 0.0
        }
    },
    {
        policy_id: '2720625000000/00001',
        response: {
            tauxRendementRetenu: 2,
            tauxRevalorisationGlobal: 2.0,
            codeProduit: '',
            capital: 209.85,
            resultatPourChaqueTaux: {
                resultatTauxTechnique: [
                    {
                        optionReversion: {
                            rentesParAge: {
                                libelleOptionReversion: 'Rente sans annuit\u00e9 garantie',
                                renteParAge: [
                                    {
                                        age: 60,
                                        rente: 12.8467846
                                    }
                                ]
                            },
                            valeurTaux: 0,
                            rentesAnnuitesGaranties: {
                                renteAnnuitesGaranties: [
                                    {
                                        rente: 8.9793212966101,
                                        annuitesGaranties: 'Rente avec annuités garanties 10 ans'
                                    },
                                    {
                                        rente: 7.76160188425064,
                                        annuitesGaranties: 'Rente avec annuités garanties 15 ans'
                                    }
                                ]
                            },
                            indiceTauxTechnique: 0
                        },
                        horsOption: {
                            libelleHorsOption: 'Rente viag\u00e8re simple',
                            rentesParPalier: {
                                renteParPalier: [
                                    {
                                        palier: 'Avant 70 ans',
                                        rente: '4.6931815635738212'
                                    },
                                    {
                                        palier: '70 - 75 ans',
                                        rente: '+20%'
                                    },
                                    {
                                        palier: 'Apr\u00e8s 75 ans',
                                        rente: '+20%'
                                    }
                                ]
                            },
                            valeurTaux: 0,
                            rentesAnnuitesGaranties: {
                                renteAnnuitesGaranties: [
                                    {
                                        annuitesGaranties: 'Rente avec annuit\u00e9s garanties 5 ans',
                                        rente: 6.006878382879454
                                    },
                                    {
                                        annuitesGaranties: 'Rente avec annuit\u00e9s garanties 10 ans',
                                        rente: 5.987521155239293
                                    },
                                    {
                                        annuitesGaranties: 'Rente avec annuit\u00e9s garanties 15 ans',
                                        rente: 5.950111131836042
                                    }
                                ]
                            },
                            rentesParAge: {
                                libelleOptionReversion: null,
                                renteParAge: [
                                    {
                                        rente: 6.0129740026646905,
                                        age: 60
                                    },
                                    {
                                        rente: 6.133233482717984,
                                        age: 61
                                    },
                                    {
                                        rente: 6.255898152372343,
                                        age: 62
                                    },
                                    {
                                        rente: 6.381016115419792,
                                        age: 63
                                    },
                                    {
                                        rente: 6.508636437728186,
                                        age: 64
                                    },
                                    {
                                        rente: 6.63880916648275,
                                        age: 65
                                    },
                                    {
                                        rente: 6.771585349812406,
                                        age: 66
                                    },
                                    {
                                        rente: 6.9070170568086535,
                                        age: 67
                                    },
                                    {
                                        rente: 7.045157397944827,
                                        age: 68
                                    },
                                    {
                                        rente: 7.1860605459037235,
                                        age: 69
                                    },
                                    {
                                        rente: 7.329781756821799,
                                        age: 70
                                    },
                                    {
                                        rente: 7.476377391958234,
                                        age: 71
                                    },
                                    {
                                        rente: 7.625904939797399,
                                        age: 72
                                    },
                                    {
                                        rente: 7.778423038593347,
                                        age: 73
                                    },
                                    {
                                        rente: 7.933991499365214,
                                        age: 74
                                    },
                                    {
                                        rente: 8.092671329352518,
                                        age: 75
                                    },
                                    {
                                        rente: 8.254524755939569,
                                        age: 76
                                    },
                                    {
                                        rente: 8.419615251058362,
                                        age: 77
                                    },
                                    {
                                        rente: 8.588007556079528,
                                        age: 78
                                    },
                                    {
                                        rente: 8.759767707201119,
                                        age: 79
                                    },
                                    {
                                        rente: 8.934963061345142,
                                        age: 80
                                    },
                                    {
                                        rente: 9.113662322572043,
                                        age: 81
                                    },
                                    {
                                        rente: 9.295935569023486,
                                        age: 82
                                    },
                                    {
                                        rente: 9.481854280403956,
                                        age: 83
                                    },
                                    {
                                        rente: 9.671491366012036,
                                        age: 84
                                    },
                                    {
                                        rente: 9.864921193332275,
                                        age: 85
                                    },
                                    {
                                        rente: 10.062219617198922,
                                        age: 86
                                    },
                                    {
                                        rente: 10.2634640095429,
                                        age: 87
                                    },
                                    {
                                        rente: 10.468733289733759,
                                        age: 88
                                    },
                                    {
                                        rente: 10.678107955528434,
                                        age: 89
                                    },
                                    {
                                        rente: 10.891670114639004,
                                        age: 90
                                    },
                                    {
                                        rente: 11.109503516931783,
                                        age: 91
                                    },
                                    {
                                        rente: 11.331693587270419,
                                        age: 92
                                    },
                                    {
                                        rente: 11.558327459015826,
                                        age: 93
                                    },
                                    {
                                        rente: 11.789494008196144,
                                        age: 94
                                    },
                                    {
                                        rente: 12.025283888360068,
                                        age: 95
                                    }
                                ]
                            },
                            indiceTauxTechnique: 0
                        }
                    }
                ]
            },
            montantAconvertirEnRenteProjetee: 209.85,
            montantElligibleSortieCapitalProjetee: 0.0
        }
    }
];
