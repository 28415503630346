function format(number, precision = 2, currency = '€', dec_point = ',', thousands_sep = ' ', currency_sep = ' ') {
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+precision) ? 0 : Math.abs(precision),
        sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
        dec = typeof dec_point === 'undefined' ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            let k = Math.pow(10, prec);
            return Math.round(n * k) / k;
        },
        s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return currency ? s.join(dec) + currency_sep + currency : s.join(dec);
}

export default format;
