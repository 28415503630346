import React from 'react';
import {Alert, Buttons, Typography} from 'components';
import {makeStyles} from '@material-ui/styles';
import {sendTag} from 'store/Tags';
import {useUser} from 'hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(3),
        backgroundImage: ``,
        backgroundPosition: 'center right',
        backgroundSize: 'cover',
        backgroundColor: 'transparent'
    },
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 100
    },
    content: {
        maxWidth: 600,
        padding: 20,
        marginTop: 5,
        background: theme.palette.white
    },
    intro: {
        transition: 'background-color 0.2s ease-in'
    },
    flex: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            '& button': {
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        }
    },
    padding: {
        padding: theme.spacing(0, 1)
    }
}));

function StepInformation({step, setStep}) {
    const classes = useStyles();
    const {user} = useUser();

    return (
        <div className={classes.root}>
            <Typography
                variant="h3"
                semibold
                color="blue"
            >
                Ma Transmission 360
            </Typography>
            <div className={classes.content}>
                <div className="flex">
                    <Typography
                        variant="h4"
                        semibold
                    >
                        Avant de commencer, quelques informations sur ce simulateur simplifié
                    </Typography>
                </div>

                <Alert
                    message={
                        <>
                            {' '}
                            Votre simulation est réalisée à partir des données que vous saisirez, des données &
                            simulateurs du service public pour le calcul de la fiscalité applicable à la succession et
                            du simulateur AXA pour la fiscalité de l’Assurance vie. L’absence de données peut influencer
                            le résultat de votre simulation.
                            <br />
                            <br />
                            Dans sa version actuelle, le simulateur ne prend pas en compte, l’adoption simple, les biens
                            ayant une fiscalité successorale spécifique, les contrats d’assurance-vie co-souscrits ou
                            démembrés ainsi que les contrats retraite non liquidés. Ce simulateur se base sur la
                            fiscalité en vigueur à la date de la simulation.
                            <br />
                            <br />
                            Le résultat de cette simulation est délivré en l’état de la règlementation à la date de
                            simulation et des informations détenues : il présente un caractère indicatif, provisoire et
                            non contractuel.
                            <br />
                            <br />
                            Nous vous informons que les données renseignées seront uniquement utilisées dans ce
                            simulateur et conservées pendant 2 ans, elles ne seront pas transmises sans un accord
                            explicite de votre part.
                        </>
                    }
                    severity="info"
                />
                <div className={classes.flex}>
                    <Buttons.Default
                        color="blue"
                        label="Je refuse"
                        previous
                        onClick={() => {
                            setStep(step - 1);
                        }}
                    />
                    <div className="flexGrow" />
                    <Buttons.Default
                        color="green"
                        label="J'ai compris"
                        next
                        onClick={() => {
                            setStep(step + 1);
                            sendTag(user, 'mes_heritiers');
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default StepInformation;
