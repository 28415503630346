const toBase64 = (file, keepMimeType = true) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(
            keepMimeType ? reader.result : reader.result.split('base64,')[1]
        );
        reader.onerror = (error) => reject(error);
    });

export default toBase64;